import { ReactElement } from "react-markdown/lib/react-markdown";

export const IconBase = ({
  width,
  height,
  fill,
  children,
}: {
  width?: number;
  height?: number;
  fill?: string;
  children: ReactElement;
}) => {
  return (
    <svg
      width={`${width || 24}`}
      height={`${height || 24}`}
      viewBox={`0 0 ${width || 24} ${height || 24}`}
      fill={`${fill || "none"}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};
