import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ScrollRestoration, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { useTranslation } from "react-i18next";
import { fetchUser } from "./apiDrivers/user";
import { useTelegram } from "./hooks/useTelegram";
import "./null.css";
import { Layout } from "./pages/Layout";
import { SetUserAction } from "./store/userStore";
import { isResponseError, setCookieLanguage } from "./utils";
import { UserLanguageEnum } from "./client";
import { LoaderPageCustom } from "./components/UI/Vendors/Mantine";
import { RouterPath } from "./definitions";
import { OnboardingPage } from "./pages/OnboardingPage";
import { useIsMobile } from "./hooks/useIsMobile";
import { Landing } from "./pages/Landing";

function App() {
  const { user, initData, isExpanded, expandMethod, isAdmin } = useTelegram();

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  const [onboardingStartNumber, setOnboardingStart] = useState(-1);

  if (!isExpanded) {
    expandMethod();
  }

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (!initData) {
      if (process.env.NODE_ENV !== "development") {
        // navigate(RouterPath.auth.index);
      }
    }
  }, [initData]);

  const userFetchQuery = useQuery([`user`, initData, user, isAdmin], fetchUser, {
    onError: (error: unknown) => {
      if (isResponseError(error) && error.response?.status === 404) {
        // navigate(RouterPath.auth.index);
      } else {
        navigate(RouterPath.error, { state: { error: JSON.stringify(error) } });
      }
    },
  });

  useEffect(() => {
    const globalLocation = window.location;
    if (globalLocation.pathname && location.pathname && location.pathname !== globalLocation.pathname) {
      location.pathname = globalLocation.pathname;
      navigate(location.pathname);
    }
  }, []);

  useEffect(() => {
    if (initData && userFetchQuery.data) {
      if (userFetchQuery.data.registered) {
        navigate(RouterPath.main.index);
      } else {
        setOnboardingStart(0);
      }
    } else {
      setOnboardingStart(0);
    }
  }, [initData, userFetchQuery.data]);

  useEffect(() => {
    if (userFetchQuery.data) {
      dispatch(SetUserAction(userFetchQuery.data));

      const lng = userFetchQuery.data.language as UserLanguageEnum;

      if (i18n.language !== lng) {
        i18n.changeLanguage(lng).then(() => {
          setCookieLanguage(lng);
        });
      }
    }
  }, [userFetchQuery.data]);

  if (!isMobile) {
    return <Landing />;
  }

  const error = isResponseError(userFetchQuery.error) ? userFetchQuery.error : undefined;

  if (
    (userFetchQuery.isLoading && error && error.response?.status !== 404 && initData) ||
    onboardingStartNumber === -1 ||
    userFetchQuery.isLoading
  ) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }

  return (
    <div className="App">
      <Layout />
      {/* {location.pathname === "/" && <WelcomePage></WelcomePage>}OnboardingPage */}
      {location.pathname === "/" && <OnboardingPage startStepNumber={onboardingStartNumber}></OnboardingPage>}
      <ScrollRestoration />
    </div>
  );
}

export default App;
