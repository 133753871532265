/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSetAppNamePostRequest
 */
export interface UserSetAppNamePostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserSetAppNamePostRequest
     */
    initData: string;
    /**
     * 
     * @type {string}
     * @memberof UserSetAppNamePostRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserSetAppNamePostRequest
     */
    appName: string;
}

/**
 * Check if a given object implements the UserSetAppNamePostRequest interface.
 */
export function instanceOfUserSetAppNamePostRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "initData" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "appName" in value;

    return isInstance;
}

export function UserSetAppNamePostRequestFromJSON(json: any): UserSetAppNamePostRequest {
    return UserSetAppNamePostRequestFromJSONTyped(json, false);
}

export function UserSetAppNamePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSetAppNamePostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initData': json['initData'],
        'userId': json['userId'],
        'appName': json['appName'],
    };
}

export function UserSetAppNamePostRequestToJSON(value?: UserSetAppNamePostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initData': value.initData,
        'userId': value.userId,
        'appName': value.appName,
    };
}

