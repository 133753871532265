/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FoodOrderLookupRestaurant
 */
export interface FoodOrderLookupRestaurant {
    /**
     * 
     * @type {string}
     * @memberof FoodOrderLookupRestaurant
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FoodOrderLookupRestaurant
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FoodOrderLookupRestaurant
     */
    images: Array<string>;
}

/**
 * Check if a given object implements the FoodOrderLookupRestaurant interface.
 */
export function instanceOfFoodOrderLookupRestaurant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "images" in value;

    return isInstance;
}

export function FoodOrderLookupRestaurantFromJSON(json: any): FoodOrderLookupRestaurant {
    return FoodOrderLookupRestaurantFromJSONTyped(json, false);
}

export function FoodOrderLookupRestaurantFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodOrderLookupRestaurant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'id': json['id'],
        'images': json['images'],
    };
}

export function FoodOrderLookupRestaurantToJSON(value?: FoodOrderLookupRestaurant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
        'images': value.images,
    };
}

