import { excursionsApi } from "../../../apiDrivers/excursions";
import { BASE_PATH, ExcursionOrderLookup, ExcursionOrderUpdateStatus } from "../../../client";
import { DATE_FORMAT, formatDateTime, TIME_FORMAT_HUMAN_NO_SEC } from "../../../utils/date";
import { TCard } from "../../../components/BaseCard/BaseCard";
import { TBadgeType } from "../../../components/badges/Badge";
import { ECurrency } from "../../../definitions";

export const fetchUserExcursionsOrdersList = async ({ queryKey }: any) => {
  const initData = queryKey[1];
  const userId = queryKey[2];

  const res = await excursionsApi.excursionsUserExcursionsOrdersUserIdGet({ initData, userId });

  return res.userExcursionOrdersLookups;
};

export type TNormalizedExcursionsOrderLookup = TCard;

export const mapExcursionOrderStatusToBadgeType: Record<ExcursionOrderUpdateStatus, TBadgeType> = {
  [ExcursionOrderUpdateStatus.Created]: "Booked",
  [ExcursionOrderUpdateStatus.Approved]: "Approved",
  [ExcursionOrderUpdateStatus.Completed]: "Completed",
  [ExcursionOrderUpdateStatus.CancelledByUser]: "Cancelled",
  [ExcursionOrderUpdateStatus.CancelledByAdmin]: "Cancelled",
};

export function normalizeExcursionOrder(
  excursionsOrders: ExcursionOrderLookup[] | undefined
): TNormalizedExcursionsOrderLookup[] {
  if (!excursionsOrders) {
    return [];
  }

  return excursionsOrders.map((order) => {
    return {
      id: order.id,
      title: order.excursion.title,
      badgeType: mapExcursionOrderStatusToBadgeType[order.status as ExcursionOrderUpdateStatus],
      status: mapExcursionOrderStatusToBadgeType[order.status as ExcursionOrderUpdateStatus],
      price: order.price,
      currency: ECurrency.EUR,
      image: `${BASE_PATH}/${order.excursion.images[0]}`,
      description: order.excursion.city,
      labels: [
        { iconType: "Clock", text: formatDateTime(order.creationDate, TIME_FORMAT_HUMAN_NO_SEC) },
        { iconType: "Done", text: formatDateTime(order.excursionDate.slice(0, -5), DATE_FORMAT) },
      ],
    };
  });
}
