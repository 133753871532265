/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FoodOrderItemsInner } from './FoodOrderItemsInner';
import {
    FoodOrderItemsInnerFromJSON,
    FoodOrderItemsInnerFromJSONTyped,
    FoodOrderItemsInnerToJSON,
} from './FoodOrderItemsInner';
import type { FoodOrderStatus } from './FoodOrderStatus';
import {
    FoodOrderStatusFromJSON,
    FoodOrderStatusFromJSONTyped,
    FoodOrderStatusToJSON,
} from './FoodOrderStatus';
import type { FoodOrderUpdateStatus } from './FoodOrderUpdateStatus';
import {
    FoodOrderUpdateStatusFromJSON,
    FoodOrderUpdateStatusFromJSONTyped,
    FoodOrderUpdateStatusToJSON,
} from './FoodOrderUpdateStatus';

/**
 * 
 * @export
 * @interface FoodOrder
 */
export interface FoodOrder {
    /**
     * 
     * @type {string}
     * @memberof FoodOrder
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FoodOrder
     */
    deliveryDate: string;
    /**
     * 
     * @type {number}
     * @memberof FoodOrder
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof FoodOrder
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof FoodOrder
     */
    userId: string;
    /**
     * 
     * @type {FoodOrderUpdateStatus}
     * @memberof FoodOrder
     */
    status?: FoodOrderUpdateStatus;
    /**
     * 
     * @type {Array<FoodOrderStatus>}
     * @memberof FoodOrder
     */
    statusHistory?: Array<FoodOrderStatus>;
    /**
     * 
     * @type {string}
     * @memberof FoodOrder
     */
    restaurantId: string;
    /**
     * 
     * @type {string}
     * @memberof FoodOrder
     */
    restaurantName: string;
    /**
     * 
     * @type {Array<FoodOrderItemsInner>}
     * @memberof FoodOrder
     */
    items?: Array<FoodOrderItemsInner>;
    /**
     * 
     * @type {string}
     * @memberof FoodOrder
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof FoodOrder
     */
    paymentMethod: FoodOrderPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof FoodOrder
     */
    contact: string;
    /**
     * 
     * @type {string}
     * @memberof FoodOrder
     */
    contactType: FoodOrderContactTypeEnum;
}


/**
 * @export
 */
export const FoodOrderPaymentMethodEnum = {
    Cash: 'CASH',
    BankCard: 'BANK_CARD'
} as const;
export type FoodOrderPaymentMethodEnum = typeof FoodOrderPaymentMethodEnum[keyof typeof FoodOrderPaymentMethodEnum];

/**
 * @export
 */
export const FoodOrderContactTypeEnum = {
    Telegram: 'TELEGRAM',
    Whatsapp: 'WHATSAPP',
    TelephoneNumber: 'TELEPHONE_NUMBER'
} as const;
export type FoodOrderContactTypeEnum = typeof FoodOrderContactTypeEnum[keyof typeof FoodOrderContactTypeEnum];


/**
 * Check if a given object implements the FoodOrder interface.
 */
export function instanceOfFoodOrder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveryDate" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "restaurantId" in value;
    isInstance = isInstance && "restaurantName" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "paymentMethod" in value;
    isInstance = isInstance && "contact" in value;
    isInstance = isInstance && "contactType" in value;

    return isInstance;
}

export function FoodOrderFromJSON(json: any): FoodOrder {
    return FoodOrderFromJSONTyped(json, false);
}

export function FoodOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'deliveryDate': json['deliveryDate'],
        'price': json['price'],
        'address': json['address'],
        'userId': json['userId'],
        'status': !exists(json, 'status') ? undefined : FoodOrderUpdateStatusFromJSON(json['status']),
        'statusHistory': !exists(json, 'statusHistory') ? undefined : ((json['statusHistory'] as Array<any>).map(FoodOrderStatusFromJSON)),
        'restaurantId': json['restaurantId'],
        'restaurantName': json['restaurantName'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(FoodOrderItemsInnerFromJSON)),
        'comment': json['comment'],
        'paymentMethod': json['paymentMethod'],
        'contact': json['contact'],
        'contactType': json['contactType'],
    };
}

export function FoodOrderToJSON(value?: FoodOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'deliveryDate': value.deliveryDate,
        'price': value.price,
        'address': value.address,
        'userId': value.userId,
        'status': FoodOrderUpdateStatusToJSON(value.status),
        'statusHistory': value.statusHistory === undefined ? undefined : ((value.statusHistory as Array<any>).map(FoodOrderStatusToJSON)),
        'restaurantId': value.restaurantId,
        'restaurantName': value.restaurantName,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(FoodOrderItemsInnerToJSON)),
        'comment': value.comment,
        'paymentMethod': value.paymentMethod,
        'contact': value.contact,
        'contactType': value.contactType,
    };
}

