/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EUserSeaTripStatus } from './EUserSeaTripStatus';
import {
    EUserSeaTripStatusFromJSON,
    EUserSeaTripStatusFromJSONTyped,
    EUserSeaTripStatusToJSON,
} from './EUserSeaTripStatus';
import type { ExcursionOrderLookupExcursion } from './ExcursionOrderLookupExcursion';
import {
    ExcursionOrderLookupExcursionFromJSON,
    ExcursionOrderLookupExcursionFromJSONTyped,
    ExcursionOrderLookupExcursionToJSON,
} from './ExcursionOrderLookupExcursion';
import type { SeaTripOrderStatus } from './SeaTripOrderStatus';
import {
    SeaTripOrderStatusFromJSON,
    SeaTripOrderStatusFromJSONTyped,
    SeaTripOrderStatusToJSON,
} from './SeaTripOrderStatus';

/**
 * 
 * @export
 * @interface SeaTripOrderLookup
 */
export interface SeaTripOrderLookup {
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrderLookup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrderLookup
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrderLookup
     */
    actualSeaTripDate: string;
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrderLookup
     */
    creationDate: string;
    /**
     * 
     * @type {EUserSeaTripStatus}
     * @memberof SeaTripOrderLookup
     */
    status: EUserSeaTripStatus;
    /**
     * 
     * @type {Array<SeaTripOrderStatus>}
     * @memberof SeaTripOrderLookup
     */
    statusHistory?: Array<SeaTripOrderStatus>;
    /**
     * 
     * @type {number}
     * @memberof SeaTripOrderLookup
     */
    price: number;
    /**
     * 
     * @type {ExcursionOrderLookupExcursion}
     * @memberof SeaTripOrderLookup
     */
    seaTrip: ExcursionOrderLookupExcursion;
}

/**
 * Check if a given object implements the SeaTripOrderLookup interface.
 */
export function instanceOfSeaTripOrderLookup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "actualSeaTripDate" in value;
    isInstance = isInstance && "creationDate" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "seaTrip" in value;

    return isInstance;
}

export function SeaTripOrderLookupFromJSON(json: any): SeaTripOrderLookup {
    return SeaTripOrderLookupFromJSONTyped(json, false);
}

export function SeaTripOrderLookupFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeaTripOrderLookup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['userId'],
        'actualSeaTripDate': json['actualSeaTripDate'],
        'creationDate': json['creationDate'],
        'status': EUserSeaTripStatusFromJSON(json['status']),
        'statusHistory': !exists(json, 'statusHistory') ? undefined : ((json['statusHistory'] as Array<any>).map(SeaTripOrderStatusFromJSON)),
        'price': json['price'],
        'seaTrip': ExcursionOrderLookupExcursionFromJSON(json['seaTrip']),
    };
}

export function SeaTripOrderLookupToJSON(value?: SeaTripOrderLookup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'actualSeaTripDate': value.actualSeaTripDate,
        'creationDate': value.creationDate,
        'status': EUserSeaTripStatusToJSON(value.status),
        'statusHistory': value.statusHistory === undefined ? undefined : ((value.statusHistory as Array<any>).map(SeaTripOrderStatusToJSON)),
        'price': value.price,
        'seaTrip': ExcursionOrderLookupExcursionToJSON(value.seaTrip),
    };
}

