import { seaTripsApi } from "../../../apiDrivers/seaTrips";

const fetchSeaTrips = async ({ queryKey }: any) => {
  const initData = queryKey[1];

  const res = await seaTripsApi.seaTripsSeaTripsListGet({
    initData,
  });

  if (!res.seaTripsList) {
    throw new Error(`Sea trips list fetch failed`);
  }

  return res.seaTripsList;
};

export default fetchSeaTrips;
