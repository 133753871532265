import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { CircleCheck } from "@gravity-ui/icons";
import { useFn } from "../../hooks/useFn";
import { RouterPath } from "../../definitions";

export const useSuccessStatusChanged = () => {
  const navigate = useNavigate();

  const navigateToOrders = useFn(() => navigate(RouterPath.order.list));

  const onSuccessCancel = useFn(async () => {
    notifications.show({
      message: "You order cancelled",
      autoClose: 10000,
      color: "green",
      icon: <CircleCheck />,
    });

    navigateToOrders();
  });

  return { navigate, navigateToOrders, onSuccessCancel };
};
