import { BASE_PATH, ECity, TransferCarTypeEnum } from "./client";
import { InformationPageMessage } from "./pages/InformationPage";

export type TCity = {
  label: keyof typeof ECity;
  value: ECity;
  coordinates: { lat: number; lng: number };
};

export type TCityArray = {
  [K in ECity]: TCity & { value: K };
}[ECity];

export type TCurrency = "RUB" | "USD" | "EUR" | "TRY";

export const CITIES: TCity[] = [
  { label: "Kalkan", value: ECity.Kalkan, coordinates: { lat: 36.267567, lng: 29.412594 } },
  { label: "Kash", value: ECity.Kash, coordinates: { lat: 36.199316, lng: 29.641323 } },
  { label: "Alania", value: ECity.Alania, coordinates: { lat: 36.536574, lng: 31.998763 } },
  { label: "Antalya", value: ECity.Antalya, coordinates: { lat: 36.887121, lng: 30.703258 } },
  // { label: "Belek", value: ECity.Belek, coordinates: { lat: 36.86189, lng: 31.057796 } },
  { label: "Bodrum", value: ECity.Bodrum, coordinates: { lat: 37.034527, lng: 27.430012 } },
  // { label: "Dalaman", value: ECity.Dalaman, coordinates: { lat: 36.765905, lng: 28.806698 } },
  // { label: "Dalyan", value: ECity.Dalyan, coordinates: { lat: 36.834672, lng: 28.641642 } },
  { label: "Didim", value: ECity.Didim, coordinates: { lat: 37.355264, lng: 27.277119 } },
  { label: "Fethiye", value: ECity.Fethiye, coordinates: { lat: 36.623529, lng: 29.112674 } },
  { label: "Marmaris", value: ECity.Marmaris, coordinates: { lat: 36.855, lng: 28.2742 } },
  { label: "Istanbul", value: ECity.Istanbul, coordinates: { lat: 41.0082, lng: 28.9784 } },
  { label: "Bursa", value: ECity.Bursa, coordinates: { lat: 40.1826, lng: 29.0669 } },
  { label: "Izmir", value: ECity.Izmir, coordinates: { lat: 38.4192, lng: 27.1287 } },
  { label: "Kushadasi", value: ECity.Kushadasi, coordinates: { lat: 37.8575, lng: 27.2592 } },
  { label: "Selcuk", value: ECity.Selcuk, coordinates: { lat: 37.948, lng: 27.3867 } },
  { label: "Oludeniz", value: ECity.Oludeniz, coordinates: { lat: 36.5487, lng: 29.1342 } },
  { label: "Demre", value: ECity.Demre, coordinates: { lat: 36.2533, lng: 29.9875 } },
  { label: "Kekova", value: ECity.Kekova, coordinates: { lat: 36.2005, lng: 29.8237 } },
  { label: "Finike", value: ECity.Finike, coordinates: { lat: 36.302, lng: 30.1497 } },
  { label: "Kemer", value: ECity.Kemer, coordinates: { lat: 36.5989, lng: 30.5572 } },
  { label: "Chamyova", value: ECity.Chamyova, coordinates: { lat: 36.649, lng: 30.5543 } },
  { label: "Side", value: ECity.Side, coordinates: { lat: 36.7697, lng: 31.3928 } },
  { label: "Kappadocia", value: ECity.Kappadocia, coordinates: { lat: 38.6437, lng: 34.8296 } },
  { label: "Mersin", value: ECity.Mersin, coordinates: { lat: 36.8121, lng: 34.6415 } },
  { label: "Ankara", value: ECity.Ankara, coordinates: { lat: 39.9334, lng: 32.8597 } },
  { label: "Trabzon", value: ECity.Trabzon, coordinates: { lat: 41.0027, lng: 39.7168 } },
  { label: "Pamukkale", value: ECity.Pamukkale, coordinates: { lat: 37.9193, lng: 29.106 } },
  { label: "Gochek", value: ECity.Gochek, coordinates: { lat: 36.267567, lng: 29.412594 } },
];

export const RouterName = {
  root: "/",
  // deployHostpage: "/travolza-web-app",
  main: "main",
  information: "information",
  informationByMessageType: "information",
  settings: "settings",
  settingsAddress: "settings/address",
  settingsLanguage: "settings/language",
  settingsSupport: "settings/support",
  deliveryPage: "delivery/deliveryPage",
  deliveryMenuById: "delivery/deliveryMenu",
  deliveryFoodItemById: "delivery/deliveryFoodItem",
  excursionsPage: "excursion/excursionsPage",
  excursionItemById: "excursion/excursionItem",
  yachtsPage: "yachts/yachtsPage",
  yachtsItemById: "yachts/yachtsItem",
  seaTripsPage: "seaTrips/seaTripsPage",
  seaTripsItemById: "seaTrips/seaTripsItem",
  transferPageByStep: "transfer/step",
  cartPage: "cart",
  orderComplete: "order/complete",
  orderList: "order/list",
  orderDeliveryCompleted: "order/completed/delivery",
  orderExcursionCompleted: "order/completed/excursion",
  orderSeaTripsCompleted: "order/completed/seaTrip",
  orderTransferCompleted: "order/completed/Transfer",
  orderYachtCompleted: "order/completed/yacht",
  error: "error",
} as const;

export const RouterPath = {
  root: RouterName.root,
  main: {
    index: `${RouterName.root}${RouterName.main}`,
  },
  information: {
    index: `${RouterName.root}${RouterName.information}`,
    byMessageType: (type: InformationPageMessage) => `${RouterName.root}${RouterName.informationByMessageType}/${type}`,
  },
  settings: {
    index: `${RouterName.root}${RouterName.settings}`,
    address: `${RouterName.root}${RouterName.settingsAddress}`,
    language: `${RouterName.root}${RouterName.settingsLanguage}`,
    support: `${RouterName.root}${RouterName.settingsSupport}`,
  },
  delivery: {
    page: `${RouterName.root}${RouterName.deliveryPage}`,
    menuById: (id: string) => `${RouterName.root}${RouterName.deliveryMenuById}/${id}`,
    foodItemById: (id: string) => `${RouterName.root}${RouterName.deliveryFoodItemById}/${id}`,
  },
  excursions: {
    page: `${RouterName.root}${RouterName.excursionsPage}`,
    itemById: (id: string) => `${RouterName.root}${RouterName.excursionItemById}/${id}`,
  },
  yachts: {
    page: `${RouterName.root}${RouterName.yachtsPage}`,
    itemById: (id: string) => `${RouterName.root}${RouterName.yachtsItemById}/${id}`,
  },
  seaTrips: {
    page: `${RouterName.root}${RouterName.seaTripsPage}`,
    itemById: (id: string) => `${RouterName.root}${RouterName.seaTripsItemById}/${id}`,
  },
  transfer: {
    pageByStep: (step: string) => `${RouterName.root}${RouterName.transferPageByStep}/${step}`,
  },
  order: {
    complete: `${RouterName.root}${RouterName.orderComplete}`,
    list: `${RouterName.root}${RouterName.orderList}`,
    completed: {
      deliveryById: (id: string) => `${RouterName.root}${RouterName.orderDeliveryCompleted}/${id}`,
      yachtById: (id: string) => `${RouterName.root}${RouterName.orderYachtCompleted}/${id}`,
      excursionById: (id: string) => `${RouterName.root}${RouterName.orderExcursionCompleted}/${id}`,
      seaTripsById: (id: string) => `${RouterName.root}${RouterName.orderSeaTripsCompleted}/${id}`,
      transferById: (id: string) => `${RouterName.root}${RouterName.orderTransferCompleted}/${id}`,
    },
  },
  cart: { index: `${RouterName.root}${RouterName.cartPage}` },
  error: `${RouterName.root}${RouterName.error}`,
} as const;

export enum ECurrency {
  USD = "$", // United States Dollar
  EUR = "€", // Euro
  JPY = "¥", // Japanese Yen
  GBP = "£", // British Pound Sterling
  CHF = "CHF", // Swiss Franc
  CAD = "CA$", // Canadian Dollar
  AUD = "A$", // Australian Dollar
  NZD = "NZ$", // New Zealand Dollar
  CNY = "CN¥", // Chinese Yuan
  HKD = "HK$", // Hong Kong Dollar
  INR = "₹", // Indian Rupee
  SGD = "S$", // Singapore Dollar
  MYR = "RM", // Malaysian Ringgit
  THB = "฿", // Thai Baht
  IDR = "Rp", // Indonesian Rupiah
  PHP = "₱", // Philippine Peso
  MXN = "Mex$", // Mexican Peso
  BRL = "R$", // Brazilian Real
  ZAR = "R", // South African Rand
  RUB = "₽", // Russian Ruble
  TRY = "₺", // Turkish Lira
}

export enum EColors {
  violet = "#7048E8",
  gray_2 = "#495057",
  gray_1 = "#212529",
  dark = "#868E96",
}

// Pick up points for transfer page
export enum EPickUpPoint {
  DALAMANAIRPORT = "DALAMAN AIRPORT",
  KALKAN = "KALKAN",
  KASH = "KASH",
  ANTALYAAIRPORT = "ANTALYA AIRPORT",
}

export type TPUPoints = {
  label: "Dalaman airport" | "Kalkan" | "Kash" | "Antalya airport";
  value: EPickUpPoint;
};

export const PUPOINTS: TPUPoints[] = [
  { label: "Dalaman airport", value: EPickUpPoint.DALAMANAIRPORT },
  { label: "Kalkan", value: EPickUpPoint.KALKAN },
  { label: "Kash", value: EPickUpPoint.KASH },
  { label: "Antalya airport", value: EPickUpPoint.ANTALYAAIRPORT },
];

// class transfer.
export enum ETransferClass {
  REGULAR = "REGULAR",
  MINIVAN = "MINIVAN",
  VIP = "VIP",
}

export type TTransferClass = {
  label: "Regular - 1-4 passangers" | "Minivan - 1-8 passangers" | "VIP - 1-6 passangers" | "Minibus - many passangers";
  value: TransferCarTypeEnum;
  seatsCount: number;
};

export const TRANSFERCLASS: TTransferClass[] = [
  { label: "Regular - 1-4 passangers", value: TransferCarTypeEnum.Sedan, seatsCount: 4 },
  { label: "Minivan - 1-8 passangers", value: TransferCarTypeEnum.Minivan, seatsCount: 8 },
  { label: "VIP - 1-6 passangers", value: TransferCarTypeEnum.Vip, seatsCount: 6 },
  { label: "Minibus - many passangers", value: TransferCarTypeEnum.Minibus, seatsCount: 100 },
];

export const assets: Record<string, string> = {
  test_img: `${BASE_PATH}/assets/test_img.png`,
  delivery_img: `${BASE_PATH}/assets/delivery.webp`,
  seatrip_img: `${BASE_PATH}/assets/seatrip.webp`,
  excursion_img: `${BASE_PATH}/assets/excursion.webp`,
  yacht_img: `${BASE_PATH}/assets/yacht.webp`,
  transfer_img: `${BASE_PATH}/assets/transfer.webp`,
  regular_icon: `${BASE_PATH}/assets/regular.svg`,
  minivan_icon: `${BASE_PATH}/assets/minivan.svg`,
  vip_icon: `${BASE_PATH}/assets/vip.svg`,
};

export const leafletAssets: Record<string, string> = {
  marker_icon: `${BASE_PATH}/assets/leaflet/marker-icon.png`,
};

export const COMMON_FILTERS = ["By name", "By price"];
