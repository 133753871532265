/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Restaurant } from './Restaurant';
import {
    RestaurantFromJSON,
    RestaurantFromJSONTyped,
    RestaurantToJSON,
} from './Restaurant';

/**
 * 
 * @export
 * @interface FoodRestaurantByIdRestaurantIdGet200Response
 */
export interface FoodRestaurantByIdRestaurantIdGet200Response {
    /**
     * 
     * @type {Restaurant}
     * @memberof FoodRestaurantByIdRestaurantIdGet200Response
     */
    restaurant?: Restaurant;
}

/**
 * Check if a given object implements the FoodRestaurantByIdRestaurantIdGet200Response interface.
 */
export function instanceOfFoodRestaurantByIdRestaurantIdGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FoodRestaurantByIdRestaurantIdGet200ResponseFromJSON(json: any): FoodRestaurantByIdRestaurantIdGet200Response {
    return FoodRestaurantByIdRestaurantIdGet200ResponseFromJSONTyped(json, false);
}

export function FoodRestaurantByIdRestaurantIdGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodRestaurantByIdRestaurantIdGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'restaurant': !exists(json, 'Restaurant') ? undefined : RestaurantFromJSON(json['Restaurant']),
    };
}

export function FoodRestaurantByIdRestaurantIdGet200ResponseToJSON(value?: FoodRestaurantByIdRestaurantIdGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Restaurant': RestaurantToJSON(value.restaurant),
    };
}

