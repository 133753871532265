import { transferRegionCoefficient } from "../constants/constantsRegion";

export const getRegionCostIndex = (address: string): number | null => {
  const addressComponents = address.split(",").map((component) => component.trim());
  // Предположим, что второе с конца слово - провинция
  const provinceName = addressComponents[addressComponents.length - 2];
  if (!provinceName) {
    return null;
  }
  for (const province of transferRegionCoefficient) {
    if (province.en.toLowerCase() === provinceName.toLowerCase()) {
      return province.expenseCoefficient;
    }
    if (province.ru.toLowerCase() === provinceName.toLowerCase()) {
      return province.expenseCoefficient;
    }
  }

  return null; // Если провинция не была найдена
};
