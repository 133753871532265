import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TelegramAuthData } from "@telegram-auth/react";

type TTelegramAuthUserStoreState = {
  data: TelegramAuthData;
};

function getInitialState(): TTelegramAuthUserStoreState {
  return {
    data: {
      id: 0,
      first_name: "",
      auth_date: 0,
      hash: "",
    },
  };
}

type TUserUpdateAction = PayloadAction<TelegramAuthData>;

const userSlice = createSlice({
  name: "tgUser",
  initialState: getInitialState(),
  reducers: {
    SetTGUserAction: (state, action: TUserUpdateAction) => {
      state.data = { ...action.payload };
    },
  },
});

export const { SetTGUserAction } = userSlice.actions;

export const tgUserReducer = userSlice.reducer;

export function selectCurrentTGUser(state: { tgUser: TTelegramAuthUserStoreState }) {
  return state.tgUser.data;
}
