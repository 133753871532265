import { OrderCompletedBase } from "../../../modules/orders/OrderCompletedBase";
import { ECurrency } from "../../../definitions";

export const OrderYachtCompletedPage = () => {
  return (
    <>
      <OrderCompletedBase
        description={""}
        image={""}
        images={[]}
        name={"Yacht"}
        price={0}
        currency={ECurrency.EUR}
        contactType={"TELEGRAM"}
        contactValue={""}
        addressValue={""}
        adultsCount={0}
        childrenCount={0}
        paymentMethod={"CASH"}
        commentValue={""}
        actualDate={new Date().toString()}
        creationDate={new Date().toString()}
        status={"Booked"}
      ></OrderCompletedBase>
    </>
  );
};
