import { excursionsApi } from "../../../apiDrivers/excursions";
import { ExcursionOrderUpdateStatus } from "../../../client";

export const fetchExcursionOrderById = async ({ queryKey }: any) => {
  const initData = queryKey[1];
  const orderId = queryKey[2];

  const res = await excursionsApi.excursionsExcursionsOrdersOrderIdGet({
    orderId,
    initData,
  });

  return res.excursionOrder;
};

export const updateExcursionOrderStatusRequest = async (
  excursionOrderId: string,
  excursionTitle: string,
  userName: string,
  status: ExcursionOrderUpdateStatus,
  initData: string
) => {
  const res = await excursionsApi.excursionsExcursionsOrdersSetOrderStatusPost({
    excursionsExcursionsOrdersSetOrderStatusPostRequest: {
      excursionOrderId,
      excursionTitle,
      userName,
      status,
      initData,
    },
  });

  return res;
};
