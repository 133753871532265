import {
  EContactType,
  ExcursionOrder,
  FoodOrder,
  FoodOrderPaymentMethodEnum,
  SeaTripOrder,
  SeaTripPricePolicy,
  Transfer,
} from "../../client";
import { ECurrency } from "../../definitions";

export enum EOrderPageIsType {
  IS_EXCURSION = "IS_EXCURSION",
  IS_SEA_TRIP = "IS_SEA_TRIP",
  IS_FOOD_DELIVERY = "IS_FOOD_DELIVERY",
  IS_YACHT = "IS_YACHT",
  IS_TRANSFER = "IS_TRANSFER",
}

type TOrderPageOrderPayment =
  | {
      priceAdult: number;
      priceKids: number;
      currency: ECurrency;
    }
  | {
      price: number;
      currency: ECurrency;
    }
  | {
      pricePolicy: SeaTripPricePolicy[];
      currency: ECurrency;
    };

type TOrderPageCapacity = {
  capacity: number;
};

export type OrderPageExcursion = Omit<ExcursionOrder, "price"> & TOrderPageOrderPayment & TOrderPageCapacity;

export type OrderPageSeaTrip = Omit<SeaTripOrder, "price"> & TOrderPageOrderPayment & TOrderPageCapacity;

export type OrderPageFoodOrder = Omit<FoodOrder, "price"> & TOrderPageOrderPayment;

export type OrderPageTransfer = Omit<Transfer, "price"> & TOrderPageOrderPayment;

export type OrderPageData = OrderPageExcursion | OrderPageSeaTrip | OrderPageFoodOrder | OrderPageTransfer;

export type OrderPageLocationState = {
  data: OrderPageData;
  // TODO: закомментировать когда будет тип YachtOrder
  // data: ExcursionOrder | SeaTripOrder | YachtOrder;
  type: EOrderPageIsType;
};

export function isExcursionOrder(order: unknown): order is ExcursionOrder {
  const typed = order as ExcursionOrder;
  return !!typed.excursionId && !!typed.userId;
}

// TODO: закомментировать когда будет тип YachtOrder
// export function isYachtOrder(order: unknown): order is YachtOrder {
//   const typed = order as YachtOrder;
//   return !!typed.yachtId && !!typed.userId;
// }

export function isSeaTripOrder(order: unknown): order is SeaTripOrder {
  const typed = order as SeaTripOrder;
  return !!typed.seaTripId && !!typed.userId;
}

export function isFoodOrder(order: unknown): order is FoodOrder {
  const typed = order as FoodOrder;
  return !!typed.items?.length;
}

export function isTransferOrder(order: unknown): order is Transfer {
  const typed = order as Transfer;
  return !!typed.carType;
}

export type TOrderFormValues = {
  contactType: EContactType;
  contact: string;
  address: string;
  adultsCount?: number;
  childrenCount?: number;
  babySeatCount?: number;
  luggageCount?: number;
  paymentMethod: "Cash" | "Bank card";
  comment: string;
  passengerNames?: string;
  flightNumber?: string;
  price: number;
};

export const mapFoodOrderPaymentMethodEnum: Record<FoodOrderPaymentMethodEnum, "Cash" | "Bank card"> = {
  [FoodOrderPaymentMethodEnum.Cash]: "Cash",
  [FoodOrderPaymentMethodEnum.BankCard]: "Bank card",
};

export const mapHumanStringToFoodOrderPaymentMethodEnum: Record<"Cash" | "Bank card", FoodOrderPaymentMethodEnum> = {
  Cash: FoodOrderPaymentMethodEnum.Cash,
  "Bank card": FoodOrderPaymentMethodEnum.BankCard,
};
