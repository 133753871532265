/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EUserSeaTripStatus = {
    Created: 'CREATED',
    Approved: 'APPROVED',
    Completed: 'COMPLETED',
    CancelledByUser: 'CANCELLED_BY_USER',
    CancelledByAdmin: 'CANCELLED_BY_ADMIN'
} as const;
export type EUserSeaTripStatus = typeof EUserSeaTripStatus[keyof typeof EUserSeaTripStatus];


export function EUserSeaTripStatusFromJSON(json: any): EUserSeaTripStatus {
    return EUserSeaTripStatusFromJSONTyped(json, false);
}

export function EUserSeaTripStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EUserSeaTripStatus {
    return json as EUserSeaTripStatus;
}

export function EUserSeaTripStatusToJSON(value?: EUserSeaTripStatus | null): any {
    return value as any;
}

