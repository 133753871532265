/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TransferOrderStatus } from './TransferOrderStatus';
import {
    TransferOrderStatusFromJSON,
    TransferOrderStatusFromJSONTyped,
    TransferOrderStatusToJSON,
} from './TransferOrderStatus';
import type { TransferStatus } from './TransferStatus';
import {
    TransferStatusFromJSON,
    TransferStatusFromJSONTyped,
    TransferStatusToJSON,
} from './TransferStatus';

/**
 * 
 * @export
 * @interface Transfer
 */
export interface Transfer {
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    from?: string;
    /**
     * 
     * @type {number}
     * @memberof Transfer
     */
    fromPointLat?: number;
    /**
     * 
     * @type {number}
     * @memberof Transfer
     */
    fromPointLon?: number;
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    to?: string;
    /**
     * 
     * @type {number}
     * @memberof Transfer
     */
    toPointLat?: number;
    /**
     * 
     * @type {number}
     * @memberof Transfer
     */
    toPointLon?: number;
    /**
     * 
     * @type {number}
     * @memberof Transfer
     */
    approximatePrice?: number;
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    carType?: TransferCarTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    userId?: string;
    /**
     * 
     * @type {TransferStatus}
     * @memberof Transfer
     */
    status?: TransferStatus;
    /**
     * 
     * @type {Array<TransferOrderStatus>}
     * @memberof Transfer
     */
    statusHistory?: Array<TransferOrderStatus>;
    /**
     * 
     * @type {number}
     * @memberof Transfer
     */
    personCount?: number;
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    luggage?: string;
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    contact?: string;
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    contactType?: TransferContactTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Transfer
     */
    babySeats?: number;
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    flightNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Transfer
     */
    passengerNames?: string;
}


/**
 * @export
 */
export const TransferCarTypeEnum = {
    Sedan: 'SEDAN',
    Minivan: 'MINIVAN',
    Vip: 'VIP',
    Minibus: 'MINIBUS',
    Midibus: 'MIDIBUS',
    Bigbus: 'BIGBUS'
} as const;
export type TransferCarTypeEnum = typeof TransferCarTypeEnum[keyof typeof TransferCarTypeEnum];

/**
 * @export
 */
export const TransferContactTypeEnum = {
    Telegram: 'TELEGRAM',
    Whatsapp: 'WHATSAPP',
    TelephoneNumber: 'TELEPHONE_NUMBER'
} as const;
export type TransferContactTypeEnum = typeof TransferContactTypeEnum[keyof typeof TransferContactTypeEnum];


/**
 * Check if a given object implements the Transfer interface.
 */
export function instanceOfTransfer(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TransferFromJSON(json: any): Transfer {
    return TransferFromJSONTyped(json, false);
}

export function TransferFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transfer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'fromPointLat': !exists(json, 'fromPointLat') ? undefined : json['fromPointLat'],
        'fromPointLon': !exists(json, 'fromPointLon') ? undefined : json['fromPointLon'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'toPointLat': !exists(json, 'toPointLat') ? undefined : json['toPointLat'],
        'toPointLon': !exists(json, 'toPointLon') ? undefined : json['toPointLon'],
        'approximatePrice': !exists(json, 'approximatePrice') ? undefined : json['approximatePrice'],
        'carType': !exists(json, 'carType') ? undefined : json['carType'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'time': !exists(json, 'time') ? undefined : json['time'],
        'creationDate': !exists(json, 'creationDate') ? undefined : json['creationDate'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'status': !exists(json, 'status') ? undefined : TransferStatusFromJSON(json['status']),
        'statusHistory': !exists(json, 'statusHistory') ? undefined : ((json['statusHistory'] as Array<any>).map(TransferOrderStatusFromJSON)),
        'personCount': !exists(json, 'personCount') ? undefined : json['personCount'],
        'luggage': !exists(json, 'luggage') ? undefined : json['luggage'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'contactType': !exists(json, 'contactType') ? undefined : json['contactType'],
        'babySeats': !exists(json, 'babySeats') ? undefined : json['babySeats'],
        'flightNumber': !exists(json, 'flightNumber') ? undefined : json['flightNumber'],
        'passengerNames': !exists(json, 'passengerNames') ? undefined : json['passengerNames'],
    };
}

export function TransferToJSON(value?: Transfer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'from': value.from,
        'fromPointLat': value.fromPointLat,
        'fromPointLon': value.fromPointLon,
        'to': value.to,
        'toPointLat': value.toPointLat,
        'toPointLon': value.toPointLon,
        'approximatePrice': value.approximatePrice,
        'carType': value.carType,
        'date': value.date,
        'time': value.time,
        'creationDate': value.creationDate,
        'comment': value.comment,
        'userId': value.userId,
        'status': TransferStatusToJSON(value.status),
        'statusHistory': value.statusHistory === undefined ? undefined : ((value.statusHistory as Array<any>).map(TransferOrderStatusToJSON)),
        'personCount': value.personCount,
        'luggage': value.luggage,
        'contact': value.contact,
        'contactType': value.contactType,
        'babySeats': value.babySeats,
        'flightNumber': value.flightNumber,
        'passengerNames': value.passengerNames,
    };
}

