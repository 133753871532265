import { useTranslation } from "react-i18next";
import { Badge, Group, Modal, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDisclosure } from "@mantine/hooks";
import { Excursion } from "../../client";
import { BaseCard } from "../../components/BaseCard/BaseCard";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { NavigationText } from "../../components/NavigationText/NavigationText";
import { LoaderPageCustom } from "../../components/UI/Vendors/Mantine/LoaderPageCustom";
import { COMMON_FILTERS, RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import fetchExcursions from "./api";
import { ALL_EXCURSIONS_FILTERS, normalizeExcursions, TExcursionsFilters } from "./definitions";
import { capitalizeFirstLetter } from "../../utils";

export const ExcursionPage = ({ pageName, pageDescription }: { pageName?: string; pageDescription?: string }) => {
  const { initData } = useTelegram();

  const navigate = useNavigate();

  const { t } = useTranslation(["excursions_page"]);

  const [openedModal, { open, close }] = useDisclosure(false);

  const excursionDescription: { name: string; description: string } = {
    name: t("Excursion"),
    description: t(
      "Select a tour and specify the necessary data. The manager will contact you to confirm the order. A deposit will be required, the amount will be communicated by the manager. The main payment is made to the driver on the day of the tour. Once confirmed, track the status of the order in the ‘Orders’ section. If availability is confirmed, the manager will provide detailed instructions. If the service is unavailable, alternatives will be offered."
    ),
  };

  const navigateToExcursion = useFn((card: { id: string }) => {
    navigate(RouterPath.excursions.itemById(card.id), { state: card });
  });

  // @ts-ignore
  const [filters, setFilters] = useState<TExcursionsFilters>(COMMON_FILTERS.concat(ALL_EXCURSIONS_FILTERS));

  const [excursions, setExcursions] = useState<Excursion[]>([]);

  const excursionsFetch = useQuery(["excursions list", initData], fetchExcursions, {
    onError: (error) => {
      console.log(error);
    },
    select: setExcursions,
  });
  const { t: t_base_card } = useTranslation("base_card");

  const filterList = COMMON_FILTERS.concat(ALL_EXCURSIONS_FILTERS).map((filter) => {
    return {
      itemName: filter,
      itemJSX: (
        <Badge
          pt={1}
          key={`filter_${filter}`}
          className="BaseCArd__tag"
          color="grape"
          radius="lg"
          size="lg"
          variant="outline"
        >
          {t_base_card(filter)}
        </Badge>
      ),
    };
  });

  const onFilterChanged = useFn((values: TExcursionsFilters) => {
    setFilters(values.length ? values : ALL_EXCURSIONS_FILTERS);
  });

  const filterByTag = useFn((excursion: Excursion) => {
    if (!filters.length) return true;

    const filteredFilters = filters.filter((filter) => {
      return !COMMON_FILTERS.includes(filter);
    });

    if (!filteredFilters.length) return true;

    return excursion.tags.some((tag) => filters.includes(capitalizeFirstLetter(tag.name)));
  });

  const filterByCommonFilters = useFn(() => {
    if (!excursions) return [];
    if (!filters.length) return excursions;

    let filtered: Excursion[] = [...excursions];

    // @ts-ignore
    if (filters.includes("By price")) {
      filtered = filtered.sort((a, b) => a.priceAdult - b.priceAdult);
    }

    // @ts-ignore
    if (filters.includes("By name")) {
      filtered = filtered.sort((a, b) => a.title.localeCompare(b.title));
    }

    return filtered;
  });

  if (excursionsFetch.isLoading) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }

  const cardsList = filterByCommonFilters()
    .filter(filterByTag)
    .map((rawCard: Excursion) => {
      const card = normalizeExcursions([rawCard])[0];
      return (
        <BaseCard
          key={card.id}
          card={card}
          onCardClick={() => {
            navigateToExcursion(card);
          }}
        ></BaseCard>
      );
    });

  return (
    <>
      <NavigationText
        arrow={{
          isShow: true,
        }}
        filterMenu={{
          isShow: true,
          onFilterChanged,
          dropdownItems: filterList,
        }}
        title={pageName || t("Excursions")}
        subtitle={t(excursionDescription.description)}
      ></NavigationText>
      <Modal
        opened={openedModal}
        onClose={close}
        withCloseButton={true}
        centered
        title={t("Excursion")}
        transitionProps={{ transition: "rotate-left" }}
      >
        <ReactMarkdown children={excursionDescription.description} />
      </Modal>

      <Group position="apart" mt="md" mb="xs">
        <Text weight={700} onClick={open} color="violet" underline={true}>
          {t("Read More")}
        </Text>
      </Group>
      {cardsList.length ? cardsList : <b>{t("No excursions found")}</b>}
      <NavigationBar></NavigationBar>
    </>
  );
};
