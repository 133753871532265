/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExcursionOrderUpdateStatus } from './ExcursionOrderUpdateStatus';
import {
    ExcursionOrderUpdateStatusFromJSON,
    ExcursionOrderUpdateStatusFromJSONTyped,
    ExcursionOrderUpdateStatusToJSON,
} from './ExcursionOrderUpdateStatus';

/**
 * 
 * @export
 * @interface ExcursionsExcursionsOrdersSetOrderStatusPostRequest
 */
export interface ExcursionsExcursionsOrdersSetOrderStatusPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExcursionsExcursionsOrdersSetOrderStatusPostRequest
     */
    initData?: string;
    /**
     * 
     * @type {string}
     * @memberof ExcursionsExcursionsOrdersSetOrderStatusPostRequest
     */
    excursionOrderId: string;
    /**
     * 
     * @type {string}
     * @memberof ExcursionsExcursionsOrdersSetOrderStatusPostRequest
     */
    excursionTitle: string;
    /**
     * 
     * @type {string}
     * @memberof ExcursionsExcursionsOrdersSetOrderStatusPostRequest
     */
    userName: string;
    /**
     * 
     * @type {ExcursionOrderUpdateStatus}
     * @memberof ExcursionsExcursionsOrdersSetOrderStatusPostRequest
     */
    status: ExcursionOrderUpdateStatus;
}

/**
 * Check if a given object implements the ExcursionsExcursionsOrdersSetOrderStatusPostRequest interface.
 */
export function instanceOfExcursionsExcursionsOrdersSetOrderStatusPostRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "excursionOrderId" in value;
    isInstance = isInstance && "excursionTitle" in value;
    isInstance = isInstance && "userName" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function ExcursionsExcursionsOrdersSetOrderStatusPostRequestFromJSON(json: any): ExcursionsExcursionsOrdersSetOrderStatusPostRequest {
    return ExcursionsExcursionsOrdersSetOrderStatusPostRequestFromJSONTyped(json, false);
}

export function ExcursionsExcursionsOrdersSetOrderStatusPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExcursionsExcursionsOrdersSetOrderStatusPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initData': !exists(json, 'initData') ? undefined : json['initData'],
        'excursionOrderId': json['excursionOrderId'],
        'excursionTitle': json['excursionTitle'],
        'userName': json['userName'],
        'status': ExcursionOrderUpdateStatusFromJSON(json['status']),
    };
}

export function ExcursionsExcursionsOrdersSetOrderStatusPostRequestToJSON(value?: ExcursionsExcursionsOrdersSetOrderStatusPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initData': value.initData,
        'excursionOrderId': value.excursionOrderId,
        'excursionTitle': value.excursionTitle,
        'userName': value.userName,
        'status': ExcursionOrderUpdateStatusToJSON(value.status),
    };
}

