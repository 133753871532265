import { IconOptions } from "leaflet";
import { BASE_PATH } from "../../client";

export const markerDefault: IconOptions = {
  iconUrl: `${BASE_PATH}/assets/leaflet/marker-default.svg`,
  //   iconSize: [30, 30], // размеры иконки
  iconAnchor: [15, 30], // точка иконки, которая соответствует ее местоположению на карте
  popupAnchor: [1, -24], // точка относительно которой будет открываться всплывающее окно
};

export const markerStart: IconOptions = {
  iconUrl: `${BASE_PATH}/assets/leaflet/marker-start.svg`,
  iconAnchor: [15, 30], // точка иконки, которая соответствует ее местоположению на карте
  popupAnchor: [1, -24], // точка относительно которой будет открываться всплывающее окно
};

export const markerFinish: IconOptions = {
  iconUrl: `${BASE_PATH}/assets/leaflet/marker-end.svg`,
  iconAnchor: [15, 30], // точка иконки, которая соответствует ее местоположению на карте
  popupAnchor: [1, -24], // точка относительно которой будет открываться всплывающее окно
};

export const markerSelected: IconOptions = {
  iconUrl: `${BASE_PATH}/assets/leaflet/marker-selected.svg`,
  iconAnchor: [15, 30], // точка иконки, которая соответствует ее местоположению на карте
  popupAnchor: [1, -24], // точка относительно которой будет открываться всплывающее окно
};
