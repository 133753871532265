import { useMap } from "react-leaflet";

type ChangeViewProps = {
  center: [number, number];
  zoom: number;
};

export const ChangeView: React.FC<ChangeViewProps> = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};
