/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SeaTripOrder } from './SeaTripOrder';
import {
    SeaTripOrderFromJSON,
    SeaTripOrderFromJSONTyped,
    SeaTripOrderToJSON,
} from './SeaTripOrder';

/**
 * 
 * @export
 * @interface SeaTripsSeaTripsOrdersOrderIdGet200Response
 */
export interface SeaTripsSeaTripsOrdersOrderIdGet200Response {
    /**
     * 
     * @type {SeaTripOrder}
     * @memberof SeaTripsSeaTripsOrdersOrderIdGet200Response
     */
    seaTripOrder?: SeaTripOrder;
}

/**
 * Check if a given object implements the SeaTripsSeaTripsOrdersOrderIdGet200Response interface.
 */
export function instanceOfSeaTripsSeaTripsOrdersOrderIdGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SeaTripsSeaTripsOrdersOrderIdGet200ResponseFromJSON(json: any): SeaTripsSeaTripsOrdersOrderIdGet200Response {
    return SeaTripsSeaTripsOrdersOrderIdGet200ResponseFromJSONTyped(json, false);
}

export function SeaTripsSeaTripsOrdersOrderIdGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeaTripsSeaTripsOrdersOrderIdGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seaTripOrder': !exists(json, 'seaTripOrder') ? undefined : SeaTripOrderFromJSON(json['seaTripOrder']),
    };
}

export function SeaTripsSeaTripsOrdersOrderIdGet200ResponseToJSON(value?: SeaTripsSeaTripsOrdersOrderIdGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seaTripOrder': SeaTripOrderToJSON(value.seaTripOrder),
    };
}

