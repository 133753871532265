import { useTranslation } from "react-i18next";
import { Button, Flex } from "@mantine/core";
import React, { useCallback } from "react";
import { TelegramAuthData } from "@telegram-auth/react";
import "./style.css";
import { RouterPath } from "../../definitions";
import { TelegramAuth } from "./components/TelegramAuth";
import { IconBot } from "../../components/UI/icons/IconBot";
import useBeforeInstallPrompt from "./beforeInstallPrompt";
import { useFn } from "../../hooks/useFn";
import { reachGoalRegistrationDone, reachGoalTelegramLogin } from "../../utils/metrika";

type TAuthPageProps = {
  onAuthCallback: (user: TelegramAuthData) => void;
};

export const AuthPage = ({ onAuthCallback }: TAuthPageProps) => {
  const { t } = useTranslation(["auth_page"]);

  reachGoalTelegramLogin();

  const onAuth = useCallback((user: TelegramAuthData) => {
    if (user.id) {
      reachGoalRegistrationDone();

      onAuthCallback(user);
    }
  }, []);

  const { isInstalled, addToHomeScreen } = useBeforeInstallPrompt();

  const navigateToBot = useFn(() => {
    window.location.replace("https://t.me/travolza_bot");
  });

  return (
    <Flex
      justify={"center"}
      pos={"absolute"}
      align={"center"}
      direction={"column"}
      rowGap={"xl"}
      style={{ bottom: 0, marginBottom: "16px", width: "100%", backgroundColor: "white" }}
    >
      {/*<div>*/}
      {/*  <Button size={"lg"} variant="white" leftIcon={<SquarePlus />} onClick={addToHomeScreen}>*/}
      {/*    {t("Add application to Home screen")}*/}
      {/*  </Button>*/}
      {/*</div>*/}
      <div>
        <TelegramAuth onAuthCallback={onAuth} authCallbackUrl={RouterPath.root}></TelegramAuth>
      </div>
      <div>
        <Button
          style={{ width: "256px", padding: "5px" }}
          size={"lg"}
          variant="light"
          leftIcon={<IconBot />}
          onClick={navigateToBot}
        >
          {t("Open Travolza Bot in Tg")}
        </Button>
      </div>
    </Flex>
  );
};
