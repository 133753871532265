import { Select, SelectItem, SelectProps } from "@mantine/core";

type SelectCustomProps = {
  onChange: any;
  value: string | null | undefined;
  data: readonly (string | SelectItem)[];
  disabled?: boolean;
  theme?: "travolza";
} & SelectProps;

export const SelectCustom = ({ theme, onChange, value, data, disabled, ...props }: SelectCustomProps) => {
  return (
    <>
      {theme === "travolza" ? (
        <Select
          readOnly={disabled}
          size="lg"
          onChange={onChange}
          value={value}
          nothingFound="No options"
          transitionProps={{
            transition: "pop-top-left",
            duration: 80,
            timingFunction: "ease",
          }}
          withinPortal
          radius="lg"
          data={data}
          styles={(theme) => ({
            input: {
              "&:focus-within": {
                borderColor: theme.colors.violet[9],
              },
            },
            item: {
              "&[data-selected]": {
                backgroundColor: theme.colors.violet[0],
                "&, &:hover": {
                  backgroundColor: theme.colors.violet[0],
                  color: theme.colors.dark[9],
                },
              },
              "&[data-hovered]": {},
            },
          })}
          {...props}
        />
      ) : (
        <Select
          {...props}
          readOnly={disabled}
          size="lg"
          onChange={onChange}
          value={value}
          nothingFound="No options"
          transitionProps={{
            transition: "pop-top-left",
            duration: 80,
            timingFunction: "ease",
          }}
          withinPortal
          radius="lg"
          data={data}
          styles={(theme) => ({
            input: {
              "&:focus-within": {
                borderColor: theme.colors.violet[9],
              },
            },
            item: {
              "&[data-selected]": {
                "&, &:hover": {
                  backgroundColor: theme.colors.violet[0],
                  color: theme.colors.violet[9],
                },
              },
              "&[data-hovered]": {},
            },
          })}
        />
      )}
    </>
  );
};
