import { BASE_PATH, SeaTrip, SeaTripSeaTripTypeEnum, SeaTripTagNameEnum } from "../../client";
import { TCard } from "../../components/BaseCard/BaseCard";
import { ECurrency } from "../../definitions";
import { capitalizeFirstLetter } from "../../utils";

export function normalizeSeaTrips(seaTrips: SeaTrip[]): TCard[] {
  return seaTrips.map((seaTrip) => {
    return {
      id: seaTrip.id,
      time: seaTrip.duration + "",
      image: `${BASE_PATH}/${seaTrip.images[0]}`,
      title: seaTrip.title,
      address: seaTrip.city,
      route: seaTrip.title,
      duration: seaTrip.duration + "",
      // @ts-ignore
      tags: seaTrip.tags.concat([{ id: seaTrip.seaTripType, name: seaTrip.seaTripType }]),
      description: seaTrip.description,
      shortDescription: seaTrip.shortDescription,
      price: seaTrip.price,
      currency: ECurrency.EUR,
    };
  });
}

export type TSeaTripsFilters = string[];

export const ALL_SEA_TRIPS_FILTERS = (Object.values(SeaTripTagNameEnum) as string[])
  .concat(Object.values(SeaTripSeaTripTypeEnum))
  .map(capitalizeFirstLetter) as TSeaTripsFilters;
