/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SeaTripOrder } from './SeaTripOrder';
import {
    SeaTripOrderFromJSON,
    SeaTripOrderFromJSONTyped,
    SeaTripOrderToJSON,
} from './SeaTripOrder';

/**
 * 
 * @export
 * @interface SeaTripsCreateSeaTripOrderPostRequest
 */
export interface SeaTripsCreateSeaTripOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof SeaTripsCreateSeaTripOrderPostRequest
     */
    initData?: string;
    /**
     * 
     * @type {SeaTripOrder}
     * @memberof SeaTripsCreateSeaTripOrderPostRequest
     */
    seaTripOrder?: SeaTripOrder;
}

/**
 * Check if a given object implements the SeaTripsCreateSeaTripOrderPostRequest interface.
 */
export function instanceOfSeaTripsCreateSeaTripOrderPostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SeaTripsCreateSeaTripOrderPostRequestFromJSON(json: any): SeaTripsCreateSeaTripOrderPostRequest {
    return SeaTripsCreateSeaTripOrderPostRequestFromJSONTyped(json, false);
}

export function SeaTripsCreateSeaTripOrderPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeaTripsCreateSeaTripOrderPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initData': !exists(json, 'initData') ? undefined : json['initData'],
        'seaTripOrder': !exists(json, 'seaTripOrder') ? undefined : SeaTripOrderFromJSON(json['seaTripOrder']),
    };
}

export function SeaTripsCreateSeaTripOrderPostRequestToJSON(value?: SeaTripsCreateSeaTripOrderPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initData': value.initData,
        'seaTripOrder': SeaTripOrderToJSON(value.seaTripOrder),
    };
}

