/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransferInput
 */
export interface TransferInput {
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    from?: string;
    /**
     * 
     * @type {number}
     * @memberof TransferInput
     */
    fromPointLat?: number;
    /**
     * 
     * @type {number}
     * @memberof TransferInput
     */
    fromPointLon?: number;
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    to?: string;
    /**
     * 
     * @type {number}
     * @memberof TransferInput
     */
    toPointLat?: number;
    /**
     * 
     * @type {number}
     * @memberof TransferInput
     */
    toPointLon?: number;
    /**
     * 
     * @type {number}
     * @memberof TransferInput
     */
    approximatePrice?: number;
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    carType?: TransferInputCarTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    userId?: string;
    /**
     * 
     * @type {number}
     * @memberof TransferInput
     */
    personCount?: number;
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    luggage?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    contact?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    contactType?: TransferInputContactTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TransferInput
     */
    babySeats?: number;
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    flightNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    initData?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferInput
     */
    passengerNames?: string;
}


/**
 * @export
 */
export const TransferInputCarTypeEnum = {
    Sedan: 'SEDAN',
    Minivan: 'MINIVAN',
    Vip: 'VIP',
    Minibus: 'MINIBUS',
    Midibus: 'MIDIBUS',
    Bigbus: 'BIGBUS'
} as const;
export type TransferInputCarTypeEnum = typeof TransferInputCarTypeEnum[keyof typeof TransferInputCarTypeEnum];

/**
 * @export
 */
export const TransferInputContactTypeEnum = {
    Telegram: 'TELEGRAM',
    Whatsapp: 'WHATSAPP',
    TelephoneNumber: 'TELEPHONE_NUMBER'
} as const;
export type TransferInputContactTypeEnum = typeof TransferInputContactTypeEnum[keyof typeof TransferInputContactTypeEnum];


/**
 * Check if a given object implements the TransferInput interface.
 */
export function instanceOfTransferInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TransferInputFromJSON(json: any): TransferInput {
    return TransferInputFromJSONTyped(json, false);
}

export function TransferInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : json['from'],
        'fromPointLat': !exists(json, 'fromPointLat') ? undefined : json['fromPointLat'],
        'fromPointLon': !exists(json, 'fromPointLon') ? undefined : json['fromPointLon'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'toPointLat': !exists(json, 'toPointLat') ? undefined : json['toPointLat'],
        'toPointLon': !exists(json, 'toPointLon') ? undefined : json['toPointLon'],
        'approximatePrice': !exists(json, 'approximatePrice') ? undefined : json['approximatePrice'],
        'carType': !exists(json, 'carType') ? undefined : json['carType'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'time': !exists(json, 'time') ? undefined : json['time'],
        'creationDate': !exists(json, 'creationDate') ? undefined : json['creationDate'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'personCount': !exists(json, 'personCount') ? undefined : json['personCount'],
        'luggage': !exists(json, 'luggage') ? undefined : json['luggage'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'contactType': !exists(json, 'contactType') ? undefined : json['contactType'],
        'babySeats': !exists(json, 'babySeats') ? undefined : json['babySeats'],
        'flightNumber': !exists(json, 'flightNumber') ? undefined : json['flightNumber'],
        'initData': !exists(json, 'initData') ? undefined : json['initData'],
        'passengerNames': !exists(json, 'passengerNames') ? undefined : json['passengerNames'],
    };
}

export function TransferInputToJSON(value?: TransferInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'fromPointLat': value.fromPointLat,
        'fromPointLon': value.fromPointLon,
        'to': value.to,
        'toPointLat': value.toPointLat,
        'toPointLon': value.toPointLon,
        'approximatePrice': value.approximatePrice,
        'carType': value.carType,
        'date': value.date,
        'time': value.time,
        'creationDate': value.creationDate,
        'comment': value.comment,
        'userId': value.userId,
        'personCount': value.personCount,
        'luggage': value.luggage,
        'contact': value.contact,
        'contactType': value.contactType,
        'babySeats': value.babySeats,
        'flightNumber': value.flightNumber,
        'initData': value.initData,
        'passengerNames': value.passengerNames,
    };
}

