import {
  Autocomplete,
  AutocompleteItem,
  AutocompleteProps,
  CloseButton,
  Loader,
  Select,
  SelectItem,
  SelectProps,
} from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { LoaderCustom } from "../LoaderCustom";

export type AutocompleteCustomProps = {
  onChange: any;
  data: readonly (string | SelectItem)[];
  disabled?: boolean;
  hasClearButton?: boolean;
} & AutocompleteProps;

export const AutocompleteCustom = ({
  disabled,
  hasClearButton = true,
  rightSection,
  value,
  onChange,
  data,
  nothingFound,
  onItemSubmit,
  ...props
}: AutocompleteCustomProps) => {
  const [currentValue, setValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const onChangeInput = useCallback((value: string) => {
    setValue(value);
    onChange?.(value);
  }, []);

  const handlerOnItemSubmit = useCallback(
    (data: AutocompleteItem) => {
      if (onItemSubmit) {
        onItemSubmit(data);
        if (inputRef.current) {
          inputRef.current.blur();
        }
      }
    },
    [inputRef.current, onItemSubmit]
  );

  return (
    <Autocomplete
      {...props}
      data={data as []}
      ref={inputRef}
      filter={() => true}
      readOnly={disabled}
      size="lg"
      onChange={onChangeInput}
      onItemSubmit={handlerOnItemSubmit}
      value={currentValue}
      nothingFound={currentValue ? <LoaderCustom /> : nothingFound}
      transitionProps={{
        transition: "pop-top-left",
        duration: 80,
        timingFunction: "ease",
      }}
      withinPortal
      radius="lg"
      rightSection={
        hasClearButton ? (
          <CloseButton
            aria-label="Clear input"
            onClick={() => {
              setValue("");
            }}
            style={{ display: currentValue ? undefined : "none" }}
          />
        ) : (
          rightSection
        )
      }
      styles={(theme) => ({
        input: {
          "&:focus-within": {
            borderColor: theme.colors.violet[9],
          },
        },
        item: {
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: theme.colors.violet[0],
              color: theme.colors.violet[9],
            },
          },
          "&[data-hovered]": {},
        },
      })}
    />
  );
};
