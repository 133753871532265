/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FoodOrderLookupRestaurant } from './FoodOrderLookupRestaurant';
import {
    FoodOrderLookupRestaurantFromJSON,
    FoodOrderLookupRestaurantFromJSONTyped,
    FoodOrderLookupRestaurantToJSON,
} from './FoodOrderLookupRestaurant';
import type { FoodOrderStatus } from './FoodOrderStatus';
import {
    FoodOrderStatusFromJSON,
    FoodOrderStatusFromJSONTyped,
    FoodOrderStatusToJSON,
} from './FoodOrderStatus';
import type { FoodOrderUpdateStatus } from './FoodOrderUpdateStatus';
import {
    FoodOrderUpdateStatusFromJSON,
    FoodOrderUpdateStatusFromJSONTyped,
    FoodOrderUpdateStatusToJSON,
} from './FoodOrderUpdateStatus';

/**
 * 
 * @export
 * @interface FoodOrderLookup
 */
export interface FoodOrderLookup {
    /**
     * 
     * @type {string}
     * @memberof FoodOrderLookup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FoodOrderLookup
     */
    creationDate: string;
    /**
     * 
     * @type {string}
     * @memberof FoodOrderLookup
     */
    deliveryDate: string;
    /**
     * 
     * @type {string}
     * @memberof FoodOrderLookup
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof FoodOrderLookup
     */
    price: number;
    /**
     * 
     * @type {FoodOrderUpdateStatus}
     * @memberof FoodOrderLookup
     */
    status: FoodOrderUpdateStatus;
    /**
     * 
     * @type {Array<FoodOrderStatus>}
     * @memberof FoodOrderLookup
     */
    statusHistory: Array<FoodOrderStatus>;
    /**
     * 
     * @type {string}
     * @memberof FoodOrderLookup
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof FoodOrderLookup
     */
    paymentMethod: FoodOrderLookupPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof FoodOrderLookup
     */
    contact: string;
    /**
     * 
     * @type {string}
     * @memberof FoodOrderLookup
     */
    contactType: FoodOrderLookupContactTypeEnum;
    /**
     * 
     * @type {FoodOrderLookupRestaurant}
     * @memberof FoodOrderLookup
     */
    restaurant: FoodOrderLookupRestaurant;
}


/**
 * @export
 */
export const FoodOrderLookupPaymentMethodEnum = {
    Cash: 'CASH',
    BankCard: 'BANK_CARD'
} as const;
export type FoodOrderLookupPaymentMethodEnum = typeof FoodOrderLookupPaymentMethodEnum[keyof typeof FoodOrderLookupPaymentMethodEnum];

/**
 * @export
 */
export const FoodOrderLookupContactTypeEnum = {
    Telegram: 'TELEGRAM',
    Whatsapp: 'WHATSAPP',
    TelephoneNumber: 'TELEPHONE_NUMBER'
} as const;
export type FoodOrderLookupContactTypeEnum = typeof FoodOrderLookupContactTypeEnum[keyof typeof FoodOrderLookupContactTypeEnum];


/**
 * Check if a given object implements the FoodOrderLookup interface.
 */
export function instanceOfFoodOrderLookup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "creationDate" in value;
    isInstance = isInstance && "deliveryDate" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "statusHistory" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "paymentMethod" in value;
    isInstance = isInstance && "contact" in value;
    isInstance = isInstance && "contactType" in value;
    isInstance = isInstance && "restaurant" in value;

    return isInstance;
}

export function FoodOrderLookupFromJSON(json: any): FoodOrderLookup {
    return FoodOrderLookupFromJSONTyped(json, false);
}

export function FoodOrderLookupFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodOrderLookup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'creationDate': json['creationDate'],
        'deliveryDate': json['deliveryDate'],
        'address': json['address'],
        'price': json['price'],
        'status': FoodOrderUpdateStatusFromJSON(json['status']),
        'statusHistory': ((json['statusHistory'] as Array<any>).map(FoodOrderStatusFromJSON)),
        'comment': json['comment'],
        'paymentMethod': json['paymentMethod'],
        'contact': json['contact'],
        'contactType': json['contactType'],
        'restaurant': FoodOrderLookupRestaurantFromJSON(json['restaurant']),
    };
}

export function FoodOrderLookupToJSON(value?: FoodOrderLookup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'creationDate': value.creationDate,
        'deliveryDate': value.deliveryDate,
        'address': value.address,
        'price': value.price,
        'status': FoodOrderUpdateStatusToJSON(value.status),
        'statusHistory': ((value.statusHistory as Array<any>).map(FoodOrderStatusToJSON)),
        'comment': value.comment,
        'paymentMethod': value.paymentMethod,
        'contact': value.contact,
        'contactType': value.contactType,
        'restaurant': FoodOrderLookupRestaurantToJSON(value.restaurant),
    };
}

