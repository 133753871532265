/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  YachtsYachtByIdYachtIdGet200Response,
  YachtsYachtListGet200Response,
  YachtsYachtRoutesYachtIdGet200Response,
} from '../models';
import {
    YachtsYachtByIdYachtIdGet200ResponseFromJSON,
    YachtsYachtByIdYachtIdGet200ResponseToJSON,
    YachtsYachtListGet200ResponseFromJSON,
    YachtsYachtListGet200ResponseToJSON,
    YachtsYachtRoutesYachtIdGet200ResponseFromJSON,
    YachtsYachtRoutesYachtIdGet200ResponseToJSON,
} from '../models';

export interface YachtsYachtByIdYachtIdGetRequest {
    yachtId: string;
    initData: string;
}

export interface YachtsYachtListGetRequest {
    initData: string;
}

export interface YachtsYachtRoutesYachtIdGetRequest {
    initData: string;
    yachtId: string;
}

/**
 * 
 */
export class YachtsApi extends runtime.BaseAPI {

    /**
     * Returns all available yachts
     */
    async yachtsYachtByIdYachtIdGetRaw(requestParameters: YachtsYachtByIdYachtIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<YachtsYachtByIdYachtIdGet200Response>> {
        if (requestParameters.yachtId === null || requestParameters.yachtId === undefined) {
            throw new runtime.RequiredError('yachtId','Required parameter requestParameters.yachtId was null or undefined when calling yachtsYachtByIdYachtIdGet.');
        }

        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling yachtsYachtByIdYachtIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/yachts/yacht_by_id/{yachtId}`.replace(`{${"yachtId"}}`, encodeURIComponent(String(requestParameters.yachtId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => YachtsYachtByIdYachtIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns all available yachts
     */
    async yachtsYachtByIdYachtIdGet(requestParameters: YachtsYachtByIdYachtIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<YachtsYachtByIdYachtIdGet200Response> {
        const response = await this.yachtsYachtByIdYachtIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all available yachts
     */
    async yachtsYachtListGetRaw(requestParameters: YachtsYachtListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<YachtsYachtListGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling yachtsYachtListGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/yachts/yacht_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => YachtsYachtListGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns all available yachts
     */
    async yachtsYachtListGet(requestParameters: YachtsYachtListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<YachtsYachtListGet200Response> {
        const response = await this.yachtsYachtListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get routes by Yacht id
     */
    async yachtsYachtRoutesYachtIdGetRaw(requestParameters: YachtsYachtRoutesYachtIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<YachtsYachtRoutesYachtIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling yachtsYachtRoutesYachtIdGet.');
        }

        if (requestParameters.yachtId === null || requestParameters.yachtId === undefined) {
            throw new runtime.RequiredError('yachtId','Required parameter requestParameters.yachtId was null or undefined when calling yachtsYachtRoutesYachtIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/yachts/yacht_routes/{yachtId}`.replace(`{${"yachtId"}}`, encodeURIComponent(String(requestParameters.yachtId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => YachtsYachtRoutesYachtIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Get routes by Yacht id
     */
    async yachtsYachtRoutesYachtIdGet(requestParameters: YachtsYachtRoutesYachtIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<YachtsYachtRoutesYachtIdGet200Response> {
        const response = await this.yachtsYachtRoutesYachtIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
