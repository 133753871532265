import { YachtsApi } from "../../../client";

export const yachtsApi = new YachtsApi();

const fetchYachts = async ({ queryKey }: any) => {
  const initData = queryKey[1];

  const res = await yachtsApi.yachtsYachtListGet({
    initData,
  });

  if (!res.yachtsList) {
    throw new Error(`Yachts list fetch failed`);
  }

  return res.yachtsList;
};

export default fetchYachts;
