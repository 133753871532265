/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ExcursionOrderUpdateStatus = {
    Created: 'CREATED',
    Approved: 'APPROVED',
    Completed: 'COMPLETED',
    CancelledByUser: 'CANCELLED_BY_USER',
    CancelledByAdmin: 'CANCELLED_BY_ADMIN'
} as const;
export type ExcursionOrderUpdateStatus = typeof ExcursionOrderUpdateStatus[keyof typeof ExcursionOrderUpdateStatus];


export function ExcursionOrderUpdateStatusFromJSON(json: any): ExcursionOrderUpdateStatus {
    return ExcursionOrderUpdateStatusFromJSONTyped(json, false);
}

export function ExcursionOrderUpdateStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExcursionOrderUpdateStatus {
    return json as ExcursionOrderUpdateStatus;
}

export function ExcursionOrderUpdateStatusToJSON(value?: ExcursionOrderUpdateStatus | null): any {
    return value as any;
}

