import { seaTripsApi } from "../../../apiDrivers/seaTrips";
import { EUserSeaTripStatus } from "../../../client";

export const fetchSeaTripOrderById = async ({ queryKey }: any) => {
  const initData = queryKey[1];
  const orderId = queryKey[2];

  const res = await seaTripsApi.seaTripsSeaTripsOrdersOrderIdGet({
    orderId,
    initData,
  });

  return res.seaTripOrder;
};

export const updateSeaTripOrderStatusRequest = async (
  seaTripOrderId: string,
  seaTripTitle: string,
  userName: string,
  status: EUserSeaTripStatus,
  initData: string
) => {
  const res = await seaTripsApi.seaTripsSeaTripsOrdersSetOrderStatusPost({
    seaTripsSeaTripsOrdersSetOrderStatusPostRequest: {
      seaTripOrderId,
      seaTripTitle,
      userName,
      status,
      initData,
    },
  });

  return res;
};
