export const CharterImage = () => {
  return (
    <svg preserveAspectRatio="xMidYMid meet"  viewBox="0 0 321 320" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.6993 81.888C33.6406 80.608 35.5713 79.296 37.4913 77.952C58.0673 63.456 154.451 -2.36801 195.059 3.67999C240.691 10.464 337.459 160.224 312.787 204.608C288.147 248.992 164.851 326.048 132.179 313.728C99.5073 301.376 11.9873 165.792 6.45129 132.512C1.7153 104.064 24.8193 86.432 31.6993 81.888Z"
        fill="#92E3A9"
      />
      <path
        opacity="0.7"
        d="M31.6993 81.888C33.6406 80.608 35.5713 79.296 37.4913 77.952C58.0673 63.456 154.451 -2.36801 195.059 3.67999C240.691 10.464 337.459 160.224 312.787 204.608C288.147 248.992 164.851 326.048 132.179 313.728C99.5073 301.376 11.9873 165.792 6.45129 132.512C1.7153 104.064 24.8193 86.432 31.6993 81.888Z"
        fill="white"
      />
      <path d="M288.884 288.864H308.116" stroke="#263238" stroke-width="0.64" stroke-miterlimit="10" />
      <path d="M9.26794 288.864H283.476" stroke="#263238" stroke-width="0.64" stroke-miterlimit="10" />
      <path
        d="M13.236 236.193H304.724V227.873H13.236V236.193Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M229.716 232.032V41.0884"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M118.484 232.032V41.8564"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.3879 161.473H307.508"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.316 84.8643H296.596"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M157.812 124.928L154.932 126.688"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M206.292 95.0083L161.652 122.528"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M212.18 96.896L176.436 118.912"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M227.828 87.2642L217.012 93.9202"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.5959 219.808L37.7159 221.6"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M89.1079 189.92L44.4679 217.44"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M94.9958 191.776L59.2198 213.824"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M110.612 182.144L99.7959 188.8"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M204.116 222.112L201.236 223.872"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M252.628 192.224L207.988 219.712"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M258.516 194.08L222.74 216.096"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M274.132 184.448L263.316 191.104"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.6999 71.2959L28.8199 73.0879"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M80.1799 41.4082L35.5399 68.9282"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M86.068 43.2642L50.324 65.3122"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M101.684 33.6641L90.9 40.3201"
        stroke="#263238"
        stroke-width="0.32"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.0759 134.368C53.0759 134.368 53.0759 154.4 53.4919 156.544C53.9079 158.688 55.1879 159.584 55.1879 159.584L56.8199 255.456L60.3399 255.648L58.1959 193.408L57.3959 159.584C57.3959 159.584 58.8359 159.648 58.9639 156.128C59.0599 152.576 60.9799 138.336 60.9799 138.336L59.6039 137.92C59.6039 137.92 57.4599 155.584 57.0119 156.128C56.5959 156.672 55.4119 155.04 55.2199 153.344C54.9959 151.616 55.2199 134.272 55.2199 134.272L53.0759 134.368Z"
        fill="#A7A7A7"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.3959 159.584C57.5453 159.52 57.7159 159.456 57.9079 159.392C58.3239 159.072 58.8999 158.272 58.9639 156.128C59.0599 152.576 60.9799 138.336 60.9799 138.336L59.6039 137.92C59.6039 137.92 57.4599 155.584 57.0119 156.128C56.5959 156.672 55.4119 155.04 55.2199 153.344C54.9959 151.616 55.2199 134.272 55.2199 134.272L53.0759 134.368C53.0759 134.368 53.0759 154.4 53.4919 156.544C53.9079 158.688 55.1879 159.584 55.1879 159.584C55.1879 159.584 55.6999 160.224 57.3959 159.584Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M56.724 134.048C56.724 134.048 60.788 128.352 67.316 125.664C73.844 123.008 85.652 121.28 86.068 121.28C86.516 121.28 75.028 124.928 71.508 126.528C67.956 128.128 63.348 130.592 61.972 132.096C60.564 133.6 54.036 144.096 54.036 144.096C54.036 144.096 54.26 139.808 56.724 134.048Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M45.556 115.552C45.556 115.552 41.46 109.856 34.932 107.2C28.404 104.512 16.628 102.784 16.18 102.784C15.764 102.784 27.22 106.432 30.772 108.032C34.292 109.664 38.9 112.128 40.308 113.6C41.684 115.104 48.212 125.6 48.212 125.6C48.212 125.6 48.02 121.344 45.556 115.552Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M54.548 131.52C54.548 131.52 43.412 115.008 40.308 113.6C37.204 112.224 34.74 110.944 30.548 112C26.356 113.088 17.684 117.792 16.084 118.656C14.484 119.52 12.34 121.024 11.476 121.44C10.612 121.856 22.516 114.784 29.14 113.952C35.796 113.088 36.98 116.288 44.148 121.856C51.348 127.424 54.548 131.52 54.548 131.52Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M54.548 131.52C54.548 131.52 47.156 119.712 42.132 118.432C37.076 117.152 31.092 119.936 29.044 121.12C26.996 122.304 24.02 128.928 23.38 131.52C22.74 134.08 22.292 136 22.292 136C22.292 136 28.724 125.504 31.284 123.904C33.876 122.304 37.844 119.84 38.996 119.616C40.18 119.392 51.988 126.592 54.548 131.52Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M51.54 125.408C51.54 125.408 37.204 115.744 29.684 115.872C22.196 115.968 12.884 118.752 10.388 119.2C7.92396 119.616 5.45996 118.976 5.45996 118.976C5.45996 118.976 16.084 122.176 19.604 122.304C23.156 122.4 34.74 119.072 38.58 120.352C42.452 121.664 51.54 125.408 51.54 125.408Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M54.3239 135.264C54.3239 135.264 49.7159 126.592 45.8759 124.64C42.0039 122.72 38.1639 126.144 32.0519 128.512C25.9399 130.88 21.3319 132.48 21.3319 132.48C21.3319 132.48 31.4119 133.024 35.2519 131.616C39.1239 130.208 45.2359 127.552 46.6119 127.744C48.0199 127.968 54.3239 135.264 54.3239 135.264Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.684 138.112C57.684 138.112 53.588 116.16 53.588 108.128C53.588 100.096 53.716 96.6719 53.716 96.6719C53.716 96.6719 56.372 107.168 56.692 108.448C57.012 109.728 57.012 111.872 57.012 111.872C57.012 111.872 58.964 100.512 61.428 97.7279C63.892 94.9439 66.996 93.5679 66.996 93.5679C66.996 93.5679 64.212 98.8159 63.668 100.288C63.124 101.792 65.908 98.2719 68.916 97.4079C71.924 96.5439 79.316 94.9439 79.316 94.9439C79.316 94.9439 69.46 98.9119 68.276 100.192C67.092 101.472 64.212 106.208 63.988 106.72C63.764 107.264 60.244 113.376 59.284 116.896C58.324 120.448 57.684 138.112 57.684 138.112Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M60.1479 111.456L68.4999 105.888C68.4999 105.888 78.7719 104.256 80.4999 104.8C82.1959 105.344 84.5639 106.944 84.5639 106.944C84.5639 106.944 77.9079 106.304 75.0279 107.68C72.1479 109.088 65.8119 111.552 62.0679 116.576C58.3239 121.632 56.9159 127.2 56.4999 129.024C56.0519 130.848 55.2199 134.272 55.2199 134.272C55.2199 134.272 53.2679 124.96 54.0359 121.184C54.7719 117.44 60.1479 111.456 60.1479 111.456Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.62 105.44C49.62 105.44 54.548 112.096 55.316 114.24C56.052 116.384 57.236 108.864 62.484 104.256C67.732 99.6483 84.66 96.8643 84.66 96.8643C84.66 96.8643 64.084 106.848 60.98 110.816C57.876 114.752 54.9 121.504 54.9 121.824C54.9 122.144 54.9 125.792 54.9 125.792L49.62 105.44Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.1719 119.392C53.1719 119.392 46.3239 107.584 42.7719 103.52C39.2519 99.4559 27.5719 90.3359 27.5719 90.3359C27.5719 90.3359 32.4039 96.3519 38.6119 104.8C44.8199 113.28 54.6759 125.696 54.6759 125.696L53.1719 119.392Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.212 142.4C52.212 142.4 41.076 133.312 35.156 135.552C29.268 137.792 22.516 144.992 22.516 144.992L21.012 153.12C21.012 153.12 28.948 141.664 32.372 140.064C35.828 138.432 40.628 137.792 42.996 138.336C45.364 138.88 52.308 144.768 52.628 145.504C52.948 146.272 52.212 142.4 52.212 142.4Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.3 115.424C49.3 115.424 47.156 113.472 45.78 105.76C44.372 98.048 46.0039 91.104 46.0039 91.104C46.0039 91.104 46.7399 95.68 48.4679 101.056C50.1639 106.4 50.164 108.352 52.756 116.256C55.316 124.192 55.636 138.432 55.54 138.752C55.412 139.104 49.3 115.424 49.3 115.424Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M66.9 117.664C66.9 117.664 70.196 113.376 74.484 112.096C78.772 110.816 83.156 110.912 83.156 110.912C83.156 110.912 76.756 109.088 72.468 109.952C68.18 110.816 64.852 115.84 63.348 120.544C61.844 125.28 61.844 136.288 61.844 136.288C61.844 136.288 64.212 121.824 66.9 117.664Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50.772 121.824C50.772 121.824 50.996 117.312 47.412 112.096C43.828 106.88 36.532 104 31.444 101.792C26.356 99.616 23.348 98.688 23.348 98.688C23.348 98.688 34.804 107.712 37.812 108.96C40.82 110.24 43.028 111.968 45.108 114.4C47.188 116.832 50.868 125.76 50.868 126.336C50.868 126.912 50.772 121.824 50.772 121.824Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M63.668 128.256C63.668 128.256 67.732 122.592 74.292 119.904C80.82 117.216 92.596 115.52 93.012 115.52C93.46 115.52 82.004 119.168 78.452 120.768C74.932 122.368 70.324 124.832 68.916 126.336C67.54 127.84 60.98 138.336 60.98 138.336C60.98 138.336 61.204 134.048 63.668 128.256Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M54.676 144.224C54.676 144.224 65.812 127.744 68.916 126.336C72.02 124.96 74.484 123.648 78.676 124.736C82.836 125.792 91.54 130.528 93.14 131.36C94.74 132.224 96.884 133.728 97.748 134.144C98.612 134.592 86.708 127.52 80.052 126.656C73.428 125.792 72.244 129.024 65.076 134.592C57.876 140.16 54.676 144.224 54.676 144.224Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M54.676 144.224C54.676 144.224 62.068 132.448 67.092 131.168C72.148 129.888 78.132 132.672 80.18 133.824C82.196 135.008 85.204 141.664 85.844 144.224C86.484 146.816 86.932 148.736 86.932 148.736C86.932 148.736 80.5 138.24 77.908 136.608C75.348 135.008 71.38 132.544 70.196 132.352C69.012 132.128 57.236 139.296 54.676 144.224Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.684 138.112C57.684 138.112 72.02 128.48 79.54 128.576C87.028 128.704 96.34 131.488 98.804 131.904C101.268 132.352 103.732 131.68 103.732 131.68C103.732 131.68 93.14 134.912 89.588 135.008C86.068 135.136 74.484 131.808 70.644 133.088C66.772 134.368 57.684 138.112 57.684 138.112Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.524 133.824C61.524 133.824 59.06 126.336 61.62 120.864C64.212 115.424 67.54 107.904 67.54 107.904C67.54 107.904 68.692 117.44 66.676 123.872C64.628 130.304 61.524 133.824 61.524 133.824Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55.316 139.296C55.316 139.296 55.316 129.984 48.884 125.6C42.452 121.184 35.284 119.808 32.692 121.184C30.132 122.592 22.42 126.88 20.18 129.76C17.908 132.672 16.084 134.912 16.084 134.912C16.084 134.912 27.444 127.424 30.996 124.832C34.516 122.272 36.244 121.632 37.524 121.952C38.804 122.272 46.196 125.696 49.108 127.936C51.988 130.208 55.316 139.296 55.316 139.296Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55.54 140.8C55.412 140.48 55.732 138.016 51.348 137.696C46.964 137.376 43.636 139.296 42.9 140.896C42.132 142.528 42.9 146.816 42.996 147.232C43.092 147.648 43.636 141.568 48.788 140.384C53.908 139.2 55.54 140.8 55.54 140.8Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.812 144.768C53.812 144.768 52.404 141.76 49.62 138.56C46.836 135.328 40.852 132.352 37.94 134.144C35.06 135.968 31.732 143.584 30.676 145.728C29.588 147.872 27.124 154.4 26.932 154.72C26.708 155.04 33.236 148.96 36.02 145.312C38.804 141.664 40.756 136.64 42.356 136.192C43.956 135.776 47.284 138.976 47.284 138.976L55.22 150.432L53.812 144.768Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M54.2279 136.96C54.2279 136.96 52.8519 130.528 48.0199 128.8C43.2199 127.104 37.4279 126.432 35.1559 127.744C32.9159 129.024 23.0599 138.112 23.0599 138.112L21.7799 141.44C21.7799 141.44 36.2439 130.848 39.5719 129.76C42.8999 128.704 42.8999 128.704 46.4199 130.848C49.9399 132.992 54.2279 136.96 54.2279 136.96Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.3959 136.96C53.3959 136.96 39.3479 128.48 36.3399 127.424C33.3639 126.336 26.6119 129.024 24.6599 131.488C22.7399 133.952 15.9879 147.328 15.6679 148.288C15.3479 149.28 20.1799 137.92 27.4439 134.592C34.7399 131.264 39.4439 131.904 43.7319 132.352C48.0199 132.768 53.3959 139.936 53.3959 139.936V136.96Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M54.9 147.968C54.9 147.968 59.476 139.296 63.348 137.376C67.22 135.456 71.06 138.88 77.172 141.216C83.284 143.584 87.892 145.184 87.892 145.184C87.892 145.184 77.812 145.728 73.94 144.352C70.1 142.944 63.988 140.256 62.612 140.48C61.204 140.704 54.9 147.968 54.9 147.968Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55.316 139.296C55.316 139.296 57.684 132.544 59.06 123.776C60.468 114.976 58.1 99.2321 55.86 94.5281C53.588 89.7921 46.964 82.0801 46.964 82.0801C46.964 82.0801 58.74 92.3841 61.3 102.016C63.892 111.648 64.308 116.8 63.252 122.048C62.164 127.296 55.316 139.296 55.316 139.296Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.5001 125.76C52.5001 125.76 51.4761 121.824 50.2921 120.992C49.1401 120.192 46.8361 121.12 46.8361 121.12C46.8361 121.12 50.8681 124.704 50.8681 126.336C50.8681 127.936 54.9961 137.696 54.9961 137.696L52.5001 125.76Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50.292 120.992C50.292 120.992 48.564 122.208 47.22 123.456C45.876 124.672 39.412 131.04 39.028 131.424C38.644 131.84 42.388 123.328 43.22 121.696C44.052 120.096 45.524 118.912 46.004 119.008C46.452 119.072 50.42 119.968 50.292 120.992Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.772 164.768C74.772 164.768 74.772 182.304 74.388 184.192C74.004 186.08 72.916 186.848 72.916 186.848L71.476 270.848L68.372 271.04L70.26 216.48L70.964 186.848C70.964 186.848 69.716 186.912 69.62 183.808C69.524 180.704 67.828 168.224 67.828 168.224L69.044 167.84C69.044 167.84 70.932 183.328 71.284 183.808C71.668 184.288 72.692 182.88 72.884 181.376C73.076 179.872 72.884 164.672 72.884 164.672L74.772 164.768Z"
        fill="#A7A7A7"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M70.964 186.848C70.836 186.784 70.6867 186.72 70.516 186.656C70.164 186.4 69.652 185.696 69.62 183.808C69.524 180.704 67.828 168.224 67.828 168.224L69.044 167.84C69.044 167.84 70.932 183.328 71.284 183.808C71.668 184.288 72.692 182.88 72.884 181.376C73.076 179.872 72.884 164.672 72.884 164.672L74.772 164.768C74.772 164.768 74.772 182.304 74.388 184.192C74.004 186.08 72.916 186.848 72.916 186.848C72.916 186.848 72.468 187.424 70.964 186.848Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M71.572 164.448C71.572 164.448 67.988 159.488 62.26 157.12C56.564 154.784 46.228 153.28 45.844 153.28C45.46 153.28 55.508 156.48 58.612 157.888C61.716 159.296 65.748 161.44 66.964 162.752C68.18 164.096 73.908 173.28 73.908 173.28C73.908 173.28 73.716 169.536 71.572 164.448Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M81.364 148.256C81.364 148.256 84.916 143.264 90.644 140.928C96.372 138.592 106.708 137.088 107.092 137.088C107.444 137.088 97.396 140.288 94.324 141.696C91.22 143.104 87.188 145.248 85.972 146.56C84.724 147.872 78.996 157.088 78.996 157.088C78.996 157.088 79.188 153.312 81.364 148.256Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.46 162.24C73.46 162.24 83.22 147.776 85.972 146.56C88.692 145.344 90.836 144.224 94.516 145.152C98.164 146.08 105.78 150.24 107.188 150.976C108.596 151.712 110.452 153.056 111.22 153.408C111.956 153.792 101.556 147.584 95.732 146.848C89.908 146.08 88.852 148.896 82.58 153.792C76.276 158.688 73.46 162.24 73.46 162.24Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.46 162.24C73.46 162.24 79.956 151.904 84.372 150.784C88.788 149.664 94.036 152.096 95.828 153.12C97.588 154.176 100.244 160 100.788 162.24C101.364 164.48 101.716 166.176 101.716 166.176C101.716 166.176 96.084 156.992 93.844 155.584C91.604 154.176 88.116 152 87.092 151.808C86.036 151.648 75.732 157.92 73.46 162.24Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M76.084 156.896C76.084 156.896 88.692 148.448 95.252 148.544C101.812 148.64 110.004 151.072 112.148 151.456C114.324 151.808 116.468 151.264 116.468 151.264C116.468 151.264 107.188 154.08 104.084 154.176C100.98 154.272 90.836 151.36 87.444 152.48C84.084 153.6 76.084 156.896 76.084 156.896Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.652 165.536C73.652 165.536 77.684 157.92 81.076 156.224C84.468 154.528 87.828 157.536 93.172 159.616C98.548 161.664 102.58 163.072 102.58 163.072C102.58 163.072 93.748 163.552 90.356 162.336C86.996 161.12 81.652 158.784 80.404 158.944C79.188 159.136 73.652 165.536 73.652 165.536Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M70.74 168.032C70.74 168.032 74.292 148.8 74.292 141.76C74.292 134.72 74.196 131.712 74.196 131.712C74.196 131.712 71.86 140.896 71.572 142.048C71.284 143.168 71.284 145.024 71.284 145.024C71.284 145.024 69.62 135.072 67.444 132.64C65.3 130.208 62.58 128.992 62.58 128.992C62.58 128.992 65.012 133.568 65.46 134.912C65.94 136.224 63.508 133.12 60.884 132.352C58.26 131.616 51.764 130.208 51.764 130.208C51.764 130.208 60.404 133.664 61.428 134.816C62.484 135.936 65.012 140.064 65.204 140.544C65.396 140.992 68.468 146.336 69.332 149.44C70.164 152.544 70.74 168.032 70.74 168.032Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M68.564 144.672L61.236 139.776C61.236 139.776 52.244 138.368 50.74 138.848C49.236 139.296 47.156 140.704 47.156 140.704C47.156 140.704 52.98 140.16 55.508 141.376C58.068 142.592 63.604 144.768 66.868 149.152C70.164 153.568 71.38 158.464 71.764 160.064C72.148 161.664 72.884 164.672 72.884 164.672C72.884 164.672 74.58 156.48 73.94 153.216C73.268 149.92 68.564 144.672 68.564 144.672Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M77.7799 139.392C77.7799 139.392 73.4599 145.216 72.7879 147.104C72.1479 148.992 71.1239 142.4 66.5159 138.368C61.9079 134.336 47.0599 131.904 47.0599 131.904C47.0599 131.904 65.1079 140.64 67.8279 144.096C70.5479 147.584 73.1719 153.472 73.1719 153.76C73.1719 154.048 73.1719 157.248 73.1719 157.248L77.7799 139.392Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.676 151.616C74.676 151.616 80.692 141.28 83.764 137.728C86.868 134.144 97.108 126.176 97.108 126.176C97.108 126.176 92.884 131.424 87.444 138.848C82.004 146.272 73.364 157.152 73.364 157.152L74.676 151.616Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M75.508 171.808C75.508 171.808 85.3 163.808 90.452 165.792C95.604 167.744 101.524 174.048 101.524 174.048L102.836 181.184C102.836 181.184 95.892 171.136 92.884 169.728C89.876 168.32 85.652 167.744 83.604 168.224C81.524 168.704 75.412 173.856 75.156 174.528C74.868 175.168 75.508 171.808 75.508 171.808Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M78.068 148.128C78.068 148.128 79.924 146.432 81.14 139.68C82.388 132.928 80.98 126.816 80.98 126.816C80.98 126.816 80.308 130.848 78.804 135.552C77.3 140.256 77.3 141.952 75.06 148.896C72.788 155.84 72.532 168.32 72.596 168.608C72.692 168.896 78.068 148.128 78.068 148.128Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M62.6439 150.112C62.6439 150.112 59.7639 146.336 55.9879 145.216C52.2439 144.096 48.3719 144.192 48.3719 144.192C48.3719 144.192 54.0359 142.592 57.7799 143.36C61.5239 144.096 64.4359 148.512 65.7479 152.64C67.0599 156.768 67.0599 166.432 67.0599 166.432C67.0599 166.432 65.0119 153.76 62.6439 150.112Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M76.788 153.76C76.788 153.76 76.564 149.792 79.732 145.216C82.868 140.672 89.236 138.144 93.716 136.224C98.164 134.272 100.82 133.472 100.82 133.472C100.82 133.472 90.772 141.376 88.148 142.496C85.492 143.616 83.572 145.12 81.748 147.264C79.924 149.376 76.692 157.184 76.692 157.696C76.692 158.208 76.788 153.76 76.788 153.76Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.46 159.392C65.46 159.392 61.908 154.432 56.18 152.064C50.452 149.728 40.116 148.224 39.764 148.224C39.38 148.224 49.428 151.424 52.532 152.832C55.604 154.24 59.668 156.384 60.884 157.696C62.1 159.04 67.828 168.224 67.828 168.224C67.828 168.224 67.636 164.48 65.46 159.392Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.364 173.376C73.364 173.376 63.604 158.944 60.884 157.696C58.164 156.48 55.988 155.36 52.34 156.288C48.66 157.248 41.076 161.376 39.668 162.112C38.26 162.88 36.372 164.192 35.604 164.576C34.868 164.928 45.3 158.752 51.124 157.984C56.916 157.248 57.972 160.064 64.244 164.928C70.548 169.824 73.364 173.376 73.364 173.376Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.364 173.376C73.364 173.376 66.868 163.072 62.484 161.952C58.068 160.8 52.788 163.264 51.028 164.288C49.236 165.312 46.612 171.136 46.036 173.376C45.492 175.648 45.108 177.344 45.108 177.344C45.108 177.344 50.74 168.128 52.98 166.72C55.252 165.312 58.708 163.168 59.764 162.976C60.788 162.784 71.124 169.088 73.364 173.376Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M70.74 168.032C70.74 168.032 58.164 159.584 51.572 159.68C45.012 159.776 36.852 162.208 34.676 162.592C32.532 162.976 30.356 162.4 30.356 162.4C30.356 162.4 39.668 165.216 42.74 165.312C45.844 165.408 55.988 162.496 59.38 163.616C62.74 164.768 70.74 168.032 70.74 168.032Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M67.348 164.288C67.348 164.288 69.524 157.696 67.252 152.928C65.012 148.128 62.1 141.568 62.1 141.568C62.1 141.568 61.076 149.92 62.836 155.552C64.628 161.184 67.348 164.288 67.348 164.288Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M72.788 169.088C72.788 169.088 72.788 160.896 78.42 157.056C84.052 153.216 90.356 152 92.596 153.216C94.868 154.432 101.62 158.176 103.604 160.704C105.556 163.264 107.156 165.216 107.156 165.216C107.156 165.216 97.204 158.656 94.1 156.384C91.028 154.144 89.524 153.568 88.372 153.856C87.252 154.144 80.788 157.152 78.26 159.136C75.7 161.088 72.788 169.088 72.788 169.088Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M72.5959 170.4C72.6919 170.112 72.4359 167.936 76.2759 167.68C80.1159 167.392 83.0279 169.088 83.6999 170.496C84.3399 171.904 83.7 175.648 83.6039 176.032C83.5079 176.384 83.0279 171.04 78.5159 170.016C74.0039 168.992 72.5959 170.4 72.5959 170.4Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.1 173.856C74.1 173.856 75.348 171.232 77.78 168.416C80.212 165.6 85.46 162.976 88.02 164.576C90.548 166.176 93.46 172.832 94.388 174.72C95.348 176.576 97.492 182.304 97.684 182.592C97.876 182.88 92.148 177.536 89.684 174.336C87.252 171.136 85.556 166.72 84.148 166.336C82.74 165.984 79.828 168.8 79.828 168.8L72.884 178.848L74.1 173.856Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.748 167.008C73.748 167.008 74.964 161.376 79.188 159.872C83.412 158.368 88.4681 157.792 90.4521 158.944C92.4361 160.064 101.044 168.032 101.044 168.032L102.196 170.944C102.196 170.944 89.5241 161.664 86.6121 160.704C83.7001 159.776 83.7 159.776 80.596 161.664C77.492 163.52 73.748 167.008 73.748 167.008Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.484 167.008C74.484 167.008 86.804 159.584 89.428 158.656C92.052 157.696 97.972 160.064 99.636 162.208C101.332 164.384 107.252 176.128 107.54 176.96C107.828 177.792 103.604 167.84 97.204 164.928C90.836 162.016 86.708 162.592 82.932 162.976C79.188 163.36 74.484 169.632 74.484 169.632V167.008Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.172 176.672C73.172 176.672 69.14 169.088 65.748 167.392C62.388 165.696 58.996 168.704 53.652 170.752C48.308 172.832 44.244 174.24 44.244 174.24C44.244 174.24 53.076 174.72 56.468 173.472C59.828 172.256 65.204 169.92 66.42 170.112C67.636 170.304 73.172 176.672 73.172 176.672Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M72.788 169.088C72.788 169.088 70.74 163.168 69.524 155.456C68.308 147.744 70.356 133.952 72.34 129.824C74.292 125.696 80.116 118.944 80.116 118.944C80.116 118.944 69.78 127.936 67.54 136.384C65.3 144.864 64.916 149.344 65.844 153.952C66.804 158.56 72.788 169.088 72.788 169.088Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M75.2519 157.184C75.2519 157.184 76.1799 153.76 77.1719 153.024C78.1959 152.32 80.2119 153.152 80.2119 153.152C80.2119 153.152 76.6919 156.288 76.6919 157.696C76.6919 159.104 73.0759 167.648 73.0759 167.648L75.2519 157.184Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M77.1719 153.024C77.1719 153.024 78.7079 154.112 79.8919 155.168C81.0759 156.256 86.7399 161.856 87.0599 162.176C87.4119 162.528 84.1479 155.072 83.4119 153.664C82.6759 152.224 81.3639 151.232 80.9799 151.264C80.5639 151.328 77.0759 152.128 77.1719 153.024Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.3239 202.752C74.3239 202.752 82.9639 199.584 88.2119 197.856C93.4919 196.096 97.4599 200.416 97.4599 200.416C97.4599 200.416 90.8999 201.344 88.5639 201.472C86.2279 201.568 82.7399 201.696 80.6279 202.528C78.5159 203.328 84.8199 203.232 86.9319 205.312C89.0439 207.424 93.4919 212.8 94.1639 214.432C94.8679 216.064 87.0599 206.72 81.5559 205.312C76.0519 203.936 71.7319 206.848 71.7319 206.848L74.3239 202.752Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.78 215.616C65.78 215.616 66.612 219.456 65.78 230.208C64.948 240.96 64.1479 256.288 64.1479 256.288L66.964 256.64C66.964 256.64 68.34 226.464 68.34 224.48C68.34 222.496 68.4679 215.36 68.4679 215.36L65.78 215.616Z"
        fill="#A7A7A7"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M67.988 204.256C67.796 203.808 64.5 196.8 62.74 195.616C60.98 194.464 56.564 192.832 54.548 192.832C52.564 192.832 47.444 193.408 47.444 193.408C47.444 193.408 56.564 195.52 59.124 197.504C61.684 199.488 66.132 204.032 66.26 204.864C66.356 205.664 67.316 207.552 67.316 207.552C67.316 207.552 69.3 207.072 67.988 204.256Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.4279 208.832C65.4279 208.832 58.4199 208 55.8599 209.888C53.2679 211.744 48.7239 217.024 48.7239 219.808C48.7239 222.624 48.9479 229.056 48.9479 228.448C48.9479 227.872 50.2279 218.752 53.1719 215.968C56.0839 213.152 57.3639 211.52 61.2359 211.04C65.0759 210.592 65.0759 210.592 65.0759 210.592L69.0439 212.672L65.4279 208.832Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M70.804 207.136C70.804 207.136 72.212 205.952 77.236 205.248C82.26 204.544 83.668 204.096 86.804 204.672C89.972 205.248 93.364 206.56 93.364 206.56C93.364 206.56 83.316 206.304 82.356 206.304C81.428 206.304 88.436 210.88 89.716 212.256C91.028 213.664 92.18 216.608 92.18 216.608C92.18 216.608 82.132 209.344 80.372 208.544C78.612 207.712 77.94 207.232 77.94 207.232C77.94 207.232 75.124 206.784 73.94 207.136C72.788 207.488 71.86 207.84 71.508 207.936C71.156 208.064 69.876 208.416 69.876 208.416L70.804 207.136Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M69.2999 203.328C69.2999 203.328 69.8759 196.544 73.1399 193.504C76.4039 190.496 87.6359 184.64 87.6359 184.64L94.5159 181.248C94.5159 181.248 92.5319 185.696 88.3399 187.456C84.1159 189.184 82.7399 187.904 80.7239 190.016C78.7399 192.128 75.6039 196.448 75.0279 198.432C74.4199 200.416 70.5799 205.92 70.3239 207.2C70.0999 208.48 69.2999 203.328 69.2999 203.328Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M69.7479 205.216C69.7479 205.216 70.4519 194.688 69.2999 192C68.1159 189.312 64.4999 183.104 63.6679 181.728C62.8679 180.32 61.8119 179.136 61.8119 179.136C61.8119 179.136 64.7239 188.384 65.5559 193.184C66.3559 197.952 66.4839 199.488 66.7079 201.472C66.9639 203.456 67.1879 205.216 67.1879 205.216H69.7479Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M68.2439 209.055C68.2439 209.055 69.7479 204.863 72.0839 201.119C74.4199 197.375 77.8119 196.543 78.3879 196.543C78.9959 196.543 80.9799 196.543 80.9799 196.543C80.9799 196.543 76.4039 198.783 75.8279 200.895C75.2519 202.975 73.9719 207.295 73.0119 208.255C72.0839 209.183 69.7479 214.079 69.1719 215.263C68.5959 216.415 67.0599 217.023 67.0599 217.023C67.0599 217.023 64.9479 213.855 68.2439 209.055Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M70.0999 209.408L67.0599 217.024L67.9879 217.824C67.9879 217.824 69.3959 216.192 70.4519 214.656C71.5079 213.152 72.3399 211.968 72.3399 211.296C72.3399 210.592 70.0999 209.408 70.0999 209.408Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M68.8839 215.808C68.8839 215.808 64.4679 206.112 63.0599 204.032C61.6519 201.92 60.1159 200.16 57.0759 199.328C54.0359 198.528 48.9159 196.992 44.3399 197.824C39.7959 198.656 31.9559 199.68 31.9559 199.68C31.9559 199.68 37.5879 202.272 44.3399 201.792C51.1239 201.344 57.0759 201.696 57.0759 201.696C57.0759 201.696 58.4839 202.272 59.5399 205.664C60.5959 209.056 62.8039 212.096 64.2119 213.376C65.6199 214.656 67.9559 217.792 67.9559 217.792L68.8839 215.808Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M69.748 205.216C69.748 205.216 64.8519 203.232 63.6679 204.96C62.5159 206.72 60.6279 215.84 60.6279 215.84C60.6279 215.84 59.7 220.64 59.476 221.088C59.252 221.568 65.6519 216.672 67.1879 212.448C68.6919 208.256 69.748 205.216 69.748 205.216Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.4199 208.256C74.4199 208.256 70.0999 208.352 70.0999 209.408C70.0999 210.464 70.6759 212.32 70.4519 214.656C70.2279 217.024 70.0039 219.104 70.0039 219.104C70.0039 219.104 74.6759 217.12 74.8999 214.432C75.1239 211.744 74.4199 208.256 74.4199 208.256Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M76.4999 208.224C76.4999 208.224 80.9479 211.264 82.4839 214.08C83.9879 216.896 85.1719 222.72 85.1719 222.72C85.1719 222.72 82.1319 216.896 79.7959 213.728C77.4599 210.56 73.9399 208 73.2359 208.832C72.5319 209.632 74.8679 207.52 76.4999 208.224Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M74.644 287.104H50.58L45.396 254.208H79.828L74.644 287.104Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M77.3 288.864H47.156L45.652 285.856H78.804L77.3 288.864Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M182.132 229.12C182.132 229.12 175.956 236.608 174.644 243.232C173.3 249.856 162.26 278.112 162.26 278.112C162.26 278.112 160.052 282.688 159.764 284.736C159.476 286.784 160.34 288.128 162.132 288.864C163.892 289.6 173.3 289.728 176.116 289.6C178.9 289.44 185.076 290.624 189.204 290.176C193.332 289.728 195.828 287.968 195.22 286.656C194.644 285.312 185.076 283.84 184.052 283.264C183.028 282.688 174.324 275.456 174.196 274.592C174.036 273.696 199.348 230.144 200.084 228.224C200.82 226.304 199.348 222.912 199.348 222.912L182.132 229.12Z"
        fill="#D9D9D9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M184.052 283.264C183.412 282.88 179.732 279.904 177.044 277.504L175.22 280.896C175.22 280.896 174.196 282.08 169.492 280.768C167.092 280.096 164.436 278.848 162.388 277.792C162.303 278.005 162.26 278.112 162.26 278.112C162.26 278.112 160.052 282.688 159.764 284.736C159.476 286.784 160.34 288.128 162.132 288.864C163.892 289.6 173.3 289.728 176.116 289.6C178.9 289.44 185.076 290.624 189.204 290.176C193.332 289.728 195.828 287.968 195.22 286.656C194.644 285.312 185.076 283.84 184.052 283.264Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M176.116 289.6C178.9 289.44 185.076 290.624 189.204 290.176C193.332 289.728 195.828 287.968 195.22 286.656C195.177 286.528 195.049 286.4 194.836 286.272C194.068 286.816 193.172 287.328 192.148 287.68C189.044 288.704 179.796 289.44 171.092 287.104C164.948 285.408 161.3 285.376 159.7 285.504C159.7 287.136 160.596 288.224 162.132 288.864C163.892 289.6 173.3 289.728 176.116 289.6Z"
        fill="white"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M183.348 190.496L201.396 223.264L175.732 232.672L164.148 208.704L183.348 190.496Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path d="M176.532 229.6L199.668 221.568" stroke="#263238" stroke-width="0.64" stroke-miterlimit="10" />
      <path d="M183.06 222.208L185.364 225.312" stroke="#263238" stroke-width="0.64" stroke-miterlimit="10" />
      <path d="M172.404 207.84L181.396 219.968" stroke="#263238" stroke-width="0.64" stroke-miterlimit="10" />
      <path
        d="M138.964 160.48C138.964 160.48 135.924 174.688 135.252 178.4C134.58 182.112 133.012 189.408 133.012 190.336C133.012 191.296 138.708 193.952 140.436 192.864C142.164 191.808 142.548 188.768 141.748 187.84C140.98 186.912 140.98 186.912 140.98 186.912C140.98 186.912 139.892 181.056 139.892 180.512C139.892 180 144.948 171.232 144.948 171.232L138.964 160.48Z"
        fill="#D9D9D9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M140.468 185.152C140.468 185.152 143.028 187.168 143.156 187.52C143.316 187.872 141.492 191.264 141.14 191.264C140.788 191.264 139.892 191.2 139.828 190.912C139.764 190.656 140.66 187.456 140.66 187.456"
        fill="#D9D9D9"
      />
      <path
        d="M140.468 185.152C140.468 185.152 143.028 187.168 143.156 187.52C143.316 187.872 141.492 191.264 141.14 191.264C140.788 191.264 139.892 191.2 139.828 190.912C139.764 190.656 140.66 187.456 140.66 187.456"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M137.876 181.6C137.876 181.6 139.348 183.968 139.476 185.216C139.604 186.464 138.58 188.192 138.58 188.192"
        fill="#D9D9D9"
      />
      <path
        d="M137.876 181.6C137.876 181.6 139.348 183.968 139.476 185.216C139.604 186.464 138.58 188.192 138.58 188.192"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M118.42 225.44C118.1 225.44 117.748 225.344 117.428 225.152C116.532 224.64 116.244 223.456 116.756 222.528L136.628 189.024C137.172 188.128 138.324 187.808 139.252 188.352C140.148 188.896 140.468 190.08 139.924 190.976L120.084 224.48C119.7 225.088 119.092 225.44 118.42 225.44Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M139.924 190.976C140.468 190.08 140.148 188.896 139.252 188.352C138.324 187.808 137.172 188.128 136.628 189.024L133.108 194.944L136.308 197.056L139.924 190.976Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M121.78 222.176L81.7799 285.92L61.5879 274.848C59.6039 273.728 58.9319 271.168 60.1799 269.248L97.5239 209.568C98.6439 207.808 101.012 207.328 102.708 208.544L121.78 222.176Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M85.5879 285.216C85.5879 282.176 83.0919 279.68 80.0519 279.68C76.9799 279.68 74.5159 282.176 74.5159 285.216C74.5159 288.256 76.9799 290.752 80.0519 290.752C83.0919 290.752 85.5879 288.256 85.5879 285.216Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M66.5799 277.152L64.9799 276.128L105.492 211.424L107.124 212.448L66.5799 277.152Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M89.7159 257.152C88.8519 257.12 87.9559 256.864 87.1879 256.352L82.9639 253.6L84.6919 250.944L88.9159 253.696C89.7159 254.208 90.7719 253.984 91.3159 253.184L98.5159 242.432C98.7719 242.016 98.8679 241.536 98.7719 241.088C98.6439 240.608 98.3879 240.224 97.9719 239.968L93.2679 237.184L94.8999 234.432L99.6039 237.216C100.756 237.92 101.588 239.072 101.876 240.384C102.196 241.696 101.908 243.072 101.172 244.192L93.9719 254.976C92.9799 256.448 91.3479 257.216 89.7159 257.152Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M135.7 220.864L101.844 194.944C101.844 194.944 99.0279 187.296 97.7159 186.56C95.0919 185.088 89.4919 188.48 85.9399 191.264C82.4199 194.08 79.7639 195.552 79.7639 195.552C79.7639 195.552 69.9079 201.152 67.9879 204.224C66.0679 207.328 67.5399 209.088 69.0119 209.376C70.4839 209.664 89.3319 206.432 90.3559 206.272C91.4119 206.144 96.5319 205.984 98.1639 206.88C99.7959 207.744 127.028 236.896 129.972 239.68C132.884 242.496 136.724 246.016 140.116 244.832C143.508 243.68 150.996 233.952 150.996 233.952L135.7 220.864Z"
        fill="#D9D9D9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M97.7159 186.56C95.0919 185.088 89.4919 188.48 85.9399 191.264C82.4199 194.08 79.7639 195.552 79.7639 195.552C79.7639 195.552 69.9079 201.152 67.9879 204.224C66.0679 207.328 67.5399 209.088 69.0119 209.376C70.4839 209.664 89.3319 206.432 90.3559 206.272C90.5479 206.272 90.8359 206.24 91.1879 206.208C91.1879 205.664 91.1559 205.024 90.9639 204.384C90.3559 202.464 87.1239 200.704 87.1239 200.704L101.844 194.944C101.844 194.944 99.0279 187.296 97.7159 186.56Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M97.7159 186.56C95.0919 185.088 89.4919 188.48 85.9399 191.264C82.4199 194.08 79.7639 195.552 79.7639 195.552C79.7639 195.552 69.9079 201.152 67.9879 204.224C66.8999 206.016 66.9319 207.328 67.4119 208.224C68.5959 204.224 74.5159 199.872 78.7399 197.888C83.1559 195.84 87.2839 192 90.0679 189.952C92.1159 188.448 96.3399 187.712 98.4839 187.456C98.1959 187.008 97.9399 186.688 97.7159 186.56Z"
        fill="white"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M146.324 175.808C146.324 175.808 142.996 185.408 142.996 188.128C142.996 190.88 143.956 193.632 143.956 193.632L133.396 220.384L153.364 237.504L181.972 191.264L146.324 175.808Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path d="M147.636 214.176L142.612 224.32" stroke="#263238" stroke-width="0.64" stroke-miterlimit="10" />
      <path d="M159.476 190.208L149.46 210.464" stroke="#263238" stroke-width="0.64" stroke-miterlimit="10" />
      <path d="M136.34 218.816L153.204 234.112" stroke="#263238" stroke-width="0.64" stroke-miterlimit="10" />
      <path
        d="M180.212 100.64C180.212 100.64 168.98 108.32 165.268 114.88C161.556 121.408 144.98 177.344 144.98 177.344C144.98 177.344 155.028 188.256 168.788 191.968C182.548 195.68 187.572 193.504 187.572 193.504C187.572 193.504 193.684 167.072 194.1 165.76C194.548 164.448 209.044 138.816 208.596 131.2C208.148 123.552 205.204 105.728 199.956 104.256C194.74 102.816 183.796 100.16 180.212 100.64Z"
        fill="white"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M189.172 133.856C189.172 133.856 180.372 136 178.452 137.408"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M184.404 141.056C181.492 143.2 178.228 145.632 177.492 146.176"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M189.172 137.504C189.172 137.504 188.116 138.304 186.644 139.392"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M214.292 79.6478C214.292 79.6478 215.38 84.2878 214.932 85.5358C214.452 86.7838 214.164 87.7118 214.164 87.7118C214.164 87.7118 216.18 90.6558 215.86 91.9038C215.54 93.1518 213.684 93.7598 213.684 93.7598C213.684 93.7598 212.756 97.4718 211.508 100.416C210.26 103.36 208.244 104.16 206.708 103.232C205.14 102.304 202.356 100.128 202.356 100.128L199.892 105.536L184.98 97.6318L186.836 92.0638C186.836 92.0638 188.404 78.5598 192.884 74.6878C197.396 70.7838 207.028 72.6718 210.26 73.5998C213.524 74.5278 214.292 79.6478 214.292 79.6478Z"
        fill="#D9D9D9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M214.292 79.6478C214.292 79.6478 215.86 79.8078 216.788 77.7918C217.716 75.7758 217.876 72.9598 216.468 74.0478C215.092 75.1358 215.092 75.1358 215.092 75.1358C215.092 75.1358 215.252 69.8558 212.276 67.8398C209.332 65.8238 206.228 67.0718 206.228 67.0718C206.228 67.0718 204.692 63.9678 196.468 66.6238C188.244 69.2478 185.14 76.9918 185.14 81.0238C185.14 85.0558 186.836 92.0639 186.836 92.0639C186.836 92.0639 188.244 93.1518 189.78 93.1518C191.348 93.1518 194.772 90.4958 194.772 90.4958C194.772 90.4958 192.756 85.0558 193.972 83.3598C195.22 81.6638 198.932 82.5918 199.572 84.6078C200.18 86.6238 200.66 87.5518 200.66 87.5518C200.66 87.5518 202.196 88.1598 202.516 87.2318C202.836 86.3038 203.124 83.0398 203.124 83.0398C203.124 83.0398 205.94 81.6638 206.228 80.7358C206.548 79.8078 205.3 77.7918 206.708 76.8318C208.116 75.9038 213.844 77.1518 214.292 79.6478Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M212.276 84.2876C212.276 84.2876 210.74 90.0476 212.148 90.8156C213.524 91.5836 214.932 85.5356 214.932 85.5356C214.932 85.5356 214.772 84.1276 212.276 84.2876Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path d="M212.276 84.2875L198.164 83.0396" stroke="#263238" stroke-width="0.64" stroke-miterlimit="10" />
      <path
        d="M209.332 92.5117C209.332 92.5117 209.044 94.2077 211.348 95.2957"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M194.772 90.4961C194.772 90.4961 195.38 93.2801 196.916 93.2801C198.484 93.2801 199.092 91.1041 199.092 91.1041"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M211.508 82.5918C211.508 82.5918 213.524 81.5038 214.932 83.3598"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M202.868 104.832C202.868 104.832 193.588 98.0158 190.676 96.5758C187.764 95.1038 182.26 95.7118 182.26 95.7118L180.212 100.64C180.212 100.64 189.812 100.48 194.74 102.816C199.668 105.152 203.892 106.304 203.892 106.304L202.868 104.832Z"
        fill="white"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M202.932 106.08C202.932 106.08 194.292 99.8077 187.828 101.376C181.364 102.944 171.188 115.872 170.42 116.64C169.62 117.44 164.34 111.968 162.58 112.352C160.82 112.736 151.412 116.64 148.66 122.528C145.94 128.416 145.94 133.696 145.94 133.696C145.94 133.696 133.588 152.896 136.34 159.936C139.06 166.976 138.676 171.296 141.812 173.44C144.948 175.616 156.692 179.52 161.588 178.944C166.484 178.336 171.764 173.056 172.948 169.152C174.132 165.216 206.26 137.6 206.26 137.6L175.124 162.304C175.124 162.304 173.332 151.904 173.556 150.944C173.748 149.952 178.644 144.48 178.452 137.408C178.228 130.368 174.516 118.432 174.516 118.432C174.516 118.432 186.452 105.28 189.812 104.32C193.14 103.328 202.932 106.08 202.932 106.08Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M238.996 172.768L238.836 168.896C238.804 168.416 238.964 167.968 239.316 167.616C239.636 167.264 240.084 167.072 240.564 167.072L253.524 167.2C254.484 167.232 255.22 168 255.252 168.928V172.768H258.452V168.928C258.42 168 258.164 167.104 257.716 166.368C256.852 164.96 255.316 164.032 253.556 164L240.596 163.872C239.252 163.872 237.94 164.416 237.012 165.376C236.084 166.368 235.572 167.68 235.636 169.024L235.796 172.768H238.996Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M210.388 139.392L213.044 146.752L245.716 155.872C245.716 155.872 253.652 162.944 253.94 165.28C254.26 167.648 254.26 168.224 253.652 169.12C253.076 169.984 250.996 169.984 250.996 169.984C250.996 169.984 248.372 171.168 245.716 172.064C243.06 172.928 241.876 172.064 240.404 169.696C238.932 167.36 237.748 162.048 237.748 162.048L203.028 159.392L194.772 142.912L210.388 139.392Z"
        fill="#D9D9D9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M212.788 179.776H207.444C206.932 179.776 206.516 179.328 206.516 178.816V176.64C206.516 176.128 206.932 175.712 207.444 175.712H212.788C213.3 175.712 213.716 176.128 213.716 176.64V178.816C213.716 179.328 213.3 179.776 212.788 179.776Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M212.788 216.064H207.444C206.932 216.064 206.516 215.648 206.516 215.104V212.96C206.516 212.416 206.932 212 207.444 212H212.788C213.3 212 213.716 212.416 213.716 212.96V215.104C213.716 215.648 213.3 216.064 212.788 216.064Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M279.028 221.056H217.716C214.42 221.056 211.732 218.368 211.732 215.072V176.8C211.732 173.472 214.42 170.816 217.716 170.816H279.028C282.324 170.816 285.012 173.472 285.012 176.8V215.072C285.012 218.368 282.324 221.056 279.028 221.056Z"
        fill="#92E3A9"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M217.556 177.088V170.816C214.324 170.912 211.732 173.536 211.732 176.8V181.12H213.556C215.764 181.12 217.556 179.328 217.556 177.088Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M211.732 211.104V215.072C211.732 218.336 214.324 220.96 217.556 221.056V215.104C217.556 212.896 215.764 211.104 213.556 211.104H211.732Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M277.972 195.936C277.972 202.656 280.5 208.096 283.604 208.096C284.084 208.096 284.564 207.968 285.012 207.712V184.128C284.564 183.904 284.084 183.744 283.604 183.744C280.5 183.744 277.972 189.216 277.972 195.936Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
      <path
        d="M193.3 107.872C193.3 107.872 200.372 108.48 203.028 111.424C205.684 114.368 212.724 141.728 212.724 141.728L195.06 146.752L185.652 125.536"
        fill="white"
      />
      <path
        d="M193.3 107.872C193.3 107.872 200.372 108.48 203.028 111.424C205.684 114.368 212.724 141.728 212.724 141.728L195.06 146.752L185.652 125.536"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
