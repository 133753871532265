import { BASE_PATH, FoodLookup, Food } from "../../client";

export type TRestaurantMenuItem = {
  id: string;
  image: string;
  name: string;
  price: number;
  description: string;
  category: string;
  count?: number;
};

export function normalizeRestaurantMenu(restaurant_menu: (FoodLookup | Food)[]): TRestaurantMenuItem[] {
  return restaurant_menu?.map((food) => {
    return {
      id: food.id,
      image: `${BASE_PATH}/${food.images[0]}`,
      name: food.title,
      price: food.price,
      description: food.shortDescription,
      category: food.category,
      count: "count" in food ? food.count : 0,
    };
  });
}
