import { ActionIcon, Avatar, Flex, Text } from "@mantine/core";

import { Minus, Plus } from "@gravity-ui/icons";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EColors, ECurrency } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { TRestaurantMenuItem } from "../../pages/DeliveryMenuPage/definitions";
import { AddItemAction, RemoveItemAction, selectCartItemCountById } from "../../store/deliveryCartStore";
import { PriceBadge } from "../PriceBadge";

export const MenuItem = ({
  menuItem,
  hasAvatar = true,
  hasPriceButton = false,
  hasCountValue = false,
  counter = {
    hasCounter: false,
    updateCount: () => {},
  },
  icon = {
    hasIcon: false,
    iconContent: <></>,
  },
  onBadgeClick,
  onMenuItemClick,
  restaurantId,
}: {
  menuItem: {
    name: string;
    count?: number;
    description: string;
    price: number;
    image: string;
    category: string;
    id: string;
  };
  restaurantId: string;
  hasAvatar?: boolean;
  icon?: {
    hasIcon: boolean;
    iconContent?: any;
  };
  counter?: {
    hasCounter: boolean;
    updateCount: (value: number) => void;
  };
  hasPriceButton?: boolean;
  hasCountValue?: boolean;
  count?: number;
  onBadgeClick?: (param: any) => void;
  onMenuItemClick?: (param: any) => void;
}): JSX.Element => {
  //   const user = useStoreUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentCounter, setCounter] = useState(menuItem.count || 0);

  const cartItemCount = useSelector(selectCartItemCountById(menuItem.id));

  const handlerDecrement = useCallback(() => {
    counter.updateCount(currentCounter ? currentCounter - 1 : 0);
    return setCounter((sc: number) => (sc ? sc - 1 : 0));
  }, [currentCounter]);

  const handlerIncrement = useCallback(() => {
    counter.updateCount(currentCounter + 1);
    return setCounter((sc: number) => sc + 1);
  }, [currentCounter]);

  const dispatchAddNewItem = useFn((item: TRestaurantMenuItem) => {
    dispatch(
      AddItemAction({
        ...item,
        restaurantId,
      })
    );
  });

  const dispatchRemoveItem = useFn((item: TRestaurantMenuItem) => {
    dispatch(
      RemoveItemAction({
        id: item.id,
      })
    );
  });

  const onClickMinus = useCallback(() => {
    dispatchRemoveItem(menuItem);
  }, [menuItem]);

  const onClickPlus = useCallback(() => {
    dispatchAddNewItem(menuItem);
  }, [menuItem]);

  return (
    <Flex gap="lg" justify="space-between" align={counter.hasCounter ? "center" : "flex-start"}>
      <Flex gap={"md"} onClick={onMenuItemClick}>
        {hasAvatar && <Avatar variant="outline" radius="md" size="lg" color="grape" src={menuItem.image} />}
        {icon.hasIcon && icon.iconContent}
        <Flex direction="column" justify={hasPriceButton ? "space-between" : "center"}>
          <Text c={EColors.gray_1} weight={700} size={"lg"}>
            {menuItem.name}
          </Text>
          {hasPriceButton && (
            <Text c={EColors.gray_2} weight={400}>
              {menuItem.description}
            </Text>
          )}
          {counter.hasCounter && (
            <Text c={EColors.gray_2} weight={400}>
              {menuItem.price}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex align={"start"}>
        {hasPriceButton && (
          <PriceBadge
            onBadgeClick={onBadgeClick}
            banknote={ECurrency.TRY}
            price={menuItem.price}
            size="small"
            hasCounter={true}
            currentCounter={cartItemCount || currentCounter}
          ></PriceBadge>
        )}
        {hasCountValue && (
          <PriceBadge
            onBadgeClick={onBadgeClick}
            banknote={ECurrency.TRY}
            price={menuItem.price}
            size="small"
            hasCounter={true}
            readOnly={true}
            currentCounter={cartItemCount || currentCounter}
          ></PriceBadge>
        )}
        {counter.hasCounter && (
          <Flex justify={"center"} gap={"md"} align={"center"}>
            <ActionIcon onClick={onClickMinus} color="violet">
              <Minus></Minus>
            </ActionIcon>
            <Text c={EColors.gray_1} weight={700} size={"lg"}>
              {cartItemCount}
            </Text>
            <ActionIcon onClick={onClickPlus} color="violet">
              <Plus></Plus>
            </ActionIcon>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
