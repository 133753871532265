import { ExcursionOrder } from "../../../client";
import { excursionsApi } from "../../../apiDrivers/excursions";

export const createExcursionOrderQuery = async (excursionOrder: ExcursionOrder, initData: string) => {
  const res = await excursionsApi.excursionsCreateExcursionOrderPost({
    excursionsCreateExcursionOrderPostRequest: {
      excursionOrder,
      initData,
    },
  });

  return res;
};
