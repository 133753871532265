import { Textarea, TextareaProps } from "@mantine/core";

export const TextAreaCustom = ({
  placeholder,
  label,
  required = false,
  disabled,
  value,
  onChange,
  ...rest
}: {
  label: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
  onChange?: any;
} & TextareaProps) => {
  return (
    <Textarea
      {...rest}
      value={value}
      readOnly={disabled}
      placeholder={placeholder}
      label={label}
      minRows={6}
      size={"lg"}
      radius={"lg"}
      onChange={onChange}
      styles={(theme) => ({
        input: {
          "&:focus-within": {
            borderColor: theme.colors.violet[9],
          },
        },
        item: {
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: theme.colors.violet[0],
              color: theme.colors.violet[9],
            },
          },
          "&[data-hovered]": {},
        },
      })}
    />
  );
};
