import { useTranslation } from "react-i18next";
import { CircleCheck, CircleQuestion, Globe, House, ShoppingCart, LogoTelegram, SquarePlus } from "@gravity-ui/icons";
import { Button, Divider, Flex, Grid, Paper, Text } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { notifications } from "@mantine/notifications";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { NavigationText } from "../../components/NavigationText/NavigationText";
import { TextInputCustom } from "../../components/UI/Vendors/Mantine/TextInputCustom";
import { RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { selectCurrentUser, SetUserNameAction } from "../../store/userStore";
import "./style.css";
import { updateUserAppNameRequest } from "./api";
import { useTelegram } from "../../hooks/useTelegram";
import useBeforeInstallPrompt from "../AuthPage/beforeInstallPrompt";

export const SettingsMainPage = () => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const { initData } = useTelegram();

  const { t } = useTranslation(["settings_page"]);

  const [nameInput, setNameInput] = useInputState("");

  const { isInstalled, addToHomeScreen } = useBeforeInstallPrompt();

  const onSuccess = useFn(async () => {
    notifications.show({
      message: "Your nickname changed",
      autoClose: 6000,
      color: "green",
      icon: <CircleCheck />,
    });
  });

  const changeUserAppNameMutation = useMutation({
    mutationFn: ([initDate, userId, appName]: [string, string, string]) => {
      return updateUserAppNameRequest(initData, userId, appName);
    },
    onSuccess,
    onError: (error) => {
      console.error(error);
    },
  });

  const onChangeName = useFn(() => {
    dispatch(SetUserNameAction(nameInput));

    changeUserAppNameMutation.mutate([initData, nameInput, currentUser.id]);
  });

  return (
    <>
      <NavigationText
        title={t("My Profile")}
        arrow={{
          isShow: false,
          onClickFunction: undefined,
        }}
      ></NavigationText>
      <Flex direction="column" gap="lg">
        {/* <Flex className="ItemPage__header" justify="start" wrap="wrap"> */}
        {/*  <Image className="ItemPage__image" height={250} radius="lg" src={assets.test_img} alt="item image" /> */}
        {/* </Flex> */}
        {/* TODO: добавить в измененный инпут поля id и value  */}
        <TextInputCustom
          placeholder={` ${currentUser.appName ?? t("Your name")}`}
          label={t("Your name")}
          onChange={setNameInput}
          id={"name"}
          value={nameInput}
        ></TextInputCustom>
        <Button onClick={onChangeName} disabled={!nameInput.length} color="violet" radius="lg" size="lg">
          {t("Submit")}
        </Button>
        <Divider my="xs" />
        <Text size={25} weight={700}>
          {t("Orders")}
        </Text>
        <Grid grow align="center">
          <Grid.Col span={4}>
            <Paper p="md" radius="lg" shadow="sm" component={Link} to={RouterPath.order.list}>
              <Flex align="center" gap={8} mb="xs">
                <ShoppingCart width={24} height={24}></ShoppingCart>
                <Text pt={2} size="md" weight={700}>
                  {t("Check order list")}
                </Text>
              </Flex>
              <Text color="gray">{t("You can see your previous orders.")}</Text>
            </Paper>
          </Grid.Col>
        </Grid>
        <Divider my="xs" />
        <Text size={25} weight={700}>
          {t("Settings")}
        </Text>
        <Grid grow align="center">
          <Grid.Col span={4}>
            <Paper p="md" radius="lg" shadow="sm" component={Link} to={RouterPath.settings.address}>
              <Flex align="center" gap={8} mb="xs">
                <House width={24} height={24}></House>
                <Text pt={2} size="md" weight={700}>
                  {t("City")}
                </Text>
              </Flex>
              <Text color="gray">{t("Change your city")}</Text>
            </Paper>
          </Grid.Col>
          <Grid.Col span={4}>
            <Paper p="md" radius="lg" shadow="sm" component={Link} to={RouterPath.settings.language}>
              <Flex align="center" gap={8} mb="xs">
                <Globe width={24} height={24}></Globe>
                <Text pt={2} size="md" weight={700}>
                  {t("Language")}
                </Text>
              </Flex>
              <Text color="gray">{t("Change language")}</Text>
            </Paper>
          </Grid.Col>
          <Grid.Col span={4}>
            <Paper
              p="md"
              radius="lg"
              shadow="sm"
              component={Link}
              target={"_blank"}
              to={"https://t.me/travolza_support"}
            >
              <Flex align="center" gap={8} mb="xs">
                <LogoTelegram width={24} height={24}></LogoTelegram>
                <Text pt={2} size="md" weight={700}>
                  {t("Support")}
                </Text>
              </Flex>
              <Text color="gray">{t("Write us in Telegram")}</Text>
            </Paper>
          </Grid.Col>
          <Grid.Col span={4}>
            <Paper p="md" radius="lg" shadow="sm" component={Link} to={RouterPath.settings.support}>
              <Flex align="center" gap={8} mb="xs">
                <CircleQuestion width={24} height={24}></CircleQuestion>
                <Text pt={2} size="md" weight={700}>
                  {t("Feedback")}
                </Text>
              </Flex>
              <Text color="gray">{t("Write us in app")}</Text>
            </Paper>
          </Grid.Col>
          <Grid.Col span={4}>
            <Paper p="md" radius="lg" shadow="sm" onClick={addToHomeScreen}>
              <Flex align="center" gap={8} mb="xs">
                <SquarePlus width={24} height={24}></SquarePlus>
                <Text pt={2} size="md" weight={700}>
                  {t("PWA")}
                </Text>
              </Flex>
              <Text color="gray">{t("Add icon of web application on the Home screen")}</Text>
            </Paper>
          </Grid.Col>
        </Grid>
      </Flex>
      <NavigationBar></NavigationBar>
    </>
  );
};
