/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EYachtCharterBoatType } from './EYachtCharterBoatType';
import {
    EYachtCharterBoatTypeFromJSON,
    EYachtCharterBoatTypeFromJSONTyped,
    EYachtCharterBoatTypeToJSON,
} from './EYachtCharterBoatType';

/**
 * 
 * @export
 * @interface Yacht
 */
export interface Yacht {
    /**
     * The unique identifier for the yacht
     * @type {string}
     * @memberof Yacht
     */
    id: string;
    /**
     * The name of the yacht
     * @type {string}
     * @memberof Yacht
     */
    name: string;
    /**
     * A detailed description of the yacht
     * @type {string}
     * @memberof Yacht
     */
    description: string;
    /**
     * The total number of cabins on the yacht
     * @type {number}
     * @memberof Yacht
     */
    cabinsCount: number;
    /**
     * The length of the yacht in meters
     * @type {number}
     * @memberof Yacht
     */
    yachtLength: number;
    /**
     * 
     * @type {string}
     * @memberof Yacht
     */
    port: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Yacht
     */
    entertainmentTags: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Yacht
     */
    goods: Array<string>;
    /**
     * Conditions and terms for chartering the yacht
     * @type {Array<string>}
     * @memberof Yacht
     */
    conditions: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Yacht
     */
    serviceOnBoard: Array<string>;
    /**
     * 
     * @type {EYachtCharterBoatType}
     * @memberof Yacht
     */
    boatType: EYachtCharterBoatType;
    /**
     * A list of URLs to images of the yacht
     * @type {Array<string>}
     * @memberof Yacht
     */
    images: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Yacht
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof Yacht
     */
    brand: string;
    /**
     * The manufacturing year of the yacht
     * @type {number}
     * @memberof Yacht
     */
    year: number;
    /**
     * Total horsepower of the yacht's engines
     * @type {number}
     * @memberof Yacht
     */
    horsepowers: number;
    /**
     * 
     * @type {number}
     * @memberof Yacht
     */
    speed: number;
    /**
     * The fuel capacity of the yacht in liters
     * @type {number}
     * @memberof Yacht
     */
    fuelCapacity: number;
    /**
     * Materials used in the yacht's construction
     * @type {Array<string>}
     * @memberof Yacht
     */
    boatMaterial: Array<string>;
    /**
     * Passenger capacity of the yacht
     * @type {number}
     * @memberof Yacht
     */
    capacity: number;
    /**
     * 
     * @type {string}
     * @memberof Yacht
     */
    referentName?: string;
    /**
     * The maximum speed of the yacht in knots
     * @type {number}
     * @memberof Yacht
     */
    maxSpeed?: number;
    /**
     * Sleeping capacity of the yacht
     * @type {number}
     * @memberof Yacht
     */
    sleepingCapacity?: number;
    /**
     * Languages spoken by the yacht crew
     * @type {Array<string>}
     * @memberof Yacht
     */
    staffLanguages?: Array<string>;
    /**
     * The overall rating of the yacht
     * @type {number}
     * @memberof Yacht
     */
    rating?: number;
    /**
     * Types of fuel the yacht uses
     * @type {Array<string>}
     * @memberof Yacht
     */
    fuelType?: Array<string>;
}

/**
 * Check if a given object implements the Yacht interface.
 */
export function instanceOfYacht(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "cabinsCount" in value;
    isInstance = isInstance && "yachtLength" in value;
    isInstance = isInstance && "port" in value;
    isInstance = isInstance && "entertainmentTags" in value;
    isInstance = isInstance && "goods" in value;
    isInstance = isInstance && "conditions" in value;
    isInstance = isInstance && "serviceOnBoard" in value;
    isInstance = isInstance && "boatType" in value;
    isInstance = isInstance && "images" in value;
    isInstance = isInstance && "model" in value;
    isInstance = isInstance && "brand" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "horsepowers" in value;
    isInstance = isInstance && "speed" in value;
    isInstance = isInstance && "fuelCapacity" in value;
    isInstance = isInstance && "boatMaterial" in value;
    isInstance = isInstance && "capacity" in value;

    return isInstance;
}

export function YachtFromJSON(json: any): Yacht {
    return YachtFromJSONTyped(json, false);
}

export function YachtFromJSONTyped(json: any, ignoreDiscriminator: boolean): Yacht {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'cabinsCount': json['cabinsCount'],
        'yachtLength': json['yachtLength'],
        'port': json['port'],
        'entertainmentTags': json['entertainmentTags'],
        'goods': json['goods'],
        'conditions': json['conditions'],
        'serviceOnBoard': json['serviceOnBoard'],
        'boatType': EYachtCharterBoatTypeFromJSON(json['boatType']),
        'images': json['images'],
        'model': json['model'],
        'brand': json['brand'],
        'year': json['year'],
        'horsepowers': json['horsepowers'],
        'speed': json['speed'],
        'fuelCapacity': json['fuelCapacity'],
        'boatMaterial': json['boatMaterial'],
        'capacity': json['capacity'],
        'referentName': !exists(json, 'referentName') ? undefined : json['referentName'],
        'maxSpeed': !exists(json, 'maxSpeed') ? undefined : json['maxSpeed'],
        'sleepingCapacity': !exists(json, 'sleepingCapacity') ? undefined : json['sleepingCapacity'],
        'staffLanguages': !exists(json, 'staffLanguages') ? undefined : json['staffLanguages'],
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'fuelType': !exists(json, 'fuelType') ? undefined : json['fuelType'],
    };
}

export function YachtToJSON(value?: Yacht | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'cabinsCount': value.cabinsCount,
        'yachtLength': value.yachtLength,
        'port': value.port,
        'entertainmentTags': value.entertainmentTags,
        'goods': value.goods,
        'conditions': value.conditions,
        'serviceOnBoard': value.serviceOnBoard,
        'boatType': EYachtCharterBoatTypeToJSON(value.boatType),
        'images': value.images,
        'model': value.model,
        'brand': value.brand,
        'year': value.year,
        'horsepowers': value.horsepowers,
        'speed': value.speed,
        'fuelCapacity': value.fuelCapacity,
        'boatMaterial': value.boatMaterial,
        'capacity': value.capacity,
        'referentName': value.referentName,
        'maxSpeed': value.maxSpeed,
        'sleepingCapacity': value.sleepingCapacity,
        'staffLanguages': value.staffLanguages,
        'rating': value.rating,
        'fuelType': value.fuelType,
    };
}

