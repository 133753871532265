import { Carousel, Embla } from "@mantine/carousel";
import { TelegramAuthData } from "@telegram-auth/react";
import { Flex } from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "@gravity-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SelectCustom } from "../../components/UI/Vendors/Mantine/SelectCustom";
import { CITIES, RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import "./style.css";
import { selectCurrentUser, SetUserCityAction } from "../../store/userStore";
import { registerUser, setUserCity } from "../../apiDrivers/user";
import { ECity } from "../../client";
import { BaseStepsSlide } from "./component/BaseStepsSlide";
import { constantSteps } from "./lib/constants";
import { ButtonCustom } from "../../components/UI/Vendors/Mantine";
import { CityMarkersMap } from "../../components/MapComponents/CityMarkersMap";
import { AuthPage } from "../AuthPage";
import { reachGoalChooseCity, reachGoalStartedOnboarding } from "../../utils/metrika";

type TOnboardingPageProps = {
  startStepNumber: number;
};

export const OnboardingPage = ({ startStepNumber }: TOnboardingPageProps) => {
  const currentUser = useSelector(selectCurrentUser);

  const dispatch = useDispatch();

  const { onAuthCallback } = useTelegram();

  const navigate = useNavigate();

  const { initData } = useTelegram();

  const [city, setCity] = useState<ECity>(ECity.Kalkan);

  const { t: welcomePageTranslation } = useTranslation(["welcome_page"]);
  const { t: stepsTranslation } = useTranslation(["steps"]);

  const [steps, setSteps] = useState(constantSteps.slice(startStepNumber));

  useEffect(() => {
    setSteps(constantSteps.slice(startStepNumber));
  }, [startStepNumber]);

  useEffect(() => {
    if (currentUser.city) {
      setCity(currentUser.city);
    }
  }, [currentUser.city]);

  const [currentScreen, setCurrentScreen] = useState(0);

  const [withIndicators, setWithIndicators] = useState(true);

  const embla = useRef<unknown>(null);

  const onAuth = useFn((user: TelegramAuthData) => {
    onAuthCallback(user);

    setCurrentScreen(currentScreen + 1);

    setWithIndicators(true);

    // @ts-ignore
    embla?.current?.scrollNext();
  });

  const nextSlide = useFn(() => {
    if (currentScreen === 1 && currentUser?.id) {
      setUserCity("", currentUser.id, city, initData);
    }

    if (currentScreen === 1) {
      reachGoalStartedOnboarding();
    }

    if (steps[currentScreen + 1]?.isTelegramAuth) {
      // do not show telegram auth screen
      if (initData) {
        setCurrentScreen(currentScreen + 2);
        // @ts-ignore
        embla?.current?.scrollNext();
      } else {
        setCurrentScreen(currentScreen + 1);
        setWithIndicators(false);
      }
    } else {
      setCurrentScreen(currentScreen + 1);
    }

    // @ts-ignore
    embla?.current?.scrollNext();
  });
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentUser.registered) {
      // navigate(RouterPath.main.index);
    }
  }, [currentUser]);

  const onSubmitCity = useFn(() => {
    dispatch(SetUserCityAction(city));
    registerUser("", currentUser.id, initData);
    nextSlide();
    navigate(RouterPath.main.index);
  });

  const onChangeCity = useFn((city: ECity) => {
    reachGoalChooseCity(city);

    setCity(city);
    dispatch(SetUserCityAction(city));
  });

  const getEmblaApi = useCallback(
    (api: Embla) => {
      if (steps[currentScreen].isTelegramAuth) {
        return null;
      } else {
        embla.current = api;
      }
    },
    [currentScreen]
  );

  return (
    <>
      <Carousel
        ref={carouselRef}
        withControls={false}
        withIndicators={withIndicators}
        className="custom-carousel"
        id="carousel"
        draggable={false}
        mx="auto"
        height={"75vh"}
        getEmblaApi={getEmblaApi}
        styles={(theme) => ({
          root: {},
          indicator: {
            width: "12px",
            height: "4px",
            backgroundColor: theme.colors.dark,
            transition: "width 250ms ease",
            ":active": {
              width: "40px",
            },
          },
        })}
      >
        {steps.map((item) => {
          return (
            <Carousel.Slide key={item.label}>
              <BaseStepsSlide
                image={item.image}
                title={stepsTranslation(item.title)}
                label={stepsTranslation(item.label)}
                subLabel={item.subLabel ? stepsTranslation(item.subLabel) : undefined}
                onButtonClick={nextSlide}
              ></BaseStepsSlide>
            </Carousel.Slide>
          );
        })}
        <Carousel.Slide>
          <CityMarkersMap cities={CITIES} selectedCity={city} onCityClick={onChangeCity}></CityMarkersMap>
          <div className="slide baseScreen">
            <Flex direction={"column"} gap={"xl"}>
              <Flex direction={"column"} gap={"xs"}>
                <Flex>
                  <div className="baseScreen__title baseScreen__text">{welcomePageTranslation("Choose your city")}</div>
                </Flex>
                <Flex>
                  <div className="baseScreen__subtitle baseScreen__text">
                    {welcomePageTranslation(
                      "Now we work in the cities on the south coast of Turkey, so you can make your choice here."
                    )}
                  </div>
                </Flex>
              </Flex>
            </Flex>
            <div className={"buttonContainer"}>
              <SelectCustom theme="travolza" onChange={onChangeCity} value={city} data={CITIES}></SelectCustom>
            </div>
          </div>
        </Carousel.Slide>
      </Carousel>

      <div style={{ backgroundColor: "white" }}>
        {steps[currentScreen]?.isTelegramAuth ? (
          <Flex justify={"center"} align={"center"} p={"xl"}>
            <AuthPage onAuthCallback={onAuth} />
          </Flex>
        ) : (
          <Flex justify={"center"} align={"center"} p={"xl"}>
            {steps.length <= currentScreen ? (
              <ButtonCustom rightIcon={<ArrowRight />} onClick={onSubmitCity} theme={"travolza"}>
                <>{welcomePageTranslation("Continue")}</>
              </ButtonCustom>
            ) : (
              <ButtonCustom onClick={nextSlide} theme={"travolza"}>
                <>{welcomePageTranslation("Next")}</>
              </ButtonCustom>
            )}
          </Flex>
        )}
      </div>
    </>
  );
};
