import { transfersApi } from "../../../apiDrivers/transfers";
import { BASE_PATH, Transfer, TransferStatus } from "../../../client";
import { TCard } from "../../../components/BaseCard/BaseCard";
import { TBadgeType } from "../../../components/badges/Badge";
import { ECurrency } from "../../../definitions";
import { DATE_FORMAT, formatDateTime, TIME_FORMAT_HUMAN_NO_SEC } from "../../../utils/date";

export const fetchUserTransfersOrdersList = async ({ queryKey }: any) => {
  const initData = queryKey[1];
  const userId = queryKey[2];

  const res = await transfersApi.transferUserTransfersUserIdGet({ initData, userId });

  return res.userTransfers;
};

export type TNormalizedTransferOrderLookup = TCard;

export const mapTransferOrderStatusToBadgeType: Record<TransferStatus, TBadgeType> = {
  [TransferStatus.Created]: "Booked",
  [TransferStatus.Approved]: "Approved",
  [TransferStatus.Completed]: "Completed",
  [TransferStatus.CancelledByUser]: "Cancelled",
  [TransferStatus.CancelledByAdmin]: "Cancelled",
};

export function normalizeTransferOrder(transferOrders: Transfer[] | undefined): TNormalizedTransferOrderLookup[] {
  if (!transferOrders) {
    return [];
  }

  return transferOrders.map((order) => {
    return {
      image: BASE_PATH + "/assets/services/transfer.webp",
      title: `${order.carType!} Transfer`,
      // address: order.from!,
      id: order.id!,
      badgeType: mapTransferOrderStatusToBadgeType[order.status!],
      price: order.approximatePrice,
      currency: ECurrency.EUR,
      status: mapTransferOrderStatusToBadgeType[order.status!],
      description: `${trimString(order.from, 25)} >> ${trimString(order.to, 25)}`,
      labels: [
        { iconType: "Clock", text: formatDateTime(order.creationDate, TIME_FORMAT_HUMAN_NO_SEC) },
        { iconType: "Done", text: formatDateTime(order.date?.slice(0, -5), DATE_FORMAT) },
      ],
    };
  });
}

function trimString(input: string | undefined, maxLength: number): string {
  if (!input) {
    return "";
  }
  if (input.length > maxLength) {
    return input.substring(0, maxLength) + "...";
  }

  return input;
}
