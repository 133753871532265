/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SeaTrip } from './SeaTrip';
import {
    SeaTripFromJSON,
    SeaTripFromJSONTyped,
    SeaTripToJSON,
} from './SeaTrip';

/**
 * 
 * @export
 * @interface SeaTripsSeaTripsListGet200Response
 */
export interface SeaTripsSeaTripsListGet200Response {
    /**
     * 
     * @type {Array<SeaTrip>}
     * @memberof SeaTripsSeaTripsListGet200Response
     */
    seaTripsList?: Array<SeaTrip>;
}

/**
 * Check if a given object implements the SeaTripsSeaTripsListGet200Response interface.
 */
export function instanceOfSeaTripsSeaTripsListGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SeaTripsSeaTripsListGet200ResponseFromJSON(json: any): SeaTripsSeaTripsListGet200Response {
    return SeaTripsSeaTripsListGet200ResponseFromJSONTyped(json, false);
}

export function SeaTripsSeaTripsListGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeaTripsSeaTripsListGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seaTripsList': !exists(json, 'seaTripsList') ? undefined : ((json['seaTripsList'] as Array<any>).map(SeaTripFromJSON)),
    };
}

export function SeaTripsSeaTripsListGet200ResponseToJSON(value?: SeaTripsSeaTripsListGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seaTripsList': value.seaTripsList === undefined ? undefined : ((value.seaTripsList as Array<any>).map(SeaTripToJSON)),
    };
}

