import { useQuery } from "@tanstack/react-query";
import { useFn } from "../../../hooks/useFn";
import { fetchUserExcursionsOrdersList, normalizeExcursionOrder } from "../api/excursionOrders";

export default function useExcursionOrders(userId: string, initData: string) {
  return useQuery({
    queryKey: [`${userId} excursions orders`, initData, userId],
    queryFn: fetchUserExcursionsOrdersList,
    select: useFn(normalizeExcursionOrder),
    onError: (error) => {
      console.log(error);
    },
  });
}
