/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EContactType = {
    Telegram: 'TELEGRAM',
    Whatsapp: 'WHATSAPP',
    TelephoneNumber: 'TELEPHONE_NUMBER'
} as const;
export type EContactType = typeof EContactType[keyof typeof EContactType];


export function EContactTypeFromJSON(json: any): EContactType {
    return EContactTypeFromJSONTyped(json, false);
}

export function EContactTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EContactType {
    return json as EContactType;
}

export function EContactTypeToJSON(value?: EContactType | null): any {
    return value as any;
}

