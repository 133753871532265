import { userApi } from "../../../apiDrivers/user";

export const updateUserLanguageRequest = async (initData: string, userId: string, lang: string) => {
  const res = await userApi.userSetLanguagePost({
    userSetLanguagePostRequest: {
      initData,
      language: lang,
      userId,
    },
  });

  return res;
};
