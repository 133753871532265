/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ECity } from './ECity';
import {
    ECityFromJSON,
    ECityFromJSONTyped,
    ECityToJSON,
} from './ECity';
import type { SeaTripPricePolicy } from './SeaTripPricePolicy';
import {
    SeaTripPricePolicyFromJSON,
    SeaTripPricePolicyFromJSONTyped,
    SeaTripPricePolicyToJSON,
} from './SeaTripPricePolicy';
import type { SeaTripTag } from './SeaTripTag';
import {
    SeaTripTagFromJSON,
    SeaTripTagFromJSONTyped,
    SeaTripTagToJSON,
} from './SeaTripTag';

/**
 * 
 * @export
 * @interface SeaTrip
 */
export interface SeaTrip {
    /**
     * 
     * @type {string}
     * @memberof SeaTrip
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof SeaTrip
     */
    rating: number;
    /**
     * 
     * @type {string}
     * @memberof SeaTrip
     */
    title: string;
    /**
     * 
     * @type {Array<SeaTripTag>}
     * @memberof SeaTrip
     */
    tags: Array<SeaTripTag>;
    /**
     * 
     * @type {Array<SeaTripPricePolicy>}
     * @memberof SeaTrip
     */
    pricePolicy: Array<SeaTripPricePolicy>;
    /**
     * 
     * @type {number}
     * @memberof SeaTrip
     */
    duration: number;
    /**
     * 
     * @type {string}
     * @memberof SeaTrip
     */
    workingDays: string;
    /**
     * 
     * @type {number}
     * @memberof SeaTrip
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof SeaTrip
     */
    deposit: number;
    /**
     * 
     * @type {number}
     * @memberof SeaTrip
     */
    discount: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SeaTrip
     */
    images: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SeaTrip
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SeaTrip
     */
    shortDescription: string;
    /**
     * 
     * @type {string}
     * @memberof SeaTrip
     */
    seaTripType: SeaTripSeaTripTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SeaTrip
     */
    capacity: number;
    /**
     * 
     * @type {string}
     * @memberof SeaTrip
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof SeaTrip
     */
    referentName: string;
    /**
     * 
     * @type {ECity}
     * @memberof SeaTrip
     */
    city: ECity;
    /**
     * 
     * @type {string}
     * @memberof SeaTrip
     */
    creationDate: string;
}


/**
 * @export
 */
export const SeaTripSeaTripTypeEnum = {
    Private: 'PRIVATE',
    Group: 'GROUP'
} as const;
export type SeaTripSeaTripTypeEnum = typeof SeaTripSeaTripTypeEnum[keyof typeof SeaTripSeaTripTypeEnum];


/**
 * Check if a given object implements the SeaTrip interface.
 */
export function instanceOfSeaTrip(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "rating" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "pricePolicy" in value;
    isInstance = isInstance && "duration" in value;
    isInstance = isInstance && "workingDays" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "deposit" in value;
    isInstance = isInstance && "discount" in value;
    isInstance = isInstance && "images" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "shortDescription" in value;
    isInstance = isInstance && "seaTripType" in value;
    isInstance = isInstance && "capacity" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "referentName" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "creationDate" in value;

    return isInstance;
}

export function SeaTripFromJSON(json: any): SeaTrip {
    return SeaTripFromJSONTyped(json, false);
}

export function SeaTripFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeaTrip {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'rating': json['rating'],
        'title': json['title'],
        'tags': ((json['tags'] as Array<any>).map(SeaTripTagFromJSON)),
        'pricePolicy': ((json['pricePolicy'] as Array<any>).map(SeaTripPricePolicyFromJSON)),
        'duration': json['duration'],
        'workingDays': json['workingDays'],
        'price': json['price'],
        'deposit': json['deposit'],
        'discount': json['discount'],
        'images': json['images'],
        'description': json['description'],
        'shortDescription': json['shortDescription'],
        'seaTripType': json['seaTripType'],
        'capacity': json['capacity'],
        'company': json['company'],
        'referentName': json['referentName'],
        'city': ECityFromJSON(json['city']),
        'creationDate': json['creationDate'],
    };
}

export function SeaTripToJSON(value?: SeaTrip | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rating': value.rating,
        'title': value.title,
        'tags': ((value.tags as Array<any>).map(SeaTripTagToJSON)),
        'pricePolicy': ((value.pricePolicy as Array<any>).map(SeaTripPricePolicyToJSON)),
        'duration': value.duration,
        'workingDays': value.workingDays,
        'price': value.price,
        'deposit': value.deposit,
        'discount': value.discount,
        'images': value.images,
        'description': value.description,
        'shortDescription': value.shortDescription,
        'seaTripType': value.seaTripType,
        'capacity': value.capacity,
        'company': value.company,
        'referentName': value.referentName,
        'city': ECityToJSON(value.city),
        'creationDate': value.creationDate,
    };
}

