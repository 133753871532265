/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ECity = {
    Everywhere: 'EVERYWHERE',
    Kalkan: 'KALKAN',
    Pamukkale: 'PAMUKKALE',
    Kash: 'KASH',
    Istanbul: 'ISTANBUL',
    Bursa: 'BURSA',
    Izmir: 'IZMIR',
    Kushadasi: 'KUSHADASI',
    Didim: 'DIDIM',
    Selcuk: 'SELCUK',
    Bodrum: 'BODRUM',
    Marmaris: 'MARMARIS',
    Gochek: 'GOCHEK',
    Fethiye: 'FETHIYE',
    Oludeniz: 'OLUDENIZ',
    Demre: 'DEMRE',
    Kekova: 'KEKOVA',
    Finike: 'FINIKE',
    Kemer: 'KEMER',
    Chamyova: 'CHAMYOVA',
    Antalya: 'ANTALYA',
    Side: 'SIDE',
    Alania: 'ALANIA',
    Kappadocia: 'KAPPADOCIA',
    Mersin: 'MERSIN',
    Ankara: 'ANKARA',
    Trabzon: 'TRABZON'
} as const;
export type ECity = typeof ECity[keyof typeof ECity];


export function ECityFromJSON(json: any): ECity {
    return ECityFromJSONTyped(json, false);
}

export function ECityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ECity {
    return json as ECity;
}

export function ECityToJSON(value?: ECity | null): any {
    return value as any;
}

