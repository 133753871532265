import { ServiceListApi } from "../../client";

export const serviceListApi = new ServiceListApi();

const fetchServicesList = async ({ queryKey }: any) => {
  const initData = queryKey[1];

  const res = await serviceListApi.generalServicesListGet({
    initData,
  });

  return res.serviceLookups;
};

export default fetchServicesList;
