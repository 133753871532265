import { Header, Avatar } from "@mantine/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RouterPath } from "../../definitions";
import { selectCurrentUser } from "../../store/userStore";
import { HeaderCitySelector } from "../HeaderCitySelector";
import "./style.css";
import { useTelegram } from "../../hooks/useTelegram";

export const AppHeader = () => {
  const user = useSelector(selectCurrentUser);

  const { initData, tgImage } = useTelegram();

  return (
    <div>
      <Header className="header" height={70}>
        <div className="headerRow">
          <HeaderCitySelector></HeaderCitySelector>
          <div className="controlElements">
            {Boolean(process.env.NODE_ENV === "development" || initData) && (
              <>
                <Link to={RouterPath.settings.index}>
                  <Avatar radius="xl" src={tgImage}></Avatar>
                </Link>
              </>
            )}
          </div>
        </div>
      </Header>
    </div>
  );
};
