import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TCartItem = {
  id: string;
  restaurantId: string;
  image: string;
  description: string;
  price: number;
  count: number;
  name: string;
  category: string;
};

type TDeliveryCartState = {
  list: TCartItem[];
  restaurant: {
    title?: string;
    id?: string;
    deliveryPrice?: number;
    deliveryPriceThreshold?: number;
  };
};

function getInitialState(): TDeliveryCartState {
  return {
    list: [],
    restaurant: {
      title: undefined,
      id: undefined,
      deliveryPrice: 0,
      deliveryPriceThreshold: 0,
    },
  };
}

type TCartAddAction = PayloadAction<Omit<TCartItem, "count">>;
type TCartRemoveAction = PayloadAction<{ id: string }>;
type TCartSetRestaurantNameAction = PayloadAction<{
  title: string;
  id: string;
  deliveryPrice?: number;
  deliveryPriceThreshold?: number;
}>;

const deliveryCartSlice = createSlice({
  name: "foodCart",
  initialState: getInitialState(),
  reducers: {
    SetRestaurantNameAction: (state, action: TCartSetRestaurantNameAction) => {
      state.restaurant = action.payload;
    },
    AddItemAction: (state, action: TCartAddAction) => {
      const item = state.list.find((_item) => _item.id === action.payload.id);
      if (item) {
        item.count += 1;
      } else {
        state.list.push({ ...action.payload, count: 1 });
      }
    },
    RemoveItemAction: (state, action: TCartRemoveAction) => {
      const item = state.list.find((_item) => _item.id === action.payload.id);
      if (item && item.count > 1) {
        item.count -= 1;
      } else {
        state.list = state.list.filter((_item) => _item.id !== action.payload.id);
      }
    },
    ClearCartAction: (state) => {
      state.list = [];
      state.restaurant.id = undefined;
      state.restaurant.title = undefined;
    },
  },
});

export const { SetRestaurantNameAction, AddItemAction, RemoveItemAction, ClearCartAction } = deliveryCartSlice.actions;

export const deliveryCartReducer = deliveryCartSlice.reducer;

export function selectDeliveryCartList(state: { foodCart: TDeliveryCartState }) {
  return state.foodCart.list;
}

export function selectDeliveryCartRestaurant(state: { foodCart: TDeliveryCartState }) {
  return state.foodCart.restaurant;
}

export function getFoodCartPrice(state: { foodCart: TDeliveryCartState }) {
  const rawPrice = state.foodCart.list.reduce((acc, item) => {
    acc += item.count * item.price;
    return acc;
  }, 0);

  const { deliveryPrice, deliveryPriceThreshold } = state.foodCart.restaurant;

  if (deliveryPrice && deliveryPriceThreshold && rawPrice < deliveryPriceThreshold) {
    return rawPrice + deliveryPrice;
  } else {
    return rawPrice;
  }
}

export function selectCartCount(state: { foodCart: TDeliveryCartState }) {
  return state.foodCart.list.reduce((acc, item) => {
    acc += item.count;
    return acc;
  }, 0);
}

export const selectCartItemCountById = (itemId: string) => (state: { foodCart: TDeliveryCartState }) => {
  const item = state.foodCart.list.find((_item) => _item.id === itemId);
  return item ? item.count : 0;
};

export const selectCartItemById = (itemId: string) => (state: { foodCart: TDeliveryCartState }) => {
  const item = state.foodCart.list.find((_item) => _item.id === itemId);
  return item;
};
