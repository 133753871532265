import { useTranslation } from "react-i18next";
import { Button, Flex, Text } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";

import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import "./style.css";

export type InformationPageMessage = "support_message" | "description" | "orderInfo";

export const InformationPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation(["information_pages"]);

  const navigateToOrders = useFn(() => navigate(RouterPath.order.list));

  const location = useLocation();

  const { message } = location?.state;

  return (
    <>
      <Flex className="InformationPage__container" justify="center" direction="column" align="center">
        <Text size="xl" weight={700} mb="xs">
          {t("Congratulations!")}
        </Text>
        <Text mb="md" size="sm" align="center" color="gray" weight={400}>
          {message}
        </Text>
        <Button fullWidth radius="lg" size="lg" color="violet" onClick={navigateToOrders}>
          {t("To my orders")}
        </Button>
      </Flex>
      <NavigationBar></NavigationBar>
    </>
  );
};
