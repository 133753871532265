import { useTranslation } from "react-i18next";
import { Button, Divider, Flex, Group, Modal, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { MouseEventHandler } from "react";
import ReactMarkdown from "react-markdown";
import { EContactType } from "../../../client";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";
import {
  NumberInputCustom,
  SelectCustom,
  TextAreaCustom,
  TextInputCustom,
} from "../../../components/UI/Vendors/Mantine";
import { TBadgeType, TERMINAL_STATUSES } from "../../../components/badges/Badge";
import { EColors, ECurrency } from "../../../definitions";
import { useFn } from "../../../hooks/useFn";
import { mapFoodOrderPaymentMethodEnum } from "../../../pages/OrderPage/definitions";
import { TIME_FORMAT_HUMAN_NO_SEC } from "../../../utils/date";
import { ItemBase } from "../../Items/ItemBase";

interface TOrderCompleted {
  children?: React.ReactNode;
  description: string;
  image: string;
  images: Array<string>;
  name: string;
  price: number;
  approximatePrice?: number;
  currency: ECurrency;
  contactType: EContactType;
  contactValue: string;
  addressValue?: string;
  adultsCount?: number;
  childrenCount?: number;
  personCount?: number;
  paymentMethod: string;
  commentValue: string;
  actualDate: string;
  actualDateLabel?: string;
  creationDate?: string;
  status: TBadgeType;
  passengerNames?: string;
  flightNumber?: string;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
}

export const OrderCompletedBase = ({
  children,
  description,
  image,
  images,
  name,
  approximatePrice,
  price,
  currency,
  contactType,
  contactValue,
  addressValue,
  adultsCount,
  childrenCount,
  personCount,
  paymentMethod,
  commentValue,
  passengerNames,
  flightNumber,
  actualDate,
  actualDateLabel = "Actual event date",
  creationDate,
  status,
  onCancel,
}: TOrderCompleted) => {
  const [openedModal, { open, close }] = useDisclosure(false);

  const { t } = useTranslation(["order_completed_base"]);

  const getContactLabel = useFn(() => {
    const value = contactType;
    if (value === EContactType.Telegram) {
      return "Telegram";
    } else if (value === EContactType.Whatsapp) {
      return "WhatsApp";
    } else {
      return t("Phone number");
    }
  });

  const getContactInputType = useFn(() => {
    const value = contactType;
    if (value === EContactType.Telegram) {
      return "text";
    } else {
      return "number";
    }
  });

  const renderExcursionItemPart = () => {
    return (
      <>
        {description.length > 70 && (
          <>
            <Modal
              opened={openedModal}
              onClose={close}
              withCloseButton={true}
              centered
              title={name}
              transitionProps={{ transition: "rotate-left" }}
            >
              <ReactMarkdown children={description} />
            </Modal>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={700} onClick={open} color="violet" underline={true}>
                {t("Read more")}
              </Text>
            </Group>
          </>
        )}
        {!!children && <>{children}</>}
      </>
    );
  };

  return (
    <>
      <ItemBase
        optionItem={{
          description,
          image,
          images,
          name,
          price: approximatePrice || price,
        }}
        children={renderExcursionItemPart()}
      ></ItemBase>
      <>
        <Divider my="sm" mb={"xl"} mt={"xl"} />
        {creationDate && (
          <DatePickerInput
            dropdownType="modal"
            label={t("Creation date")}
            placeholder={t("Date")}
            value={new Date(creationDate)}
            valueFormat={TIME_FORMAT_HUMAN_NO_SEC}
            color="violet"
            readOnly={true}
            radius="lg"
            lang={"ru"}
            size="lg"
            c={EColors.violet}
            style={{ color: EColors.violet }}
          />
        )}
        {actualDate && (
          <>
            <DatePickerInput
              dropdownType="modal"
              label={t(actualDateLabel)}
              placeholder={t("Date")}
              value={new Date(actualDate)}
              color="violet"
              readOnly={true}
              radius="lg"
              size="lg"
              lang={"ru"}
              c={EColors.violet}
              valueFormat={TIME_FORMAT_HUMAN_NO_SEC}
              style={{ color: EColors.violet }}
            />
            {/* <Divider my="sm" mb={"xl"} mt={"xl"} /> */}
          </>
        )}
        <Flex direction={"column"} gap={"md"}>
          <TextInputCustom
            label={getContactLabel()}
            type={getContactInputType()}
            value={contactValue}
            disabled={true}
          ></TextInputCustom>
          {!!addressValue && (
            <TextInputCustom label={t("Address")} value={addressValue} disabled={true}></TextInputCustom>
          )}
        </Flex>

        {!!(adultsCount || childrenCount) && (
          <Flex direction={"column"} gap={"md"}>
            {/* <Flex className="ItemPage__header" justify="start" wrap="wrap">
              <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
                <Text className="mainCard__title" color={EColors.gray_1} weight={700}>
                  People
                </Text>
              </Group>
            </Flex> */}
            <Flex gap="md">
              <NumberInputCustom
                disabled={true}
                withAsterisk={true}
                placeholder="count"
                label="Adults"
                defaultValue={adultsCount}
              />
              <NumberInputCustom
                disabled={true}
                placeholder={t("count")}
                label={t("Children")}
                defaultValue={childrenCount}
              />
            </Flex>
          </Flex>
        )}
        {!!personCount && (
          // <Flex direction={"column"} gap={"md"}>
          //   <Flex className="ItemPage__header" justify="start" wrap="wrap">
          //     <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
          //       <Text className="mainCard__title" color={EColors.gray_1} weight={700}>
          //         People
          //       </Text>
          //     </Group>
          //   </Flex>
          <Flex direction={"column"} gap="md">
            <NumberInputCustom
              disabled={true}
              withAsterisk={true}
              placeholder={t("count")}
              label={t("Person")}
              defaultValue={personCount}
            />
          </Flex>
          // </Flex>
        )}
        {!!passengerNames && (
          <>
            <Flex direction={"column"} gap={"md"}>
              <TextAreaCustom disabled={true} label={t("Passenger names")} value={passengerNames} />
            </Flex>
          </>
        )}
        {!!flightNumber && (
          <>
            <Flex direction={"column"} gap={"md"}>
              <TextAreaCustom disabled={true} label={t("Passenger names")} value={flightNumber} />
            </Flex>
          </>
        )}
        {!!commentValue && (
          <>
            <Flex direction={"column"} gap={"md"}>
              <TextAreaCustom
                disabled={true}
                placeholder={t("Any questions ?")}
                label={t("Your comment")}
                value={commentValue}
              />
            </Flex>
          </>
        )}
      </>

      <Flex mt={0} direction={"column"} gap={"lg"} style={{ flex: "1 1 auto" }}>
        {!!paymentMethod && (
          <Flex direction={"column"} gap={"md"}>
            <Flex className="ItemPage__header" justify="start" wrap="wrap">
              <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
                <Text className="mainCard__title" color={EColors.gray_1} weight={700}>
                  {t("Payment method")}
                </Text>
              </Group>
            </Flex>
            <SelectCustom
              data={[t("Cash"), t("Bank card")]}
              // @ts-ignore
              value={t(mapFoodOrderPaymentMethodEnum[paymentMethod])}
              disabled={true}
              onChange={undefined}
            />
          </Flex>
        )}
        <Flex direction={"column"}>
          <Text weight={400} size={16} c={EColors.dark}>
            {approximatePrice ? t("Total approximate price") : t("Total")}
          </Text>
          <Text weight={700} size={34} c={EColors.gray_1}>
            {`${approximatePrice || price} ${currency}`}
          </Text>
        </Flex>
        {onCancel && !TERMINAL_STATUSES.includes(status) && (
          <Flex direction={"column"} justify={"space-between"} gap={"md"}>
            <Button
              fullWidth={true}
              type="submit"
              onClick={onCancel}
              variant="filled"
              color="red"
              radius="lg"
              size="xl"
            >
              {t("Cancel Order")}
            </Button>
          </Flex>
        )}
      </Flex>
      <NavigationBar></NavigationBar>
    </>
  );
};
