// import { normalizeRestaurant } from "./definitions";

import { ChevronDown, ChevronUp } from "@gravity-ui/icons";
import { ActionIcon, Button, Collapse, Divider, Flex, Group, Modal, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { LoaderPageCustom } from "../../components/UI/Vendors/Mantine/LoaderPageCustom";
import { assets, EColors, ECurrency, RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import { ItemBase } from "../../modules/Items/ItemBase";
import { EOrderPageIsType } from "../OrderPage/definitions";
import fetchYachtsById from "./api";
import { normalizeYacht, TYachtRouteLookup } from "./definitions";

interface IItemDelivery {
  optionItem?: {
    programme?: string;
    image: string;
    name: string;
    description: string;
    long_description?: string;
    price: number;
    count: number;
    images: string[];
    id: number | string;
  };
  id?: string;
}

export const YachtsItemPage = ({
  optionItem = {
    // TODO: когда в будет ручка сделать дефолтную картинку
    // image: "No Image",
    image: assets.yacht_img,
    images: [],
    name: "No Name",
    description: "No description",
    price: 0,
    count: 0,
    id: 0,
  },
}: IItemDelivery): JSX.Element => {
  const navigate = useNavigate();
  const { initData } = useTelegram();
  const { id } = useParams();
  const [openedModal, { open, close }] = useDisclosure(false);
  const [openedCollapseProgram, toggleProgramm] = useDisclosure(false);
  const [openedCollapseFeatures, toggleFeatures] = useDisclosure(false);
  const [openedCollapseFacilities, toggleFacilities] = useDisclosure(false);
  const [openedCollapseService, toggleService] = useDisclosure(false);
  const [openedCollapseEntertainment, toggleEntertainment] = useDisclosure(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const item = optionItem;

  const yachtsRouteByIdQuery = useQuery([`Yacht_${id}`, initData, id], fetchYachtsById, {
    onSuccess: (result) => {
      console.log(result, "result yacht");
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const onDateClick = (date: Date) => {
    setSelectedDate(date);
  };

  const toOrderPage = useFn(() => {
    navigate(RouterPath.order.complete, {
      state: {
        // data: getYachtOrder(),
        type: EOrderPageIsType.IS_YACHT,
      },
    });
  });

  // const getYachtOrder = useFn((): YachtOrder => {
  //   return {
  //     userId: currentUser.id,
  //     contact: "",
  //     contactType: "TELEGRAM",
  //     yachtId: yacht.id,
  //     yachtDate: formatDateTime(selectedDate!.toString()),
  //     personsCount: 0,
  //     childrenCount: 0,
  //     comment: "",
  //     paymentMethod: "",
  //   };
  // });

  if (yachtsRouteByIdQuery.isLoading || !yachtsRouteByIdQuery.data) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }
  // TODO: заменить на select в useQuery
  const yachtsRoute = normalizeYacht(yachtsRouteByIdQuery.data!);

  const renderYachtRouteItemPart = () => {
    return (
      <>
        renderYachtsItemPart
        {JSON.stringify(yachtsRoute)}
      </>
    );
  };
  const renderYachtRouteItem = (Route: TYachtRouteLookup) => {
    return (
      <>
        fffff
        {/* TODO: надо посинить типы апишек, поему то все | undefined */}
        {/* <ItemBase optionItem={yacht!} children={renderYachtRouteItemPart()}></ItemBase> */}
      </>
    );
  };

  // TODO: что-то тут не так с данными по сравнению с тем что ожидалось на макетах
  const yacht = {
    description:
      "asd asd asd asdasdasd asf adas g s апывоа првоап рыовлап дыа пдырлвао прыапырвкгп тапрыкш пиыпао ылварпыкшпылдвапрыавпр ывап ывапол ывап рыващпы автп sfdghs odfiug skdflg hsfgo ывалдп жоывап ылавп",
    referentName: "Yacht",
    name: "Yacht",
    isForChildrenOnly: true,
    isForAdultsOnly: true,
    priceAdult: 500,
    priceKids: 400,
    image: assets.test_img,
    images: [assets.test_img, assets.test_img],
    price: 0,
    count: 0,
  };
  // yacht.name = yacht.referentName;

  const renderyachtItemPart = () => {
    return (
      <>
        {yacht.description!.length > 70 && (
          <>
            <Modal
              opened={openedModal}
              onClose={close}
              withCloseButton={true}
              centered
              title={yacht.referentName!}
              transitionProps={{ transition: "rotate-left" }}
            >
              {yacht.description}
            </Modal>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={700} onClick={open} color="violet" underline={true}>
                Read More
              </Text>
            </Group>
          </>
        )}
        <>
          <Divider my="sm" />
          <Flex wrap={"wrap"} gap={"xl"}>
            <Flex direction={"column"} gap={"lg"} style={{ flex: "0 0 50%" }}>
              <Text weight={700} size={20} c={"#212529"}>
                Included:
              </Text>
              <Flex direction={"column"} gap={"md"}>
                <Text weight={400} size={16} c={"#495057"}>
                  Fuel
                </Text>
                <Text weight={400} size={16} c={"#495057"}>
                  Food
                </Text>
                <Text weight={400} size={16} c={"#495057"}>
                  Cleaning
                </Text>
              </Flex>
            </Flex>
            <Flex>
              <Flex direction={"column"} gap={"lg"}>
                <Text weight={700} size={20} c={"#212529"}>
                  Not Included:
                </Text>
                <Flex direction={"column"} gap={"md"}>
                  <Text weight={400} size={16} c={"#495057"}>
                    Transition Log
                  </Text>
                  <Text weight={400} size={16} c={"#495057"}>
                    Food
                  </Text>
                  <Text weight={400} size={16} c={"#495057"}>
                    Skipper
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Divider my="sm" />
          <Text weight={700} size={20} c={"#212529"} mb={"md"}>
            More Info about {item.name}
          </Text>
          <Flex direction={"column"} gap="xs">
            <Flex justify={"space-between"} onClick={toggleProgramm.toggle}>
              <Text weight={700} size={18} color="violet">
                Tour Programm
              </Text>
              <ActionIcon radius="lg" variant="filled" color="violet">
                {openedCollapseProgram && <ChevronUp color="white"></ChevronUp>}
                {!openedCollapseProgram && <ChevronDown color="white"></ChevronDown>}
              </ActionIcon>
            </Flex>
            <Collapse in={openedCollapseProgram}>
              <Text weight={400}>
                {item.programme}
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio, dignissimos. Et, vitae. Ea sequi
                architecto dolor rem, dignissimos porro similique cum provident placeat temporibus nisi illum eius nemo
                officiis ullam.
              </Text>
            </Collapse>
            <Flex justify={"space-between"} onClick={toggleFeatures.toggle}>
              <Text weight={700} size={18} color="violet">
                Features
              </Text>
              <ActionIcon radius="lg" variant="filled" color="violet">
                {openedCollapseFeatures && <ChevronUp color="white"></ChevronUp>}
                {!openedCollapseFeatures && <ChevronDown color="white"></ChevronDown>}
              </ActionIcon>
            </Flex>
            <Collapse in={openedCollapseFeatures}>
              <Text weight={400}>
                {item.programme}
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio, dignissimos. Et, vitae. Ea sequi
                architecto dolor rem, dignissimos porro similique cum provident placeat temporibus nisi illum eius nemo
                officiis ullam.
              </Text>
            </Collapse>
            <Flex justify={"space-between"} onClick={toggleFacilities.toggle}>
              <Text weight={700} size={18} color="violet">
                Facilities
              </Text>
              <ActionIcon radius="lg" variant="filled" color="violet">
                {openedCollapseFacilities && <ChevronUp color="white"></ChevronUp>}
                {!openedCollapseFacilities && <ChevronDown color="white"></ChevronDown>}
              </ActionIcon>
            </Flex>
            <Collapse in={openedCollapseFacilities}>
              <Text weight={400}>
                {item.programme}
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio, dignissimos. Et, vitae. Ea sequi
                architecto dolor rem, dignissimos porro similique cum provident placeat temporibus nisi illum eius nemo
                officiis ullam.
              </Text>
            </Collapse>
            <Flex justify={"space-between"} onClick={toggleService.toggle}>
              <Text weight={700} size={18} color="violet">
                Service on board
              </Text>
              <ActionIcon radius="lg" variant="filled" color="violet">
                {openedCollapseService && <ChevronUp color="white"></ChevronUp>}
                {!openedCollapseService && <ChevronDown color="white"></ChevronDown>}
              </ActionIcon>
            </Flex>
            <Collapse in={openedCollapseService}>
              <Text weight={400}>
                {item.programme}
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio, dignissimos. Et, vitae. Ea sequi
                architecto dolor rem, dignissimos porro similique cum provident placeat temporibus nisi illum eius nemo
                officiis ullam.
              </Text>
            </Collapse>
            <Flex justify={"space-between"} onClick={toggleEntertainment.toggle}>
              <Text weight={700} size={18} color="violet">
                Entertainment
              </Text>
              <ActionIcon radius="lg" variant="filled" color="violet">
                {openedCollapseEntertainment && <ChevronUp color="white"></ChevronUp>}
                {!openedCollapseEntertainment && <ChevronDown color="white"></ChevronDown>}
              </ActionIcon>
            </Flex>
            <Collapse in={openedCollapseEntertainment}>
              <Text weight={400}>
                {item.programme}
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio, dignissimos. Et, vitae. Ea sequi
                architecto dolor rem, dignissimos porro similique cum provident placeat temporibus nisi illum eius nemo
                officiis ullam.
              </Text>
            </Collapse>
          </Flex>
        </>
        <Flex mt={30} direction={"column"} gap={"lg"} style={{ flex: "1 1 auto" }}>
          <Flex gap={"lg"}>
            {yacht.price && (
              <>
                <Flex direction={"column"}>
                  <Text weight={400} size={16} c={EColors.dark}>
                    Price
                  </Text>
                  <Text weight={700} size={34} c={EColors.gray_1}>
                    {`${yacht.price} ${ECurrency.EUR}`}
                  </Text>
                </Flex>
              </>
            )}
          </Flex>

          <Flex direction={"column"} justify={"space-between"} gap={"md"}>
            <DatePickerInput
              dropdownType="modal"
              placeholder="Date"
              value={selectedDate}
              onChange={onDateClick}
              lang={"ru"}
              color="violet"
              excludeDate={(date) => {
                return date.getTime() < Date.now();
              }}
              radius="lg"
              size="lg"
              c={EColors.violet}
              styles={(theme) => ({
                input: {
                  "&": {
                    borderColor: theme.colors.violet[7],
                    color: theme.colors.violet[7],
                    wordBreak: "keep-all",
                  },
                  "&:focus": {
                    borderColor: theme.colors.violet[7],
                  },
                },
              })}
              // rightSection={<Calendar></Calendar>}
              style={{ color: EColors.violet }}
            />
            <Button
              fullWidth={true}
              disabled={!selectedDate}
              onClick={toOrderPage}
              variant="filled"
              color="violet"
              radius="lg"
              size="xl"
            >
              Next step
            </Button>
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <>
      <ItemBase optionItem={{ ...yacht!, count: 0 }} children={renderyachtItemPart()}></ItemBase>
      <NavigationBar></NavigationBar>
    </>
  );
};
