import { ShoppingCart } from "@gravity-ui/icons";
import { ActionIcon, Badge, Flex, Text } from "@mantine/core";
import { useCallback } from "react";
import { ECurrency } from "../../definitions";

export const PriceBadge = ({
  price,
  banknote = ECurrency.TRY,
  size = "small",
  hasCounter = false,
  currentCounter = 0,
  onIconClick,
  onBadgeClick,
  readOnly,
}: {
  price: number;
  banknote: string;
  size?: string;
  hasCounter?: boolean;
  currentCounter?: number;
  onIconClick?: any;
  onBadgeClick?: any;
  readOnly?: boolean;
}) => {
  const renderCounter = useCallback(
    (_hasCounter: boolean, _currentCounter: number, _onIconClick: () => void) => {
      return (
        <Flex align="center">
          <ActionIcon size="xl" radius="lg" variant="" style={{ color: "white" }} onClick={_onIconClick}>
            <ShoppingCart width={24} height={24}></ShoppingCart>
          </ActionIcon>
          {readOnly ? (
            <Text size="lg">{_currentCounter}</Text>
          ) : (
            _hasCounter && _currentCounter !== 0 && <Text size="lg">{_currentCounter}</Text>
          )}
        </Flex>
      );
    },
    [hasCounter, currentCounter, onIconClick]
  );
  return (
    <Badge
      color="violet"
      radius={size === "big" ? "lg" : "md"}
      pt={size === "big" ? 30 : 20}
      pb={size === "big" ? 30 : 20}
      pr={size === "big" ? 45 : 20}
      pl={size === "big" ? 45 : 20}
      onClick={onBadgeClick}
      variant="filled"
      rightSection={renderCounter(hasCounter, currentCounter, onIconClick)}
    >
      <Flex gap={4} align="end">
        <Text size="lg" style={{ minWidth: "47px", textAlign: "right" }}>
          {price}
        </Text>
        <Text size="lg">
          {banknote}
        </Text>
      </Flex>
    </Badge>
  );
};
