import { ECity } from "../client";

const COUNTER_ID = 97545731;

enum EGoal {
  ONBOARDING_STARTED = "started_onborging",
  TELEGRAM_LOGIN = "telegram_login",
  REGISTRATION_DONE = "registration_done",
  CHOOSE_CITY = "chose_city",
  TRANSFER_START = "transfer_start",
  TRANSFER_CHOSED_POINTS = "start_finish_point_chose",
  TRANSFER_SUBMIT = "order_submitted",
  EXCURSIONS_OPEN = "excursion_catefory_open",
  EXCURSIONS_ITEM_OPEN = "excursion_details",
  EXCURSION_DATE_CHOOSE = "excursion_date_chose",
  EXCURSION_NEXT_STEP = "excursion_next_step",
  EXCURSION_SUBMIT = "submit_excursion_order",
  SEA_TRIPS_OPEN = "sea_trips_open",
  SEA_TRIPS_ITEM_OPEN = "sea_trip_details",
  SEA_TRIP_DATE_CHOOSE = "sea_trips_date",
  SUBMIT_SEA_TRIP_ORDER = "submit_seatrips_order",
}

export function isDevelopmentEnv(): boolean {
  return process.env.NODE_ENV === "development";
}

// @ts-expect-error TypeScript doesn't know about ym (and it shouldn't)
const metrika = (type: string, goal: string, params?: Record<string, unknown>) => ym(COUNTER_ID, type, goal, params);

function reachGoal(goal: EGoal, params?: Record<string, unknown>) {
  if (!isDevelopmentEnv()) {
    metrika("reachGoal", goal, params);
  } else {
    console.log("REACH GOAL (fake though)", goal, params);
  }
}

export function metrikaHit(url: string, params?: Record<string, string>) {
  if (!isDevelopmentEnv()) {
    metrika("hit", url, { params });
  } else {
    console.log("METRIKA HIT (fake though)", url, params);
  }
}

export const reachGoalStartedOnboarding = () => reachGoal(EGoal.ONBOARDING_STARTED);
export const reachGoalTelegramLogin = () => reachGoal(EGoal.TELEGRAM_LOGIN);
export const reachGoalRegistrationDone = () => reachGoal(EGoal.REGISTRATION_DONE);
export const reachGoalChooseCity = (city: ECity) => reachGoal(EGoal.CHOOSE_CITY, { city });
export const reachGoalTransferStart = () => reachGoal(EGoal.TRANSFER_START);
export const reachGoalTransferChosedPoints = (from: string, to: string) =>
  reachGoal(EGoal.TRANSFER_CHOSED_POINTS, { from, to });
export const reachGoalTransferSubmit = () => reachGoal(EGoal.TRANSFER_SUBMIT);
export const reachGoalExcursionsOpen = () => reachGoal(EGoal.EXCURSIONS_OPEN);
export const reachGoalExcursionsItemOpen = (name: string) => reachGoal(EGoal.EXCURSIONS_ITEM_OPEN, { name });
export const reachGoalExcursionDateChoose = (date: string) => reachGoal(EGoal.EXCURSION_DATE_CHOOSE, { date });
export const reachGoalExcursionNextStep = () => reachGoal(EGoal.EXCURSION_NEXT_STEP);
export const reachGoalExcursionSubmit = () => reachGoal(EGoal.EXCURSION_SUBMIT);
export const reachGoalSeaTripsOpen = () => reachGoal(EGoal.SEA_TRIPS_OPEN);
export const reachGoalSeaTripsItemOpen = (name: string) => reachGoal(EGoal.SEA_TRIPS_ITEM_OPEN, { name });
export const reachGoalSeaTripDateChoose = (date: string) => reachGoal(EGoal.SEA_TRIP_DATE_CHOOSE, { date });
export const reachGoalSubmitSeaTripOrder = () => reachGoal(EGoal.SUBMIT_SEA_TRIP_ORDER);
