/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExcursionOrderLookupExcursion } from './ExcursionOrderLookupExcursion';
import {
    ExcursionOrderLookupExcursionFromJSON,
    ExcursionOrderLookupExcursionFromJSONTyped,
    ExcursionOrderLookupExcursionToJSON,
} from './ExcursionOrderLookupExcursion';
import type { ExcursionOrderStatus } from './ExcursionOrderStatus';
import {
    ExcursionOrderStatusFromJSON,
    ExcursionOrderStatusFromJSONTyped,
    ExcursionOrderStatusToJSON,
} from './ExcursionOrderStatus';
import type { ExcursionOrderUpdateStatus } from './ExcursionOrderUpdateStatus';
import {
    ExcursionOrderUpdateStatusFromJSON,
    ExcursionOrderUpdateStatusFromJSONTyped,
    ExcursionOrderUpdateStatusToJSON,
} from './ExcursionOrderUpdateStatus';

/**
 * 
 * @export
 * @interface ExcursionOrderLookup
 */
export interface ExcursionOrderLookup {
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrderLookup
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrderLookup
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrderLookup
     */
    excursionDate: string;
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrderLookup
     */
    creationDate: string;
    /**
     * 
     * @type {number}
     * @memberof ExcursionOrderLookup
     */
    price: number;
    /**
     * 
     * @type {ExcursionOrderUpdateStatus}
     * @memberof ExcursionOrderLookup
     */
    status?: ExcursionOrderUpdateStatus;
    /**
     * 
     * @type {Array<ExcursionOrderStatus>}
     * @memberof ExcursionOrderLookup
     */
    statusHistory?: Array<ExcursionOrderStatus>;
    /**
     * 
     * @type {ExcursionOrderLookupExcursion}
     * @memberof ExcursionOrderLookup
     */
    excursion: ExcursionOrderLookupExcursion;
}

/**
 * Check if a given object implements the ExcursionOrderLookup interface.
 */
export function instanceOfExcursionOrderLookup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "excursionDate" in value;
    isInstance = isInstance && "creationDate" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "excursion" in value;

    return isInstance;
}

export function ExcursionOrderLookupFromJSON(json: any): ExcursionOrderLookup {
    return ExcursionOrderLookupFromJSONTyped(json, false);
}

export function ExcursionOrderLookupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExcursionOrderLookup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['userId'],
        'excursionDate': json['excursionDate'],
        'creationDate': json['creationDate'],
        'price': json['price'],
        'status': !exists(json, 'status') ? undefined : ExcursionOrderUpdateStatusFromJSON(json['status']),
        'statusHistory': !exists(json, 'statusHistory') ? undefined : ((json['statusHistory'] as Array<any>).map(ExcursionOrderStatusFromJSON)),
        'excursion': ExcursionOrderLookupExcursionFromJSON(json['excursion']),
    };
}

export function ExcursionOrderLookupToJSON(value?: ExcursionOrderLookup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'excursionDate': value.excursionDate,
        'creationDate': value.creationDate,
        'price': value.price,
        'status': ExcursionOrderUpdateStatusToJSON(value.status),
        'statusHistory': value.statusHistory === undefined ? undefined : ((value.statusHistory as Array<any>).map(ExcursionOrderStatusToJSON)),
        'excursion': ExcursionOrderLookupExcursionToJSON(value.excursion),
    };
}

