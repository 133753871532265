import { useForm } from "@mantine/form";
import { EContactType } from "../../../client";
import { fieldRequired } from "../../../components/forms/validation/required";
import { TUser } from "../../../store/userStore";
import { isFoodOrder, isTransferOrder, OrderPageLocationState, TOrderFormValues } from "../definitions";
import { initializeForm, useGetPrice } from "../utils";

export function useOrderForm(currentUser: TUser, locationState: OrderPageLocationState) {
  const getPrice = useGetPrice(locationState);

  return useForm<TOrderFormValues>({
    initialValues: initializeForm(currentUser, locationState),

    transformValues: (values) => {
      return {
        ...values,
        price: getPrice(values),
      };
    },

    validate: {
      contact: (value, values) => {
        // @ts-ignore
        if (value && [EContactType.Whatsapp, EContactType.TelephoneNumber].includes(values.contactType)) {
          if ((value!.match(/\d/g)?.length ?? 0) < 7) {
            return "Type your phone number";
          } else {
            return null;
          }
        } else {
          return fieldRequired(value, "Contact");
        }
      },
      contactType: (value) => fieldRequired(value, "Contact type"),
      address: (value) => {
        if (isTransferOrder(locationState.data)) {
          return null;
        }
        return fieldRequired(value, "Address");
      },
      adultsCount: (value) => {
        if (isFoodOrder(locationState.data)) {
          return null;
        }
        return fieldRequired(value!, "Persons count");
      },
      passengerNames: (value) => {
        if (isTransferOrder(locationState.data)) {
          return fieldRequired(value!, "Passenger names");
        } else {
          return null;
        }
      },
    },
  });
}
