import { Button, ButtonProps } from "@mantine/core";
import { ReactElement } from "react-markdown/lib/react-markdown";

type ButtonCustom = {
  onClick: () => void;
  disabled?: boolean;
  label?: string;
  value?: any;
  children?: ReactElement;
  theme?: "travolza";
} & ButtonProps;

export const ButtonCustom = ({ value, label, disabled, onClick, children, theme, ...props }: ButtonCustom) => {
  return (
    <>
      {theme === "travolza" ? (
        <Button
          onClick={onClick}
          color={"dark"}
          disabled={disabled}
          radius={"lg"}
          size="lg"
          w={"100%"}
          {...props}
          // styles={(theme) => ({
          //   root: {
          //     backgroundColor: "#131621",
          //     width: "100%",
          //     fontSize: "1.2rem",
          //     // padding: "0px 24px",
          //     // border: "none",
          //     // borderRadius: "var(--radius-sm)",
          //     // opacity: 0,
          //   },
          // })}
        >
          {children}
        </Button>
      ) : (
        <Button onClick={onClick} color="violet" disabled={disabled} radius="lg" size="lg" {...props}>
          {children}
        </Button>
      )}
    </>
  );
};
