/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserGetUserTgChatIdGet200Response,
  UserGetUserTgChatIdGet304Response,
  UserRegisterUserPostRequest,
  UserSendSupportMessagePostRequest,
  UserSetAddressPostRequest,
  UserSetAppNamePostRequest,
  UserSetCityPostRequest,
  UserSetLanguagePostRequest,
} from '../models';
import {
    UserGetUserTgChatIdGet200ResponseFromJSON,
    UserGetUserTgChatIdGet200ResponseToJSON,
    UserGetUserTgChatIdGet304ResponseFromJSON,
    UserGetUserTgChatIdGet304ResponseToJSON,
    UserRegisterUserPostRequestFromJSON,
    UserRegisterUserPostRequestToJSON,
    UserSendSupportMessagePostRequestFromJSON,
    UserSendSupportMessagePostRequestToJSON,
    UserSetAddressPostRequestFromJSON,
    UserSetAddressPostRequestToJSON,
    UserSetAppNamePostRequestFromJSON,
    UserSetAppNamePostRequestToJSON,
    UserSetCityPostRequestFromJSON,
    UserSetCityPostRequestToJSON,
    UserSetLanguagePostRequestFromJSON,
    UserSetLanguagePostRequestToJSON,
} from '../models';

export interface UserGetUserTgChatIdGetRequest {
    tgChatId: string;
    initData: string;
    tgName?: string;
    isAdmin?: boolean;
}

export interface UserRegisterUserPostOperationRequest {
    userRegisterUserPostRequest: UserRegisterUserPostRequest;
}

export interface UserSendSupportMessagePostOperationRequest {
    userSendSupportMessagePostRequest: UserSendSupportMessagePostRequest;
}

export interface UserSetAddressPostOperationRequest {
    userSetAddressPostRequest: UserSetAddressPostRequest;
}

export interface UserSetAppNamePostOperationRequest {
    userSetAppNamePostRequest: UserSetAppNamePostRequest;
}

export interface UserSetCityPostOperationRequest {
    userSetCityPostRequest: UserSetCityPostRequest;
}

export interface UserSetLanguagePostOperationRequest {
    userSetLanguagePostRequest: UserSetLanguagePostRequest;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Get user by his tgChatId
     */
    async userGetUserTgChatIdGetRaw(requestParameters: UserGetUserTgChatIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGetUserTgChatIdGet200Response>> {
        if (requestParameters.tgChatId === null || requestParameters.tgChatId === undefined) {
            throw new runtime.RequiredError('tgChatId','Required parameter requestParameters.tgChatId was null or undefined when calling userGetUserTgChatIdGet.');
        }

        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling userGetUserTgChatIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.tgName !== undefined) {
            queryParameters['tgName'] = requestParameters.tgName;
        }

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        if (requestParameters.isAdmin !== undefined) {
            queryParameters['isAdmin'] = requestParameters.isAdmin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/get_user/{tgChatId}`.replace(`{${"tgChatId"}}`, encodeURIComponent(String(requestParameters.tgChatId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGetUserTgChatIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Get user by his tgChatId
     */
    async userGetUserTgChatIdGet(requestParameters: UserGetUserTgChatIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGetUserTgChatIdGet200Response> {
        const response = await this.userGetUserTgChatIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set user registered ? to true
     */
    async userRegisterUserPostRaw(requestParameters: UserRegisterUserPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userRegisterUserPostRequest === null || requestParameters.userRegisterUserPostRequest === undefined) {
            throw new runtime.RequiredError('userRegisterUserPostRequest','Required parameter requestParameters.userRegisterUserPostRequest was null or undefined when calling userRegisterUserPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/register_user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRegisterUserPostRequestToJSON(requestParameters.userRegisterUserPostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set user registered ? to true
     */
    async userRegisterUserPost(requestParameters: UserRegisterUserPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userRegisterUserPostRaw(requestParameters, initOverrides);
    }

    /**
     * Send message to support
     */
    async userSendSupportMessagePostRaw(requestParameters: UserSendSupportMessagePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userSendSupportMessagePostRequest === null || requestParameters.userSendSupportMessagePostRequest === undefined) {
            throw new runtime.RequiredError('userSendSupportMessagePostRequest','Required parameter requestParameters.userSendSupportMessagePostRequest was null or undefined when calling userSendSupportMessagePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/send_support_message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserSendSupportMessagePostRequestToJSON(requestParameters.userSendSupportMessagePostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send message to support
     */
    async userSendSupportMessagePost(requestParameters: UserSendSupportMessagePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userSendSupportMessagePostRaw(requestParameters, initOverrides);
    }

    /**
     * Set user address
     */
    async userSetAddressPostRaw(requestParameters: UserSetAddressPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userSetAddressPostRequest === null || requestParameters.userSetAddressPostRequest === undefined) {
            throw new runtime.RequiredError('userSetAddressPostRequest','Required parameter requestParameters.userSetAddressPostRequest was null or undefined when calling userSetAddressPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/set_address`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserSetAddressPostRequestToJSON(requestParameters.userSetAddressPostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set user address
     */
    async userSetAddressPost(requestParameters: UserSetAddressPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userSetAddressPostRaw(requestParameters, initOverrides);
    }

    /**
     * Set user app name for contacting
     */
    async userSetAppNamePostRaw(requestParameters: UserSetAppNamePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userSetAppNamePostRequest === null || requestParameters.userSetAppNamePostRequest === undefined) {
            throw new runtime.RequiredError('userSetAppNamePostRequest','Required parameter requestParameters.userSetAppNamePostRequest was null or undefined when calling userSetAppNamePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/set_app_name`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserSetAppNamePostRequestToJSON(requestParameters.userSetAppNamePostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set user app name for contacting
     */
    async userSetAppNamePost(requestParameters: UserSetAppNamePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userSetAppNamePostRaw(requestParameters, initOverrides);
    }

    /**
     * Set user city
     */
    async userSetCityPostRaw(requestParameters: UserSetCityPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userSetCityPostRequest === null || requestParameters.userSetCityPostRequest === undefined) {
            throw new runtime.RequiredError('userSetCityPostRequest','Required parameter requestParameters.userSetCityPostRequest was null or undefined when calling userSetCityPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/set_city`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserSetCityPostRequestToJSON(requestParameters.userSetCityPostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set user city
     */
    async userSetCityPost(requestParameters: UserSetCityPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userSetCityPostRaw(requestParameters, initOverrides);
    }

    /**
     * Set user language
     */
    async userSetLanguagePostRaw(requestParameters: UserSetLanguagePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userSetLanguagePostRequest === null || requestParameters.userSetLanguagePostRequest === undefined) {
            throw new runtime.RequiredError('userSetLanguagePostRequest','Required parameter requestParameters.userSetLanguagePostRequest was null or undefined when calling userSetLanguagePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/set_language`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserSetLanguagePostRequestToJSON(requestParameters.userSetLanguagePostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set user language
     */
    async userSetLanguagePost(requestParameters: UserSetLanguagePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userSetLanguagePostRaw(requestParameters, initOverrides);
    }

}
