import { restaurantsApi } from "../../../apiDrivers/restaurants";

const fetchFoodById = async ({ queryKey }: any) => {
  const initData = queryKey[1];
  const id = queryKey[2];
  if (!id) {
    throw new Error(`API fetchFoodById use failed`);
  }

  const res = await restaurantsApi.foodRestaurantMenuFoodByIdFoodIdGet({
    initData,
    foodId: id,
  });

  if (!res.foodItem) {
    throw new Error(`Returns all food of restaurant fetch failed`);
  }

  return res.foodItem;
};

export default fetchFoodById;
