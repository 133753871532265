/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EUserSeaTripStatus } from './EUserSeaTripStatus';
import {
    EUserSeaTripStatusFromJSON,
    EUserSeaTripStatusFromJSONTyped,
    EUserSeaTripStatusToJSON,
} from './EUserSeaTripStatus';

/**
 * 
 * @export
 * @interface SeaTripOrderStatus
 */
export interface SeaTripOrderStatus {
    /**
     * 
     * @type {EUserSeaTripStatus}
     * @memberof SeaTripOrderStatus
     */
    status: EUserSeaTripStatus;
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrderStatus
     */
    updateTime: string;
}

/**
 * Check if a given object implements the SeaTripOrderStatus interface.
 */
export function instanceOfSeaTripOrderStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "updateTime" in value;

    return isInstance;
}

export function SeaTripOrderStatusFromJSON(json: any): SeaTripOrderStatus {
    return SeaTripOrderStatusFromJSONTyped(json, false);
}

export function SeaTripOrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeaTripOrderStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': EUserSeaTripStatusFromJSON(json['status']),
        'updateTime': json['updateTime'],
    };
}

export function SeaTripOrderStatusToJSON(value?: SeaTripOrderStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': EUserSeaTripStatusToJSON(value.status),
        'updateTime': value.updateTime,
    };
}

