import { useQuery } from "@tanstack/react-query";
import { fetchUserFoodOrdersList, normalizeFoodOrder } from "../api/foodOrders";
import { useFn } from "../../../hooks/useFn";

export default function useFoodOrders(userId: string, initData: string) {
  return useQuery({
    queryKey: [`${userId} food orders`, initData, userId],
    queryFn: fetchUserFoodOrdersList,
    select: useFn(normalizeFoodOrder),
    onError: (error) => {
      console.log(error);
    },
  });
}
