/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FoodLookup } from './FoodLookup';
import {
    FoodLookupFromJSON,
    FoodLookupFromJSONTyped,
    FoodLookupToJSON,
} from './FoodLookup';
import type { RestaurantTag } from './RestaurantTag';
import {
    RestaurantTagFromJSON,
    RestaurantTagFromJSONTyped,
    RestaurantTagToJSON,
} from './RestaurantTag';

/**
 * 
 * @export
 * @interface Restaurant
 */
export interface Restaurant {
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    googleMapsUrl: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Restaurant
     */
    images: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    workingHours: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    deliveryTime: string;
    /**
     * 
     * @type {Array<RestaurantTag>}
     * @memberof Restaurant
     */
    tags: Array<RestaurantTag>;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    rating: number;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    address: string;
    /**
     * 
     * @type {boolean}
     * @memberof Restaurant
     */
    onlyTakeAway: boolean;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    deliveryPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    deliveryPriceThreshold?: number;
    /**
     * 
     * @type {Array<FoodLookup>}
     * @memberof Restaurant
     */
    items?: Array<FoodLookup>;
}

/**
 * Check if a given object implements the Restaurant interface.
 */
export function instanceOfRestaurant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "googleMapsUrl" in value;
    isInstance = isInstance && "images" in value;
    isInstance = isInstance && "workingHours" in value;
    isInstance = isInstance && "deliveryTime" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "rating" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "onlyTakeAway" in value;

    return isInstance;
}

export function RestaurantFromJSON(json: any): Restaurant {
    return RestaurantFromJSONTyped(json, false);
}

export function RestaurantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Restaurant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'googleMapsUrl': json['googleMapsUrl'],
        'images': json['images'],
        'workingHours': json['workingHours'],
        'deliveryTime': json['deliveryTime'],
        'tags': ((json['tags'] as Array<any>).map(RestaurantTagFromJSON)),
        'rating': json['rating'],
        'address': json['address'],
        'onlyTakeAway': json['onlyTakeAway'],
        'deliveryPrice': !exists(json, 'deliveryPrice') ? undefined : json['deliveryPrice'],
        'deliveryPriceThreshold': !exists(json, 'deliveryPriceThreshold') ? undefined : json['deliveryPriceThreshold'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(FoodLookupFromJSON)),
    };
}

export function RestaurantToJSON(value?: Restaurant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'googleMapsUrl': value.googleMapsUrl,
        'images': value.images,
        'workingHours': value.workingHours,
        'deliveryTime': value.deliveryTime,
        'tags': ((value.tags as Array<any>).map(RestaurantTagToJSON)),
        'rating': value.rating,
        'address': value.address,
        'onlyTakeAway': value.onlyTakeAway,
        'deliveryPrice': value.deliveryPrice,
        'deliveryPriceThreshold': value.deliveryPriceThreshold,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(FoodLookupToJSON)),
    };
}

