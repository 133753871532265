import { Text, Popover, PopoverProps, ActionIcon } from "@mantine/core";
import { CircleQuestion } from "@gravity-ui/icons";

type HelpTooltipParam = {
  text: string;
} & Pick<PopoverProps, "position"> &
  Pick<PopoverProps, "width">;

export const HelpTooltip = ({ position, text, width = 320 }: HelpTooltipParam) => {
  return (
    <Popover width={width} position={position} withArrow shadow="md" radius={"lg"}>
      <Popover.Target>
        <ActionIcon variant="transparent" color="gray" radius="xl" aria-label="Question" size={"md"} ml={2}>
          <CircleQuestion width={50} height={50}></CircleQuestion>
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown bg={"dark"}>
        <Text size={"xl"} color={"white"}>
          {text}
        </Text>
      </Popover.Dropdown>
    </Popover>
  );
};
