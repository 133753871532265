import { excursionsApi } from "../../../apiDrivers/excursions";

const fetchExcursionById = async ({ queryKey }: any) => {
  const initData = queryKey[1];
  const id = queryKey[2];
  if (!id) {
    throw new Error(`API get excursion by id use failed`);
  }

  const res = await excursionsApi.excursionsExcursionByIdExcursionIdGet({
    initData,
    excursionId: id,
  });

  if (!res.excursion) {
    throw new Error(`get excursion by id fetch failed`);
  }

  return res.excursion;
};

export default fetchExcursionById;
