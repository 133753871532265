import { TransferCarTypeEnum } from "../../../client";
import { TTransferPoint, TTransferRoute } from "../lib/types";

/*
 Как этим пользоваться?
 transferRoutes - маршруты от точки до точки.
 transferPoints - набор точке для маршрутов.

 0. Проверить нет ли уже этих точек в transferPoints
 1. Добавить точку в transferPoints
 2. Из имен точек добавить маршрут в transferRoutes

 Пример для transferPoints:

 Алгоритм проверяет по очереди
 1. boundingbox - если нашел, то остальное не смотрит
 2. requireSearchNameTags - проверяет обязательные подстроки, после чего переходит к optionalSearchNameTags, если не пустой
 3. optionalSearchNameTags - хотя бы одно слово из списка (если оно там есть) - должно находиться в адресе

  {
    name: "Kalkan", - имя которое можно использовать в from или  to в transferRoutes
    language: {
      ru: {
        requireSearchNameTags: ["Калкан", "Антал", "Каш"], - обязательные слова которые должны находиться в адресе точки
        optionalSearchNameTags: [], - хотябы одно слово из этого списка должно находиться в строке адреса точки
      },
      en: {
        requireSearchNameTags: ["Kalkan", "Antalya", "Kaş"], - аналогично на для английского адреса - можно проверить в приложении введя нужный адрес
        optionalSearchNameTags: [], - алогично для английского адреса
      },
    },
  },
  {
    name: "Antalya Otogar", - имя которе нужно для from или to в transferRoutes
    language: {
      ru: {
        requireSearchNameTags: ["Антал"],
        optionalSearchNameTags: ["Автовокзал Анталии", "Автовокзал"],
      },
      en: {
        requireSearchNameTags: ["Antalya"],
        optionalSearchNameTags: ["Antalya Otogar", "Otogar"],
      },
    },
    boundingbox: {                                                  - область из которой все точки будут засчитываться в эту точку
      topLeft: { lat: 36.925357303466654, lon: 30.666205404240742 }, - левый верхний угол прямоугольника
      bottomRight: { lat: 36.92335881706464, lon: 30.668972408139865 }, - правый нижний угол прямоугольника
    },
  },
  ====================================

  Пример для transferRoutes:

  {
    from: "Milas-Bodrum Havalimani", - имя(name) точки
    to: "Bozburun", - имя(name) точки
    reversible: true, - можно ли в обратную сторону использовать маршрут для определения цены
    distance: {
      distanceValue: 185000, - предположительная дистанция до точки
      distanceError: 5000, - допуск на сколько можно дальше уехать по этой цене если адреса точке попали под критерии данного маршрута
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] +
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 4000, - цена за седан + надбавка
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 5000, - цена за вип
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 7500, - цена за минивен
    }, - есть остальные типы маршрута, если для них не указано, то цена берется не из таблицы а из следующего метода рассчета цены (на момент написания кода это рассчет по дистанции)
  },


*/

// надбавка над каждым трансфером
const additionalPrice = {
  [TransferCarTypeEnum.Sedan]: 300,
  [TransferCarTypeEnum.Vip]: 500,
  [TransferCarTypeEnum.Minivan]: 300,
  [TransferCarTypeEnum.Minibus]: 500,
};

export const transferRoutes: TTransferRoute[] = [
  {
    from: "Milas-Bodrum Havalimani",
    to: "Bozburun",
    reversible: true,
    distance: {
      distanceValue: 185000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 8000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 4000,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 5000,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 7500,
    },
  },
  {
    from: "Kaş",
    to: "Kalkan",
    reversible: true,
    distance: {
      distanceValue: 30000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1200,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 600,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 700,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 800,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Ören",
    reversible: true,
    distance: {
      distanceValue: 55000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1500,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 2000,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 3000,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Didim - Akbük",
    reversible: true,
    distance: {
      distanceValue: 75000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3600,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 3300,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 3575,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 5000,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Göcek",
    reversible: true,
    distance: {
      distanceValue: 172000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 7000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 3500,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 4000,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 6000,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Fethiye",
    reversible: true,
    distance: {
      distanceValue: 200000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 8000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 4000,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 4500,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 6000,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Marmaris",
    reversible: true,
    distance: {
      distanceValue: 129000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 6000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 3000,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 3300,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 4700,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Dalaman",
    reversible: true,
    distance: {
      distanceValue: 165000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 7000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 3500,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 4000,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 6000,
    },
  },
  {
    from: "Kalkan",
    to: "Dalaman",
    reversible: true,
    distance: {
      distanceValue: 122000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 4700,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 3200,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 3400,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 6800,
    },
  },
  {
    from: "Kash",
    to: "Dalaman",
    reversible: true,
    distance: {
      distanceValue: 150000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 4700,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 3200,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 3400,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 6800,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Koyunbaba",
    reversible: true,
    distance: {
      distanceValue: 60000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2640,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1320,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1430,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2640,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Gümüslük",
    reversible: true,
    distance: {
      distanceValue: 55000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2640,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1320,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1430,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2640,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Datça",
    reversible: true,
    distance: {
      distanceValue: 88000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 8000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 4000,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 5000,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 7500,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Çeşme",
    reversible: true,
    distance: {
      distanceValue: 281000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 12000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 6000,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 7000,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 9000,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Kuşadası",
    reversible: true,
    distance: {
      distanceValue: 120000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 8000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 4000,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 5000,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 6000,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "İzmir",
    reversible: true,
    distance: {
      distanceValue: 203000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 8000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 4000,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 5000,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 6000,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Gümüşlük",
    reversible: true,
    distance: {
      distanceValue: 62000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1320 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1320,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1430,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2640,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Kadıkalesi",
    reversible: true,
    distance: {
      distanceValue: 62000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1320 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1320,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1430,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2640,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Bağla",
    reversible: true,
    distance: {
      distanceValue: 59000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1320 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1320,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1430,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2640,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Akyarlar",
    reversible: true,
    distance: {
      distanceValue: 59000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1320 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1320,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1430,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2640,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Yalıçiftlik",
    reversible: true,
    distance: {
      distanceValue: 52000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 960 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1040,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1040,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 1920,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Göltürkbükü",
    reversible: true,
    distance: {
      distanceValue: 48000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1200 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1200,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1300,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2400,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Gündoğan",
    reversible: true,
    distance: {
      distanceValue: 48000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1200 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1200,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1300,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2400,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Ortakent",
    reversible: true,
    distance: {
      distanceValue: 58000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2160,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1080,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1170,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2160,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Bitez",
    reversible: true,
    distance: {
      distanceValue: 47000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 960 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 960,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1040,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 1920,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Torba",
    reversible: true,
    distance: {
      distanceValue: 48000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2200,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1100,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1200,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2000,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Bodrum",
    reversible: true,
    distance: {
      distanceValue: 60000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 960 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 960,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1040,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 1920,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Güvercinlik",
    reversible: true,
    distance: {
      distanceValue: 26000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1680,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 840,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 910,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 1680,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Güllük",
    reversible: true,
    distance: {
      distanceValue: 15000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1680,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 840,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 910,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 1680,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Yalıkavak",
    reversible: true,
    distance: {
      distanceValue: 58000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2640,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1320,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1430,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2640,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Turgutreis",
    reversible: true,
    distance: {
      distanceValue: 54000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1640,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1320,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1430,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2640,
    },
  },
  {
    from: "Milas-Bodrum Havalimani",
    to: "Bodrum",
    reversible: true,
    distance: {
      distanceValue: 35000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1920,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 960,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1040,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 1920,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Antalya",
    reversible: true,
    distance: {
      distanceValue: 12000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1800,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 900,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 950,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 1450,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Fethiye",
    reversible: true,
    distance: {
      distanceValue: 210000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 9000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 4500,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 4800,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 8000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Kundu",
    reversible: true,
    distance: {
      distanceValue: 15000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1800,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 900,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 950,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 1450,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Demre",
    reversible: true,
    distance: {
      distanceValue: 155000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 4400,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 2200,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 2500,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 5800,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Konyaaltı",
    reversible: true,
    distance: {
      distanceValue: 155000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 950,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1000,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 1500,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Boğazkent",
    reversible: true,
    distance: {
      distanceValue: 44000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1500,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1600,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2400,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Çolaklı",
    reversible: true,
    distance: {
      distanceValue: 80000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1450,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1500,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 1600,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Kızılağaç",
    reversible: true,
    distance: {
      distanceValue: 80000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1950 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1950,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 2100,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 3150,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Çıralı",
    reversible: true,
    distance: {
      distanceValue: 94000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1500,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1700,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 4500,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "İncekum",
    reversible: true,
    distance: {
      distanceValue: 155000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2650 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 2650,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 2800,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 4200,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Avsallar",
    reversible: true,
    distance: {
      distanceValue: 100000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2650 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 2650,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 2800,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 4200,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Konaklı",
    reversible: true,
    distance: {
      distanceValue: 116000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3000 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 3000,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 3200,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 4850,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Ölüdeniz",
    reversible: true,
    distance: {
      distanceValue: 215000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 5000 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 5000,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 5350,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 8000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Göcek",
    reversible: true,
    distance: {
      distanceValue: 234000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 5250 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 5250,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 5400,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 8000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Dalaman",
    reversible: true,
    distance: {
      distanceValue: 250000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 5400 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 5400,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 5600,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 9000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Marmaris",
    reversible: true,
    distance: {
      distanceValue: 331000,
      distanceError: 15000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 8800 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 8800,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 9400,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 14050,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Bodrum",
    reversible: true,
    distance: {
      distanceValue: 435000,
      distanceError: 20000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 11300 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 11300,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 12050,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 18050,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Denizli",
    reversible: true,
    distance: {
      distanceValue: 237000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 5350 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 5350,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 5700,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 8550,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Pamukkale",
    reversible: true,
    distance: {
      distanceValue: 237000,
      distanceError: 15000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 6050 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 6050,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 6450,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 9650,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Burdur",
    reversible: true,
    distance: {
      distanceValue: 134000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3400 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 3400,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 3650,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 5450,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Isparta",
    reversible: true,
    distance: {
      distanceValue: 150000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3600 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 3600,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 4000,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 6000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Kızılot",
    reversible: true,
    distance: {
      distanceValue: 82000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2100 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 2100,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 2250,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 3350,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Kumköy",
    reversible: true,
    distance: {
      distanceValue: 30000,
      distanceError: 8000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1600,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1300,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1400,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2100,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Lara",
    reversible: true,
    distance: {
      distanceValue: 15000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1800,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 900,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 950,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 1450,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Antalya Otogar",
    reversible: true,
    distance: {
      distanceValue: 18000,
      distanceError: 5000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1800,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 900,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 950,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 1450,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Belek",
    reversible: true,
    distance: {
      distanceValue: 32000,
      distanceError: 8000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2600,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1300,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1400,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2100,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Bogazkent",
    reversible: true,
    distance: {
      distanceValue: 40000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 300,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1500,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1600,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2400,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Colakli",
    reversible: true,
    distance: {
      distanceValue: 50000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1500,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1600,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2450,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Göynük",
    reversible: true,
    distance: {
      distanceValue: 50000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2400,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1200,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1400,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 3000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Beldibi",
    reversible: true,
    distance: {
      distanceValue: 60000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2400,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1200,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1400,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 3000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Kemer",
    reversible: true,
    distance: {
      distanceValue: 60000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3200,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1200,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1400,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 3000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Çamyuva",
    reversible: true,
    distance: {
      distanceValue: 60000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2400,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1200,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1300,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 3000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Side",
    reversible: true,
    distance: {
      distanceValue: 65000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3350,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1700,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1800,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2750,
    },
  },

  {
    from: "Antalya Havalimani",
    to: "Sorgun",
    reversible: true,
    distance: {
      distanceValue: 65000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3350,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1700,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1800,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2750,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Titreyengöl",
    reversible: true,
    distance: {
      distanceValue: 65000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3350,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1700,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1800,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2750,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Kiris",
    reversible: true,
    distance: {
      distanceValue: 65000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3350,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1200,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1400,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 3000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Manavgat",
    reversible: true,
    distance: {
      distanceValue: 65000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 3350,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1700,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1800,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 2750,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Kizilagaç",
    reversible: true,
    distance: {
      distanceValue: 75000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1950 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1950,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 2100,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 3150,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Tekirova",
    reversible: true,
    distance: {
      distanceValue: 75000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1950 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1200,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1300,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 3150,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Kizilot",
    reversible: true,
    distance: {
      distanceValue: 80000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 4200,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 2100,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 2250,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 3350,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Girali",
    reversible: true,
    distance: {
      distanceValue: 95000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 5000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 2500,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 2250,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 4000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Okurcalar",
    reversible: true,
    distance: {
      distanceValue: 95000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 5000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 2500,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 2600,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 4000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Olimpos",
    reversible: true,
    distance: {
      distanceValue: 100000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 1500 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1500,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1700,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 4500,
    },
  },

  {
    from: "Antalya Havalimani",
    to: "Adrasan",
    reversible: true,
    distance: {
      distanceValue: 105000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 2750 * 2,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 1500,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 1700,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 4500,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Kumluca",
    reversible: true,
    distance: {
      distanceValue: 115000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 4000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 2000,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 2200,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 5000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Alanya",
    reversible: true,
    distance: {
      distanceValue: 125000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 6100,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 3050,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 3250,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 4900,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Mahmutlar",
    reversible: true,
    distance: {
      distanceValue: 135000,
      distanceError: 10000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 7100,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 3550,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 3800,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 5650,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Finike",
    reversible: true,
    distance: {
      distanceValue: 200000,
      distanceError: 15000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 4000,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 2000,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 2200,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 5000,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Kaş",
    reversible: true,
    distance: {
      distanceValue: 200000,
      distanceError: 15000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 5350,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 2700,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 3000,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 6600,
    },
  },
  {
    from: "Antalya Havalimani",
    to: "Kalkan",
    reversible: true,
    distance: {
      distanceValue: 200000,
      distanceError: 100000,
    },
    price: {
      [TransferCarTypeEnum.Vip]: additionalPrice[TransferCarTypeEnum.Vip] + 5350,
      [TransferCarTypeEnum.Sedan]: additionalPrice[TransferCarTypeEnum.Sedan] + 2700,
      [TransferCarTypeEnum.Minivan]: additionalPrice[TransferCarTypeEnum.Minivan] + 3000,
      [TransferCarTypeEnum.Minibus]: additionalPrice[TransferCarTypeEnum.Minibus] + 6600,
    },
  },
];

const transferPoints: TTransferPoint[] = [
  {
    name: "Antalya Havalimani",
    language: {
      ru: {
        requireSearchNameTags: ["Антал"],
        optionalSearchNameTags: ["Хавалимани", "Аэропорт"],
      },
      en: {
        requireSearchNameTags: ["Antalya"],
        optionalSearchNameTags: ["Antalya Havalimani", "Havalimani", "Airport"],
      },
    },
    center: { lat: 36.89994245, lon: 30.798191449837162 },
    boundingbox: {
      topLeft: { lat: 36.9345131, lon: 30.7803488 },
      bottomRight: { lat: 36.86937940701357, lon: 30.819219731405752 },
    },
  },
  {
    name: "Antalya Konyaalt",
    language: {
      ru: {
        requireSearchNameTags: ["Коньяалт"],
        optionalSearchNameTags: ["Анталия Коньяалт", "Коньяалт"],
      },
      en: {
        requireSearchNameTags: ["Konyaalt"],
        optionalSearchNameTags: ["Antalya Konyaalt", "Konyaalt"],
      },
    },
    center: { lat: 36.871803, lon: 30.6536774 },
  },
  {
    name: "Milas-Bodrum Havalimani",
    language: {
      ru: {
        requireSearchNameTags: ["Милас"],
        optionalSearchNameTags: ["Хавалимани", "Аэропорт", "Милас-Аэропорт Бодрум", "Милас-Бодрум"],
      },
      en: {
        requireSearchNameTags: ["Milas-Bodrum"],
        optionalSearchNameTags: ["Milas-Bodrum Havalimani", "Havalimani", "Airport"],
      },
    },
    boundingbox: {
      topLeft: { lat: 37.27451030788575, lon: 27.636982177359844 },
      bottomRight: { lat: 37.22746205786527, lon: 27.69488488948962 },
    },
  },
  {
    name: "Antalya Otogar",
    language: {
      ru: {
        requireSearchNameTags: ["Антал"],
        optionalSearchNameTags: ["Автовокзал Анталии", "Автовокзал"],
      },
      en: {
        requireSearchNameTags: ["Antalya"],
        optionalSearchNameTags: ["Antalya Otogar", "Otogar"],
      },
    },
    boundingbox: {
      topLeft: { lat: 36.925357303466654, lon: 30.666205404240742 },
      bottomRight: { lat: 36.92335881706464, lon: 30.668972408139865 },
    },
  },
  {
    name: "Belek",
    language: {
      ru: {
        requireSearchNameTags: ["Белек", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Belek", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Bogazkent",
    language: {
      ru: {
        requireSearchNameTags: ["Антал"],
        optionalSearchNameTags: ["Богазкент", "Богазкент"],
      },
      en: {
        requireSearchNameTags: ["Antalya"],
        optionalSearchNameTags: ["Bogazkent", "Boğazkent"],
      },
    },
  },
  {
    name: "Kundu",
    language: {
      ru: {
        requireSearchNameTags: ["Кунду", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Kundu", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Kundu",
    language: {
      ru: {
        requireSearchNameTags: ["Кунду", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Kundu", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Colakli",
    language: {
      ru: {
        requireSearchNameTags: ["Колакли", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Colakli", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Kumköy",
    language: {
      ru: {
        requireSearchNameTags: ["Кумкой", "Сома"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Kumköy", "Soma"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Side",
    language: {
      ru: {
        requireSearchNameTags: ["Сиде", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Side", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Sorgun",
    language: {
      ru: {
        requireSearchNameTags: ["Соргун", "Анкар"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Sorgun", "Ankara"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Titreyengöl",
    language: {
      ru: {
        requireSearchNameTags: ["Титрейенгель", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Titreyengöl", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Kizilagaç",
    language: {
      ru: {
        requireSearchNameTags: ["Антал"],
        optionalSearchNameTags: ["Кызылагач", "Ольха"],
      },
      en: {
        requireSearchNameTags: ["Kizilagaç", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Kizilot",
    language: {
      ru: {
        requireSearchNameTags: ["Кизилот", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Kizilot", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Alanya",
    language: {
      ru: {
        requireSearchNameTags: ["Антал"],
        optionalSearchNameTags: ["Аланья", "Алания"],
      },
      en: {
        requireSearchNameTags: ["Alanya", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Göynük",
    language: {
      ru: {
        requireSearchNameTags: ["Гейнюк", "Аванос"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Göynük", "Avanos"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Kemer",
    language: {
      ru: {
        requireSearchNameTags: ["Кемер", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Kemer", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Kiris",
    language: {
      ru: {
        requireSearchNameTags: ["Антал"],
        optionalSearchNameTags: ["Балок", "Кириш"],
      },
      en: {
        requireSearchNameTags: ["Antalya"],
        optionalSearchNameTags: ["Kiriş", "Kiris"],
      },
    },
  },
  {
    name: "Çamyuva",
    language: {
      ru: {
        requireSearchNameTags: ["Чамьюва", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Çamyuva", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Tekirova",
    language: {
      ru: {
        requireSearchNameTags: ["Текирова", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Tekirova", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Olimpos",
    language: {
      ru: {
        requireSearchNameTags: ["Антал"],
        optionalSearchNameTags: ["Олимп", "Олимпос"],
      },
      en: {
        requireSearchNameTags: ["Olimpos", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Adrasan",
    language: {
      ru: {
        requireSearchNameTags: ["Адрасан", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Adrasan", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Kumluca",
    language: {
      ru: {
        requireSearchNameTags: ["Кумлуджа", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Kumluca", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Finike",
    language: {
      ru: {
        requireSearchNameTags: ["Финике", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Finike", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Manavgat",
    language: {
      ru: {
        requireSearchNameTags: ["Манавгат", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Manavgat", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Okurcalar",
    language: {
      ru: {
        requireSearchNameTags: ["Окурджалар", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Okurcalar", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Bozburun",
    language: {
      ru: {
        requireSearchNameTags: ["Бозбурун", "Мармарис"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Bozburun", "Marmaris"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Ören",
    language: {
      ru: {
        requireSearchNameTags: ["Орен", "Эмирдаг"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Emirdağ"],
        optionalSearchNameTags: ["Ören", "Oren"],
      },
    },
  },
  {
    name: "Didim - Akbük",
    language: {
      ru: {
        requireSearchNameTags: ["Акбук", "Дидим"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Akbük", "Didim"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Göcek",
    language: {
      ru: {
        requireSearchNameTags: ["Гечек", "Фетхие"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Göcek", "Fethiye"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Fethiye",
    language: {
      ru: {
        requireSearchNameTags: ["Фетхие", "Мугла"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Fethiye", "Muğla"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Datça",
    language: {
      ru: {
        requireSearchNameTags: ["Датча", "Мугла"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Datça", "Muğla"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Marmaris",
    language: {
      ru: {
        requireSearchNameTags: ["Мармарис"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Marmaris"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Dalaman",
    language: {
      ru: {
        requireSearchNameTags: ["Даламан"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Dalaman"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Koyunbaba",
    language: {
      ru: {
        requireSearchNameTags: ["Коюнбаба", "Бодрум"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Koyunbaba", "Bodrum"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Turgutreis",
    language: {
      ru: {
        requireSearchNameTags: ["Тургутрейс", "Бодрум"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Turgutreis", "Bodrum"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Bodrum",
    language: {
      ru: {
        requireSearchNameTags: ["Бодрум"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Bodrum"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Torba",
    language: {
      ru: {
        requireSearchNameTags: ["Бодрум"],
        optionalSearchNameTags: ["Мешок", "Торба"],
      },
      en: {
        requireSearchNameTags: ["Torba", "Bodrum"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Kalkan",
    language: {
      ru: {
        requireSearchNameTags: ["Калкан", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Kalkan", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Mahmutlar",
    language: {
      ru: {
        requireSearchNameTags: ["Махмутлар", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Mahmutlar", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Demre",
    language: {
      ru: {
        requireSearchNameTags: ["Демре", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Demre", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Konyaaltı",
    language: {
      ru: {
        requireSearchNameTags: ["Коньяалты", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Konyaaltı", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Otogar",
    language: {
      ru: {
        requireSearchNameTags: ["автобусный", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Otogar", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Lara",
    language: {
      ru: {
        requireSearchNameTags: ["Лара", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Lara", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Çolaklı",
    language: {
      ru: {
        requireSearchNameTags: ["Чолаклы", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Çolaklı", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Kızılağaç",
    language: {
      ru: {
        requireSearchNameTags: ["Кызылагач", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Kızılağaç", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Kızılot",
    language: {
      ru: {
        requireSearchNameTags: ["Кызылот", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Kızılot", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Çıralı",
    language: {
      ru: {
        requireSearchNameTags: ["Чиралы", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Çıralı", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "İncekum",
    language: {
      ru: {
        requireSearchNameTags: ["Инджекум", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["İncekum", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Avsallar",
    language: {
      ru: {
        requireSearchNameTags: ["Авсаллар", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Avsallar", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Konaklı",
    language: {
      ru: {
        requireSearchNameTags: ["Конаклы", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Konaklı", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Ölüdeniz",
    language: {
      ru: {
        requireSearchNameTags: ["Олюдениз", "Фетхие"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Ölüdeniz", "Fethiye"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Ölüdeniz",
    language: {
      ru: {
        requireSearchNameTags: ["Олюдениз", "Фетхие"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Ölüdeniz", "Fethiye"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Denizli",
    language: {
      ru: {
        requireSearchNameTags: ["Денизли", "Эгейский регион"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Denizli", "Aegean"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Pamukkale",
    language: {
      ru: {
        requireSearchNameTags: ["Памуккале", "Денизли", "Эгейский регион"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Pamukkale", "Denizli", "Aegean"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Burdur",
    language: {
      ru: {
        requireSearchNameTags: ["Бурдур"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Burdur"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Isparta",
    language: {
      ru: {
        requireSearchNameTags: ["Ыспарта"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Isparta"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Çeşme",
    language: {
      ru: {
        requireSearchNameTags: ["Чешме", "Измир", "Эгейский регион"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Çeşme", "Izmir", "Aegean"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Kuşadası",
    language: {
      ru: {
        requireSearchNameTags: ["Кушадасы", "Айдын", "Эгейский регион"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Kuşadası", "Aydın", "Aegean"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Izmir",
    language: {
      ru: {
        requireSearchNameTags: ["Измир", "Эгейский регион"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Izmir", "Aegean"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Gümüşlük",
    language: {
      ru: {
        requireSearchNameTags: ["Гюмюшлюк", "Бодрум", "Эгейский регион"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Gümüşlük", "Bodrum", "Aegean"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Kadıkalesi",
    language: {
      ru: {
        requireSearchNameTags: ["Кадыкалеси", "Эгейский регион"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Kadıkalesi", "Aegean"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Bağla",
    language: {
      ru: {
        requireSearchNameTags: ["Багла"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Bağla"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Akyarlar",
    language: {
      ru: {
        requireSearchNameTags: ["Акьярлар", "Бодрум"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Bodrum", "Akyarlar"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Yalıçiftlik",
    language: {
      ru: {
        requireSearchNameTags: ["Ялычифтлик"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Yalıçiftlik"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Yalıkavak",
    language: {
      ru: {
        requireSearchNameTags: ["Ялыкавак"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Yalıkavak"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Göltürkbükü",
    language: {
      ru: {
        requireSearchNameTags: ["Гёльтюркбюкю", "Бодрум"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Bodrum", "Göltürkbükü"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Gündoğan",
    language: {
      ru: {
        requireSearchNameTags: ["Гюндоган", "Бодрум"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Bodrum", "Gündoğan"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Ortakent",
    language: {
      ru: {
        requireSearchNameTags: ["Яхши", "Бодрум"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Bodrum", "Ortakent"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Bitez",
    language: {
      ru: {
        requireSearchNameTags: ["Битез", "Бодрум"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Bodrum", "Bitez"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Bitez",
    language: {
      ru: {
        requireSearchNameTags: ["Битез", "Бодрум"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Bodrum", "Bitez"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Güvercinlik",
    language: {
      ru: {
        requireSearchNameTags: ["Гюверджинлик", "Бодрум"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Bodrum", "Güvercinlik"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Güllük",
    language: {
      ru: {
        requireSearchNameTags: ["Гюллюк", "Миляс"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Milas", "Güllük"],
        optionalSearchNameTags: [],
      },
    },
  },
  {
    name: "Beldibi",
    language: {
      ru: {
        requireSearchNameTags: ["Бельдиби", "Антал"],
        optionalSearchNameTags: [],
      },
      en: {
        requireSearchNameTags: ["Beldibi", "Antalya"],
        optionalSearchNameTags: [],
      },
    },
  },
];

export const transferPointsObject = transferPoints.reduce((prevValue, currentValue) => {
  prevValue[currentValue.name] = currentValue;
  return prevValue;
}, {} as Record<string, TTransferPoint>);
