import { ECity, User, UserApi } from "../client";

export const userApi = new UserApi();

export type TFetchUserQueryKey = [string, string, string];

const fetchUser = async ({ queryKey }: any): Promise<User | undefined> => {
  const initData = queryKey[1];
  const tgName = queryKey[2].tgName;
  const isAdmin = queryKey[3] ?? false;

  let tgChatId;

  if (process.env.NODE_ENV === "development") {
    tgChatId = queryKey[2].id ?? 438129957;
  } else {
    tgChatId = queryKey[2].id ?? "";
  }

  const res = await userApi.userGetUserTgChatIdGet({
    tgChatId,
    initData,
    isAdmin,
  });

  return res.user;
};

const setUserCity = async (cacheKey: string, userId: string, city: ECity, initData: string): Promise<unknown> => {
  return await userApi.userSetCityPost({
    userSetCityPostRequest: {
      userId,
      initData,
      city,
    },
  });
};

export const registerUser = async (cacheKey: string, userId: string, initData: string): Promise<unknown> => {
  return await userApi.userRegisterUserPost({
    userRegisterUserPostRequest: {
      userId,
      initData,
    },
  });
};

export { fetchUser, setUserCity };
