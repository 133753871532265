import { ActionIcon, Button, Divider, Flex, Grid, Group, Modal, Paper, Text } from "@mantine/core";
import { DatePickerInput, TimeInput } from "@mantine/dates";
import { useDisclosure, useInputState } from "@mantine/hooks";
import { LatLngTuple } from "leaflet";
import { ChangeEvent, Suspense, useCallback, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Clock } from "@gravity-ui/icons";
import { useTranslation } from "react-i18next";
import { TransferCarTypeEnum } from "../../client";
import { RouteByPointsMap } from "../../components/MapComponents/RouteByPointsMap";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { PointPickerInput } from "../../components/UI/Elements/PointPickerInput";
import { SelectCustom } from "../../components/UI/Vendors/Mantine";
import { IconMinibus } from "../../components/UI/icons/IconMinibus";
import { IconMinivan } from "../../components/UI/icons/IconMinivan";
import { IconSedan } from "../../components/UI/icons/IconSedan";
import { IconVip } from "../../components/UI/icons/IconVip";
import { EColors, ECurrency, RouterPath, TRANSFERCLASS, TTransferClass } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import { ItemBase } from "../../modules/Items/ItemBase";
import { selectCurrentUser } from "../../store/userStore";
import { formatDateTime } from "../../utils/date";
import { EOrderPageIsType, OrderPageTransfer } from "../OrderPage/definitions";
import useExchangeRate from "../../externalAPIs/exchangeAPI";
import { calculateTransferPrice } from "./lib/calculateTransferPrice";
import { NavigationText } from "../../components/NavigationText/NavigationText";
import { reachGoalTransferChosedPoints } from "../../utils/metrika";

function formatDistance(distance: number) {
  let units = "m";
  if (distance >= 1000) {
    distance /= 1000;
    units = "km";
  }
  return `${distance.toFixed(1)} ${units}`;
}

function formatDuration(duration: number) {
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.round((duration / 60) % 60);
  if (hours > 0) {
    return `${hours} h ${minutes} m`;
  } else {
    return `${minutes} m`;
  }
}

export const TransferPage = ({ pageName, pageDescription }: { pageName?: string; pageDescription?: string }) => {
  const { t } = useTranslation(["transfer_page"]);

  const transfer: { name: string; description: string } = {
    name: t("Transfer"),
    description: t(
      "A transfer is an opportunity to book a car in advance from one place to another. You have to choose the date, type of car and number of passengers. After completing the order, you will be contacted by our operator, who will contact you by telegram."
    ),
  };

  let { step } = useParams();

  if (!step) {
    step = "1";
  }
  const { initData } = useTelegram();

  // const [transfer, setTransfer] = useState<Transfer | null>({});

  const [openedModal, { open, close }] = useDisclosure(false);

  // const handleTransferCountIncrement = useFn(() => {
  //   setTransfer({ ...transfer, personCount: (transfer?.personCount || 0) + 1 });
  // });
  // handleTransferCountIncrement();

  // const handleTransferCountDecrement = useFn(() => {
  //   setTransfer({ ...transfer, personCount: (transfer?.personCount || 0) - 1 });
  // });

  const [transferClass, setTransferClass] = useState<TTransferClass>({
    label: "Regular - 1-4 passangers",
    value: TransferCarTypeEnum.Sedan,
    seatsCount: 4,
  });

  const [phone, setPhone] = useState("+90 900 0000");

  const handleChangePhone = (newPhone: string) => {
    setPhone(newPhone);
  };

  const currentUser = useSelector(selectCurrentUser);
  const [date, setDate] = useState<Date | null>(null);
  const [isDatePicked, setIsDatePicked] = useState<boolean>(false);
  const [time, setTime] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [personCount, setPersonCount] = useState<number>(0);
  const [babySeats, setBabySeats] = useState<number>(0);
  const [luggage, setLuggage] = useState<number>(0);
  const [routeDistance, setRouteDistance] = useState<number>(0);
  const [routeDuration, setRouteDuration] = useState<number>(0);
  const [location1, setLocation1] = useState<LatLngTuple>([0, 0]);
  const [location2, setLocation2] = useState<LatLngTuple>([0, 0]);
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [isFormCompleted, setIsFormCompleted] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>();
  const timeInputRef = useRef<HTMLInputElement>(null);

  const [addressInput, setAddressInput] = useInputState("");
  const { data } = useExchangeRate("TRY", "EUR");
  const exchangeRate = Number(data) || 0.035;
  const toOrderPage = useFn(() => {
    if (price > 0) {
      navigate(RouterPath.order.complete, {
        state: {
          data: getTransferOrder(),
          type: EOrderPageIsType.IS_TRANSFER,
        },
      });
    } else {
      // notifications.show({
      //   message: t("Try again"),
      //   autoClose: 10000,
      //   color: "yellow",
      //   icon: <MapPin />,
      // });
    }
  });

  const getTransferOrder = useFn((): OrderPageTransfer => {
    const order: OrderPageTransfer = {
      id: "",
      from: address1,
      fromPointLat: location1[0],
      fromPointLon: location1[1],
      to: address2,
      toPointLat: location2[0],
      toPointLon: location2[1],
      approximatePrice: 0,
      price,
      carType: transferClass.value,
      date: formatDateTime(selectedDate!.toString()),
      time: selectedTime?.toString(),
      comment: "",
      userId: currentUser.id,
      status: "CREATED",
      personCount: 1,
      luggage: "",
      contact: "",
      contactType: "TELEGRAM",
      babySeats: 0,
      flightNumber: "",
      currency: ECurrency.EUR,
      passengerNames: "",
    };

    return order;
  });

  const keys = Object.keys(TransferCarTypeEnum);

  const onChangeTransferCarType = useCallback(
    (value: TransferCarTypeEnum) => {
      const currentTransferClass = TRANSFERCLASS.filter((item: TTransferClass) => {
        return t(item.value) === value;
      })[0];
      setTransferClass(currentTransferClass);
    },
    [TRANSFERCLASS]
  );

  useEffect(() => {
    setPrice(
      calculateTransferPrice({
        from: address1,
        to: address2,
        routeDistance,
        transferClass,
        exchangeRate,
        pointFrom: { lat: location1[0], lon: location1[1] },
        pointTo: { lat: location2[0], lon: location2[1] },
      })
    );
    // Math.ceil((routeDistance / 1000) * (transferClass.value.length / 10 + 1)));
    if (location1[0] !== 0 && location2[0] && address1 && address2 && transferClass && selectedDate && selectedTime) {
      setIsFormCompleted(true);

      reachGoalTransferChosedPoints(address1, address2);
    } else {
      setIsFormCompleted(false);
    }
  }, [routeDistance, transferClass, selectedDate, selectedTime]);

  const navigate = useNavigate();

  const isExcludedDate = useFn((date: Date) => {
    if (date.getTime() < Date.now()) {
      return true;
    }

    // TODO: проверка на забронированные даты

    return false;
  });

  const BabyItem = {
    name: "Baby Seat",
    count: 0,
    description: "",
    price: 0,
    image: "",
    category: "",
    id: "",
  };
  const renderTransferIcon = () => {
    switch (transferClass.value) {
      case TransferCarTypeEnum.Sedan:
        return <IconSedan height={25} width={25}></IconSedan>;
      case TransferCarTypeEnum.Vip:
        return <IconVip></IconVip>;
      case TransferCarTypeEnum.Minivan:
        return <IconMinivan></IconMinivan>;
      case TransferCarTypeEnum.Minibus:
        return <IconMinibus></IconMinibus>;
      default:
        break;
    }
  };

  const pickerControl = (
    <ActionIcon variant="subtle" color="gray" onClick={() => timeInputRef.current?.showPicker()}>
      <Clock />
    </ActionIcon>
  );

  const renderTransferPart = () => (
    <>
      <Modal
        opened={openedModal}
        onClose={close}
        withCloseButton={true}
        centered
        title={t("Transfer")}
        transitionProps={{ transition: "rotate-left" }}
      >
        <ReactMarkdown children={transfer.description} />
      </Modal>

      <Group position="apart" mt="md" mb="xs">
        <Text weight={700} onClick={open} color="violet" underline={true}>
          {t("Read More")}
        </Text>
      </Group>

      <Flex direction={"column"} gap={"md"}>
        <PointPickerInput
          label={t("Pick up start point")}
          onAddressPick={(address: string) => {
            setAddress1(address);
          }}
          onLocationPick={(location: LatLngTuple) => {
            setLocation1(location);
          }}
          placeholder={t("from")}
          pointDefault={location1}
          addressDefault={address1}
          markerType="start"
        ></PointPickerInput>
        <PointPickerInput
          label={t("Pick up end point")}
          onAddressPick={(address: string) => {
            setAddress2(address);
          }}
          onLocationPick={(location: LatLngTuple) => {
            setLocation2(location);
          }}
          placeholder={t("to")}
          pointDefault={location2}
          addressDefault={address2}
          markerType="end"
        ></PointPickerInput>
        {location1 && location2 && (
          <>
            <Flex>
              <Suspense fallback="">
                <RouteByPointsMap
                  withNotification={true}
                  fromLocation={location1}
                  toLocation={location2}
                  getDistance={(value) => {
                    setRouteDistance(value);
                  }}
                  getDuration={(value) => {
                    setRouteDuration(value);
                  }}
                ></RouteByPointsMap>
              </Suspense>
            </Flex>
          </>
        )}
        {Boolean(routeDistance) && Boolean(routeDuration) && (
          <Flex direction={"column"}>
            <Text color="#868E96">Расстояние и приблизительное время в пути</Text>
            <Text fw={700} style={{ fontSize: "18px" }}>
              {formatDistance(routeDistance)}{" "}
              <Text component="span" color="#868E96">
                •
              </Text>{" "}
              {formatDuration(routeDuration)}
            </Text>
          </Flex>
        )}
        <Divider my="sm" mb={"xl"} mt={"xl"} />
        <>
          <SelectCustom
            onChange={onChangeTransferCarType}
            value={t(transferClass.value)}
            data={Object.values(TransferCarTypeEnum).map((item) => t(item))}
          ></SelectCustom>
          <Grid grow align="center">
            <Grid.Col span={1}>
              <Paper p="md" radius="lg" shadow="sm">
                <Flex align="center" gap={8} mb="xs">
                  {renderTransferIcon()}
                  <Text pt={2} size="md" weight={700}>
                    {t(transferClass.value)}
                  </Text>
                </Flex>
                <Text color="gray">{t(transferClass.label)}</Text>
                <Text color="gray"></Text>
              </Paper>
            </Grid.Col>
          </Grid>
        </>
        <Divider my="sm" mb={"xl"} mt={"xl"} />
        <Flex mt={30} direction={"column"} gap={"lg"} style={{ flex: "1 1 auto" }}>
          <Flex direction={"column"}>
            <Text weight={400} size={16} c={EColors.dark}>
              {t("Adult price")}
            </Text>
            <Text weight={700} size={34} c={EColors.gray_1}>
              {`${price || 0} ${ECurrency.EUR}`}
            </Text>
          </Flex>
          <Flex direction={"column"} justify={"space-between"} gap={"md"}>
            <DatePickerInput
              dropdownType="modal"
              placeholder={t("Date")}
              value={selectedDate}
              excludeDate={isExcludedDate}
              onChange={setSelectedDate}
              color="violet"
              radius="lg"
              size="lg"
              lang={"ru"}
              c={EColors.violet}
              styles={(theme) => ({
                input: {
                  "&": {
                    borderColor: theme.colors.violet[7],
                    color: theme.colors.violet[7],
                    wordBreak: "keep-all",
                  },
                  "&:focus": {
                    borderColor: theme.colors.violet[7],
                  },
                },
              })}
              style={{ color: EColors.violet }}
            />
            <TimeInput
              placeholder={t("Time")}
              ref={timeInputRef}
              rightSection={pickerControl}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSelectedTime(e.target.value);
              }}
              color="violet"
              radius="lg"
              size="lg"
              c={EColors.violet}
              styles={(theme) => ({
                input: {
                  "&": {
                    borderColor: theme.colors.violet[7],
                    color: theme.colors.violet[7],
                    wordBreak: "keep-all",
                  },
                  "&:focus": {
                    borderColor: theme.colors.violet[7],
                  },
                },
              })}
              style={{ color: EColors.violet }}
            ></TimeInput>
            <Button
              fullWidth={true}
              disabled={!isFormCompleted}
              onClick={toOrderPage}
              variant="filled"
              color="violet"
              radius="lg"
              size="xl"
            >
              {t("Next step")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );

  return (
    <>
      <NavigationText
        arrow={{
          isShow: true,
        }}
        title={pageName || t(`Transfer`)}
        subtitle={pageDescription || ""}
      ></NavigationText>
      <ItemBase
        optionItem={{
          count: 0,
          description: transfer.description,
          image: "",
          images: [],
          name: "",
          price: 151515151,
        }}
        children={renderTransferPart()}
      ></ItemBase>

      <NavigationBar></NavigationBar>
    </>
  );
};
