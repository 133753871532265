import { Outlet, useLocation } from "react-router-dom";
import { AppHeader } from "../../components/Header/AppHeader";
import "./style.css";
import { RouterName } from "../../definitions";

export const Layout = () => {
  const location = useLocation();

  // fallback for welcome screen
  if (location.pathname === RouterName.root) {
    return <></>;
  }

  return (
    <>
      <AppHeader></AppHeader>
      <div className={"defaultPage"} style={{ justifyContent: "center" }}>
        <Outlet />
      </div>
    </>
  );
};
