import React, { useEffect, useState } from "react";
import { Image } from "@mantine/core";
// @ts-ignore
import step1 from "./step1.svg";
// @ts-ignore
import step2 from "./step2.svg";
// @ts-ignore
import step3 from "./step3.svg";
//@ts-ignore
import step4 from "./step4.svg";
//@ts-ignore
import step5 from "./step5.svg";

const steps = [step1, step2, step3, step4, step5];

const StepLoader: React.FC<{ totalSteps: number; currentStep: number }> = ({ totalSteps, currentStep }) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (currentStep <= totalSteps && currentStep > 0) {
      setStep(currentStep - 1);
    }
  }, [currentStep, totalSteps]);

  const StepImage = steps[step];

  return (
    <div>
      <Image width={32} height={32} src={StepImage} />
    </div>
  );
};

export default StepLoader;
