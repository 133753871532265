import { useQuery } from "@tanstack/react-query";
import { useFn } from "../../../hooks/useFn";
import { fetchUserTransfersOrdersList, normalizeTransferOrder } from "../api/transferOrders";

export default function useTransferOrders(userId: string, initData: string) {
  return useQuery({
    queryKey: [`${userId} transfers`, initData, userId],
    queryFn: fetchUserTransfersOrdersList,
    select: useFn(normalizeTransferOrder),
    onError: (error) => {
      console.log(error);
    },
  });
}
