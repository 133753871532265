import { externalApis } from "../../externalAPIs/definitions";
import "./style.css";

export const AddressMap = ({ onKalkanClick, onKashClick }: { onKalkanClick: () => void; onKashClick: () => void }) => {
  return (
    <div>
      <div className="map">
        <div className="marker mapMarkerKalkan" onClick={onKalkanClick}></div>
        <div className="marker mapMarkerKas" onClick={onKashClick}></div>
        <iframe
          title="welcome map"
          frameBorder={"0"}
          width="100%"
          height="100%"
          src={externalApis.googleMapsKalkanKash}
        ></iframe>
      </div>
    </div>
  );
};
