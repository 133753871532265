import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UserLanguageEnum } from "../../../client";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";
import { NavigationText } from "../../../components/NavigationText/NavigationText";
import { SelectCustom } from "../../../components/UI/Vendors/Mantine/SelectCustom";
import { useFn } from "../../../hooks/useFn";
import { selectCurrentUser, SetUserLanguageAction } from "../../../store/userStore";
import { setCookieLanguage } from "../../../utils";
import { useTelegram } from "../../../hooks/useTelegram";
import { updateUserLanguageRequest } from "./api";

export const SettingsLanguage = () => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["settings"]);

  const { initData } = useTelegram();

  const [language, setLanguage] = useState<string>(currentUser.language.toLowerCase() || "en");

  const changeUserLanguageMutation = useMutation({
    mutationFn: ([initData, userId, language]: [string, string, string]) => {
      return updateUserLanguageRequest(initData, userId, language.toUpperCase());
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const onChangeLanguage = useFn((language: UserLanguageEnum) => {
    setLanguage(language);

    dispatch(SetUserLanguageAction(language));

    changeUserLanguageMutation.mutate([initData, currentUser.id, language]);

    const lng = language.toUpperCase() as UserLanguageEnum;
    i18n.changeLanguage(lng).then(() => {
      setCookieLanguage(lng);
    });
  });

  const titlePage = t("Language");
  const subtitle = "";

  return (
    <>
      <NavigationText
        arrow={{
          isShow: true,
        }}
        title={titlePage}
        subtitle={subtitle}
      ></NavigationText>

      <>
        <SelectCustom onChange={onChangeLanguage} value={language} data={["ru", "en"]}></SelectCustom>
      </>
      <NavigationBar></NavigationBar>
    </>
  );
};
