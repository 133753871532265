import storage from "localforage";
import { PersistConfig, Persistor } from "redux-persist/es/types";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { deliveryCartReducer } from "./deliveryCartStore";
import { foodOrdersReducer } from "./orders/foodOrders";
import { transfersOrdersReducer } from "./orders/transferOrders";
import { userReducer } from "./userStore";
import { tgUserReducer } from "./telegramAuthUserStore";
import { excursionsOrdersReducer } from "./orders/excursionOrders";
import { seaTripsOrdersReducer } from "./orders/seaTripOrders";

export const reducers = combineReducers({
  user: userReducer,
  foodCart: deliveryCartReducer,
  foodOrders: foodOrdersReducer,
  excursionOrders: excursionsOrdersReducer,
  seaTripOrders: seaTripsOrdersReducer,
  transferOrders: transfersOrdersReducer,
  tgUser: tgUserReducer,
});

export type TStore = typeof store;
export type TGlobalState = ReturnType<typeof store.getState>;
export type TDispatch = typeof store.dispatch;

const persistConfig: PersistConfig<any> = {
  key: "root",
  storage,
  whitelist: ["tgUser"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export function initStore(): { store: TStore; persistor: Persistor } {
  console.log("store configured with persistor");
  return { store, persistor };
}

export const dispatch = store.dispatch;
