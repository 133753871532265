import { BASE_PATH, ServiceLookup } from "../../client";
import { TCard } from "../../components/BaseCard/BaseCard";
import { reachGoalExcursionsOpen, reachGoalSeaTripsOpen, reachGoalTransferStart } from "../../utils/metrika";

export const mapServiceTitleToRoute: { [key: string]: string } = {
  excursions: "excursion/excursionsPage",
  restaurants: "delivery/deliveryPage",
  seaTrips: "seaTrips/seaTripsPage",
  transfers: "transfer/step/1",
  yachts: "yachts/yachtsPage",
};

export const mapServiceToMetrikaCallback: { [key: string]: () => void } = {
  transfers: reachGoalTransferStart,
  excursions: reachGoalExcursionsOpen,
  restaurants: () => {},
  seaTrips: reachGoalSeaTripsOpen,
  yachts: () => {},
};

export const disabledServices = ["restaurants", "yachtCharters"];

function createLabels(count?: string) {
  if (count) {
    return [{ iconType: "ShoppingCart", text: count }];
  } else {
    return [];
  }
}

export type TNormalizedService = TCard & { route: string; metrikaCallback?: () => void };

export function normalizeService(services: ServiceLookup[] | undefined, isAdmin = false): TNormalizedService[] {
  if (!services) {
    return [];
  }

  let filteredServices = services;
  if (!isAdmin) {
    filteredServices = services.filter((service) => !disabledServices.includes(service.routeTitle));
  }

  return filteredServices.reverse().map((service) => {
    return {
      id: service.title,
      title: service.title,
      description: service.description,
      image: `${BASE_PATH}/${service.image}`,
      route: mapServiceTitleToRoute[service.routeTitle],
      labels: createLabels(service.count),
      metrikaCallback: mapServiceToMetrikaCallback[service.routeTitle],
    };
  });
}
