import { TransferCarTypeEnum } from "../../../client";
import { TTransferClass } from "../../../definitions";
import { calculateTransferPriceFromDistance } from "./calculateTransferPriceFromDistance";
import { calculateTransferPriceFromKalkan } from "./calculateTransferPriceFromKalkan";
import { calculateTransferPriceFromOil } from "./calculateTransferPriceFromOil";
import { calculateTransferPriceFromPriceList } from "./calculateTransferPriceFromPriceList";
import { TPoint } from "./types";

type calculateTransferPriceParams = {
  from: string;
  to: string;
  routeDistance: number;
  transferClass: TTransferClass;
  exchangeRate: number;
  pointFrom: TPoint;
  pointTo: TPoint;
};

export const calculateTransferPrice = ({
  from,
  to,
  routeDistance,
  transferClass,
  exchangeRate,
  pointFrom,
  pointTo,
}: calculateTransferPriceParams) => {
  console.log(
    "test dist",
    routeDistance,
    "PriceList",
    calculateTransferPriceFromPriceList(from, to, pointFrom, pointTo, transferClass.value, routeDistance) ?? 0,
    "Oil",
    calculateTransferPriceFromOil(from, to, routeDistance),
    "Distance",
    calculateTransferPriceFromDistance(from, to, routeDistance, transferClass.value)
  );

  let result = 0;

  const resultFromPriceList = calculateTransferPriceFromPriceList(
    from,
    to,
    pointFrom,
    pointTo,
    transferClass.value,
    routeDistance
  );
  const resultFromDistance = calculateTransferPriceFromDistance(from, to, routeDistance, transferClass.value);
  const { calculateOilPrice: resultFromOil, minOilPrice } = calculateTransferPriceFromOil(from, to, routeDistance);

  if (resultFromPriceList !== 0) {
    result = resultFromPriceList;
  } else if (resultFromDistance !== 0) {
    result = resultFromDistance;
  } else if (resultFromOil !== 0) {
    result = resultFromOil;
  }

  if (minOilPrice !== 0 && result < minOilPrice) {
    result = minOilPrice;
  }
  console.log("test dist transfer price result:", result);

  return Math.ceil((result ?? 0) * exchangeRate);
};
