/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EContactType } from './EContactType';
import {
    EContactTypeFromJSON,
    EContactTypeFromJSONTyped,
    EContactTypeToJSON,
} from './EContactType';
import type { EUserSeaTripStatus } from './EUserSeaTripStatus';
import {
    EUserSeaTripStatusFromJSON,
    EUserSeaTripStatusFromJSONTyped,
    EUserSeaTripStatusToJSON,
} from './EUserSeaTripStatus';
import type { ExcursionOrderExcursion } from './ExcursionOrderExcursion';
import {
    ExcursionOrderExcursionFromJSON,
    ExcursionOrderExcursionFromJSONTyped,
    ExcursionOrderExcursionToJSON,
} from './ExcursionOrderExcursion';
import type { SeaTripOrderStatus } from './SeaTripOrderStatus';
import {
    SeaTripOrderStatusFromJSON,
    SeaTripOrderStatusFromJSONTyped,
    SeaTripOrderStatusToJSON,
} from './SeaTripOrderStatus';

/**
 * 
 * @export
 * @interface SeaTripOrder
 */
export interface SeaTripOrder {
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrder
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrder
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrder
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrder
     */
    contact: string;
    /**
     * 
     * @type {EContactType}
     * @memberof SeaTripOrder
     */
    contactType: EContactType;
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrder
     */
    seaTripId: string;
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrder
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrder
     */
    actualSeaTripDate: string;
    /**
     * 
     * @type {number}
     * @memberof SeaTripOrder
     */
    personsCount: number;
    /**
     * 
     * @type {number}
     * @memberof SeaTripOrder
     */
    childrenCount: number;
    /**
     * 
     * @type {EUserSeaTripStatus}
     * @memberof SeaTripOrder
     */
    status?: EUserSeaTripStatus;
    /**
     * 
     * @type {Array<SeaTripOrderStatus>}
     * @memberof SeaTripOrder
     */
    statusHistory?: Array<SeaTripOrderStatus>;
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrder
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof SeaTripOrder
     */
    paymentMethod: string;
    /**
     * 
     * @type {number}
     * @memberof SeaTripOrder
     */
    price: number;
    /**
     * 
     * @type {ExcursionOrderExcursion}
     * @memberof SeaTripOrder
     */
    seaTrip?: ExcursionOrderExcursion;
}

/**
 * Check if a given object implements the SeaTripOrder interface.
 */
export function instanceOfSeaTripOrder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "contact" in value;
    isInstance = isInstance && "contactType" in value;
    isInstance = isInstance && "seaTripId" in value;
    isInstance = isInstance && "actualSeaTripDate" in value;
    isInstance = isInstance && "personsCount" in value;
    isInstance = isInstance && "childrenCount" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "paymentMethod" in value;
    isInstance = isInstance && "price" in value;

    return isInstance;
}

export function SeaTripOrderFromJSON(json: any): SeaTripOrder {
    return SeaTripOrderFromJSONTyped(json, false);
}

export function SeaTripOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeaTripOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': json['userId'],
        'address': json['address'],
        'contact': json['contact'],
        'contactType': EContactTypeFromJSON(json['contactType']),
        'seaTripId': json['seaTripId'],
        'creationDate': !exists(json, 'creationDate') ? undefined : json['creationDate'],
        'actualSeaTripDate': json['actualSeaTripDate'],
        'personsCount': json['personsCount'],
        'childrenCount': json['childrenCount'],
        'status': !exists(json, 'status') ? undefined : EUserSeaTripStatusFromJSON(json['status']),
        'statusHistory': !exists(json, 'statusHistory') ? undefined : ((json['statusHistory'] as Array<any>).map(SeaTripOrderStatusFromJSON)),
        'comment': json['comment'],
        'paymentMethod': json['paymentMethod'],
        'price': json['price'],
        'seaTrip': !exists(json, 'seaTrip') ? undefined : ExcursionOrderExcursionFromJSON(json['seaTrip']),
    };
}

export function SeaTripOrderToJSON(value?: SeaTripOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'address': value.address,
        'contact': value.contact,
        'contactType': EContactTypeToJSON(value.contactType),
        'seaTripId': value.seaTripId,
        'creationDate': value.creationDate,
        'actualSeaTripDate': value.actualSeaTripDate,
        'personsCount': value.personsCount,
        'childrenCount': value.childrenCount,
        'status': EUserSeaTripStatusToJSON(value.status),
        'statusHistory': value.statusHistory === undefined ? undefined : ((value.statusHistory as Array<any>).map(SeaTripOrderStatusToJSON)),
        'comment': value.comment,
        'paymentMethod': value.paymentMethod,
        'price': value.price,
        'seaTrip': ExcursionOrderExcursionToJSON(value.seaTrip),
    };
}

