import { Badge } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Restaurant } from "../../client";
import { BaseCard } from "../../components/BaseCard/BaseCard";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { NavigationText } from "../../components/NavigationText/NavigationText";
import { LoaderPageCustom } from "../../components/UI/Vendors/Mantine/LoaderPageCustom";
import { RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import fetchRestaurants from "./api";
import { normalizeRestaurants } from "./definitions";
import "./style.css";

export const DeliveryPage = ({ pageName, pageDescription }: { pageName?: string; pageDescription?: string }) => {
  const { initData } = useTelegram();

  const navigate = useNavigate();
  const navigateToRestaurant = useFn((card: { id: string }) => {
    navigate(RouterPath.delivery.menuById(card.id), { state: card });
  });

  const [allFilters, setAllFilters] = useState<string[]>([]);
  const [filters, setFilters] = useState<string[]>([]);

  const restaurantsQuery = useQuery(["restaurant list", initData], fetchRestaurants, {
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!restaurantsQuery.data) return;
    const _filters = restaurantsQuery.data.map((rest) => rest.tags.map((tag) => tag.name)).flat();
    console.log(_filters);
    setAllFilters([...new Set(_filters)]);
  }, [restaurantsQuery.data]);

  const filterByTag = useFn((restaurant: Restaurant) => {
    if (!filters.length) return true;

    return restaurant.tags.some((tag) => filters.includes(tag.name));
  });

  const onFilterChanged = useFn((values: string[]) => {
    setFilters(values.length ? values : allFilters);
  });

  const filterList = allFilters.map((filter) => {
    return {
      itemName: filter,
      itemJSX: (
        <Badge
          pt={1}
          key={`filter_${filter}`}
          className="BaseCArd__tag"
          color="grape"
          radius="lg"
          size="lg"
          variant="outline"
        >
          {filter}
        </Badge>
      ),
    };
  });

  if (restaurantsQuery.isLoading || !restaurantsQuery.data) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }

  const cardsList = restaurantsQuery.data?.filter(filterByTag).map((rawRestaurant: Restaurant) => {
    const card = normalizeRestaurants([rawRestaurant])[0];
    return (
      <BaseCard
        key={card.id}
        card={card}
        onCardClick={() => {
          navigateToRestaurant(card);
        }}
      ></BaseCard>
    );
  });

  return (
    <>
      <NavigationText
        arrow={{
          isShow: true,
        }}
        filterMenu={{
          isShow: true,
          onFilterChanged,
          dropdownItems: filterList,
        }}
        title={pageName || `Доставка еды`}
        subtitle={pageDescription || ""}
      ></NavigationText>
      {cardsList.length ? (
        cardsList
      ) : (
        <>
          <b>No restaurants working now</b>
        </>
      )}
      <NavigationBar></NavigationBar>
    </>
  );
};
