/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ServiceLookup } from './ServiceLookup';
import {
    ServiceLookupFromJSON,
    ServiceLookupFromJSONTyped,
    ServiceLookupToJSON,
} from './ServiceLookup';

/**
 * 
 * @export
 * @interface GeneralServicesListGet200Response
 */
export interface GeneralServicesListGet200Response {
    /**
     * 
     * @type {Array<ServiceLookup>}
     * @memberof GeneralServicesListGet200Response
     */
    serviceLookups?: Array<ServiceLookup>;
}

/**
 * Check if a given object implements the GeneralServicesListGet200Response interface.
 */
export function instanceOfGeneralServicesListGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GeneralServicesListGet200ResponseFromJSON(json: any): GeneralServicesListGet200Response {
    return GeneralServicesListGet200ResponseFromJSONTyped(json, false);
}

export function GeneralServicesListGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralServicesListGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceLookups': !exists(json, 'serviceLookups') ? undefined : ((json['serviceLookups'] as Array<any>).map(ServiceLookupFromJSON)),
    };
}

export function GeneralServicesListGet200ResponseToJSON(value?: GeneralServicesListGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceLookups': value.serviceLookups === undefined ? undefined : ((value.serviceLookups as Array<any>).map(ServiceLookupToJSON)),
    };
}

