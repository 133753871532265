import { Flex, Image } from "@mantine/core";
import { StepProps } from "../../types";
import "./stepSlide.css";

type TBaseStepsSlideProps = {
  image: string | JSX.Element;
  title: string;
  label: string;
  subLabel?: string;
} & StepProps;

export const BaseStepsSlide = ({ image, label, title, subLabel, onButtonClick }: TBaseStepsSlideProps) => {
  return (
    <>
      <div className="slide baseScreen">
        <Flex justify={"space-between"} direction={"column"} h={"100%"}>
          <Flex direction={"column"} gap={"xl"}>
            <Flex justify={"center"}>
              {typeof image === "string" && (
                <Image
                  className="ItemPage__image stepSlide__image"
                  height={"30vh"}
                  radius="lg"
                  src={image}
                  alt="item image"
                />
              )}
              {typeof image !== "string" && <div className="baseScreen__image stepSlide__image">{image}</div>}
            </Flex>
            <Flex justify={"center"} direction={"column"} gap={"xs"}>
              <Flex>
                <div className="baseScreen__title baseScreen__text">{title}</div>
              </Flex>
              <Flex>
                <div className="baseScreen__subtitle baseScreen__text">{label}</div>
              </Flex>
              {subLabel && (
                <Flex>
                  <div className="baseScreen__subsubtitle baseScreen__text">{subLabel}</div>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </div>
    </>
  );
};
