/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TransferInput,
  TransferSetOrderStatusPostRequest,
  TransferTransferByIdTransferIdGet200Response,
  TransferUpdateTransferPostRequest,
  TransferUserTransfersUserIdGet200Response,
} from '../models';
import {
    TransferInputFromJSON,
    TransferInputToJSON,
    TransferSetOrderStatusPostRequestFromJSON,
    TransferSetOrderStatusPostRequestToJSON,
    TransferTransferByIdTransferIdGet200ResponseFromJSON,
    TransferTransferByIdTransferIdGet200ResponseToJSON,
    TransferUpdateTransferPostRequestFromJSON,
    TransferUpdateTransferPostRequestToJSON,
    TransferUserTransfersUserIdGet200ResponseFromJSON,
    TransferUserTransfersUserIdGet200ResponseToJSON,
} from '../models';

export interface TransferCreateTransferPostRequest {
    transferInput: TransferInput;
}

export interface TransferSetOrderStatusPostOperationRequest {
    transferSetOrderStatusPostRequest: TransferSetOrderStatusPostRequest;
}

export interface TransferTransferByIdTransferIdGetRequest {
    initData: string;
    transferId: string;
}

export interface TransferUpdateTransferPostOperationRequest {
    transferUpdateTransferPostRequest: TransferUpdateTransferPostRequest;
}

export interface TransferUserTransfersUserIdGetRequest {
    initData: string;
    userId: string;
}

/**
 * 
 */
export class TransferApi extends runtime.BaseAPI {

    /**
     * User creates request for transfer
     */
    async transferCreateTransferPostRaw(requestParameters: TransferCreateTransferPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.transferInput === null || requestParameters.transferInput === undefined) {
            throw new runtime.RequiredError('transferInput','Required parameter requestParameters.transferInput was null or undefined when calling transferCreateTransferPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/transfer/create_transfer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransferInputToJSON(requestParameters.transferInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User creates request for transfer
     */
    async transferCreateTransferPost(requestParameters: TransferCreateTransferPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.transferCreateTransferPostRaw(requestParameters, initOverrides);
    }

    /**
     * Update status for Transfer
     */
    async transferSetOrderStatusPostRaw(requestParameters: TransferSetOrderStatusPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.transferSetOrderStatusPostRequest === null || requestParameters.transferSetOrderStatusPostRequest === undefined) {
            throw new runtime.RequiredError('transferSetOrderStatusPostRequest','Required parameter requestParameters.transferSetOrderStatusPostRequest was null or undefined when calling transferSetOrderStatusPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/transfer/set_order_status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransferSetOrderStatusPostRequestToJSON(requestParameters.transferSetOrderStatusPostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update status for Transfer
     */
    async transferSetOrderStatusPost(requestParameters: TransferSetOrderStatusPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.transferSetOrderStatusPostRaw(requestParameters, initOverrides);
    }

    /**
     * Returns Transfer by id
     */
    async transferTransferByIdTransferIdGetRaw(requestParameters: TransferTransferByIdTransferIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TransferTransferByIdTransferIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling transferTransferByIdTransferIdGet.');
        }

        if (requestParameters.transferId === null || requestParameters.transferId === undefined) {
            throw new runtime.RequiredError('transferId','Required parameter requestParameters.transferId was null or undefined when calling transferTransferByIdTransferIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transfer/transfer_by_id/{transferId}`.replace(`{${"transferId"}}`, encodeURIComponent(String(requestParameters.transferId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TransferTransferByIdTransferIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns Transfer by id
     */
    async transferTransferByIdTransferIdGet(requestParameters: TransferTransferByIdTransferIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TransferTransferByIdTransferIdGet200Response> {
        const response = await this.transferTransferByIdTransferIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Transfer
     */
    async transferUpdateTransferPostRaw(requestParameters: TransferUpdateTransferPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.transferUpdateTransferPostRequest === null || requestParameters.transferUpdateTransferPostRequest === undefined) {
            throw new runtime.RequiredError('transferUpdateTransferPostRequest','Required parameter requestParameters.transferUpdateTransferPostRequest was null or undefined when calling transferUpdateTransferPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/transfer/update_transfer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransferUpdateTransferPostRequestToJSON(requestParameters.transferUpdateTransferPostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Transfer
     */
    async transferUpdateTransferPost(requestParameters: TransferUpdateTransferPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.transferUpdateTransferPostRaw(requestParameters, initOverrides);
    }

    /**
     * Returns all user transfer orders
     */
    async transferUserTransfersUserIdGetRaw(requestParameters: TransferUserTransfersUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TransferUserTransfersUserIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling transferUserTransfersUserIdGet.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling transferUserTransfersUserIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/transfer/user_transfers/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TransferUserTransfersUserIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns all user transfer orders
     */
    async transferUserTransfersUserIdGet(requestParameters: TransferUserTransfersUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TransferUserTransfersUserIdGet200Response> {
        const response = await this.transferUserTransfersUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
