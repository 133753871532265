import { useTranslation } from "react-i18next";
import { Badge, Group, Modal, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDisclosure } from "@mantine/hooks";
import { BaseCard } from "../../components/BaseCard/BaseCard";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { NavigationText } from "../../components/NavigationText/NavigationText";
import { LoaderPageCustom } from "../../components/UI/Vendors/Mantine/LoaderPageCustom";
import { COMMON_FILTERS, RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import fetchSeaTrips from "./api";
import { ALL_SEA_TRIPS_FILTERS, normalizeSeaTrips, TSeaTripsFilters } from "./definitions";
import { SeaTrip } from "../../client";
import { capitalizeFirstLetter } from "../../utils";

export const SeaTripsPage = ({ pageName, pageDescription }: { pageName?: string; pageDescription?: string }) => {
  const { t } = useTranslation(["sea_trips_page"]);

  const { initData } = useTelegram();

  const navigate = useNavigate();

  const [openedModal, { open, close }] = useDisclosure(false);

  const seaTripDescription: { name: string; description: string } = {
    name: t("Sea Trips"),
    description: t(
      "Select the sea walk and specify the necessary data. Manager link to you to confirm the order. Potrebut deposit, amount reported manager. Principal payment made to the driver on the day of the walk. After confirmation track the status of the order under ‘order’. If availability is confirmed manager provide detailed instructions. In case of inaccessibility of services will be offered alternative."
    ),
  };

  const navigateToSeaTrip = useFn((card: { id: string }) => {
    navigate(RouterPath.seaTrips.itemById(card.id));
  });

  // @ts-ignore
  const [filters, setFilters] = useState<TSeaTripsFilters>(COMMON_FILTERS.concat(ALL_SEA_TRIPS_FILTERS));

  const [seaTrips, setSeaTrips] = useState<SeaTrip[]>([]);

  const seaTripsQuery = useQuery(["sea trips list", initData], fetchSeaTrips, {
    onError: (error) => {
      console.log(error);
    },
    select: setSeaTrips,
  });

  const { t: t_base_card } = useTranslation("base_card");

  const filterList = COMMON_FILTERS.concat(ALL_SEA_TRIPS_FILTERS).map((filter) => {
    return {
      itemName: filter,
      itemJSX: (
        <Badge
          pt={1}
          key={`filter_${filter}`}
          className="BaseCArd__tag"
          color="grape"
          radius="lg"
          size="lg"
          variant="outline"
        >
          {t_base_card(filter)}
        </Badge>
      ),
    };
  });

  const onFilterChanged = useFn((values: TSeaTripsFilters) => {
    setFilters(values.length ? values : ALL_SEA_TRIPS_FILTERS);
  });

  const filterByTagOrType = useFn((seaTrip: SeaTrip) => {
    if (!filters.length) return true;

    const filteredFilters = filters.filter((filter) => {
      return !COMMON_FILTERS.includes(filter);
    });

    if (!filteredFilters.length) return true;

    if (seaTrip.tags.some((tag) => filteredFilters.includes(capitalizeFirstLetter(tag.name)))) {
      return true;
    }

    return filteredFilters.includes(capitalizeFirstLetter(seaTrip.seaTripType));
  });

  const filterByCommonFilters = useFn(() => {
    if (!seaTrips) return [];
    if (!filters.length) return seaTrips;

    let filtered: SeaTrip[] = [...seaTrips];

    // @ts-ignore
    if (filters.includes("By price")) {
      filtered = filtered.sort((a, b) => a.price - b.price);
    }

    // @ts-ignore
    if (filters.includes("By name")) {
      filtered = filtered.sort((a, b) => a.title.localeCompare(b.title));
    }

    return filtered;
  });

  if (seaTripsQuery.isLoading) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }

  const cardsList = filterByCommonFilters()
    .filter(filterByTagOrType)
    .map((seaTrip) => {
      const card = normalizeSeaTrips([seaTrip])[0];
      return (
        <BaseCard
          key={card.id}
          card={card}
          onCardClick={() => {
            navigateToSeaTrip(card);
          }}
        ></BaseCard>
      );
    });

  return (
    <>
      <NavigationText
        arrow={{
          isShow: true,
        }}
        filterMenu={{
          isShow: true,
          onFilterChanged,
          dropdownItems: filterList,
        }}
        title={pageName || t("Sea Trips")}
        subtitle={t(seaTripDescription.description)}
      ></NavigationText>
      <Modal
        opened={openedModal}
        onClose={close}
        withCloseButton={true}
        centered
        title={t("Sea Trips")}
        transitionProps={{ transition: "rotate-left" }}
      >
        <ReactMarkdown children={seaTripDescription.description} />
      </Modal>

      <Group position="apart" mt="md" mb="xs">
        <Text weight={700} onClick={open} color="violet" underline={true}>
          {t("Read More")}
        </Text>
      </Group>
      {cardsList.length ? cardsList : <b>{t("No sea trips found")}</b>}
      <NavigationBar></NavigationBar>
    </>
  );
};
