import { Select } from "@mantine/core";
import { useEffect, useState } from "react";
import { ChevronDown, MapPin } from "@gravity-ui/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CITIES } from "../../definitions";
import "./style.css";
import { useTelegram } from "../../hooks/useTelegram";
import { selectCurrentUser, SetUserCityAction } from "../../store/userStore";
import { setUserCity } from "../../apiDrivers/user";
import { ECity } from "../../client";
import { useFn } from "../../hooks/useFn";
import { dispatch } from "../../store/rootStore";

const getTextWidth = (text: string, font: string) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  // @ts-ignore
  context.font = font;
  // @ts-ignore
  const width = context.measureText(text).width;
  return width;
};

const calculateMaxWidth = (data: any[], font: string) => {
  let maxWidth = 0;
  data.forEach((item) => {
    const width = getTextWidth(item.label, font);
    if (width > maxWidth) {
      maxWidth = width;
    }
  });
  return maxWidth;
};

export const HeaderCitySelector = () => {
  const navigate = useNavigate();
  const { initData } = useTelegram();

  const { t } = useTranslation("city");

  const user = useSelector(selectCurrentUser);

  const [inputWidth, setInputWidth] = useState<number | string>("auto");

  useEffect(() => {
    const font = "14px Arial";
    const maxWidth = calculateMaxWidth(CITIES, font);
    setInputWidth(maxWidth + 70);
  }, []);
  const [selectedCity, setSelectedCity] = useState<ECity>(user.city);

  useEffect(() => {
    setSelectedCity(user.city);
  }, [user.city]);

  const onUpdateCity = useFn((city: ECity | null) => {
    if (!city) return;
    setSelectedCity(city);
    setUserCity("", user.id, city, initData).then(() => {
      dispatch(SetUserCityAction(city));
    });
  });

  const cites = CITIES.map((city) => ({
    ...city,
    label: t(city.label),
  }));

  return (
    <Select
      variant="unstyled"
      value={selectedCity}
      onChange={onUpdateCity}
      data={cites}
      icon={<MapPin />}
      iconWidth={30}
      size="lg"
      rightSection={<ChevronDown />}
      styles={(theme) => ({
        rightSection: {
          "&": {
            color: theme.colors.violet[9],
          },
        },
        input: {
          width: inputWidth,
        },
      })}
    />
  );
};
