import { createMemoryRouter, createRoutesFromElements, Route } from "react-router-dom";
import App from "./App";
import { RouterName } from "./definitions";
import { CartPage } from "./pages/CartPage";
import { DeliveryFoodItemPage } from "./pages/DeliveryFoodItemPage";
import { DeliveryMenuPage } from "./pages/DeliveryMenuPage";
import { DeliveryPage } from "./pages/DeliveryPage";
import ErrorPage from "./pages/ErrorPage";
import { ExcursionItemPage } from "./pages/ExcursionItemPage";
import { ExcursionPage } from "./pages/ExcursionPage";
import { InformationPage } from "./pages/InformationPage";
import { ItemPage } from "./pages/ItemPage";
import { MainPage } from "./pages/MainPage";
import { OrderDeliveryCompletedPage } from "./pages/OrderCompletedPages/OrderDeliveryCompletedPage";
import { OrderExcursionCompletedPage } from "./pages/OrderCompletedPages/OrderExcursionCompletedPage";
import { OrderSeaTripCompletedPage } from "./pages/OrderCompletedPages/OrderSeaTripCompletedPage";
import { OrderTransferCompletedPage } from "./pages/OrderCompletedPages/OrderTransferCompletedPage";
import { OrderYachtCompletedPage } from "./pages/OrderCompletedPages/OrderYachtCompletedPage";
import { OrderListPage } from "./pages/OrderListPage";
import { OrderPage } from "./pages/OrderPage";
import { SeaTripsItemPage } from "./pages/SeatripsItemPage";
import { SeaTripsPage } from "./pages/SeaTripsPage";
import { SettingsMainPage } from "./pages/SettingsMainPage";
import { SettingsAddress } from "./pages/SettingsPage/SettingsAddress";
import { SettingsLanguage } from "./pages/SettingsPage/SettingsLanguage";
import { SettingsSupport } from "./pages/SettingsPage/SettingsSupport";
import { TransferPage } from "./pages/TransferPage";
import DeployHostpage from "./pages/DeployHostpage";
import { YachtsItemPage } from "./pages/YachtsItemPage";
import { YachtsPage } from "./pages/YachtsPage";
import { AuthPage } from "./pages/AuthPage";

export const router = createMemoryRouter(
  createRoutesFromElements(
    <Route path={RouterName.root} errorElement={<ErrorPage></ErrorPage>} element={<App />}>
      {/*<Route path={RouterName.deployHostpage} element={<DeployHostpage />}></Route>*/}
      <Route path={RouterName.main} element={<MainPage />}></Route>
      <Route path={RouterName.information} element={<InformationPage />}></Route>
      <Route path={RouterName.informationByMessageType + "/:message_type"} element={<InformationPage />}></Route>
      {/* settings */}
      <Route path={RouterName.settings} element={<SettingsMainPage />}></Route>
      <Route path={RouterName.settingsAddress} element={<SettingsAddress />}></Route>
      <Route path={RouterName.settingsLanguage} element={<SettingsLanguage />}></Route>
      <Route path={RouterName.settingsSupport} element={<SettingsSupport />}></Route>
      <Route path="item/:type/:id" element={<ItemPage></ItemPage>}></Route>
      {/* delivery/ */}
      <Route path={RouterName.deliveryPage} element={<DeliveryPage />} />
      <Route path={RouterName.deliveryMenuById + "/:id"} element={<DeliveryMenuPage></DeliveryMenuPage>}></Route>
      <Route
        path={RouterName.deliveryFoodItemById + "/:id"}
        element={<DeliveryFoodItemPage></DeliveryFoodItemPage>}
      ></Route>
      {/* excursion/ */}
      <Route path={RouterName.excursionsPage} element={<ExcursionPage></ExcursionPage>}></Route>
      <Route path={RouterName.excursionItemById + "/:id"} element={<ExcursionItemPage></ExcursionItemPage>}></Route>
      {/* yachts/ */}
      <Route path={RouterName.yachtsPage} element={<YachtsPage></YachtsPage>}></Route>
      <Route path={RouterName.yachtsItemById + "/:id"} element={<YachtsItemPage></YachtsItemPage>}></Route>
      {/* seatrips/ */}
      <Route path={RouterName.seaTripsPage} element={<SeaTripsPage></SeaTripsPage>}></Route>
      <Route path={RouterName.seaTripsItemById + "/:id"} element={<SeaTripsItemPage></SeaTripsItemPage>}></Route>
      {/* transfer/ */}
      <Route path={RouterName.transferPageByStep + "/:step"} element={<TransferPage />} />
      <Route path={RouterName.error} element={<ErrorPage />} />
      <Route path={RouterName.cartPage} element={<CartPage></CartPage>}></Route>
      {/* orders */}
      <Route path={RouterName.orderList} element={<OrderListPage></OrderListPage>}></Route>
      <Route path={RouterName.orderComplete} element={<OrderPage></OrderPage>}></Route>
      <Route
        path={RouterName.orderDeliveryCompleted + "/:id"}
        element={<OrderDeliveryCompletedPage></OrderDeliveryCompletedPage>}
      ></Route>
      <Route
        path={RouterName.orderExcursionCompleted + "/:id"}
        element={<OrderExcursionCompletedPage></OrderExcursionCompletedPage>}
      ></Route>
      <Route
        path={RouterName.orderYachtCompleted + "/:id"}
        element={<OrderYachtCompletedPage></OrderYachtCompletedPage>}
      ></Route>
      <Route
        path={RouterName.orderSeaTripsCompleted + "/:id"}
        element={<OrderSeaTripCompletedPage></OrderSeaTripCompletedPage>}
      ></Route>
      <Route
        path={RouterName.orderTransferCompleted + "/:id"}
        element={<OrderTransferCompletedPage></OrderTransferCompletedPage>}
      ></Route>
    </Route>
  )
);
