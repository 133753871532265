/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExcursionOrderUpdateStatus } from './ExcursionOrderUpdateStatus';
import {
    ExcursionOrderUpdateStatusFromJSON,
    ExcursionOrderUpdateStatusFromJSONTyped,
    ExcursionOrderUpdateStatusToJSON,
} from './ExcursionOrderUpdateStatus';

/**
 * 
 * @export
 * @interface ExcursionOrderStatus
 */
export interface ExcursionOrderStatus {
    /**
     * 
     * @type {ExcursionOrderUpdateStatus}
     * @memberof ExcursionOrderStatus
     */
    status: ExcursionOrderUpdateStatus;
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrderStatus
     */
    updateTime: string;
}

/**
 * Check if a given object implements the ExcursionOrderStatus interface.
 */
export function instanceOfExcursionOrderStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "updateTime" in value;

    return isInstance;
}

export function ExcursionOrderStatusFromJSON(json: any): ExcursionOrderStatus {
    return ExcursionOrderStatusFromJSONTyped(json, false);
}

export function ExcursionOrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExcursionOrderStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': ExcursionOrderUpdateStatusFromJSON(json['status']),
        'updateTime': json['updateTime'],
    };
}

export function ExcursionOrderStatusToJSON(value?: ExcursionOrderStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': ExcursionOrderUpdateStatusToJSON(value.status),
        'updateTime': value.updateTime,
    };
}

