export function combineDateAndTime(dateStr?: string, timeStr?: string): string {
  if (!dateStr) {
    return "";
  }
  if (!timeStr) {
    return dateStr;
  }

  const datePart = dateStr.substring(0, 11);
  const timezonePart = dateStr.substring(16);

  const combinedStr = `${datePart}${timeStr}${timezonePart}`;

  return combinedStr;
}
