/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FoodFoodOrdersOrderIdGet200Response,
  FoodFoodOrdersSetOrderStatusPostRequest,
  FoodRestaurantByIdRestaurantIdGet200Response,
  FoodRestaurantMenuFoodByIdFoodIdGet200Response,
  FoodRestaurantMenuRestaurantIdGet200Response,
  FoodRestaurantsListGet200Response,
  FoodUserFoodOrdersCreatePost200Response,
  FoodUserFoodOrdersCreatePostRequest,
  FoodUserFoodOrdersUserIdGet200Response,
} from '../models';
import {
    FoodFoodOrdersOrderIdGet200ResponseFromJSON,
    FoodFoodOrdersOrderIdGet200ResponseToJSON,
    FoodFoodOrdersSetOrderStatusPostRequestFromJSON,
    FoodFoodOrdersSetOrderStatusPostRequestToJSON,
    FoodRestaurantByIdRestaurantIdGet200ResponseFromJSON,
    FoodRestaurantByIdRestaurantIdGet200ResponseToJSON,
    FoodRestaurantMenuFoodByIdFoodIdGet200ResponseFromJSON,
    FoodRestaurantMenuFoodByIdFoodIdGet200ResponseToJSON,
    FoodRestaurantMenuRestaurantIdGet200ResponseFromJSON,
    FoodRestaurantMenuRestaurantIdGet200ResponseToJSON,
    FoodRestaurantsListGet200ResponseFromJSON,
    FoodRestaurantsListGet200ResponseToJSON,
    FoodUserFoodOrdersCreatePost200ResponseFromJSON,
    FoodUserFoodOrdersCreatePost200ResponseToJSON,
    FoodUserFoodOrdersCreatePostRequestFromJSON,
    FoodUserFoodOrdersCreatePostRequestToJSON,
    FoodUserFoodOrdersUserIdGet200ResponseFromJSON,
    FoodUserFoodOrdersUserIdGet200ResponseToJSON,
} from '../models';

export interface FoodFoodOrdersOrderIdGetRequest {
    initData: string;
    orderId: string;
}

export interface FoodFoodOrdersSetOrderStatusPostOperationRequest {
    foodFoodOrdersSetOrderStatusPostRequest: FoodFoodOrdersSetOrderStatusPostRequest;
}

export interface FoodRestaurantByIdRestaurantIdGetRequest {
    initData: string;
    restaurantId: string;
}

export interface FoodRestaurantMenuFoodByIdFoodIdGetRequest {
    initData: string;
    foodId: string;
}

export interface FoodRestaurantMenuRestaurantIdGetRequest {
    initData: string;
    restaurantId: string;
}

export interface FoodRestaurantsListGetRequest {
    initData: string;
}

export interface FoodUserFoodOrdersCreatePostOperationRequest {
    foodUserFoodOrdersCreatePostRequest: FoodUserFoodOrdersCreatePostRequest;
}

export interface FoodUserFoodOrdersUserIdGetRequest {
    initData: string;
    userId: string;
}

/**
 * 
 */
export class RestaurantsApi extends runtime.BaseAPI {

    /**
     * Return food order by id
     */
    async foodFoodOrdersOrderIdGetRaw(requestParameters: FoodFoodOrdersOrderIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FoodFoodOrdersOrderIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling foodFoodOrdersOrderIdGet.');
        }

        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling foodFoodOrdersOrderIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/food/food_orders/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FoodFoodOrdersOrderIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Return food order by id
     */
    async foodFoodOrdersOrderIdGet(requestParameters: FoodFoodOrdersOrderIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FoodFoodOrdersOrderIdGet200Response> {
        const response = await this.foodFoodOrdersOrderIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update status for food order
     */
    async foodFoodOrdersSetOrderStatusPostRaw(requestParameters: FoodFoodOrdersSetOrderStatusPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.foodFoodOrdersSetOrderStatusPostRequest === null || requestParameters.foodFoodOrdersSetOrderStatusPostRequest === undefined) {
            throw new runtime.RequiredError('foodFoodOrdersSetOrderStatusPostRequest','Required parameter requestParameters.foodFoodOrdersSetOrderStatusPostRequest was null or undefined when calling foodFoodOrdersSetOrderStatusPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/food/food_orders/set_order_status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FoodFoodOrdersSetOrderStatusPostRequestToJSON(requestParameters.foodFoodOrdersSetOrderStatusPostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update status for food order
     */
    async foodFoodOrdersSetOrderStatusPost(requestParameters: FoodFoodOrdersSetOrderStatusPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.foodFoodOrdersSetOrderStatusPostRaw(requestParameters, initOverrides);
    }

    /**
     * Returns restaurant by ID
     */
    async foodRestaurantByIdRestaurantIdGetRaw(requestParameters: FoodRestaurantByIdRestaurantIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FoodRestaurantByIdRestaurantIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling foodRestaurantByIdRestaurantIdGet.');
        }

        if (requestParameters.restaurantId === null || requestParameters.restaurantId === undefined) {
            throw new runtime.RequiredError('restaurantId','Required parameter requestParameters.restaurantId was null or undefined when calling foodRestaurantByIdRestaurantIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/food/restaurant_by_id/{restaurantId}`.replace(`{${"restaurantId"}}`, encodeURIComponent(String(requestParameters.restaurantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FoodRestaurantByIdRestaurantIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns restaurant by ID
     */
    async foodRestaurantByIdRestaurantIdGet(requestParameters: FoodRestaurantByIdRestaurantIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FoodRestaurantByIdRestaurantIdGet200Response> {
        const response = await this.foodRestaurantByIdRestaurantIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns food by id
     */
    async foodRestaurantMenuFoodByIdFoodIdGetRaw(requestParameters: FoodRestaurantMenuFoodByIdFoodIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FoodRestaurantMenuFoodByIdFoodIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling foodRestaurantMenuFoodByIdFoodIdGet.');
        }

        if (requestParameters.foodId === null || requestParameters.foodId === undefined) {
            throw new runtime.RequiredError('foodId','Required parameter requestParameters.foodId was null or undefined when calling foodRestaurantMenuFoodByIdFoodIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/food/restaurant_menu/food_by_id/{foodId}`.replace(`{${"foodId"}}`, encodeURIComponent(String(requestParameters.foodId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FoodRestaurantMenuFoodByIdFoodIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns food by id
     */
    async foodRestaurantMenuFoodByIdFoodIdGet(requestParameters: FoodRestaurantMenuFoodByIdFoodIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FoodRestaurantMenuFoodByIdFoodIdGet200Response> {
        const response = await this.foodRestaurantMenuFoodByIdFoodIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all food of restaurant
     */
    async foodRestaurantMenuRestaurantIdGetRaw(requestParameters: FoodRestaurantMenuRestaurantIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FoodRestaurantMenuRestaurantIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling foodRestaurantMenuRestaurantIdGet.');
        }

        if (requestParameters.restaurantId === null || requestParameters.restaurantId === undefined) {
            throw new runtime.RequiredError('restaurantId','Required parameter requestParameters.restaurantId was null or undefined when calling foodRestaurantMenuRestaurantIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/food/restaurant_menu/{restaurantId}`.replace(`{${"restaurantId"}}`, encodeURIComponent(String(requestParameters.restaurantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FoodRestaurantMenuRestaurantIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns all food of restaurant
     */
    async foodRestaurantMenuRestaurantIdGet(requestParameters: FoodRestaurantMenuRestaurantIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FoodRestaurantMenuRestaurantIdGet200Response> {
        const response = await this.foodRestaurantMenuRestaurantIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all available restaurants
     */
    async foodRestaurantsListGetRaw(requestParameters: FoodRestaurantsListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FoodRestaurantsListGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling foodRestaurantsListGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/food/restaurants_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FoodRestaurantsListGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns all available restaurants
     */
    async foodRestaurantsListGet(requestParameters: FoodRestaurantsListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FoodRestaurantsListGet200Response> {
        const response = await this.foodRestaurantsListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create food order
     */
    async foodUserFoodOrdersCreatePostRaw(requestParameters: FoodUserFoodOrdersCreatePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FoodUserFoodOrdersCreatePost200Response>> {
        if (requestParameters.foodUserFoodOrdersCreatePostRequest === null || requestParameters.foodUserFoodOrdersCreatePostRequest === undefined) {
            throw new runtime.RequiredError('foodUserFoodOrdersCreatePostRequest','Required parameter requestParameters.foodUserFoodOrdersCreatePostRequest was null or undefined when calling foodUserFoodOrdersCreatePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/food/user_food_orders/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FoodUserFoodOrdersCreatePostRequestToJSON(requestParameters.foodUserFoodOrdersCreatePostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FoodUserFoodOrdersCreatePost200ResponseFromJSON(jsonValue));
    }

    /**
     * Create food order
     */
    async foodUserFoodOrdersCreatePost(requestParameters: FoodUserFoodOrdersCreatePostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FoodUserFoodOrdersCreatePost200Response> {
        const response = await this.foodUserFoodOrdersCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all user food orders
     */
    async foodUserFoodOrdersUserIdGetRaw(requestParameters: FoodUserFoodOrdersUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FoodUserFoodOrdersUserIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling foodUserFoodOrdersUserIdGet.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling foodUserFoodOrdersUserIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/food/user_food_orders/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FoodUserFoodOrdersUserIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns all user food orders
     */
    async foodUserFoodOrdersUserIdGet(requestParameters: FoodUserFoodOrdersUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FoodUserFoodOrdersUserIdGet200Response> {
        const response = await this.foodUserFoodOrdersUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
