import { excursionsApi } from "../../../apiDrivers/excursions";

const fetchExcursions = async ({ queryKey }: any) => {
  const initData = queryKey[1];

  const res = await excursionsApi.excursionsExcursionsListGet({
    initData,
  });

  if (!res.excursionsList) {
    throw new Error(`Excursions list fetch failed`);
  }

  return res.excursionsList;
};

export default fetchExcursions;
