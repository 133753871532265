/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FoodLookup } from './FoodLookup';
import {
    FoodLookupFromJSON,
    FoodLookupFromJSONTyped,
    FoodLookupToJSON,
} from './FoodLookup';

/**
 * 
 * @export
 * @interface FoodRestaurantMenuRestaurantIdGet200Response
 */
export interface FoodRestaurantMenuRestaurantIdGet200Response {
    /**
     * 
     * @type {Array<FoodLookup>}
     * @memberof FoodRestaurantMenuRestaurantIdGet200Response
     */
    restaurantMenu: Array<FoodLookup>;
}

/**
 * Check if a given object implements the FoodRestaurantMenuRestaurantIdGet200Response interface.
 */
export function instanceOfFoodRestaurantMenuRestaurantIdGet200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "restaurantMenu" in value;

    return isInstance;
}

export function FoodRestaurantMenuRestaurantIdGet200ResponseFromJSON(json: any): FoodRestaurantMenuRestaurantIdGet200Response {
    return FoodRestaurantMenuRestaurantIdGet200ResponseFromJSONTyped(json, false);
}

export function FoodRestaurantMenuRestaurantIdGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodRestaurantMenuRestaurantIdGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'restaurantMenu': ((json['restaurantMenu'] as Array<any>).map(FoodLookupFromJSON)),
    };
}

export function FoodRestaurantMenuRestaurantIdGet200ResponseToJSON(value?: FoodRestaurantMenuRestaurantIdGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'restaurantMenu': ((value.restaurantMenu as Array<any>).map(FoodLookupToJSON)),
    };
}

