import { YachtsApi } from "../../../client";

export const yachtsApi = new YachtsApi();

const fetchYachtsById = async ({ queryKey }: any) => {
  const initData = queryKey[1];
  const id = queryKey[2];
  if (!id) {
    throw new Error(`API Returns yacht of all available yachts use failed`);
  }

  const res = await yachtsApi.yachtsYachtRoutesYachtIdGet({
    initData,
    yachtId: id,
  });

  return res.yachtRoutes;
};

export default fetchYachtsById;
