import { BASE_PATH, Yacht } from "../../client";

export type TYachtLookup = {
  id: string;
  image: string;
  name: string;
  title: string;
  model: string;
  port: string;
  year: number;
  description: string;
};

export function normalizeYachts(yachts: Yacht[]): TYachtLookup[] {
  return yachts.map((yacht) => {
    return {
      id: yacht.id,
      image: `${BASE_PATH}/${yacht.images[0]}`,
      name: yacht.name,
      title: yacht.name,
      model: yacht.model,
      port: yacht.port,
      year: yacht.year,
      description: `Material: ${yacht.boatMaterial}, Type: ${yacht.boatType}, Brand: ${yacht.brand}`,
    };
  });
}
