import { useTranslation } from "react-i18next";
import { Flex, Group, Text } from "@mantine/core";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Suspense } from "react";
import { TransferStatus } from "../../../client";
import { PointPickerInput } from "../../../components/UI/Elements/PointPickerInput";
import { LoaderPageCustom } from "../../../components/UI/Vendors/Mantine/LoaderPageCustom";
import { NumberInputCustom } from "../../../components/UI/Vendors/Mantine/NumberInputCustom";
import { EColors, ECurrency } from "../../../definitions";
import { useFn } from "../../../hooks/useFn";
import { useTelegram } from "../../../hooks/useTelegram";
import { OrderCompletedBase } from "../../../modules/orders/OrderCompletedBase";
import { selectCurrentUser } from "../../../store/userStore";
import { mapTransferOrderStatusToBadgeType } from "../../OrderListPage/api/transferOrders";
import { useSuccessStatusChanged } from "../common";
import { fetchTransferById, updateTransferStatusRequest } from "./api";
import { RouteByPointsMap } from "../../../components/MapComponents/RouteByPointsMap";
import { combineDateAndTime } from "./lib/combineDateAndTime";

export const OrderTransferCompletedPage = () => {
  const { initData } = useTelegram();

  const currentUser = useSelector(selectCurrentUser);

  const { id } = useParams();

  const { t } = useTranslation(["transfer_order_page_completed"]);

  const transferOrderQuery = useQuery({
    queryKey: [currentUser.id, initData, id],
    queryFn: fetchTransferById,
    onError: (error) => {
      console.log(error);
    },
  });
  const { onSuccessCancel } = useSuccessStatusChanged();

  const updateTransferStatusMutation = useMutation({
    mutationFn: ([transferId, userName, status, initData]: [string, string, TransferStatus, string]) => {
      return updateTransferStatusRequest(transferId, userName, status, initData);
    },
    onSuccess: onSuccessCancel,
    onError: (error) => {
      console.error(error);
    },
  });

  const onCancel = useFn(() => {
    updateTransferStatusMutation.mutate([
      transferOrderQuery.data!.id!,
      currentUser.tgName ? currentUser.tgName : currentUser.tgChatId,
      TransferStatus.CancelledByUser,
      initData,
    ]);
  });

  if (!transferOrderQuery.data || transferOrderQuery.isLoading || !transferOrderQuery.data) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }

  const transfer = transferOrderQuery.data;

  // const imagesPaths = transfer.seaTrip!.images.map((image) => {
  //   return `${BASE_PATH}/${image}`;
  // });

  const renderTransferFields = () => (
    <Flex direction={"column"} gap={"lg"}>
      <Flex direction={"column"} gap={"md"}>
        <Flex className="ItemPage__header" justify="start" wrap="wrap">
          <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
            <Text className="mainCard__title" color={EColors.gray_1} weight={700}>
              {t("Route points")}
            </Text>
          </Group>
        </Flex>
        <Flex gap="md" direction={"column"}>
          <PointPickerInput label={t("Start point")} addressDefault={transfer.from} disabled={true}></PointPickerInput>
          <PointPickerInput label={t("End point")} addressDefault={transfer.to} disabled={true}></PointPickerInput>
        </Flex>
      </Flex>
      {!!transfer.fromPointLat && !!transfer.toPointLat && !!transfer.fromPointLon && !!transfer.toPointLon && (
        <>
          <Flex>
            <Suspense fallback="...Loading">
              <RouteByPointsMap
                withNotification={false}
                fromLocation={[transfer.fromPointLat, transfer.fromPointLon]}
                toLocation={[transfer.toPointLat, transfer.toPointLon]}
                getDistance={(value) => {}}
              ></RouteByPointsMap>
            </Suspense>
          </Flex>
        </>
      )}
      {/* <Divider my="sm" mb={"xl"} mt={"xl"} /> */}
      <Flex direction={"column"} gap={"md"}>
        <Flex className="ItemPage__header" justify="start" wrap="wrap">
          <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
            <Text className="mainCard__title" color={EColors.gray_1} weight={700}>
              {t("Additional Items")}
            </Text>
          </Group>
        </Flex>
        <Flex gap="md">
          <NumberInputCustom
            disabled={true}
            placeholder="count"
            label={t("Baby seats")}
            defaultValue={transfer.babySeats}
          />
          <NumberInputCustom
            placeholder={t("count")}
            label={t("Luggage")}
            disabled={true}
            defaultValue={Number(transfer.luggage)}
          />
        </Flex>
      </Flex>
    </Flex>
  );

  return (
    <>
      <OrderCompletedBase
        description={`${t("Transfer rout from")} ${transfer.from}, ${t("to")} ${transfer.to}`}
        image={""}
        images={[]}
        name={`${transfer!.carType!} ${t("Transfer")}`}
        price={transfer.approximatePrice!}
        approximatePrice={transfer.approximatePrice!}
        currency={ECurrency.EUR}
        contactType={transfer.contactType!}
        contactValue={transfer.contact!}
        personCount={transfer.personCount!}
        passengerNames={transfer.passengerNames}
        flightNumber={transfer.flightNumber}
        commentValue={transfer.comment!}
        actualDate={combineDateAndTime(transfer.date, transfer.time)}
        status={mapTransferOrderStatusToBadgeType[transfer.status!]}
        onCancel={onCancel}
        paymentMethod={""}
        creationDate={""}
        children={renderTransferFields()}
      ></OrderCompletedBase>
    </>
  );
};

function formatDate(dateString: string | undefined): string {
  if (!dateString) {
    return "";
  }
  const reformattedDateStr = dateString.split(" ")[0].split(".").reverse().join("-");
  const date = new Date(reformattedDateStr);

  return date.toISOString().substring(0, 10);
}
