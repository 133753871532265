import { useLocation, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { ECurrency } from "../../../definitions";
import { OrderCompletedBase } from "../../../modules/orders/OrderCompletedBase";
import { useTelegram } from "../../../hooks/useTelegram";
import { selectCurrentUser } from "../../../store/userStore";
import { fetchFoodOrderById, updateFoodnOrderStatusRequest } from "./api";
import { LoaderPageCustom } from "../../../components/UI/Vendors/Mantine/LoaderPageCustom";
import { BASE_PATH, FoodOrderUpdateStatus } from "../../../client";
import { MenuItem } from "../../../components/MenuItem";
import { normalizeRestaurantMenu } from "../../DeliveryMenuPage/definitions";
import { useSuccessStatusChanged } from "../common";
import { useFn } from "../../../hooks/useFn";
import { mapFoodOrderStatusToBadgeType } from "../../OrderListPage/api/foodOrders";

export const OrderDeliveryCompletedPage = () => {
  const location = useLocation();

  const { initData } = useTelegram();

  const currentUser = useSelector(selectCurrentUser);

  const { id } = useParams();

  const { onSuccessCancel } = useSuccessStatusChanged();

  const foodOrderQuery = useQuery({
    queryKey: [`${currentUser.id} excursion order ${id}`, initData, id],
    queryFn: fetchFoodOrderById,
    onError: (error) => {
      console.log(error);
    },
  });

  const updateFoodOrderStatusMutation = useMutation({
    mutationFn: ([orderId, restaurantName, restaurantId, userName, status, initData]: [
      string,
      string,
      string,
      string,
      FoodOrderUpdateStatus,
      string
    ]) => {
      return updateFoodnOrderStatusRequest(orderId, restaurantName, restaurantId, userName, status, initData);
    },
    onSuccess: onSuccessCancel,
    onError: (error) => {
      console.error(error);
    },
  });

  const onCancel = useFn(() => {
    updateFoodOrderStatusMutation.mutate([
      foodOrderQuery.data!.orderLookup.id,
      foodOrderQuery.data!.orderLookup.restaurant.name,
      foodOrderQuery.data!.orderLookup.restaurant.id,
      currentUser.tgName ? currentUser.tgName : currentUser.tgChatId,
      FoodOrderUpdateStatus.CancelledByUser,
      initData,
    ]);
  });

  if (!foodOrderQuery.data || foodOrderQuery.isLoading) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }

  const { orderLookup, items } = foodOrderQuery.data;

  const imagesPaths = orderLookup.restaurant!.images.map((image) => {
    return `${BASE_PATH}/${image}`;
  });

  return (
    <OrderCompletedBase
      description={""}
      image={imagesPaths[0]}
      images={imagesPaths}
      name={"Delivery"}
      price={orderLookup.price}
      currency={ECurrency.TRY}
      contactType={orderLookup.contactType}
      contactValue={orderLookup.contact}
      addressValue={orderLookup.address}
      adultsCount={0}
      childrenCount={0}
      actualDate={orderLookup.deliveryDate}
      actualDateLabel={"Delivery date"}
      creationDate={orderLookup.creationDate}
      paymentMethod={orderLookup.paymentMethod}
      commentValue={orderLookup.comment}
      status={mapFoodOrderStatusToBadgeType[orderLookup.status]}
      onCancel={onCancel}
    >
      {items.map((menuItem) => {
        const normalized = normalizeRestaurantMenu([menuItem])[0];
        return (
          <MenuItem
            menuItem={normalized}
            hasPriceButton={false}
            hasCountValue={true}
            count={normalized.count}
            key={menuItem.id}
            restaurantId={""}
          ></MenuItem>
        );
      })}
    </OrderCompletedBase>
  );
};
