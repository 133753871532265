/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TransferStatus } from './TransferStatus';
import {
    TransferStatusFromJSON,
    TransferStatusFromJSONTyped,
    TransferStatusToJSON,
} from './TransferStatus';

/**
 * 
 * @export
 * @interface TransferSetOrderStatusPostRequest
 */
export interface TransferSetOrderStatusPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TransferSetOrderStatusPostRequest
     */
    initData?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferSetOrderStatusPostRequest
     */
    transferId: string;
    /**
     * 
     * @type {string}
     * @memberof TransferSetOrderStatusPostRequest
     */
    userName: string;
    /**
     * 
     * @type {TransferStatus}
     * @memberof TransferSetOrderStatusPostRequest
     */
    status: TransferStatus;
}

/**
 * Check if a given object implements the TransferSetOrderStatusPostRequest interface.
 */
export function instanceOfTransferSetOrderStatusPostRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transferId" in value;
    isInstance = isInstance && "userName" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function TransferSetOrderStatusPostRequestFromJSON(json: any): TransferSetOrderStatusPostRequest {
    return TransferSetOrderStatusPostRequestFromJSONTyped(json, false);
}

export function TransferSetOrderStatusPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferSetOrderStatusPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initData': !exists(json, 'initData') ? undefined : json['initData'],
        'transferId': json['transferId'],
        'userName': json['userName'],
        'status': TransferStatusFromJSON(json['status']),
    };
}

export function TransferSetOrderStatusPostRequestToJSON(value?: TransferSetOrderStatusPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initData': value.initData,
        'transferId': value.transferId,
        'userName': value.userName,
        'status': TransferStatusToJSON(value.status),
    };
}

