import { getRegionCostIndex } from "./getRegionCostIndex";

const basePrice = 2000;
const baseDistance = 400000;

export const calculateTransferPriceFromOil = (from: string, to: string, distanceInMeters: number) => {
  const pricePer1000m = basePrice / (baseDistance / 1000);

  const totalPriceForDistance = (distanceInMeters / 1000) * pricePer1000m;

  const finalPrice = totalPriceForDistance * 2;
  const costIndexFrom = getRegionCostIndex(from) ?? 1;
  const costIndexTo = getRegionCostIndex(to) ?? 1;
  const costIndexFromNew = costIndexFrom < 1 ? 1 : costIndexFrom;
  const costIndexToNew = costIndexTo < 1 ? 1 : costIndexTo;

  const costIndex = Math.max(costIndexFromNew, costIndexToNew);
  const additionalPrice = finalPrice * costIndex;

  return {
    minOilPrice: finalPrice,
    calculateOilPrice: finalPrice + additionalPrice,
  };
};
