export const transferRegionCoefficient = [
  { ru: "Адана", en: "Adana", costIndex: 0.85, expenseCoefficient: 1.0 },
  { ru: "Адыяман", en: "Adıyaman", costIndex: 0.8, expenseCoefficient: 0.9 },
  { ru: "Афьонкарахисар", en: "Afyonkarahisar", costIndex: 0.75, expenseCoefficient: 0.85 },
  { ru: "Агры", en: "Ağrı", costIndex: 0.7, expenseCoefficient: 0.8 },
  { ru: "Амасья", en: "Amasya", costIndex: 0.8, expenseCoefficient: 0.9 },
  { ru: "Анкара", en: "Ankara", costIndex: 1.2, expenseCoefficient: 1.3 },
  { ru: "Анталья", en: "Antalya", costIndex: 1.15, expenseCoefficient: 1.25 },
  { ru: "Артвин", en: "Artvin", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Айдын", en: "Aydın", costIndex: 0.9, expenseCoefficient: 0.95 },
  { ru: "Баликесир", en: "Balıkesir", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Биледжик", en: "Bilecik", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Бингёль", en: "Bingöl", costIndex: 0.7, expenseCoefficient: 0.75 },
  { ru: "Битлис", en: "Bitlis", costIndex: 0.65, expenseCoefficient: 0.7 },
  { ru: "Болу", en: "Bolu", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Бурса", en: "Bursa", costIndex: 1.0, expenseCoefficient: 1.05 },
  { ru: "Чанаккале", en: "Çanakkale", costIndex: 0.9, expenseCoefficient: 0.95 },
  { ru: "Чанкыры", en: "Çankırı", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Чорум", en: "Çorum", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Денизли", en: "Denizli", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Диярбакыр", en: "Diyarbakır", costIndex: 0.8, expenseCoefficient: 0.85 },
  { ru: "Эдирне", en: "Edirne", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Элязыг", en: "Elazığ", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Эрзинджан", en: "Erzincan", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Эрзурум", en: "Erzurum", costIndex: 0.7, expenseCoefficient: 0.75 },
  { ru: "Эскишехир", en: "Eskişehir", costIndex: 0.9, expenseCoefficient: 0.95 },
  { ru: "Газиантеп", en: "Gaziantep", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Гиресун", en: "Giresun", costIndex: 0.8, expenseCoefficient: 0.85 },
  { ru: "Гюмюшхане", en: "Gümüşhane", costIndex: 0.7, expenseCoefficient: 0.75 },
  { ru: "Хаккяри", en: "Hakkâri", costIndex: 0.65, expenseCoefficient: 0.7 },
  { ru: "Хатай", en: "Hatay", costIndex: 0.9, expenseCoefficient: 0.95 },
  { ru: "Испарта", en: "Isparta", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Мерсин", en: "Mersin", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Стамбул", en: "İstanbul", costIndex: 1.5, expenseCoefficient: 2.0 },
  { ru: "Измир", en: "İzmir", costIndex: 1.3, expenseCoefficient: 1.5 },
  { ru: "Карс", en: "Kars", costIndex: 0.7, expenseCoefficient: 0.8 },
  { ru: "Кастамону", en: "Kastamonu", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Кайсери", en: "Kayseri", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Кыркларели", en: "Kırklareli", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Киршехир", en: "Kırşehir", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Коджаэли", en: "Kocaeli", costIndex: 1.0, expenseCoefficient: 1.1 },
  { ru: "Конья", en: "Konya", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Кютахья", en: "Kütahya", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Малатья", en: "Malatya", costIndex: 0.8, expenseCoefficient: 0.85 },
  { ru: "Маниса", en: "Manisa", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Кахраманмараш", en: "Kahramanmaraş", costIndex: 0.8, expenseCoefficient: 0.85 },
  { ru: "Мардин", en: "Mardin", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Мугла", en: "Muğla", costIndex: 1.0, expenseCoefficient: 1.1 },
  { ru: "Муш", en: "Muş", costIndex: 0.7, expenseCoefficient: 0.75 },
  { ru: "Невшехир", en: "Nevşehir", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Нигде", en: "Niğde", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Орду", en: "Ordu", costIndex: 0.8, expenseCoefficient: 0.85 },
  { ru: "Ризе", en: "Rize", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Сакарья", en: "Sakarya", costIndex: 0.9, expenseCoefficient: 0.95 },
  { ru: "Самсун", en: "Samsun", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Сиирт", en: "Siirt", costIndex: 0.7, expenseCoefficient: 0.75 },
  { ru: "Синоп", en: "Sinop", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Сивас", en: "Sivas", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Текирдаг", en: "Tekirdağ", costIndex: 0.9, expenseCoefficient: 0.95 },
  { ru: "Токат", en: "Tokat", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Трабзон", en: "Trabzon", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Тунджели", en: "Tunceli", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Шанлыурфа", en: "Şanlıurfa", costIndex: 0.8, expenseCoefficient: 0.85 },
  { ru: "Ушак", en: "Uşak", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Ван", en: "Van", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Йозгат", en: "Yozgat", costIndex: 0.7, expenseCoefficient: 0.75 },
  { ru: "Зонгулдак", en: "Zonguldak", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Аксарай", en: "Aksaray", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Байбурт", en: "Bayburt", costIndex: 0.7, expenseCoefficient: 0.75 },
  { ru: "Караман", en: "Karaman", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Кириккале", en: "Kırıkkale", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Батман", en: "Batman", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Ширнак", en: "Şırnak", costIndex: 0.7, expenseCoefficient: 0.75 },
  { ru: "Бартын", en: "Bartın", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Ардахан", en: "Ardahan", costIndex: 0.7, expenseCoefficient: 0.75 },
  { ru: "Ыгдыр", en: "Iğdır", costIndex: 0.7, expenseCoefficient: 0.75 },
  { ru: "Ялова", en: "Yalova", costIndex: 0.85, expenseCoefficient: 0.9 },
  { ru: "Карабюк", en: "Karabük", costIndex: 0.8, expenseCoefficient: 0.85 },
  { ru: "Килис", en: "Kilis", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Османие", en: "Osmaniye", costIndex: 0.75, expenseCoefficient: 0.8 },
  { ru: "Дюздже", en: "Düzce", costIndex: 0.75, expenseCoefficient: 0.8 },
];
