import { Badge } from "@mantine/core";
import { MantineColor } from "@mantine/styles";

export type TBadgeType = "Ordered" | "Booked" | "In delivery" | "Approved" | "Completed" | "Cancelled";

export const TERMINAL_STATUSES: TBadgeType[] = ["Completed", "Cancelled"];

export type TBadgeProps = {
  value: string;
  type: TBadgeType;
};

const MapBadgeTypeToColor: Record<TBadgeType, MantineColor> = {
  Booked: "yellow",
  Ordered: "yellow",
  "In delivery": "yellow",
  Approved: "green",
  Completed: "green",
  Cancelled: "red",
};

export const ALL_STATUSES = Object.keys(MapBadgeTypeToColor) as TBadgeType[];

export const OrderBadge = (props: TBadgeProps) => {
  return (
    <Badge className="" size="lg" color={MapBadgeTypeToColor[props.type]} radius="lg" variant="filled">
      {props.value}
    </Badge>
  );
};
