import { Flex, Modal, TextInputProps } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { HTMLInputTypeAttribute, useCallback, useRef, useState } from "react";
import { PointPickerMap } from "../../../MapComponents/PointPickerMap";
import { ButtonCustom } from "../../Vendors/Mantine/ButtonCustom";
import { TextInputCustom } from "../../Vendors/Mantine/TextInputCustom";

type Point = [number, number];

export const PointPickerInput = ({
  onAddressPick,
  onLocationPick,
  label,
  disabled = false,
  addressDefault,
  pointDefault,
  markerType,
  ...pointPickerInputProps
}: {
  label: string;
  onAddressPick?: (address: string) => void;
  onLocationPick?: (location: [number, number]) => void;
  disabled?: boolean;
  markerType?: 'start' | 'end' | 'default'
  addressDefault?: string;
  pointDefault?: Point;
} & TextInputProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const [openedModal, { open, close }] = useDisclosure(false);
  const [point, setPoint] = useState<Point>([0, 0]);
  const [address, setAddress] = useState<string>(addressDefault || "");
  const [pickedPoint, setPickedPoint] = useState<Point>([0, 0]);
  const [pickedAddress, setPickedAddress] = useState<string>(addressDefault || "");
  console.log("123 addressDefault", addressDefault, pickedAddress);

  const onSubmitButtonClick = useCallback(() => {
    console.log("123", pickedPoint, pickedAddress);

    if (!disabled && onAddressPick) {
      setAddress(pickedAddress);
      onAddressPick(pickedAddress);
    }
    if (!disabled && onLocationPick) {
      onLocationPick(pickedPoint);
    }
    close();
  }, [disabled, onAddressPick, pickedAddress, pickedPoint, onLocationPick, close]);

  const onClick = useCallback(() => {
    open();
    inputRef.current?.blur();
  }, []);

  return (
    <>
      <Modal
        opened={openedModal}
        onClose={() => {
          if (!disabled && onAddressPick && addressDefault) {
            setPickedAddress(addressDefault);
            setAddress(addressDefault);
            onAddressPick(addressDefault);
          }
          if (!disabled && onLocationPick && pointDefault) {
            setPickedPoint(pointDefault);
            onLocationPick(pointDefault);
          }
          close();
        }}
        withCloseButton={true}
        centered
        title={label}
        transitionProps={{ transition: "rotate-left" }}
      >
        <PointPickerMap
          disabled={disabled}
          onPointPick={setPickedPoint}
          onAddressPick={setPickedAddress}
          address={pickedAddress}
          markerType={markerType}
          location={pickedPoint}
          {...pointPickerInputProps}
        ></PointPickerMap>

        <Flex justify={"center"} pt={"sm"}>
          <ButtonCustom onClick={onSubmitButtonClick} children={<>Done</>}></ButtonCustom>
        </Flex>
      </Modal>
      <TextInputCustom
        ref={inputRef}
        label={label}
        onClick={onClick}
        readOnly={true}
        value={address}
        disabled={disabled}
        {...pointPickerInputProps}
      ></TextInputCustom>
    </>
  );
};
