/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FoodOrder } from './FoodOrder';
import {
    FoodOrderFromJSON,
    FoodOrderFromJSONTyped,
    FoodOrderToJSON,
} from './FoodOrder';

/**
 * 
 * @export
 * @interface FoodUserFoodOrdersCreatePostRequest
 */
export interface FoodUserFoodOrdersCreatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodUserFoodOrdersCreatePostRequest
     */
    initData?: string;
    /**
     * 
     * @type {FoodOrder}
     * @memberof FoodUserFoodOrdersCreatePostRequest
     */
    foodOrder?: FoodOrder;
}

/**
 * Check if a given object implements the FoodUserFoodOrdersCreatePostRequest interface.
 */
export function instanceOfFoodUserFoodOrdersCreatePostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FoodUserFoodOrdersCreatePostRequestFromJSON(json: any): FoodUserFoodOrdersCreatePostRequest {
    return FoodUserFoodOrdersCreatePostRequestFromJSONTyped(json, false);
}

export function FoodUserFoodOrdersCreatePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodUserFoodOrdersCreatePostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initData': !exists(json, 'initData') ? undefined : json['initData'],
        'foodOrder': !exists(json, 'foodOrder') ? undefined : FoodOrderFromJSON(json['foodOrder']),
    };
}

export function FoodUserFoodOrdersCreatePostRequestToJSON(value?: FoodUserFoodOrdersCreatePostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initData': value.initData,
        'foodOrder': FoodOrderToJSON(value.foodOrder),
    };
}

