/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FoodOrderUpdateStatus } from './FoodOrderUpdateStatus';
import {
    FoodOrderUpdateStatusFromJSON,
    FoodOrderUpdateStatusFromJSONTyped,
    FoodOrderUpdateStatusToJSON,
} from './FoodOrderUpdateStatus';

/**
 * 
 * @export
 * @interface FoodOrderStatus
 */
export interface FoodOrderStatus {
    /**
     * 
     * @type {FoodOrderUpdateStatus}
     * @memberof FoodOrderStatus
     */
    status: FoodOrderUpdateStatus;
    /**
     * 
     * @type {string}
     * @memberof FoodOrderStatus
     */
    updateTime: string;
}

/**
 * Check if a given object implements the FoodOrderStatus interface.
 */
export function instanceOfFoodOrderStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "updateTime" in value;

    return isInstance;
}

export function FoodOrderStatusFromJSON(json: any): FoodOrderStatus {
    return FoodOrderStatusFromJSONTyped(json, false);
}

export function FoodOrderStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodOrderStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': FoodOrderUpdateStatusFromJSON(json['status']),
        'updateTime': json['updateTime'],
    };
}

export function FoodOrderStatusToJSON(value?: FoodOrderStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': FoodOrderUpdateStatusToJSON(value.status),
        'updateTime': value.updateTime,
    };
}

