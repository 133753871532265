import { useTranslation } from "react-i18next";
import { House, Receipt, ShoppingCart } from "@gravity-ui/icons";
import { Button, Flex, Indicator } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import "./style.css";
import { selectCartCount } from "../../store/deliveryCartStore";

export const NavigationBar = ({
  children,
  mb = "",
  mt,
}: {
  children?: any;
  mb?: number | string;
  mt?: number | string;
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation(["navigation_bar"]);

  const navigateToMain = useFn(() => navigate(RouterPath.main.index));
  const navigateToMyOrders = useFn(() => navigate(RouterPath.order.list));
  const navigateToCart = useFn(() => navigate(RouterPath.cart.index));

  const cartCount = useSelector(selectCartCount);

  const location = useLocation();
  const pathName = location.pathname;
  let homeStyle = {};
  let searchStyle = {};
  let cartStyle = {};

  switch (pathName) {
    case RouterPath.cart.index:
      cartStyle = { color: "#7950f2" };
      break;
    case RouterPath.order.list:
      searchStyle = { color: "#7950f2" };
      break;
    default:
      homeStyle = { color: "#7950f2" };
      break;
  }

  return (
    <div style={{ marginBottom: mb, marginTop: mt }} className="navigationBar__positionRelative">
      <div className="navigationBar">
        <Flex className="navTopSection" justify="center" mb="md">
          {children}
        </Flex>
        <div className="navElements" style={{ gridTemplateColumns: `repeat(${cartCount === 0 ? 2 : 3}, 1fr)` }}>
          <Button onClick={navigateToMain} className="navButton" color="violet" variant="subtle" radius="lg" size="lg">
            <div className="navElement">
              <div className="navElement__icon">
                <House style={homeStyle}></House>
              </div>
              <div className="navElement__text">{t("Main page")}</div>
            </div>
          </Button>
          <Button
            onClick={navigateToMyOrders}
            className="navButton"
            color="violet"
            variant="subtle"
            radius="lg"
            size="lg"
          >
            <div className="navElement">
              <div className="navElement__icon">
                <Receipt style={searchStyle}></Receipt>
              </div>
              <div className="navElement__text">{t("Orders page")}</div>
            </div>
          </Button>

          {cartCount > 0 && (
            <Indicator
              inline
              disabled={cartCount === 0}
              label={cartCount}
              size={22}
              radius={"lg"}
              processing
              color="violet"
              position="top-end"
              offset={20}
            >
              <Button
                onClick={navigateToCart}
                className="navButton"
                color="violet"
                variant="subtle"
                radius="lg"
                size="lg"
              >
                <div className="navElement">
                  <div className="navElement__icon">
                    <ShoppingCart style={cartStyle}></ShoppingCart>
                  </div>

                  <div className="navElement__text">{t("Cart")}</div>
                </div>
              </Button>
            </Indicator>
          )}
        </div>
      </div>
    </div>
  );
};
