/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FoodOrderUpdateStatus } from './FoodOrderUpdateStatus';
import {
    FoodOrderUpdateStatusFromJSON,
    FoodOrderUpdateStatusFromJSONTyped,
    FoodOrderUpdateStatusToJSON,
} from './FoodOrderUpdateStatus';

/**
 * 
 * @export
 * @interface FoodFoodOrdersSetOrderStatusPostRequest
 */
export interface FoodFoodOrdersSetOrderStatusPostRequest {
    /**
     * 
     * @type {string}
     * @memberof FoodFoodOrdersSetOrderStatusPostRequest
     */
    initData?: string;
    /**
     * 
     * @type {string}
     * @memberof FoodFoodOrdersSetOrderStatusPostRequest
     */
    foodOrderId: string;
    /**
     * 
     * @type {string}
     * @memberof FoodFoodOrdersSetOrderStatusPostRequest
     */
    restaurantName: string;
    /**
     * 
     * @type {string}
     * @memberof FoodFoodOrdersSetOrderStatusPostRequest
     */
    restaurantId?: string;
    /**
     * 
     * @type {string}
     * @memberof FoodFoodOrdersSetOrderStatusPostRequest
     */
    userName: string;
    /**
     * 
     * @type {FoodOrderUpdateStatus}
     * @memberof FoodFoodOrdersSetOrderStatusPostRequest
     */
    status: FoodOrderUpdateStatus;
}

/**
 * Check if a given object implements the FoodFoodOrdersSetOrderStatusPostRequest interface.
 */
export function instanceOfFoodFoodOrdersSetOrderStatusPostRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "foodOrderId" in value;
    isInstance = isInstance && "restaurantName" in value;
    isInstance = isInstance && "userName" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function FoodFoodOrdersSetOrderStatusPostRequestFromJSON(json: any): FoodFoodOrdersSetOrderStatusPostRequest {
    return FoodFoodOrdersSetOrderStatusPostRequestFromJSONTyped(json, false);
}

export function FoodFoodOrdersSetOrderStatusPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodFoodOrdersSetOrderStatusPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initData': !exists(json, 'initData') ? undefined : json['initData'],
        'foodOrderId': json['foodOrderId'],
        'restaurantName': json['restaurantName'],
        'restaurantId': !exists(json, 'restaurantId') ? undefined : json['restaurantId'],
        'userName': json['userName'],
        'status': FoodOrderUpdateStatusFromJSON(json['status']),
    };
}

export function FoodFoodOrdersSetOrderStatusPostRequestToJSON(value?: FoodFoodOrdersSetOrderStatusPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initData': value.initData,
        'foodOrderId': value.foodOrderId,
        'restaurantName': value.restaurantName,
        'restaurantId': value.restaurantId,
        'userName': value.userName,
        'status': FoodOrderUpdateStatusToJSON(value.status),
    };
}

