import { BaseCard, TCard } from "../../../components/BaseCard/BaseCard";
import { TBadgeType } from "../../../components/badges/Badge";

type TProps = {
  list: TCard[];
  navigateFn: (card: TCard) => void;
  filters: TBadgeType[];
};

export function OrderCardList(props: TProps) {
  return (
    <>
      {props.list
        .filter((card) => props.filters.includes(card.status as TBadgeType))
        .map((card) => {
          return (
            <BaseCard
              key={card.id}
              card={{
                ...card,
              }}
              onCardClick={() => {
                props.navigateFn(card);
              }}
            ></BaseCard>
          );
        })}
    </>
  );
}
