import { BASE_PATH, Excursion, ExcursionTagNameEnum } from "../../client";
import { TCard } from "../../components/BaseCard/BaseCard";
import { ECurrency } from "../../definitions";
import { capitalizeFirstLetter } from "../../utils";

export function normalizeExcursions(excursions: Excursion[]): TCard[] {
  return excursions.map((excursion) => {
    return {
      id: excursion.id,
      time: excursion.duration + "",
      image: `${BASE_PATH}/${excursion.images[0]}`,
      title: excursion.title,
      address: excursion.city,
      route: excursion.title,
      duration: excursion.duration + "",
      tags: excursion.tags,
      description: excursion.description,
      shortDescription: excursion.shortDescription,
      price: excursion.priceAdult,
      currency: ECurrency.EUR,
    };
  });
}

export type TExcursionsFilters = string[];

export const ALL_EXCURSIONS_FILTERS: TExcursionsFilters =
  Object.values(ExcursionTagNameEnum).map(capitalizeFirstLetter);
