import { BASE_PATH, Food } from "../../client";

export type TFoodItemLookup = {
  id: string;
  image: string;
  name: string;
  price: number;
  description: string;
  images: string[];
};

export function normalizeFoodById(food: Food): TFoodItemLookup {
  const imagesPaths = food.images.map((image) => {
    return `${BASE_PATH}/${image}`;
  });
  return {
    id: food.id,
    image: `${BASE_PATH}/${food.images[0]}`,

    images: imagesPaths,
    name: food.title,
    price: food.price,
    description: food.description,
  };
}
