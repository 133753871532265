/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ECity } from './ECity';
import {
    ECityFromJSON,
    ECityFromJSONTyped,
    ECityToJSON,
} from './ECity';

/**
 * 
 * @export
 * @interface Sight
 */
export interface Sight {
    /**
     * Unique identifier for the Sight
     * @type {string}
     * @memberof Sight
     */
    id: string;
    /**
     * Rating of the Sight
     * @type {number}
     * @memberof Sight
     */
    rating: number;
    /**
     * Price of the Sight
     * @type {number}
     * @memberof Sight
     */
    price: number;
    /**
     * 
     * @type {ECity}
     * @memberof Sight
     */
    city: ECity;
    /**
     * Title of the Sight
     * @type {string}
     * @memberof Sight
     */
    title: string;
    /**
     * Description of the Sight
     * @type {string}
     * @memberof Sight
     */
    description: string;
    /**
     * Start date of the Season for the Sight, optional
     * @type {string}
     * @memberof Sight
     */
    startSeasonDate?: string;
    /**
     * End date of the Season for the Sight, optional
     * @type {string}
     * @memberof Sight
     */
    endSeasonDate?: string;
    /**
     * Name of the referent for the Sight
     * @type {string}
     * @memberof Sight
     */
    referentName: string;
    /**
     * Identifier of the location, optional
     * @type {string}
     * @memberof Sight
     */
    locationId?: string;
    /**
     * Identifier of the Yacht Route associated with the Sight
     * @type {string}
     * @memberof Sight
     */
    yachtRouteId: string;
}

/**
 * Check if a given object implements the Sight interface.
 */
export function instanceOfSight(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "rating" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "referentName" in value;
    isInstance = isInstance && "yachtRouteId" in value;

    return isInstance;
}

export function SightFromJSON(json: any): Sight {
    return SightFromJSONTyped(json, false);
}

export function SightFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sight {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'rating': json['rating'],
        'price': json['price'],
        'city': ECityFromJSON(json['city']),
        'title': json['title'],
        'description': json['description'],
        'startSeasonDate': !exists(json, 'startSeasonDate') ? undefined : json['startSeasonDate'],
        'endSeasonDate': !exists(json, 'endSeasonDate') ? undefined : json['endSeasonDate'],
        'referentName': json['referentName'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'yachtRouteId': json['yachtRouteId'],
    };
}

export function SightToJSON(value?: Sight | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rating': value.rating,
        'price': value.price,
        'city': ECityToJSON(value.city),
        'title': value.title,
        'description': value.description,
        'startSeasonDate': value.startSeasonDate,
        'endSeasonDate': value.endSeasonDate,
        'referentName': value.referentName,
        'locationId': value.locationId,
        'yachtRouteId': value.yachtRouteId,
    };
}

