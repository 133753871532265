import { Xmark } from "@gravity-ui/icons";
import { Notification } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BaseCard } from "../../components/BaseCard/BaseCard";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { NavigationText } from "../../components/NavigationText/NavigationText";
import { LoaderPageCustom } from "../../components/UI/Vendors/Mantine/LoaderPageCustom";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import { selectCurrentUser } from "../../store/userStore";
import fetchServicesList from "./api";
import { normalizeService } from "./definitions";
import "./style.css";

export const MainPage = () => {
  const currentUser = useSelector(selectCurrentUser);

  const { initData } = useTelegram();

  const navigate = useNavigate();

  const { t } = useTranslation(["main_page"]);

  const navigateToCard = useFn((card: { route: string }) => {
    navigate(`/${card.route}`);
  });

  const servicesQuery = useQuery({
    queryKey: ["services list", initData, currentUser.city],
    queryFn: fetchServicesList,
    select: (services) => normalizeService(services, currentUser.role === "ADMIN"),
    onError: (error) => {
      console.log(error);
    },
  });

  if (servicesQuery.isLoading || !servicesQuery.data) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }

  if (servicesQuery.error) {
    return (
      <Notification icon={<Xmark />} color="red" withCloseButton={false}>
        Failed to load services list. Please contact admins
      </Notification>
    );
  }
  const cardsList = servicesQuery.data?.map((card) => {
    return (
      <BaseCard
        key={card.title}
        card={{ ...card, id: "dd" }}
        onCardClick={() => {
          if (card.metrikaCallback) {
            card.metrikaCallback();
          }

          navigateToCard(card);
        }}
      ></BaseCard>
    );
  });

  return (
    <>
      <NavigationText
        title={t("Welcome") + ", " + currentUser.appName}
        subtitle=""
        arrow={{
          isShow: false,
          onClickFunction: undefined,
        }}
      ></NavigationText>
      {cardsList}
      <NavigationBar></NavigationBar>
    </>
  );
};
