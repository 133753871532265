import { userApi } from "../../apiDrivers/user";

export const updateUserAppNameRequest = async (initData: string, appName: string, userId: string) => {
  const res = await userApi.userSetAppNamePost({
    userSetAppNamePostRequest: {
      initData,
      userId,
      appName,
    },
  });

  return res;
};
