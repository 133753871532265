export const BoatImage = () => {
  return (
    <svg preserveAspectRatio="xMidYMid meet"  viewBox="0 0 321 320" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M309.471 155.682C306.153 183.78 293.324 216.449 267.701 231.457C228.986 254.138 232.227 289.022 156.661 304.187C144.88 306.552 133.699 307.548 123.156 307.351C117.489 307.246 112.002 306.789 106.711 306.01C58.6843 298.995 26.3649 265.612 14.507 225.314C-1.09682 172.316 30.0603 137.619 50.7125 116.714C71.3646 95.8087 86.4897 30.6721 160.659 15.1796C171.968 12.8225 183.533 11.6193 195.095 11.9079C221.484 12.5556 250.267 21.2206 270.184 39.307C271.68 40.6657 273.134 42.075 274.551 43.5265C302.234 71.9406 313.996 117.375 309.471 155.682Z"
        fill="#92D4E3"
      />
      <path
        opacity="0.7"
        d="M309.471 155.682C306.153 183.78 293.324 216.449 267.701 231.457C228.986 254.138 232.227 289.022 156.661 304.187C144.88 306.552 133.699 307.548 123.156 307.351C117.489 307.246 112.002 306.789 106.711 306.01C58.6843 298.995 26.3649 265.612 14.507 225.314C-1.09682 172.316 30.0603 137.619 50.7125 116.714C71.3646 95.8087 86.4897 30.6721 160.659 15.1796C171.968 12.8225 183.533 11.6193 195.095 11.9079C221.484 12.5556 250.267 21.2206 270.184 39.307C271.68 40.6657 273.134 42.075 274.551 43.5265C302.234 71.9406 313.996 117.375 309.471 155.682Z"
        fill="white"
      />
      <path
        d="M11.7806 211.703H20.6498V192.192H30.5826V203.898H36.2587V141.108H47.6104V173.035H59.3173V185.806H71.7333V172.326H81.6667V196.094H88.052V138.27H102.952V196.803H109.692V138.979H122.817V173.39H146.94V159.91H162.904V171.971H188.446V179.775H184.19V188.999H197.315V180.84H216.472V153.169H239.885V206.737H253.72V189.354H275.36L280.682 194.675H293.452V170.197H305.869V217.379"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.6104 177.292H57.1886"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.6104 181.549H57.1886"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.6104 185.806H57.1886"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.6104 190.063H57.1886"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.6104 194.32H57.1886"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.6104 198.577H57.1886"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.3873 144.3V203.898"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41.5797 144.3V203.898"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.7727 144.3V203.898"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M83.7953 210.639H29.8734V215.251H81.3121"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M78.1192 196.803H66.4124V209.574"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M146.586 177.292V211.703"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M162.905 171.971V212.767"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M216.472 180.84V199.287H196.96V194.32"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M239.176 161.329H223.921V163.812H239.176V161.329Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M239.176 168.778H223.921V171.262H239.176V168.778Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M239.176 176.228H223.921V178.711H239.176V176.228Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M239.176 183.678H223.921V186.161H239.176V183.678Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M239.176 191.127H223.921V193.611H239.176V191.127Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M239.176 198.577H223.921V201.06H239.176V198.577Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M260.106 212.767H264.008V205.672H294.162V209.929H301.257"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M296.645 174.455V200.351"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M299.838 174.455V200.351"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M303.031 174.455V200.351"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M169.002 182.126H165.033V185.806H169.002V182.126Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M175.618 182.126H171.649V185.806H175.618V182.126Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M182.234 182.126H178.265V185.806H182.234V182.126Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M169.002 189.964H165.033V193.644H169.002V189.964Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M175.618 189.964H171.649V193.644H175.618V189.964Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M182.234 189.964H178.265V193.644H182.234V189.964Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M169.002 197.802H165.033V201.482H169.002V197.802Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M175.618 197.802H171.649V201.482H175.618V197.802Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M182.234 197.802H178.265V201.482H182.234V197.802Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.2385 142.335H90.3271V145.61H93.2385V142.335Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M99.7889 142.335H96.8776V145.61H99.7889V142.335Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.2385 149.614H90.3271V152.889H93.2385V149.614Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M99.7889 149.614H96.8776V152.889H99.7889V149.614Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.2385 156.891H90.3271V160.166H93.2385V156.891Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M99.7889 156.891H96.8776V160.166H99.7889V156.891Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.2385 164.169H90.3271V167.444H93.2385V164.169Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M99.7889 164.169H96.8776V167.444H99.7889V164.169Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.2385 171.447H90.3271V174.722H93.2385V171.447Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M99.7889 171.447H96.8776V174.722H99.7889V171.447Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.2385 178.725H90.3271V181.999H93.2385V178.725Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M99.7889 178.725H96.8776V181.999H99.7889V178.725Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.2385 186.002H90.3271V189.277H93.2385V186.002Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M99.7889 186.002H96.8776V189.277H99.7889V186.002Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M93.2385 193.281H90.3271V196.556H93.2385V193.281Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M99.7889 193.281H96.8776V196.556H99.7889V193.281Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M122.714 179.453H142"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M122.714 186.73H142"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M122.714 194.008H142"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M122.714 201.286H142"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M122.714 208.564H142"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.6207 116.137C73.421 116.014 73.2175 115.902 73.0107 115.803C69.8312 114.289 67.2571 115.501 65.7429 117.772C64.2287 120.043 63.7743 121.255 62.7144 120.195C61.6546 119.135 59.9887 117.62 57.5663 118.983C55.1439 120.346 55.5976 121.709 54.2351 122.011C52.8725 122.314 51.9637 120.952 49.844 121.405C47.7243 121.86 46.5128 123.525 46.0584 123.828C45.604 124.131 41.9701 125.494 41.9701 125.494H89.0607C89.0607 125.494 88.3035 121.254 85.8811 120.043C83.4587 118.831 81.6418 120.951 80.2786 121.86C78.916 122.769 78.6127 122.011 78.6127 122.011C78.6127 122.011 77.3307 119.527 75.4075 117.59"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M241.331 61.9655C240.814 61.6461 240.286 61.3562 239.75 61.1015C231.508 57.177 224.837 60.3162 220.912 66.2035C216.988 72.0903 215.81 75.2301 213.063 72.4832C210.316 69.7357 205.998 65.8112 199.719 69.3434C193.44 72.8755 194.617 76.4077 191.085 77.1923C187.553 77.977 185.198 74.4448 179.703 75.6224C174.209 76.8 171.069 81.1168 169.891 81.9021C168.714 82.6867 159.295 86.2189 159.295 86.2189H281.351C281.351 86.2189 279.389 75.2301 273.109 72.0903C266.83 68.9504 262.121 74.4448 258.588 76.8C255.056 79.1546 254.272 77.1923 254.272 77.1923C254.272 77.1923 250.948 70.7533 245.964 65.7331"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M110.849 37.6148C110.513 37.4075 110.17 37.2193 109.823 37.0536C104.471 34.5051 100.139 36.5441 97.5906 40.3662C95.0421 44.1889 94.2779 46.2273 92.4936 44.4436C90.7099 42.66 87.9067 40.1115 83.8293 42.4046C79.7518 44.6984 80.5166 46.9915 78.2229 47.5016C75.9291 48.011 74.4002 45.7179 70.8322 46.482C67.2642 47.2462 65.2258 50.05 64.461 50.5595C63.6962 51.0689 57.5803 53.3627 57.5803 53.3627H136.835C136.835 53.3627 135.561 46.2273 131.484 44.1883C127.406 42.1492 124.348 45.7172 122.055 47.2462C119.761 48.7752 119.251 47.5009 119.251 47.5009C119.251 47.5009 117.093 43.3198 113.857 40.0603"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M274.017 126.66C273.681 126.452 273.338 126.264 272.991 126.098C267.639 123.55 263.307 125.589 260.759 129.411C258.21 133.234 257.445 135.272 255.662 133.489C253.878 131.705 251.075 129.156 246.997 131.45C242.92 133.743 243.685 136.036 241.391 136.546C239.097 137.056 237.568 134.763 234 135.527C230.433 136.292 228.394 139.095 227.629 139.604C226.864 140.114 220.748 142.408 220.748 142.408H300.003C300.003 142.408 298.729 135.272 294.652 133.233C290.574 131.194 287.516 134.762 285.223 136.291C282.929 137.82 282.419 136.546 282.419 136.546C282.419 136.546 280.261 132.365 277.025 129.105"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50.7849 95.4049C59.3849 95.4049 66.3567 88.4332 66.3567 79.8331C66.3567 71.233 59.3849 64.2612 50.7849 64.2612C42.1848 64.2612 35.213 71.233 35.213 79.8331C35.213 88.4332 42.1848 95.4049 50.7849 95.4049Z"
        fill="#92D4E3"
      />
      <path
        opacity="0.32"
        d="M50.7849 95.4049C59.3849 95.4049 66.3567 88.4332 66.3567 79.8331C66.3567 71.233 59.3849 64.2612 50.7849 64.2612C42.1848 64.2612 35.213 71.233 35.213 79.8331C35.213 88.4332 42.1848 95.4049 50.7849 95.4049Z"
        fill="white"
      />
      <path
        d="M50.7849 89.8747C56.3307 89.8747 60.8265 85.3789 60.8265 79.8331C60.8265 74.2873 56.3307 69.7915 50.7849 69.7915C45.2391 69.7915 40.7433 74.2873 40.7433 79.8331C40.7433 85.3789 45.2391 89.8747 50.7849 89.8747Z"
        fill="#92D4E3"
      />
      <path
        d="M34.0942 229.461L56.0878 229.462"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.755 231.913L43.1079 231.912"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.3931 236.113L35.8971 236.114"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.8292 235.764H51.7614"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M171.825 229.467L193.818 229.468"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M195.982 231.919L165.334 231.918"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M147.668 233.318L117.021 233.316"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M142.62 236.119L158.124 236.119"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M166.056 235.77L173.988 235.77"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M115.218 229.464L96.0238 229.463"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M156.321 229.466L119.46 229.465"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M80.6056 231.914L107.352 231.915"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.4814 252.573L79.2277 252.574"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M106.722 235.767H99.7998"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M193.975 246.276H187.052"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M91.5784 248.372H84.6561"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M291.167 229.472L313.161 229.473"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M315.324 231.924L284.677 231.923"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M58.6113 247.321L27.9642 247.319"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M230.954 245.578L200.307 245.576"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M213.287 253.98L182.64 253.979"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M267.01 233.323L236.363 233.321"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M261.962 236.124L277.466 236.125"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M285.398 235.774L293.33 235.775"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M293.329 251.883L301.262 251.883"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M234.561 229.469L215.367 229.469"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M275.663 229.471L238.802 229.47"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M293.69 243.829L256.829 243.827"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M312.438 259.237L263.318 259.235"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55.3659 259.226L6.24524 259.224"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.948 231.919L226.694 231.92"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M226.065 235.772H219.142"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.9845 286.814L88.8584 286.817"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M170.969 291.539L127.242 291.537"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M120.925 297.199L68.9377 297.197"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M244.821 292.013L285.147 292.015"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M241.419 296.732L211.781 296.73"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M226.357 300.978L158.336 300.975"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M199.15 285.877L220.527 285.878"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.2395 273.129L43.6737 273.13"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M298.673 225.017L312.547 225.018"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9233 225.005L294.049 225.017"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M143.2 70.585C143.2 70.585 121.02 116.193 107.774 156.34C96.4347 190.705 76.0021 255.592 76.0021 255.592C76.0021 255.592 99.0574 253.061 110.023 249.125C120.989 245.189 137.577 236.754 137.577 236.754C137.577 236.754 126.999 207.576 122.956 170.118C120.648 148.729 124.362 123.163 126.33 115.853C128.298 108.543 143.2 70.585 143.2 70.585Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M145.292 39.7407H147.426L240.154 253.627"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M144.606 47.811C144.606 47.811 155.852 103.2 168.786 140.033C181.72 176.866 208.993 238.16 208.993 238.16L138.305 239.176L144.606 47.811Z"
        fill="white"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M144.454 37.7329L137.134 254.109"
        stroke="#263238"
        stroke-width="2.56"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M138.305 239.176L213.261 238.297"
        stroke="#263238"
        stroke-width="2.56"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M231.548 259.909C231.548 259.909 222.058 257.755 220.423 257.476C218.787 257.197 216.953 257.237 216.754 257.516C216.555 257.795 216.515 258.313 216.634 258.313C216.754 258.313 219.625 258.154 220.542 258.433C221.459 258.712 231.15 260.786 231.15 260.786L231.548 259.909Z"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M231.552 244.309C231.552 244.309 230.373 244.897 230.046 245.487C229.719 246.076 229.719 247.516 229.654 247.777C229.589 248.039 229.589 248.236 229.916 248.366C230.243 248.497 230.243 248.497 230.243 248.497C230.243 248.497 230.177 249.806 230.57 250.002C230.962 250.199 231.879 250.002 231.879 250.002L232.271 250.722L234.889 249.02L234.365 247.646C234.365 247.646 235.216 246.141 234.692 245.225C234.169 244.309 232.533 244.047 231.552 244.309Z"
        fill="white"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M231.552 247.515C231.552 247.515 232.272 246.403 232.533 247.188C232.795 247.973 232.86 248.497 232.468 248.497C232.075 248.497 232.075 248.497 232.075 248.497"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M234.889 249.02L232.272 250.722C232.272 250.722 231.225 254.125 231.225 255.237C231.225 256.349 231.486 258.248 231.486 258.248L230.766 262.567L237.965 263.025C237.965 263.025 238.423 262.24 238.554 260.866C238.684 259.492 238.423 253.667 237.965 252.358C237.507 251.049 234.889 249.02 234.889 249.02Z"
        fill="#92D4E3"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M227.69 256.808L224.876 257.004C224.876 257.004 223.698 258.248 223.633 258.443C223.568 258.64 223.931 258.832 224.091 258.712C224.25 258.593 224.61 258.194 224.61 258.194C224.61 258.194 223.932 259.231 224.41 259.27C224.889 259.31 225.168 258.752 225.168 258.752C225.168 258.752 225.008 259.669 225.287 259.51C225.566 259.35 226.085 258.234 226.085 258.234L227.44 258.114L227.69 256.808Z"
        fill="white"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M233.384 251.573C233.384 251.573 232.992 252.031 232.664 253.013C232.337 253.995 232.009 257.332 232.009 257.332L227.69 256.809L227.428 258.772C227.428 258.772 233.645 259.688 233.972 259.688C234.299 259.688 236.066 254.06 236.066 254.06"
        fill="#92D4E3"
      />
      <path
        d="M233.384 251.573C233.384 251.573 232.992 252.031 232.664 253.013C232.337 253.995 232.009 257.332 232.009 257.332L227.69 256.809L227.428 258.772C227.428 258.772 233.645 259.688 233.972 259.688C234.299 259.688 236.066 254.06 236.066 254.06"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M235.855 262.82C235.855 262.82 235.815 261.783 235.376 261.345C234.938 260.906 231.708 259.59 231.07 259.471C230.432 259.351 228.438 262.422 228.438 262.422L235.855 262.82Z"
        fill="white"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M108.932 258.444C108.932 258.444 141.151 251.036 143.267 250.801C145.384 250.566 171.841 251.742 172.664 252.095C173.487 252.447 174.31 256.563 174.31 256.563C174.31 256.563 177.367 257.034 178.426 258.092C179.484 259.15 180.542 261.502 180.542 261.502L108.932 258.444Z"
        fill="#92D4E3"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M148.794 253.741L141.386 257.034L165.256 258.209L167.608 256.798V254.682L148.794 253.741Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.1598 255.043L86.066 277.003H240.559L241.33 263.133L73.1598 255.043Z"
        fill="#92D4E3"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M241.33 263.133H245.761L249.036 277.003H242.101L241.33 263.133Z"
        fill="white"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M250.975 252.741C250.975 252.741 249.887 252.128 249.75 253.013C249.614 253.897 249.683 256.007 249.41 256.551C249.138 257.096 248.865 257.299 248.865 257.299C248.865 257.299 243.694 256.074 243.558 256.483C243.422 256.891 243.558 257.231 243.898 257.231C244.239 257.231 250.158 258.864 250.566 259C250.975 259.136 251.247 258.932 251.519 258.932C251.791 258.932 252.199 259.273 252.063 259.545C251.927 259.817 251.315 260.225 251.315 260.225C251.315 260.225 249.41 259.477 248.934 260.157C248.458 260.837 247.505 263.831 247.913 263.9C248.321 263.968 251.452 264.104 251.519 264.444C251.587 264.784 251.791 265.328 251.383 265.328C250.975 265.328 250.566 265.465 250.566 265.465L250.022 276.76H254.309C254.309 276.76 254.105 275.739 253.697 275.331C253.288 274.922 252.812 274.31 252.812 274.31C252.812 274.31 253.425 261.654 253.493 261.109C253.56 260.565 253.969 259.884 254.309 259.749C254.65 259.612 258.596 258.932 258.8 258.864C259.004 258.796 259.208 255.666 259.072 255.19C258.936 254.714 258.12 253.217 257.847 253.148C257.576 253.081 250.975 252.741 250.975 252.741Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M246.008 257.708L241.33 263.133"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M246.961 258.252L245.761 263.133"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M240.154 262.864V253.627C240.154 252.967 239.618 252.431 238.958 252.431H235.857C235.433 252.431 235.041 252.656 234.826 253.022L229.157 262.676"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.1598 255.043L66.3656 241.042L170.901 249.508L234.826 253.022"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M82.5928 242.453L88.9422 255.74"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M125.747 245.98L125.864 257.386"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M170.901 249.508L170.547 259.973"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M240.56 277.003L240.755 273.481L82.7145 271.3L86.0662 277.003H240.56Z"
        fill="#263238"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M229.893 244.568C229.893 244.568 229.534 245.391 229.37 245.544C229.207 245.697 227.836 246.002 227.836 246.002C227.836 246.002 229.925 247.039 233.189 247.404C233.189 247.404 234.266 247.709 234.657 247.679C235.049 247.648 236.671 247.544 236.671 247.544C236.671 247.544 235.589 246.548 235.327 245.999C235.066 245.45 235.245 244.843 234.723 243.959C234.2 243.075 233.368 242.645 232.666 242.739C231.536 242.891 230.533 243.466 230.271 243.801C230.011 244.136 229.893 244.568 229.893 244.568Z"
        fill="#92D4E3"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M235.328 246.46C235.328 246.46 234.112 246.46 232.996 246.426C231.84 246.391 229.515 245.697 229.417 245.668L229.682 244.783C229.705 244.79 231.989 245.472 233.023 245.503C234.125 245.537 235.328 245.537 235.328 245.537V246.46H235.328Z"
        fill="#263238"
      />
      <path
        d="M305.532 277.25L294.609 276.335C294.609 276.335 291.852 276.106 288.67 275.42C285.489 274.733 285.701 275.42 284.004 275.877C282.307 276.335 280.186 275.877 277.429 275.19C274.672 274.504 271.279 275.42 268.098 276.106C264.916 276.792 262.159 275.877 258.13 274.504C254.1 273.131 252.827 275.419 249.01 275.648C245.192 275.877 242.647 273.132 236.708 271.758C230.77 270.385 230.133 273.818 223.983 276.106C217.833 278.394 215.712 272.445 210.622 271.53C206.038 270.705 198.8 276.156 197.405 277.25H305.532Z"
        fill="white"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M194.434 277.25L183.511 276.335C183.511 276.335 180.754 276.106 177.572 275.42C174.391 274.733 174.603 275.42 172.906 275.877C171.21 276.335 169.089 275.877 166.332 275.19C163.575 274.504 160.181 275.42 157 276.106C153.818 276.792 151.061 275.877 147.032 274.504C143.002 273.131 141.73 275.419 137.912 275.648C134.094 275.877 131.549 273.132 125.61 271.758C119.672 270.385 119.036 273.818 112.885 276.106C106.735 278.394 104.614 272.445 99.5234 271.53C94.9397 270.705 87.702 276.156 86.3062 277.25H194.434Z"
        fill="white"
        stroke="#263238"
        stroke-width="0.64"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
