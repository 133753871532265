/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExcursionOrder } from './ExcursionOrder';
import {
    ExcursionOrderFromJSON,
    ExcursionOrderFromJSONTyped,
    ExcursionOrderToJSON,
} from './ExcursionOrder';

/**
 * 
 * @export
 * @interface ExcursionsCreateExcursionOrderPostRequest
 */
export interface ExcursionsCreateExcursionOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExcursionsCreateExcursionOrderPostRequest
     */
    initData?: string;
    /**
     * 
     * @type {ExcursionOrder}
     * @memberof ExcursionsCreateExcursionOrderPostRequest
     */
    excursionOrder?: ExcursionOrder;
}

/**
 * Check if a given object implements the ExcursionsCreateExcursionOrderPostRequest interface.
 */
export function instanceOfExcursionsCreateExcursionOrderPostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExcursionsCreateExcursionOrderPostRequestFromJSON(json: any): ExcursionsCreateExcursionOrderPostRequest {
    return ExcursionsCreateExcursionOrderPostRequestFromJSONTyped(json, false);
}

export function ExcursionsCreateExcursionOrderPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExcursionsCreateExcursionOrderPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initData': !exists(json, 'initData') ? undefined : json['initData'],
        'excursionOrder': !exists(json, 'excursionOrder') ? undefined : ExcursionOrderFromJSON(json['excursionOrder']),
    };
}

export function ExcursionsCreateExcursionOrderPostRequestToJSON(value?: ExcursionsCreateExcursionOrderPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initData': value.initData,
        'excursionOrder': ExcursionOrderToJSON(value.excursionOrder),
    };
}

