import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setUserCity } from "../../../apiDrivers/user";
import { ECity } from "../../../client";
import { AddressMap } from "../../../components/AddressMap";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";
import { NavigationText } from "../../../components/NavigationText/NavigationText";
import { SelectCustom } from "../../../components/UI/Vendors/Mantine/SelectCustom";
import { CITIES } from "../../../definitions";
import { useFn } from "../../../hooks/useFn";
import { useTelegram } from "../../../hooks/useTelegram";
import { selectCurrentUser, SetUserCityAction } from "../../../store/userStore";

export const SettingsAddress = () => {
  const { initData } = useTelegram();

  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const { t } = useTranslation(["settings"]);

  const [city, setCity] = useState<ECity>(currentUser.city || ECity.Kalkan);

  const onKalkanClick = useFn(() => {
    onChangeCity(ECity.Kalkan);
  });

  const onKasClick = useFn(() => {
    onChangeCity(ECity.Kash);
  });

  const onChangeCity = useFn((city: ECity) => {
    setCity(city);

    setUserCity("", currentUser.id, city, initData);

    dispatch(SetUserCityAction(city));
  });

  const titlePage = t("Address");
  const subtitle = "";

  return (
    <>
      <NavigationText
        arrow={{
          isShow: true,
        }}
        title={titlePage}
        subtitle={subtitle}
      ></NavigationText>
      <>
        <SelectCustom onChange={onChangeCity} value={city} data={CITIES}></SelectCustom>
        <AddressMap onKalkanClick={onKalkanClick} onKashClick={onKasClick}></AddressMap>
      </>

      <NavigationBar></NavigationBar>
    </>
  );
};
