/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SeaTripsCreateSeaTripOrderPostRequest,
  SeaTripsSeaTripByIdSeaTripIdGet200Response,
  SeaTripsSeaTripsListGet200Response,
  SeaTripsSeaTripsOrdersOrderIdGet200Response,
  SeaTripsSeaTripsOrdersSetOrderStatusPostRequest,
  SeaTripsUserSeaTripsOrdersUserIdGet200Response,
} from '../models';
import {
    SeaTripsCreateSeaTripOrderPostRequestFromJSON,
    SeaTripsCreateSeaTripOrderPostRequestToJSON,
    SeaTripsSeaTripByIdSeaTripIdGet200ResponseFromJSON,
    SeaTripsSeaTripByIdSeaTripIdGet200ResponseToJSON,
    SeaTripsSeaTripsListGet200ResponseFromJSON,
    SeaTripsSeaTripsListGet200ResponseToJSON,
    SeaTripsSeaTripsOrdersOrderIdGet200ResponseFromJSON,
    SeaTripsSeaTripsOrdersOrderIdGet200ResponseToJSON,
    SeaTripsSeaTripsOrdersSetOrderStatusPostRequestFromJSON,
    SeaTripsSeaTripsOrdersSetOrderStatusPostRequestToJSON,
    SeaTripsUserSeaTripsOrdersUserIdGet200ResponseFromJSON,
    SeaTripsUserSeaTripsOrdersUserIdGet200ResponseToJSON,
} from '../models';

export interface SeaTripsCreateSeaTripOrderPostOperationRequest {
    seaTripsCreateSeaTripOrderPostRequest: SeaTripsCreateSeaTripOrderPostRequest;
}

export interface SeaTripsSeaTripByIdSeaTripIdGetRequest {
    initData: string;
    seaTripId: string;
}

export interface SeaTripsSeaTripsListGetRequest {
    initData: string;
}

export interface SeaTripsSeaTripsOrdersOrderIdGetRequest {
    initData: string;
    orderId: string;
}

export interface SeaTripsSeaTripsOrdersSetOrderStatusPostOperationRequest {
    seaTripsSeaTripsOrdersSetOrderStatusPostRequest: SeaTripsSeaTripsOrdersSetOrderStatusPostRequest;
}

export interface SeaTripsUserSeaTripsOrdersUserIdGetRequest {
    initData: string;
    userId: string;
}

/**
 * 
 */
export class SeaTripApi extends runtime.BaseAPI {

    /**
     * User creates request for sea trip
     */
    async seaTripsCreateSeaTripOrderPostRaw(requestParameters: SeaTripsCreateSeaTripOrderPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.seaTripsCreateSeaTripOrderPostRequest === null || requestParameters.seaTripsCreateSeaTripOrderPostRequest === undefined) {
            throw new runtime.RequiredError('seaTripsCreateSeaTripOrderPostRequest','Required parameter requestParameters.seaTripsCreateSeaTripOrderPostRequest was null or undefined when calling seaTripsCreateSeaTripOrderPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sea_trips/create_sea_trip_order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SeaTripsCreateSeaTripOrderPostRequestToJSON(requestParameters.seaTripsCreateSeaTripOrderPostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User creates request for sea trip
     */
    async seaTripsCreateSeaTripOrderPost(requestParameters: SeaTripsCreateSeaTripOrderPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.seaTripsCreateSeaTripOrderPostRaw(requestParameters, initOverrides);
    }

    /**
     * Returns Sea trip by id
     */
    async seaTripsSeaTripByIdSeaTripIdGetRaw(requestParameters: SeaTripsSeaTripByIdSeaTripIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SeaTripsSeaTripByIdSeaTripIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling seaTripsSeaTripByIdSeaTripIdGet.');
        }

        if (requestParameters.seaTripId === null || requestParameters.seaTripId === undefined) {
            throw new runtime.RequiredError('seaTripId','Required parameter requestParameters.seaTripId was null or undefined when calling seaTripsSeaTripByIdSeaTripIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sea_trips/sea_trip_by_id/{seaTripId}`.replace(`{${"seaTripId"}}`, encodeURIComponent(String(requestParameters.seaTripId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeaTripsSeaTripByIdSeaTripIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns Sea trip by id
     */
    async seaTripsSeaTripByIdSeaTripIdGet(requestParameters: SeaTripsSeaTripByIdSeaTripIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SeaTripsSeaTripByIdSeaTripIdGet200Response> {
        const response = await this.seaTripsSeaTripByIdSeaTripIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all available sea trips
     */
    async seaTripsSeaTripsListGetRaw(requestParameters: SeaTripsSeaTripsListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SeaTripsSeaTripsListGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling seaTripsSeaTripsListGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sea_trips/sea_trips_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeaTripsSeaTripsListGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns all available sea trips
     */
    async seaTripsSeaTripsListGet(requestParameters: SeaTripsSeaTripsListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SeaTripsSeaTripsListGet200Response> {
        const response = await this.seaTripsSeaTripsListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return sea trip order by id
     */
    async seaTripsSeaTripsOrdersOrderIdGetRaw(requestParameters: SeaTripsSeaTripsOrdersOrderIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SeaTripsSeaTripsOrdersOrderIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling seaTripsSeaTripsOrdersOrderIdGet.');
        }

        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling seaTripsSeaTripsOrdersOrderIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sea_trips/sea_trips_orders/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeaTripsSeaTripsOrdersOrderIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Return sea trip order by id
     */
    async seaTripsSeaTripsOrdersOrderIdGet(requestParameters: SeaTripsSeaTripsOrdersOrderIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SeaTripsSeaTripsOrdersOrderIdGet200Response> {
        const response = await this.seaTripsSeaTripsOrdersOrderIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update status for Sea Trip order
     */
    async seaTripsSeaTripsOrdersSetOrderStatusPostRaw(requestParameters: SeaTripsSeaTripsOrdersSetOrderStatusPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.seaTripsSeaTripsOrdersSetOrderStatusPostRequest === null || requestParameters.seaTripsSeaTripsOrdersSetOrderStatusPostRequest === undefined) {
            throw new runtime.RequiredError('seaTripsSeaTripsOrdersSetOrderStatusPostRequest','Required parameter requestParameters.seaTripsSeaTripsOrdersSetOrderStatusPostRequest was null or undefined when calling seaTripsSeaTripsOrdersSetOrderStatusPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/sea_trips/sea_trips_orders/set_order_status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SeaTripsSeaTripsOrdersSetOrderStatusPostRequestToJSON(requestParameters.seaTripsSeaTripsOrdersSetOrderStatusPostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update status for Sea Trip order
     */
    async seaTripsSeaTripsOrdersSetOrderStatusPost(requestParameters: SeaTripsSeaTripsOrdersSetOrderStatusPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.seaTripsSeaTripsOrdersSetOrderStatusPostRaw(requestParameters, initOverrides);
    }

    /**
     * Returns all user sea trips orders
     */
    async seaTripsUserSeaTripsOrdersUserIdGetRaw(requestParameters: SeaTripsUserSeaTripsOrdersUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SeaTripsUserSeaTripsOrdersUserIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling seaTripsUserSeaTripsOrdersUserIdGet.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling seaTripsUserSeaTripsOrdersUserIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/sea_trips/user_sea_trips_orders/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SeaTripsUserSeaTripsOrdersUserIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns all user sea trips orders
     */
    async seaTripsUserSeaTripsOrdersUserIdGet(requestParameters: SeaTripsUserSeaTripsOrdersUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SeaTripsUserSeaTripsOrdersUserIdGet200Response> {
        const response = await this.seaTripsUserSeaTripsOrdersUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
