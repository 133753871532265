/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Food } from './Food';
import {
    FoodFromJSON,
    FoodFromJSONTyped,
    FoodToJSON,
} from './Food';
import type { FoodOrderLookup } from './FoodOrderLookup';
import {
    FoodOrderLookupFromJSON,
    FoodOrderLookupFromJSONTyped,
    FoodOrderLookupToJSON,
} from './FoodOrderLookup';

/**
 * 
 * @export
 * @interface FoodFoodOrdersOrderIdGet200Response
 */
export interface FoodFoodOrdersOrderIdGet200Response {
    /**
     * 
     * @type {FoodOrderLookup}
     * @memberof FoodFoodOrdersOrderIdGet200Response
     */
    orderLookup: FoodOrderLookup;
    /**
     * 
     * @type {Array<Food>}
     * @memberof FoodFoodOrdersOrderIdGet200Response
     */
    orderItems: Array<Food>;
}

/**
 * Check if a given object implements the FoodFoodOrdersOrderIdGet200Response interface.
 */
export function instanceOfFoodFoodOrdersOrderIdGet200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orderLookup" in value;
    isInstance = isInstance && "orderItems" in value;

    return isInstance;
}

export function FoodFoodOrdersOrderIdGet200ResponseFromJSON(json: any): FoodFoodOrdersOrderIdGet200Response {
    return FoodFoodOrdersOrderIdGet200ResponseFromJSONTyped(json, false);
}

export function FoodFoodOrdersOrderIdGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodFoodOrdersOrderIdGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderLookup': FoodOrderLookupFromJSON(json['orderLookup']),
        'orderItems': ((json['orderItems'] as Array<any>).map(FoodFromJSON)),
    };
}

export function FoodFoodOrdersOrderIdGet200ResponseToJSON(value?: FoodFoodOrdersOrderIdGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderLookup': FoodOrderLookupToJSON(value.orderLookup),
        'orderItems': ((value.orderItems as Array<any>).map(FoodToJSON)),
    };
}

