/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Yacht } from './Yacht';
import {
    YachtFromJSON,
    YachtFromJSONTyped,
    YachtToJSON,
} from './Yacht';

/**
 * 
 * @export
 * @interface YachtsYachtListGet200Response
 */
export interface YachtsYachtListGet200Response {
    /**
     * 
     * @type {Array<Yacht>}
     * @memberof YachtsYachtListGet200Response
     */
    yachtsList?: Array<Yacht>;
}

/**
 * Check if a given object implements the YachtsYachtListGet200Response interface.
 */
export function instanceOfYachtsYachtListGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function YachtsYachtListGet200ResponseFromJSON(json: any): YachtsYachtListGet200Response {
    return YachtsYachtListGet200ResponseFromJSONTyped(json, false);
}

export function YachtsYachtListGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): YachtsYachtListGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'yachtsList': !exists(json, 'yachtsList') ? undefined : ((json['yachtsList'] as Array<any>).map(YachtFromJSON)),
    };
}

export function YachtsYachtListGet200ResponseToJSON(value?: YachtsYachtListGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'yachtsList': value.yachtsList === undefined ? undefined : ((value.yachtsList as Array<any>).map(YachtToJSON)),
    };
}

