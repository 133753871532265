import dayjs, { Dayjs } from "dayjs";

export const DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "HH:mm:ss";
export const TIME_FORMAT_HUMAN = "DD.MM.YYYY HH:mm:ss";
export const TIME_FORMAT_HUMAN_NO_SEC = "DD.MM.YYYY HH:mm";
export const TIME_FORMAT_SERVER = "YYYY-MM-DDTHH:mm:ssZZ";
export const TIME_FORMAT_SERVER_EXTENDED = "YYYY-MM-DDTHH:mm:ss.SSSZZ";
export const TIME_FORMAT_WITH_TIMEZONE = "DD.MM.YYYY HH:mm:ss [UTC]Z";

export function safeParseDate(date?: string | number | undefined): Dayjs {
  const parsed = dayjs(date, [TIME_FORMAT_SERVER]);
  return parsed.isValid() ? parsed : dayjs(date);
}

export function formatDateTime(dateTime: string | number | undefined, format: string = TIME_FORMAT_SERVER): string {
  return dateTime && dateTime !== "" ? safeParseDate(dateTime).format(format) : "–";
}
