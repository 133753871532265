import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RouterPath, assets } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import "./style.css";

interface IItemPage {
  optionItem?: {
    programme?: string;
    image: string;
    name: string;
    description: string;
    long_description?: string;
    price: number;
    count: number;
    id: string | number;
  };
}

export const ItemPage = ({
  optionItem = {
    // TODO: когда в будет ручка сделать дефолтную картинку
    // image: "No Image",
    image: assets.delivery_img,
    name: "No Name",
    description: "No description",
    price: 0,
    count: 0,
    id: 0,
  },
}: IItemPage): JSX.Element => {
  const { id, type } = useParams();
  const { initData } = useTelegram();

  const navigate = useNavigate();

  // const cartItems = useStoreCart();

  const item = [].filter((elem: { id: string | undefined }) => elem.id === id)[0] || optionItem;

  const [currentCounter, setCounter] = useState(0);
  const [value, setValue] = useState<Date | null>(null);
  const [isDatePicked, setIsDatePicked] = useState<boolean>(false);
  const [openedModal, { open, close }] = useDisclosure(false);
  const [openedCollapseProgram, toggleProgramm] = useDisclosure(false);
  const [openedCollapseFeatures, toggleFeatures] = useDisclosure(false);
  const [openedCollapseFacilities, toggleFacilities] = useDisclosure(false);
  const [openedCollapseService, toggleService] = useDisclosure(false);
  const [openedCollapseEntertainment, toggleEntertainment] = useDisclosure(false);

  const toOrderPage = useFn(() => {
    // TODO:  поменять как поймем систему с корзиной
    navigate(RouterPath.order.complete);
  });

  const toOrderListPage = useFn(() => {
    navigate(RouterPath.order.list);
  });

  const addItemToCart = useFn(() => {});

  // TODO: разбить на компоненты одноименные с типом, потенциально оставить данный компонент и в зависимости от типа
  switch (type) {
    case "delivery": {
      // <ItemDelivery id={id}></ItemDelivery>;
      if (!id) {
        throw Error("Error with navigate to undefined or ''-id");
      }
      navigate(RouterPath.delivery.menuById(id));
      break;
    }
    case "yachts": {
      break;
    }
    default: {
      break;
    }
  }

  return <></>;

  // return (
  //   <>
  //     <NavigationText
  //       arrow={{
  //         isShow: true,
  //       }}
  //       title={`Back`}
  //     ></NavigationText>
  //     <div className="ItemPage__content">
  //       <Flex className="ItemPage__header" justify="start" wrap="wrap">
  //         <Image className="ItemPage__image" height={200} radius="lg" src={item.image} alt="item image" />
  //         <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
  //           <Text className="mainCard__title" color="" weight={700}>
  //             {item.name} {type}
  //           </Text>
  //         </Group>
  //       </Flex>
  //       <Group position="apart" mt="md" mb="xs">
  //         <Text weight={400}>
  //           {item.description}
  //           Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus ipsa eveniet eos autem veniam perspiciatis
  //           neque necessitatibus est odit maiores! At harum dolores ducimus voluptatem consectetur explicabo animi fugit
  //           dignissimos! Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus ipsa eveniet eos autem veniam
  //           perspiciatis neque necessitatibus est odit maiores! At harum dolores ducimus voluptatem consectetur
  //           explicabo animi fugit dignissimos!
  //         </Text>
  //       </Group>
  //       {type !== "delivery" && (
  //         <>
  //           <Modal
  //             opened={openedModal}
  //             onClose={close}
  //             withCloseButton={true}
  //             centered
  //             title={item.name}
  //             transitionProps={{ transition: "rotate-left" }}
  //           >
  //             {item.long_description}
  //             Potter ipsum wand elf parchment wingardium. Me daily voldemort sing cabinet are padma. Sir half-blood
  //             mewing dungeons headless horntail parchment which. Vulture-hat spectacles betrayal letters cannot. Banquet
  //             gringotts clean tonight pear-tickle yew daisies fenrir. Snitch totalus stand roaring hedwig find harpies.
  //             Vulture-hat moon wool 20 12. Hexed alohamora granger together quidditch time-turner dobby us. Daisies
  //             frisbees crossbow ipsum years voldemort gargoyles yaxley us yer. Glasses hedwig hexed boggarts seven for.
  //             Ickle essence red prophet prefect’s my golden potter. Forest ludo grindylows find crossbow. To doe
  //             metamorphimagus ravenclaw’s vanishing werewolf snivellus and. Minerva doxycide in viktor chalice nose
  //             fawkes tears rock-cake. Ministry-of-magic so will giant world stan spells be feint. Dead.
  //           </Modal>
  //
  //           <Group position="apart" mt="md" mb="xs">
  //             <Text weight={700} onClick={open} color="violet" underline={true}>
  //               Read More
  //             </Text>
  //           </Group>
  //           {/* todo: заменить на яхты, когда появятся */}
  //           {type === "yachts" && (
  //             <>
  //               <Divider my="sm" />
  //               <Flex wrap={"wrap"} gap={"xl"}>
  //                 <Flex direction={"column"} gap={"lg"} style={{ flex: "0 0 50%" }}>
  //                   <Text weight={700} size={20} c={"#212529"}>
  //                     Included:
  //                   </Text>
  //                   <Flex direction={"column"} gap={"md"}>
  //                     <Text weight={400} size={16} c={"#495057"}>
  //                       Fuel
  //                     </Text>
  //                     <Text weight={400} size={16} c={"#495057"}>
  //                       Food
  //                     </Text>
  //                     <Text weight={400} size={16} c={"#495057"}>
  //                       Cleaning
  //                     </Text>
  //                   </Flex>
  //                 </Flex>
  //                 <Flex>
  //                   <Flex direction={"column"} gap={"lg"}>
  //                     <Text weight={700} size={20} c={"#212529"}>
  //                       Not Included:
  //                     </Text>
  //                     <Flex direction={"column"} gap={"md"}>
  //                       <Text weight={400} size={16} c={"#495057"}>
  //                         Transition Log
  //                       </Text>
  //                       <Text weight={400} size={16} c={"#495057"}>
  //                         Food
  //                       </Text>
  //                       <Text weight={400} size={16} c={"#495057"}>
  //                         Skipper
  //                       </Text>
  //                     </Flex>
  //                   </Flex>
  //                 </Flex>
  //               </Flex>
  //               <Divider my="sm" />
  //               <Text weight={700} size={20} c={"#212529"} mb={"md"}>
  //                 More Info about {item.name}
  //               </Text>
  //               <Flex direction={"column"} gap="xs">
  //                 <Flex justify={"space-between"} onClick={toggleProgramm.toggle}>
  //                   <Text weight={700} size={18} color="violet">
  //                     Tour Programm
  //                   </Text>
  //                   <ActionIcon radius="lg" variant="filled" color="violet">
  //                     {openedCollapseProgram && <ChevronUp color="white"></ChevronUp>}
  //                     {!openedCollapseProgram && <ChevronDown color="white"></ChevronDown>}
  //                   </ActionIcon>
  //                 </Flex>
  //                 <Collapse in={openedCollapseProgram}>
  //                   <Text weight={400}>
  //                     {item.programme}
  //                     Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio, dignissimos. Et, vitae. Ea sequi
  //                     architecto dolor rem, dignissimos porro similique cum provident placeat temporibus nisi illum eius
  //                     nemo officiis ullam.
  //                   </Text>
  //                 </Collapse>
  //                 <Flex justify={"space-between"} onClick={toggleFeatures.toggle}>
  //                   <Text weight={700} size={18} color="violet">
  //                     Features
  //                   </Text>
  //                   <ActionIcon radius="lg" variant="filled" color="violet">
  //                     {openedCollapseFeatures && <ChevronUp color="white"></ChevronUp>}
  //                     {!openedCollapseFeatures && <ChevronDown color="white"></ChevronDown>}
  //                   </ActionIcon>
  //                 </Flex>
  //                 <Collapse in={openedCollapseFeatures}>
  //                   <Text weight={400}>
  //                     {item.programme}
  //                     Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio, dignissimos. Et, vitae. Ea sequi
  //                     architecto dolor rem, dignissimos porro similique cum provident placeat temporibus nisi illum eius
  //                     nemo officiis ullam.
  //                   </Text>
  //                 </Collapse>
  //                 <Flex justify={"space-between"} onClick={toggleFacilities.toggle}>
  //                   <Text weight={700} size={18} color="violet">
  //                     Facilities
  //                   </Text>
  //                   <ActionIcon radius="lg" variant="filled" color="violet">
  //                     {openedCollapseFacilities && <ChevronUp color="white"></ChevronUp>}
  //                     {!openedCollapseFacilities && <ChevronDown color="white"></ChevronDown>}
  //                   </ActionIcon>
  //                 </Flex>
  //                 <Collapse in={openedCollapseFacilities}>
  //                   <Text weight={400}>
  //                     {item.programme}
  //                     Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio, dignissimos. Et, vitae. Ea sequi
  //                     architecto dolor rem, dignissimos porro similique cum provident placeat temporibus nisi illum eius
  //                     nemo officiis ullam.
  //                   </Text>
  //                 </Collapse>
  //                 <Flex justify={"space-between"} onClick={toggleService.toggle}>
  //                   <Text weight={700} size={18} color="violet">
  //                     Service on board
  //                   </Text>
  //                   <ActionIcon radius="lg" variant="filled" color="violet">
  //                     {openedCollapseService && <ChevronUp color="white"></ChevronUp>}
  //                     {!openedCollapseService && <ChevronDown color="white"></ChevronDown>}
  //                   </ActionIcon>
  //                 </Flex>
  //                 <Collapse in={openedCollapseService}>
  //                   <Text weight={400}>
  //                     {item.programme}
  //                     Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio, dignissimos. Et, vitae. Ea sequi
  //                     architecto dolor rem, dignissimos porro similique cum provident placeat temporibus nisi illum eius
  //                     nemo officiis ullam.
  //                   </Text>
  //                 </Collapse>
  //                 <Flex justify={"space-between"} onClick={toggleEntertainment.toggle}>
  //                   <Text weight={700} size={18} color="violet">
  //                     Entertainment
  //                   </Text>
  //                   <ActionIcon radius="lg" variant="filled" color="violet">
  //                     {openedCollapseEntertainment && <ChevronUp color="white"></ChevronUp>}
  //                     {!openedCollapseEntertainment && <ChevronDown color="white"></ChevronDown>}
  //                   </ActionIcon>
  //                 </Flex>
  //                 <Collapse in={openedCollapseEntertainment}>
  //                   <Text weight={400}>
  //                     {item.programme}
  //                     Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio, dignissimos. Et, vitae. Ea sequi
  //                     architecto dolor rem, dignissimos porro similique cum provident placeat temporibus nisi illum eius
  //                     nemo officiis ullam.
  //                   </Text>
  //                 </Collapse>
  //               </Flex>
  //             </>
  //           )}
  //           <Flex mt={30} direction={"column"} gap={"lg"} style={{ flex: "1 1 auto" }}>
  //             <Flex direction={"column"}>
  //               <Text weight={400} size={16} c={"#868E96"}>
  //                 Total
  //               </Text>
  //               <Text weight={700} size={34} c={"#212529"}>
  //                 {`40 200`}
  //               </Text>
  //             </Flex>
  //             <Flex direction={"column"} justify={"space-between"} gap={"md"}>
  //               <DatePickerInput
  //                 dropdownType="modal"
  //                 placeholder="Date"
  //                 value={value}
  //                 onChange={setValue}
  //                 onClick={() => setIsDatePicked(true)}
  //                 color="violet"
  //                 radius="lg"
  //                 size="lg"
  //                 c="#7048E8"
  //                 styles={(theme) => ({
  //                   input: {
  //                     "&": {
  //                       borderColor: theme.colors.violet[7],
  //                       color: theme.colors.violet[7],
  //                       wordBreak: "keep-all",
  //                     },
  //                     "&:focus": {
  //                       borderColor: theme.colors.violet[7],
  //                     },
  //                   },
  //                 })}
  //                 rightSection={<Calendar></Calendar>}
  //                 style={{ color: "#7048E8" }}
  //               />
  //               <Button fullWidth={true} onClick={toOrderPage} variant="filled" color="violet" radius="lg" size="xl">
  //                 Next step
  //               </Button>
  //             </Flex>
  //           </Flex>
  //         </>
  //       )}
  //       {type === "delivery" && (
  //         <>
  //           <Flex direction={"column"} gap={"lg"}>
  //             <Flex className="itemCounter" justify="center" gap="lg" align="center">
  //               <ActionIcon
  //                 size={50}
  //                 radius="lg"
  //                 variant="filled"
  //                 color="violet"
  //                 onClick={() => setCounter((sc: number) => (sc ? sc - 1 : 0))}
  //               >
  //                 <Minus></Minus>
  //               </ActionIcon>
  //               <PriceBadge
  //                 hasCounter={true}
  //                 currentCounter={currentCounter}
  //                 onIconClick={toOrderPage}
  //                 banknote="ECurrency.TRY"
  //                 price={100}
  //                 size="big"
  //               ></PriceBadge>
  //               <ActionIcon
  //                 size={50}
  //                 radius="lg"
  //                 variant="filled"
  //                 color="violet"
  //                 onClick={() => setCounter((sc: number) => sc + 1)}
  //               >
  //                 <Plus></Plus>
  //               </ActionIcon>
  //             </Flex>
  //             <Button fullWidth={true} onClick={toOrderListPage} variant="filled" color="violet" radius="lg" size="xl">
  //               Next step
  //             </Button>
  //           </Flex>
  //         </>
  //       )}
  //     </div>
  //     <NavigationBar mb={type === "delivery" ? "10vh" : "0vh"}>{type === "delivery" && <></>}</NavigationBar>
  //   </>
  // );
};
