/**
 * Предпочтительным ключем для перевода является сама фраза, чтобы
 *  в случае проблем с интернационализацией у пользователя оставалась возможность пользоваться сервисом
 */
export type TAppI18n = {
  welcome_page: Record<string, string>;
  main_page: Record<string, string>;
  navigation_bar: Record<string, string>;
  steps: Record<string, string>;
  transfer_page: Record<string, string>;
  auth_page: Record<string, string>;
  settings_page: Record<string, string>;
  delivery_menu_page: Record<string, string>;
  excursions_page: Record<string, string>;
  base_card: Record<string, string>;
  sea_trips_page: Record<string, string>;
  item_base: Record<string, string>;
  order_page: Record<string, string>;
  transfer_order_page_completed: Record<string, string>;
  order_completed_base: Record<string, string>;
  transfer_types: Record<string, string>;
  information_pages: Record<string, string>;
  order_list_page: Record<string, string>;
  settings: Record<string, string>;
  city: Record<string, string>;
};

export const en: TAppI18n = {
  welcome_page: {
    "Get started": "Get started",
    "Welcome to": "Welcome to",
    Continue: "Continue",
    "Choose your city": "Choose your city",
    "Now we work in the cities on the south coast of Turkey, so you can make your choice here.":
      "Now we work in the cities on the south coast of Turkey, so you can make your choice here.",
    Next: "Next",
  },
  main_page: {
    Welcome: "Welcome",
  },
  information_pages: {
    "Congratulations!": "Congratulations!",
    "To my orders": "To my orders",
  },
  settings_page: {
    "My Profile": "My Profile",
    "Your name": "Your name",
    "Check order list": "Check order list",
    "You can see your previous orders.": "You can see your previous orders.",
    Settings: "Settings",
    Language: "Language",
    "Change your city": "Change your city",
    Support: "Support",
    Feedback: "Feedback",
    "Write us in app": "Write us in app",
    PWA: "PWA",
    "Add icon of web application on the Home screen": "Add icon of web application on the Home screen",
    "Change language": "Change language",
    "Write us in Telegram": "Write us in Telegram",
    Orders: "Orders",
    City: "City",
    Submit: "Submit",
  },
  delivery_menu_page: {
    "Open in google maps": "Open in google maps",
    Menu: "Menu",
  },
  excursions_page: {
    Excursions: "Excursions",
    "Read More": "Read More",
    "No excursions found": "No excursions found",
    "Select a tour and specify the necessary data. The manager will contact you to confirm the order. A deposit will be required, the amount will be communicated by the manager. The main payment is made to the driver on the day of the tour. Once confirmed, track the status of the order in the ‘Orders’ section. If availability is confirmed, the manager will provide detailed instructions. If the service is unavailable, alternatives will be offered.":
      "Select a tour and specify the necessary data. The manager will contact you to confirm the order. A deposit will be required, the amount will be communicated by the manager. The main payment is made to the driver on the day of the tour. Once confirmed, track the status of the order in the ‘Orders’ section. If availability is confirmed, the manager will provide detailed instructions. If the service is unavailable, alternatives will be offered.",
  },
  base_card: {
    HISTORICAL: "HISTORICAL",
    NATURE: "NATURE",
    ACTION: "ACTION",
    DIVING: "DIVING",
    HIKING: "HIKING",
    EXTREME: "EXTREME",
    SPORT: "SPORT",
    Historical: "Historical",
    Nature: "Nature",
    Action: "Action",
    Diving: "Diving",
    Hiking: "Hiking",
    Extreme: "Extreme",
    Sport: "Sport",
    "By name": "By name",
    "By price": "By price",
    FISHING: "РЫБАЛКА",
    Fishing: "Рыбалка",
    REGULAR: "ОБЫЧНЫЙ",
    Regular: "Обычный",
    PARTY: "ВЕЧЕРИНКА",
    Party: "Вечеринка",
    SUNSET: "ЗАКАТ",
    Sunset: "Закат",
  },
  city: {
    Kalkan: "Kalkan",
    kalkan: "kalkan",
    Kash: "Kash",
    kash: "kash",
    Alania: "Alania",
    alania: "alania",
    Antalya: "Antalya",
    antalya: "antalya",
    Belek: "Belek",
    belek: "belek",
    Bodrum: "Bodrum",
    bodrum: "bodrum",
    Dalaman: "Dalaman",
    dalaman: "dalaman",
    Dalyan: "Dalyan",
    dalyan: "dalyan",
    Didim: "Didim",
    didim: "didim",
    Fethiye: "Fethiye",
    fethiye: "fethiye",
    Marmaris: "Marmaris",
    marmaris: "marmaris",
    Istanbul: "Istanbul",
    istanbul: "istanbul",
    Bursa: "Bursa",
    bursa: "bursa",
    Izmir: "Izmir",
    izmir: "izmir",
    Kushadasi: "Kushadasi",
    kushadasi: "kushadasi",
    Selcuk: "Selcuk",
    selcuk: "selcuk",
    Oludeniz: "Oludeniz",
    oludeniz: "oludeniz",
    Demre: "Demre",
    demre: "demre",
    Kekova: "Kekova",
    kekova: "kekova",
    Finike: "Finike",
    finike: "finike",
    Kemer: "Kemer",
    kemer: "kemer",
    Chamyova: "Chamyova",
    chamyova: "chamyova",
    Side: "Side",
    side: "side",
    Kappadocia: "Kappadocia",
    kappadocia: "kappadocia",
    Mersin: "Mersin",
    mersin: "mersin",
    Ankara: "Ankara",
    ankara: "ankara",
    Trabzon: "Trabzon",
    trabzon: "trabzon",
    Pamukkale: "Pamukkale",
    pamukkale: "pamukkale",
    Gochek: "Gochek",
    gochek: "gochek",
    Everywhere: "Everywhere",
    everywhere: "everywhere",
  },
  sea_trips_page: {
    "Read more": "Read more",
    "Sea Trips": "Sea Trips",
    "No sea trips found": "No sea trips found",
    "Select the sea walk and specify the necessary data. Manager link to you to confirm the order. Potrebut deposit, amount reported manager. Principal payment made to the driver on the day of the walk. After confirmation track the status of the order under ‘order’. If availability is confirmed manager provide detailed instructions. In case of inaccessibility of services will be offered alternative.":
      "Select the sea walk and specify the necessary data. Manager link to you to confirm the order. Potrebut deposit, amount reported manager. Principal payment made to the driver on the day of the walk. After confirmation track the status of the order under ‘order’. If availability is confirmed manager provide detailed instructions. In case of inaccessibility of services will be offered alternative",
  },
  item_base: {
    Back: "Back",
  },
  order_page: {
    "Dear customer, your request has been received and successfully created.":
      "Dear customer, your request has been received and successfully created.",
    "You order created": "You order created",
    Back: "Back",
    "Complete Order": "Complete Order",
    "Select your contact": "Select your contact",
    People: "People",
    "Additional items": "Additional items",
    "Payment method": "Payment method",
    "Passenger names": "Passenger names",
    Comment: "Comment",
    "You can enter flight number": "You can enter flight number",
    "Submit Order": "Submit Order",
    "Surname Patronymic Name, ...": "Surname Patronymic Name, ...",
    "Please enter the names of the passengers": "Please enter the names of the passengers",
    "Phone number": "Phone number",
    "@Your login": "@Your login",
    Children: "Children",
    count: "count",
    Adults: "Adults",
    "Baby seats": "Baby seats",
    Luggage: "Luggage",
    "Any questions ?": "Any questions ?",
    "Your Message": "Your Message",
    "Place an order, after its confirmation, the manager will tell you how to pay for the order. Specify your preferred payment method: cash, bank card, money transfer":
      "Place an order, after its confirmation, the manager will tell you how to pay for the order. Specify your preferred payment method: cash, bank card, money transfer",
  },
  transfer_order_page_completed: {
    "Route points": "Route points",
    "Start point": "Start point",
    "End point": "End point",
    "Additional Items": "Additional Items",
    count: "count",
    Luggage: "Luggage",
    "Transfer rout from": "Transfer rout from",
    to: "to",
    Transfer: "Transfer",
  },
  order_completed_base: {
    "Read more": "Read more",
    "Actual event date": "Actual event date",
    "Phone number": "Phone number",
    "Delivery date": "Delivery date",
    "Creation date": "Creation date",
    Date: "Date",
    Address: "Address",
    Children: "Children",
    Person: "Person",
    "Passenger names": "Passenger names",
    "Flight number": "Flight number",
    "Any questions ?": "Any questions ?",
    "Your comment": "Your comment",
    "Payment method": "Payment method",
    Cash: "Cash",
    "Bank card": "Bank card",
    "Total approximate price": "Total approximate price",
    Total: "Total",
    Submit: "Submit",
    "Cancel Order": "Cancel Order",
  },
  navigation_bar: {
    "Main page": "Main page",
    "Orders page": "Orders page",
    Cart: "Cart",
  },
  transfer_types: {
    SEDAN: "Sedan",
    MINIVAN: "Minivan",
    VIP: "VIP",
    MINIBUS: "Minibus",
    MIDIBUS: "Midibus",
    BIGBUS: "Bigbus",
  },
  transfer_page: {
    Transfer: "Transfer",
    SEDAN: "SEDAN",
    MINIVAN: "MINIVAN",
    VIP: "VIP",
    MINIBUS: "MINIBUS",
    MIDIBUS: "MIDIBUS",
    BIGBUS: "BIGBUS",
    "Adult price": "Adult price",
    "Kids price": "Kids price",
    "A transfer is an opportunity to book a car in advance from one place to another. You have to choose the date, type of car and number of passengers. After completing the order, you will be contacted by our operator, who will contact you by telegram.":
      "Select a route and specify the necessary data. A manager will contact you to confirm your order. No deposit is required. Once confirmed, track the status of the order in the ‘Orders’ section. If availability is confirmed, the manager will provide detailed instructions. If the service is unavailable, alternatives will be offered.",
    "Regular - 1-4 passangers": "Regular - 1-4 passangers",
    "Minivan - 1-8 passangers": "Minivan - 1-8 passangers",
    "VIP - 1-6 passangers": "VIP - 1-6 passangers",
    "Minibus - many passangers": "Minibus - many passangers",
    "Baby Seat": "Baby Seat",
    "Read More": "Read More",
    "Try again": "Try again",
    "Pick up start point": "Pick up start point",
    from: "from",
    "Pick up end point": "Pick up end point",
    to: "to",
    Total: "Total",
    Time: "Time",
    Date: "Date",
    "Next step": "Next step",
    "Error witch allow to geolocation. The approximate location is shown":
      "Error witch allow to geolocation. The approximate location is shown",
    "Geolocation could not be determined": "Geolocation could not be determined",
    "Route was successfully built": "Route was successfully built",
    "Error. Couldn't set a route, select other points": "Error. Couldn't set a route, select other points",
    Copied: "Copied",
    Copy: "Copy",
    Position: "Position",
    "Your current location": "Your current location",
    Address: "Address",
  },
  auth_page: {
    "Open Travolza Bot in Tg": "Open Travolza Bot in Tg",
    "Add application to Home screen": "Add application to Home screen",
  },
  steps: {
    "Lets’s explore \n Turkey together!": "Lets’s explore \n Turkey together!",
    "Discovering the treasures of Turkey with this Telegram application":
      "Discovering the treasures of Turkey with this Telegram application",
    "Choose among trusted travel service providers": "Choose among trusted travel service providers",
    "We've put together the best deals with easy navigation through the cities":
      "We've put together the best deals with easy navigation through the cities",
    "Book services in advanced or already on site": "Book services in advanced or already on site",
    "Explore ratings and descriptions, see photos, compare prices":
      "Explore ratings and descriptions, see photos, compare prices",
    "See prices in different currencies": "See prices in different currencies",
    "Pay with cash in dollars, euros, pounds and lira or by card transfer":
      "Pay with cash in dollars, euros, pounds and lira or by card transfer",
    "Kalkan, Turkey": "Kalkan, Turkey",
    "Is it your current location?You can always switch the city in the settings later.":
      "Is it your current location?You can always switch the city in the settings later.",
    "Please log in to continue": "Please log in to continue",
    "Only Telegram users can use the app at the moment": "Only Telegram users can use the app at the moment",
    "We are already working on adding new authorization methods":
      "We are already working on adding new authorization methods",
  },
  order_list_page: {
    "My orders": "My orders",
  },
  settings: {
    Language: "Language",
    Address: "Address",
    Support: "Support",
    "Your message sent": "Your message sent",
    "Submit a message": "Submit a message",
    "Your Message": "Your Message",
    "Type your problem here ": "Type your problem here ",
  },
};

export const ru: TAppI18n = {
  welcome_page: {
    "Get started": "Начать",
    "Welcome to": "Добро пожаловать в",
    Continue: "Продолжить",
    "Choose your city": "Выберите город",
    "Now we work in the cities on the south coast of Turkey, so you can make your choice here.":
      "Сейчас мы работаем в городах на южном побережье Турции, поэтому сделайте свой выбор здесь.",
    Next: "Далее",
  },
  main_page: {
    Welcome: "Добро пожаловать",
  },
  delivery_menu_page: {
    "Open in google maps": "Открыть в google maps",
    Menu: "Меню",
  },
  settings_page: {
    "My Profile": "Мой профиль",
    "Your name": "Ваше имя",
    "Check order list": "Проверить список заказов",
    "You can see your previous orders.": "Вы можете посмотреть свои предыдущие заказы.",
    Settings: "Настройки",
    Language: "Язык",
    "Change your city": "Изменить город",
    Support: "Поддержка",
    Feedback: "Обратная связь",
    "Write us in app": "Напишите нам в приложении",
    PWA: "PWA",
    "Add icon of web application on the Home screen": "Добавить иконку веб-приложения на Экран",
    "Change language": "Изменить язык",
    "Write us in Telegram": "Напишите нам в Telegram",
    Orders: "Заказы",
    City: "Город",
    Submit: "Отправить",
  },
  navigation_bar: {
    "Main page": "Главная",
    "Orders page": "Заказы",
    Cart: "Корзина",
  },
  excursions_page: {
    Excursions: "Экскурсии",
    "Read More": "Подробнее",
    "No excursions found": "Экскурсии не найдены",
    "Select a tour and specify the necessary data. The manager will contact you to confirm the order. A deposit will be required, the amount will be communicated by the manager. The main payment is made to the driver on the day of the tour. Once confirmed, track the status of the order in the ‘Orders’ section. If availability is confirmed, the manager will provide detailed instructions. If the service is unavailable, alternatives will be offered.":
      "Выберите тур и укажите необходимые данные. Менеджер свяжется с вами для подтверждения заказа. Потребуется депозит, сумму сообщит менеджер. Основная оплата производится водителю в день экскурсии. После подтверждения отслеживайте статус заказа в разделе `Заказы`. Если доступность подтвердится, менеджер предоставит подробные инструкции. В случае недоступности услуги будут предложены альтернативы.",
  },
  base_card: {
    HISTORICAL: "ИСТОРИЧЕСКИЙ",
    NATURE: "ПРИРОДА",
    ACTION: "ЭКШН",
    DIVING: "ДАЙВИНГ",
    HIKING: "ТУРИЗМ",
    EXTREME: "ЭКСТРЕМАЛЬНЫЙ",
    SPORT: "СПОРТ",
    Historical: "Historical",
    Nature: "Исторический",
    Action: "Природа",
    Diving: "Экшн",
    Hiking: "Туризм",
    Extreme: "Экстремальный",
    Sport: "Спорт",
    "By name": "По названию",
    "By price": "По цене",
    FISHING: "FISHING",
    Fishing: "Fishing",
    REGULAR: "REGULAR",
    Regular: "Regular",
    PARTY: "PARTY",
    Party: "Party",
    SUNSET: "SUNSET",
    Sunset: "Sunset",
  },
  city: {
    Kalkan: "Калкан",
    kalkan: "калкан",
    Kash: "Каш",
    kash: "каш",
    Alania: "Алания",
    alania: "алания",
    Antalya: "Анталья",
    antalya: "анталья",
    Belek: "Белек",
    belek: "белек",
    Bodrum: "Бодрум",
    bodrum: "бодрум",
    Dalaman: "Даламан",
    dalaman: "даламан",
    Dalyan: "Далян",
    dalyan: "далян",
    Didim: "Дидим",
    didim: "дидим",
    Fethiye: "Фетхие",
    fethiye: "фетхие",
    Marmaris: "Мармарис",
    marmaris: "мармарис",
    Istanbul: "Стамбул",
    istanbul: "стамбул",
    Bursa: "Бурса",
    bursa: "бурса",
    Izmir: "Измир",
    izmir: "измир",
    Kushadasi: "Кушадасы",
    kushadasi: "кушадасы",
    Selcuk: "Сельчук",
    selcuk: "сельчук",
    Oludeniz: "Олюдениз",
    oludeniz: "олюдениз",
    Demre: "Демре",
    demre: "демре",
    Kekova: "Кекова",
    kekova: "кекова",
    Finike: "Финике",
    finike: "финике",
    Kemer: "Кемер",
    kemer: "кемер",
    Chamyova: "Чамюва",
    chamyova: "чамюва",
    Side: "Сиде",
    side: "сиде",
    Kappadocia: "Каппадокия",
    kappadocia: "каппадокия",
    Mersin: "Мерсин",
    mersin: "мерсин",
    Ankara: "Анкара",
    ankara: "анкара",
    Trabzon: "Трабзон",
    trabzon: "трабзон",
    Pamukkale: "Памуккале",
    pamukkale: "памуккале",
    Gochek: "Гечек",
    gochek: "гечек",
    Everywhere: "Везде",
    everywhere: "везде",
  },
  sea_trips_page: {
    "Read More": "Подробнее",
    "Sea Trips": "Морские прогулки",
    "No sea trips found": "Морские прогулки не найдены",
    "Select the sea walk and specify the necessary data. Manager link to you to confirm the order. Potrebut deposit, amount reported manager. Principal payment made to the driver on the day of the walk. After confirmation track the status of the order under ‘order’. If availability is confirmed manager provide detailed instructions. In case of inaccessibility of services will be offered alternative.": `Выберите морскую прогулку и укажите необходимые данные. Менеджер свяжется с вами для подтверждения заказа. Потребуется депозит, сумму сообщит менеджер. Основная оплата производится водителю в день прогулки. После подтверждения отслеживайте статус заказа в разделе "Заказы". Если доступность подтвердится, менеджер предоставит подробные инструкции. В случае недоступности услуги будут предложены альтернативы.`,
  },
  item_base: {
    Back: "Назад",
  },
  order_page: {
    "Dear customer, your request has been received and successfully created.":
      "Уважаемый клиент, ваш запрос был получен и успешно создан.",
    "You order created": "Ваш заказ создан",
    Back: "Назад",
    "Complete Order": "Завершить заказ",
    "Select your contact": "Выберите контакт",
    People: "Люди",
    "Additional items": "Дополнительные услуги",
    "Payment method": "Способ оплаты",
    "Passenger names": "Имена пассажиров",
    Comment: "Комментарий",
    "You can enter flight number": "Вы можете ввести номер рейса",
    "Submit Order": "Отправить заказ",
    "Surname Patronymic Name, ...": "Фамилия Имя Отчество, ...",
    "Please enter the names of the passengers": "Пожалуйста, введите имена пассажиров",
    "Phone number": "Номер телефона",
    "@Your login": "@Ваш логин",
    Children: "Дети",
    count: "количество",
    Adults: "Взрослые",
    "Baby seats": "Детские кресла",
    Luggage: "Багаж",
    "Any questions ?": "Есть вопросы ?",
    "Your Message": "Ваше сообщение",
    "Place an order, after its confirmation, the manager will tell you how to pay for the order. Specify your preferred payment method: cash, bank card, money transfer":
      "Оформите заказ, после его подтверждения менеджер вам расскажет как оплатить заказ. Укажите предпочтительный способ оплаты: кэш, банковской картой, переводом",
  },
  transfer_order_page_completed: {
    "Route points": "Точки маршрута",
    "Start point": "Начальная точка",
    "End point": "Конечная точка",
    "Additional Items": "Дополнительные услуги",
    count: "количество",
    Luggage: "Багаж",
    "Transfer rout from": "Трансфер из",
    to: "в",
    Transfer: "Трансфер",
    "Baby seats": "Детские кресла",
  },
  order_completed_base: {
    "Read More": "Подробнее",
    "Actual event date": "Фактическая дата события",
    "Phone number": "Номер телефона",
    "Delivery date": "Дата доставки",
    "Creation date": "Дата создания",
    Date: "Дата",
    Address: "Адрес",
    Children: "Дети",
    Person: "Человек",
    "Passenger names": "Имена пассажиров",
    "Flight number": "Номер рейса",
    "Any questions ?": "Есть вопросы ?",
    "Your comment": "Ваш комментарий",
    "Payment method": "Способ оплаты",
    Cash: "Наличные",
    "Bank card": "Банковская карта",
    "Total approximate price": "Общая приблизительная цена",
    Total: "Итого",
    Submit: "Отправить",
    "Cancel Order": "Отменить заказ",
  },
  transfer_page: {
    Transfer: "Трансфер",
    SEDAN: "Седан",
    MINIVAN: "Минивэн",
    VIP: "ВИП",
    MINIBUS: "Микроавтобус",
    MIDIBUS: "Средний автобус",
    BIGBUS: "Большой автобус",
    "Adult price": "Цена для взрослых",
    "Kids price": "Цена для детей",
    "A transfer is an opportunity to book a car in advance from one place to another. You have to choose the date, type of car and number of passengers. After completing the order, you will be contacted by our operator, who will contact you by telegram.":
      'Выберите маршрут и укажите необходимые данные. Менеджер свяжется с вами для подтверждения заказа. Депозит не требуется. После подтверждения отслеживайте статус заказа в разделе "Заказы". Если доступность подтвердится, менеджер предоставит подробные инструкции. В случае недоступности услуги будут предложены альтернативы.',
    "Regular - 1-4 passangers": "Обычный - 1-4 пассажира",
    "Minivan - 1-8 passangers": "Минивэн - 1-8 пассажиров",
    "VIP - 1-6 passangers": "VIP - 1-6 пассажиров",
    "Minibus - many passangers": "Микроавтобус - много пассажиров",
    "Baby Seat": "Детское сиденье",
    "Read More": "Подробнее",
    "Try again": "Повторите попытку",
    "Pick up start point": "Выберите начальную точку",
    from: "от",
    "Pick up end point": "Выберите конечную точку",
    to: "до",
    Total: "Total",
    Time: "Время",
    Date: "Дата",
    "Next step": "К следующему шагу",
    "Error witch allow to geolocation. The approximate location is shown":
      "Ошибка, связанная с разрешением на геолокацию. Показано приблизительное местоположение",
    "Geolocation could not be determined": "Геолокацию определить не удалось",
    "Route was successfully built": "Маршрут был успешно построен",
    "Error. Couldn't set a route, select other points": "Ошибка. Не удалось задать маршрут, выберите все точки",
    Copied: "Cкопировано",
    Copy: "Копировать",
    Position: "Точка",
    "Your current location": "Ваше текущее местоположение",
    Address: "Адрес",
  },
  auth_page: {
    "Open Travolza Bot in Tg": "Открыть Travolza Bot в Tg",
    "Add application to Home screen": "Добавьте приложение на главный экран",
  },
  transfer_types: {
    SEDAN: "Седан",
    MINIVAN: "Минивен",
    VIP: "VIP",
    MINIBUS: "Мини-автобус",
    MIDIBUS: "Мидибус",
    BIGBUS: "Большой автобус",
  },
  steps: {
    "Lets’s explore \n Turkey together!": "Давай исследовать Турцию вместе!",
    "Discovering the treasures of Turkey with this Telegram application":
      "Откройте для себя сокровища Турции с помощью нашего приложения",
    "Choose among trusted travel service providers": "Проверенные туристические сервисы",
    "We've put together the best deals with easy navigation through the cities":
      "Мы собрали лучшие предложения c удобной навигацией по городам",
    "Book services in advanced or already on site": "Заказывай услуги заранее или уже на месте",
    "Explore ratings and descriptions, see photos, compare prices":
      "Изучай рейтинг и описание, смотри фотографии, сравнивай цены",
    "See prices in different currencies": "Смотри цены в разной валюте",
    "Pay with cash in dollars, euros, pounds and lira or by card transfer":
      "Оплачивай наличными в долларах, евро, фунтах и лирах или переводом на карту",
    "Kalkan, Turkey": "Калкан, Турция",
    "Is it your current location?You can always switch the city in the settings later.":
      "Это твоё текущее место назначение? Ты всегда можешь изменить город в настройках позже.",
    "Please log in to continue": "Пожалуйста, войдите, чтобы продолжить",
    "Only Telegram users can use the app at the moment":
      "На данный момент приложение доступно только пользователям Telegram",
    "We are already working on adding new authorization methods":
      "Мы уже работаем над добавлением новых методов авторизации",
  },
  information_pages: {
    "Congratulations!": "Поздравляем!",
    "To my orders": "К моим заказам",
  },
  order_list_page: {
    "My orders": "Мои заказы",
  },
  settings: {
    Language: "Язык",
    Address: "Адрес",
    Support: "Поддержка",
    "Your message sent": "Ваше сообщение отправлено",
    "Submit a message": "Отправить сообщение",
    "Your Message": "Ваше сообщение",
    "Введите вашу проблему здесь ": "Type your problem here ",
  },
};
