/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Excursion } from './Excursion';
import {
    ExcursionFromJSON,
    ExcursionFromJSONTyped,
    ExcursionToJSON,
} from './Excursion';

/**
 * 
 * @export
 * @interface ExcursionsExcursionsListGet200Response
 */
export interface ExcursionsExcursionsListGet200Response {
    /**
     * 
     * @type {Array<Excursion>}
     * @memberof ExcursionsExcursionsListGet200Response
     */
    excursionsList?: Array<Excursion>;
}

/**
 * Check if a given object implements the ExcursionsExcursionsListGet200Response interface.
 */
export function instanceOfExcursionsExcursionsListGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExcursionsExcursionsListGet200ResponseFromJSON(json: any): ExcursionsExcursionsListGet200Response {
    return ExcursionsExcursionsListGet200ResponseFromJSONTyped(json, false);
}

export function ExcursionsExcursionsListGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExcursionsExcursionsListGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'excursionsList': !exists(json, 'excursionsList') ? undefined : ((json['excursionsList'] as Array<any>).map(ExcursionFromJSON)),
    };
}

export function ExcursionsExcursionsListGet200ResponseToJSON(value?: ExcursionsExcursionsListGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'excursionsList': value.excursionsList === undefined ? undefined : ((value.excursionsList as Array<any>).map(ExcursionToJSON)),
    };
}

