/* eslint-disable no-irregular-whitespace */
import React from "react";
import { ArrowRight } from "@gravity-ui/icons";
import { Anchor, Box, Flex, Image, Text, Title } from "@mantine/core";

import "./style.css";

// @ts-ignore
import Frame from "./Frame.svg";

// @ts-ignore
import mobile from "./mobile.svg";

// @ts-ignore
import qr from "./qr.svg";

// @ts-ignore
import tg from "./tg.svg";

import { ButtonCustom } from "../../components/UI/Vendors/Mantine";
import { useFn } from "../../hooks/useFn";

export const Landing = () => {
  const navigateToBot = useFn(() => {
    window.location.replace("https://t.me/travolza_bot");
  });

  return (
    <Flex justify={"center"} gap={38} className={"media"}>
      <Box
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        p={20}
        maw={960}
        miw={600}
      >
        <img src={Frame} className={"image"}></img>
      </Box>
      <Box
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        p={20}
        maw={880}
      >
        <Flex direction={"column"} gap={60}>
          <Flex direction={"column"} maw={880} gap={32}>
            <Title order={1} className={"heading"} maw={720}>
              Туристический маркетплейс в вашем кармане
            </Title>
            <Flex direction={"column"} gap={20}>
              <Text
                mt={16}
                className={"text"}
                style={{
                  lineHeight: "",
                }}
                maw={720}
              >
                Исследуйте Турцию с нашим удобным PWA и Telegram приложением, где вы можете бронировать экскурсии
                и морские прогулки, заказывать трансферы и арендовать яхты в различных городах страны.
              </Text>
              <Text className={"text"} mt={8}>
                Всё в одном приложении с удобной навигацией по городам Турции.
              </Text>
            </Flex>
            <ButtonCustom
              children={<>Подробнее</>}
              // className="button"
              size={"lg"}
              maw={180}
              rightIcon={<ArrowRight />}
              onClick={navigateToBot}
              theme={"travolza"}
            ></ButtonCustom>
          </Flex>

          <Flex direction={"column"} maw={880} gap={32}>
            <Text
              mt={16}
              className={"text"}
              style={{
                lineHeight: "",
              }}
              maw={620}
            >
              Сервис доступен только на мобильных телефонах. Вы можете воспользоваться им следующими способами:
            </Text>
            <Flex justify={"space-between"} className="vertical-sections">
              <Flex justify={"center"} align={"center"}>
                <Flex direction={"column"} maw={200} p={8}>
                  <Title align={"center"} order={6} className={"section-heading"} mb={10}>
                    Способ №1
                  </Title>
                  <Image className={"section-image"} width={"96"} h={96} src={mobile} alt="Mobile" mb={16}></Image>
                  <Text align={"center"} size={"sm"} className={"font"}>
                    Откройте сайт <Anchor href={"https://travolza.com"}>travolza.com </Anchor> в мобильном браузере
                  </Text>
                </Flex>
              </Flex>
              <Flex>
                <Flex direction={"column"} maw={200} p={8}>
                  <Title align={"center"} order={6} className={"section-heading"} mb={10}>
                    Способ №2
                  </Title>
                  <Image className={"section-image"} width={"96"} h={96} src={qr} alt="QR" mb={16}></Image>

                  <Text align={"center"} size={"sm"} className={"font"}>
                    Отсканируйте QR-код с помощью камеры
                  </Text>
                </Flex>
              </Flex>
              <Flex>
                <Flex direction={"column"} maw={200} p={8}>
                  <Title align={"center"} order={6} className={"section-heading"} mb={10}>
                    Способ №3
                  </Title>
                  <Image className={"section-image"} width={"96"} h={96} src={tg} alt="Telegram" mb={16}></Image>

                  <Text align={"center"} size={"sm"} className={"font"}>
                    Запустите <Anchor href={"https://t.me/travolza_bot"}>@travolza_bot </Anchor> в приложении Telegram
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
