import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L, { LatLngBoundsExpression } from "leaflet";
import { customIcon } from "../PointPickerMap/PointPickerMap";
import { externalApis } from "../../../externalAPIs/definitions";
import { BASE_PATH, ECity } from "../../../client";
import { TCity } from "../../../definitions";
import { markerDefault, markerSelected } from "../constants";

type CityMarkersMapProps = {
  cities: TCity[];
  selectedCity: string;
  onCityClick: (city: ECity) => void;
};

type MarkerAdjustViewProps = {
  city: TCity;
};

export const defaultMarker = L.icon(markerDefault);

export const selectedMarker = L.icon(markerSelected);

export const CityMarkersMap: React.FC<CityMarkersMapProps> = ({ cities, selectedCity, onCityClick }) => {
  const start = useRef<string>(selectedCity);

  const bounds: LatLngBoundsExpression = cities.map((city) => [city.coordinates.lat, city.coordinates.lng]);

  const MarkerAdjustView: React.FC<MarkerAdjustViewProps> = ({ city }) => {
    const map = useMap();

    useEffect(() => {
      if (start.current !== selectedCity && city.value === selectedCity) {
        map.flyTo([city.coordinates.lat, city.coordinates.lng], 14, {
          animate: true,
          duration: 1.5,
        });
      }
    }, [selectedCity, city]);

    return null;
  };

  const ChangeMapView: React.FC<{ bounds: LatLngBoundsExpression }> = ({ bounds }) => {
    const map = useMap();
    map.fitBounds(bounds);
    return null;
  };

  return (
    <MapContainer
      style={{ height: "50vh", width: "100%" }}
      // whenCreated={map => map.fitBounds(bounds)}
      attributionControl={false}
    >
      <TileLayer url={externalApis.mapbox} id="mapbox/streets-v11" />
      {cities.map((city, idx) => {
        const icon = city.value === selectedCity ? selectedMarker : defaultMarker;
        return (
          <Marker
            key={`city-marker-${idx}`}
            position={city.coordinates}
            icon={icon}
            eventHandlers={{
              click: () => onCityClick(city.value),
            }}
          >
            <Popup>{city.label}</Popup>
            <MarkerAdjustView city={city} />
          </Marker>
        );
      })}
      {cities.length > 0 && <ChangeMapView bounds={bounds} />}
    </MapContainer>
  );
};
