import { Button, Flex, Group, Modal, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import ReactMarkdown from "react-markdown";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { LoaderPageCustom } from "../../components/UI/Vendors/Mantine/LoaderPageCustom";
import { EColors, ECurrency, RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import { ItemBase } from "../../modules/Items/ItemBase";
import { selectExcursionOrderById } from "../../store/orders/excursionOrders";
import fetchExcursionById from "./api";
import { ExcursionLookup, normalizeExcursionById } from "./definitions";
import { EOrderPageIsType, OrderPageExcursion } from "../OrderPage/definitions";
import { selectCurrentUser } from "../../store/userStore";
import { formatDateTime } from "../../utils/date";

import "./style.css";
import {
  reachGoalExcursionDateChoose,
  reachGoalExcursionNextStep,
  reachGoalExcursionsItemOpen,
} from "../../utils/metrika";

export const ExcursionItemPage = () => {
  const { initData } = useTelegram();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation(["transfer_page"]);

  const currentUser = useSelector(selectCurrentUser);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [openedModal, { open, close }] = useDisclosure(false);

  const excursionByIdQuery = useQuery({
    queryKey: [`excursion${id}`, initData, id],
    queryFn: fetchExcursionById,
    onError: (error) => {
      console.error(error);
    },
    select: useFn((data) => normalizeExcursionById(data, currentUser.language)),
  });

  const toOrderPage = useFn(() => {
    reachGoalExcursionNextStep();
    navigate(RouterPath.order.complete, {
      state: {
        data: getExcursionOrder(),
        type: EOrderPageIsType.IS_EXCURSION,
      },
    });
  });

  const getExcursionOrder = useFn((): OrderPageExcursion => {
    return {
      userId: currentUser.id,
      contact: "",
      contactType: "TELEGRAM",
      address: currentUser.address,
      priceAdult: excursion.priceAdult,
      priceKids: excursion.priceKids,
      price: +excursion.price,
      currency: ECurrency.EUR,
      excursionId: excursion.id,
      excursionDate: formatDateTime(selectedDate!.toString()),
      personsCount: 1,
      childrenCount: 0,
      comment: "",
      paymentMethod: "",
      capacity: excursion.capacity,
    };
  });

  const onDateClick = (date: Date) => {
    reachGoalExcursionDateChoose(formatDateTime(date.toString()));
    setSelectedDate(date);
  };

  const orderData = useSelector(
    selectExcursionOrderById(excursionByIdQuery.data ? excursionByIdQuery.data.id : "null")
  );

  if (excursionByIdQuery.isLoading || !excursionByIdQuery.data) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }

  reachGoalExcursionsItemOpen(excursionByIdQuery.data.name);

  const excursion: ExcursionLookup = excursionByIdQuery.data;

  const renderExcursionItemPart = () => {
    return (
      <>
        {excursion.description.length > 70 && (
          <>
            <Modal
              opened={openedModal}
              onClose={close}
              withCloseButton={true}
              centered
              title={excursion.name}
              transitionProps={{ transition: "rotate-left" }}
            >
              <ReactMarkdown children={excursion.description} />
            </Modal>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={700} onClick={open} color="violet" underline={true}>
                {t("Read More")}
              </Text>
            </Group>
          </>
        )}

        <Flex mt={30} direction={"column"} gap={"lg"} style={{ flex: "1 1 auto" }}>
          <Flex gap={"lg"}>
            {!excursion.isForChildrenOnly && !excursion.isForAdultsOnly && (
              <>
                <Flex direction={"column"}>
                  <Text weight={400} size={16} c={EColors.dark}>
                    {t("Adult price")}
                  </Text>
                  <Text weight={700} size={34} c={EColors.gray_1}>
                    {`${excursion.priceAdult} ${ECurrency.EUR}`}
                  </Text>
                </Flex>
                {!!excursion.priceKids && (
                  <Flex direction={"column"}>
                    <Text weight={400} size={16} c={EColors.dark}>
                      {t("Kids price")}
                    </Text>
                    <Text weight={700} size={34} c={EColors.gray_1}>
                      {`${excursion.priceKids} ${ECurrency.EUR}`}
                    </Text>
                  </Flex>
                )}
              </>
            )}
            {excursion.isForChildrenOnly && !!excursion.priceKids && (
              <Flex direction={"column"}>
                <Text weight={400} size={16} c={EColors.dark}>
                  {t("Kids price")}
                </Text>
                <Text weight={700} size={34} c={EColors.gray_1}>
                  {`${excursion.priceKids} ${ECurrency.EUR}`}
                </Text>
              </Flex>
            )}
            {excursion.isForAdultsOnly && (
              <>
                <Flex direction={"column"}>
                  <Text weight={400} size={16} c={EColors.dark}>
                    {t("Adult price")}
                  </Text>
                  <Text weight={700} size={34} c={EColors.gray_1}>
                    {`${excursion.priceAdult} ${ECurrency.EUR}`}
                  </Text>
                </Flex>
              </>
            )}
          </Flex>

          <Flex direction={"column"} justify={"space-between"} gap={"md"}>
            <DatePickerInput
              dropdownType="modal"
              placeholder="Date"
              value={selectedDate}
              onChange={onDateClick}
              color="violet"
              excludeDate={(date) => {
                return date.getTime() < Date.now();
              }}
              radius="lg"
              size="lg"
              lang={"ru"}
              c={EColors.violet}
              styles={(theme) => ({
                input: {
                  "&": {
                    borderColor: theme.colors.violet[7],
                    color: theme.colors.violet[7],
                    wordBreak: "keep-all",
                  },
                  "&:focus": {
                    borderColor: theme.colors.violet[7],
                  },
                },
              })}
              // rightSection={<Calendar></Calendar>}
              style={{ color: EColors.violet }}
            />
            <Button
              fullWidth={true}
              disabled={!selectedDate}
              onClick={toOrderPage}
              variant="filled"
              color="violet"
              radius="lg"
              size="xl"
            >
              {t("Next step")}
            </Button>
          </Flex>
        </Flex>
      </>
    );
  };

  return (
    <>
      <ItemBase optionItem={{ ...excursion, count: 0 }} children={renderExcursionItemPart()}></ItemBase>
      <NavigationBar></NavigationBar>
    </>
  );
};
