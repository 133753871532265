import { TransferCarTypeEnum } from "../../../client";
import { transferPointsObject, transferRoutes } from "../constants/constantsTableList";
import { TPoint, TTransferRoute } from "./types";

const additionPrice = 300;

export const calculateTransferPriceFromPriceList = (
  addressFrom: string,
  addressTo: string,
  pointFrom: TPoint,
  pointTo: TPoint,
  carType: TransferCarTypeEnum,
  distance?: number
): number => {
  const pointFromName = findPointName(addressFrom, pointFrom);

  const pointToName = findPointName(addressTo, pointTo);

  if (!pointFromName || !pointToName) return 0;

  const route = findRoute(pointFromName, pointToName, distance);

  if (!route || !route.price || !route.price[carType]) return 0;

  return route.price[carType] ?? 0 + additionPrice;
};

const pointInBoundingBox = (point: TPoint, boundingBox: { topLeft: TPoint; bottomRight: TPoint }): boolean => {
  const res =
    point.lat <= boundingBox.topLeft.lat &&
    point.lat >= boundingBox.bottomRight.lat &&
    point.lon >= boundingBox.topLeft.lon &&
    point.lon <= boundingBox.bottomRight.lon;

  return res;
};

const findPointName = (address: string, addressPoint: TPoint): string | null => {
  const matchPoints = [];
  for (const pointName in transferPointsObject) {
    const point = transferPointsObject[pointName];
    if (point.boundingbox && pointInBoundingBox(addressPoint, point.boundingbox)) {
      return point.name;
    } else {
      const { ru, en } = point.language;
      const countMatchesTags = matchesTags(address, ru) === 0 ? matchesTags(address, en) : matchesTags(address, ru);

      if (countMatchesTags !== 0) {
        matchPoints.push({ name: point.name, countMatchesTags });
      }
    }
  }
  if (matchPoints.length) {
    return matchPoints.sort((a, b) => b.countMatchesTags - a.countMatchesTags)[0].name;
  }
  return null;
};

// Check if address matches required and optional tags
const matchesTags = (
  address: string,
  languageDetails: { requireSearchNameTags: string[]; optionalSearchNameTags: string[] }
): number => {
  const required =
    languageDetails.requireSearchNameTags.length === 0
      ? true
      : languageDetails.requireSearchNameTags.every((tag) => address.includes(tag));

  const optional =
    languageDetails.optionalSearchNameTags.length === 0
      ? true
      : languageDetails.optionalSearchNameTags.some((tag) => address.includes(tag));

  if (required && optional) {
    return languageDetails.requireSearchNameTags.length + 1;
  } else {
    return 0;
  }
};

// Helper function to find a valid route between two points
const findRoute = (fromName: string, toName: string, actualDistance: number | undefined): TTransferRoute | null => {
  const possibleRoutes = transferRoutes.filter((route) => {
    const matchesDirect = route.from === fromName && route.to === toName;

    const matchesReverse = route.reversible && route.from === toName && route.to === fromName;
    if (!(matchesDirect || matchesReverse)) return false;
    if (route.distance && actualDistance) {
      console.log("test", route.distance, actualDistance);

      const withinDistanceRange = actualDistance <= route.distance.distanceValue + route.distance.distanceError;

      return withinDistanceRange;
    } else {
      return true;
    }
  });

  return possibleRoutes[0] || null;
};
