import { useQuery } from "@tanstack/react-query";
import { TCurrency } from "../definitions";
import { externalApis } from "./definitions";

export const getExchangeRate = (from: TCurrency, to: TCurrency) => {
  const url = `${externalApis.openExchangeRate}/convert?from=${to}&to=${from}`;
  const result = fetch(url)
    .then((response) => response.json())
    .then((json) => {
      return json.result;
    });

  return result;
};

const useExchangeRate = (from: TCurrency, to: TCurrency) => {
  const fetchExchangeRate = async (): Promise<number> => {
    const url = `${externalApis.openExchangeRate}/${from}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Failed to fetch exchange rate");
    }
    const json = await response.json();
    const rate = json?.rates?.[to];

    return rate;
  };

  return useQuery(["exchangeRate", from, to], () => fetchExchangeRate(), {
    enabled: !!from && !!to,
  });
};

export default useExchangeRate;
