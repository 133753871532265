/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExcursionsCreateExcursionOrderPostRequest,
  ExcursionsExcursionByIdExcursionIdGet200Response,
  ExcursionsExcursionsListGet200Response,
  ExcursionsExcursionsOrdersOrderIdGet200Response,
  ExcursionsExcursionsOrdersSetOrderStatusPostRequest,
  ExcursionsUserExcursionsOrdersUserIdGet200Response,
} from '../models';
import {
    ExcursionsCreateExcursionOrderPostRequestFromJSON,
    ExcursionsCreateExcursionOrderPostRequestToJSON,
    ExcursionsExcursionByIdExcursionIdGet200ResponseFromJSON,
    ExcursionsExcursionByIdExcursionIdGet200ResponseToJSON,
    ExcursionsExcursionsListGet200ResponseFromJSON,
    ExcursionsExcursionsListGet200ResponseToJSON,
    ExcursionsExcursionsOrdersOrderIdGet200ResponseFromJSON,
    ExcursionsExcursionsOrdersOrderIdGet200ResponseToJSON,
    ExcursionsExcursionsOrdersSetOrderStatusPostRequestFromJSON,
    ExcursionsExcursionsOrdersSetOrderStatusPostRequestToJSON,
    ExcursionsUserExcursionsOrdersUserIdGet200ResponseFromJSON,
    ExcursionsUserExcursionsOrdersUserIdGet200ResponseToJSON,
} from '../models';

export interface ExcursionsCreateExcursionOrderPostOperationRequest {
    excursionsCreateExcursionOrderPostRequest: ExcursionsCreateExcursionOrderPostRequest;
}

export interface ExcursionsExcursionByIdExcursionIdGetRequest {
    initData: string;
    excursionId: string;
}

export interface ExcursionsExcursionsListGetRequest {
    initData: string;
}

export interface ExcursionsExcursionsOrdersOrderIdGetRequest {
    initData: string;
    orderId: string;
}

export interface ExcursionsExcursionsOrdersSetOrderStatusPostOperationRequest {
    excursionsExcursionsOrdersSetOrderStatusPostRequest: ExcursionsExcursionsOrdersSetOrderStatusPostRequest;
}

export interface ExcursionsUserExcursionsOrdersUserIdGetRequest {
    initData: string;
    userId: string;
}

/**
 * 
 */
export class ExcursionsApi extends runtime.BaseAPI {

    /**
     * User create request for Excursion
     */
    async excursionsCreateExcursionOrderPostRaw(requestParameters: ExcursionsCreateExcursionOrderPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.excursionsCreateExcursionOrderPostRequest === null || requestParameters.excursionsCreateExcursionOrderPostRequest === undefined) {
            throw new runtime.RequiredError('excursionsCreateExcursionOrderPostRequest','Required parameter requestParameters.excursionsCreateExcursionOrderPostRequest was null or undefined when calling excursionsCreateExcursionOrderPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/excursions/create_excursion_order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExcursionsCreateExcursionOrderPostRequestToJSON(requestParameters.excursionsCreateExcursionOrderPostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User create request for Excursion
     */
    async excursionsCreateExcursionOrderPost(requestParameters: ExcursionsCreateExcursionOrderPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.excursionsCreateExcursionOrderPostRaw(requestParameters, initOverrides);
    }

    /**
     * Returns excursion by id
     */
    async excursionsExcursionByIdExcursionIdGetRaw(requestParameters: ExcursionsExcursionByIdExcursionIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExcursionsExcursionByIdExcursionIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling excursionsExcursionByIdExcursionIdGet.');
        }

        if (requestParameters.excursionId === null || requestParameters.excursionId === undefined) {
            throw new runtime.RequiredError('excursionId','Required parameter requestParameters.excursionId was null or undefined when calling excursionsExcursionByIdExcursionIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/excursions/excursion_by_id/{excursionId}`.replace(`{${"excursionId"}}`, encodeURIComponent(String(requestParameters.excursionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExcursionsExcursionByIdExcursionIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns excursion by id
     */
    async excursionsExcursionByIdExcursionIdGet(requestParameters: ExcursionsExcursionByIdExcursionIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExcursionsExcursionByIdExcursionIdGet200Response> {
        const response = await this.excursionsExcursionByIdExcursionIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all available excursions
     */
    async excursionsExcursionsListGetRaw(requestParameters: ExcursionsExcursionsListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExcursionsExcursionsListGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling excursionsExcursionsListGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/excursions/excursions_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExcursionsExcursionsListGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns all available excursions
     */
    async excursionsExcursionsListGet(requestParameters: ExcursionsExcursionsListGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExcursionsExcursionsListGet200Response> {
        const response = await this.excursionsExcursionsListGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return excursions order by id
     */
    async excursionsExcursionsOrdersOrderIdGetRaw(requestParameters: ExcursionsExcursionsOrdersOrderIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExcursionsExcursionsOrdersOrderIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling excursionsExcursionsOrdersOrderIdGet.');
        }

        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling excursionsExcursionsOrdersOrderIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/excursions/excursions_orders/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExcursionsExcursionsOrdersOrderIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Return excursions order by id
     */
    async excursionsExcursionsOrdersOrderIdGet(requestParameters: ExcursionsExcursionsOrdersOrderIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExcursionsExcursionsOrdersOrderIdGet200Response> {
        const response = await this.excursionsExcursionsOrdersOrderIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update status for Excursion order
     */
    async excursionsExcursionsOrdersSetOrderStatusPostRaw(requestParameters: ExcursionsExcursionsOrdersSetOrderStatusPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.excursionsExcursionsOrdersSetOrderStatusPostRequest === null || requestParameters.excursionsExcursionsOrdersSetOrderStatusPostRequest === undefined) {
            throw new runtime.RequiredError('excursionsExcursionsOrdersSetOrderStatusPostRequest','Required parameter requestParameters.excursionsExcursionsOrdersSetOrderStatusPostRequest was null or undefined when calling excursionsExcursionsOrdersSetOrderStatusPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/excursions/excursions_orders/set_order_status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExcursionsExcursionsOrdersSetOrderStatusPostRequestToJSON(requestParameters.excursionsExcursionsOrdersSetOrderStatusPostRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update status for Excursion order
     */
    async excursionsExcursionsOrdersSetOrderStatusPost(requestParameters: ExcursionsExcursionsOrdersSetOrderStatusPostOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.excursionsExcursionsOrdersSetOrderStatusPostRaw(requestParameters, initOverrides);
    }

    /**
     * Returns all user excursions orders
     */
    async excursionsUserExcursionsOrdersUserIdGetRaw(requestParameters: ExcursionsUserExcursionsOrdersUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExcursionsUserExcursionsOrdersUserIdGet200Response>> {
        if (requestParameters.initData === null || requestParameters.initData === undefined) {
            throw new runtime.RequiredError('initData','Required parameter requestParameters.initData was null or undefined when calling excursionsUserExcursionsOrdersUserIdGet.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling excursionsUserExcursionsOrdersUserIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.initData !== undefined) {
            queryParameters['initData'] = requestParameters.initData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/excursions/user_excursions_orders/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExcursionsUserExcursionsOrdersUserIdGet200ResponseFromJSON(jsonValue));
    }

    /**
     * Returns all user excursions orders
     */
    async excursionsUserExcursionsOrdersUserIdGet(requestParameters: ExcursionsUserExcursionsOrdersUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExcursionsUserExcursionsOrdersUserIdGet200Response> {
        const response = await this.excursionsUserExcursionsOrdersUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
