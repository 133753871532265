/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SeaTrip } from './SeaTrip';
import {
    SeaTripFromJSON,
    SeaTripFromJSONTyped,
    SeaTripToJSON,
} from './SeaTrip';

/**
 * 
 * @export
 * @interface SeaTripsSeaTripByIdSeaTripIdGet200Response
 */
export interface SeaTripsSeaTripByIdSeaTripIdGet200Response {
    /**
     * 
     * @type {SeaTrip}
     * @memberof SeaTripsSeaTripByIdSeaTripIdGet200Response
     */
    seaTrip?: SeaTrip;
    /**
     * 
     * @type {Array<string>}
     * @memberof SeaTripsSeaTripByIdSeaTripIdGet200Response
     */
    bookedDates?: Array<string>;
}

/**
 * Check if a given object implements the SeaTripsSeaTripByIdSeaTripIdGet200Response interface.
 */
export function instanceOfSeaTripsSeaTripByIdSeaTripIdGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SeaTripsSeaTripByIdSeaTripIdGet200ResponseFromJSON(json: any): SeaTripsSeaTripByIdSeaTripIdGet200Response {
    return SeaTripsSeaTripByIdSeaTripIdGet200ResponseFromJSONTyped(json, false);
}

export function SeaTripsSeaTripByIdSeaTripIdGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeaTripsSeaTripByIdSeaTripIdGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seaTrip': !exists(json, 'seaTrip') ? undefined : SeaTripFromJSON(json['seaTrip']),
        'bookedDates': !exists(json, 'bookedDates') ? undefined : json['bookedDates'],
    };
}

export function SeaTripsSeaTripByIdSeaTripIdGet200ResponseToJSON(value?: SeaTripsSeaTripByIdSeaTripIdGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seaTrip': SeaTripToJSON(value.seaTrip),
        'bookedDates': value.bookedDates,
    };
}

