import { useTranslation } from "react-i18next";
import { Badge, Flex, MediaQuery } from "@mantine/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavigationText } from "../../components/NavigationText/NavigationText";

// import "./style.css";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { LoaderPageCustom } from "../../components/UI/Vendors/Mantine/LoaderPageCustom";
import { ALL_STATUSES, TBadgeType } from "../../components/badges/Badge";
import { RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import { selectCurrentUser } from "../../store/userStore";
import { OrderCardList } from "./ components/OrderCardList";
import useExcursionOrders from "./hooks/useExcursionOrders";
import useFoodOrders from "./hooks/useFoodOrders";
import useSeaTripsOrders from "./hooks/useSeaTripOrders";
import useTransferOrders from "./hooks/useTransferOrders";

export const OrderListPage = () => {
  const navigate = useNavigate();

  const { t } = useTranslation(["order_list_page"]);

  const { initData } = useTelegram();

  const currentUser = useSelector(selectCurrentUser);

  const [filters, setFilters] = useState(ALL_STATUSES);

  const navigateToFoodOrder = useFn((card: { id: string }) => {
    navigate(RouterPath.order.completed.deliveryById(card.id), { state: card });
  });

  const navigateToExcursionOrder = useFn((card: { id: string }) => {
    navigate(RouterPath.order.completed.excursionById(card.id), { state: card });
  });

  const navigateToYachtOrder = useFn((card: { id: string }) => {
    navigate(RouterPath.order.completed.yachtById(card.id), { state: card });
  });

  const navigateToSeaTripOrder = useFn((card: { id: string }) => {
    navigate(RouterPath.order.completed.seaTripsById(card.id), { state: card });
  });

  const navigateToTransferOrder = useFn((card: { id: string }) => {
    navigate(RouterPath.order.completed.transferById(card.id), { state: card });
  });

  const foodOrders = useFoodOrders(currentUser.id, initData);

  const excursionOrders = useExcursionOrders(currentUser.id, initData);

  const seaTripsOrders = useSeaTripsOrders(currentUser.id, initData);

  const transferOrders = useTransferOrders(currentUser.id, initData);

  const onFilterChanged = useFn((values: TBadgeType[]) => {
    setFilters(values.length ? values : ALL_STATUSES);
  });

  if (
    foodOrders.isLoading ||
    !foodOrders.data ||
    excursionOrders.isLoading ||
    !excursionOrders.data ||
    seaTripsOrders.isLoading ||
    !seaTripsOrders.data ||
    transferOrders.isLoading ||
    !transferOrders.data
  ) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }

  const filterList = ALL_STATUSES.map((filter) => {
    return {
      itemName: filter,
      itemJSX: (
        <Badge
          pt={1}
          key={`filter_${filter}`}
          className="BaseCArd__tag"
          color="grape"
          radius="lg"
          size="lg"
          variant="outline"
        >
          {`${filter}`}
        </Badge>
      ),
    };
  });

  return (
    <>
      <NavigationText
        arrow={{
          isShow: true,
        }}
        filterMenu={{
          isShow: true,
          onFilterChanged,
          dropdownItems: filterList,
        }}
        title={t("My orders")}
      ></NavigationText>
      <Flex direction={"column"}>
        <MediaQuery query="(orientation: landscape)" styles={{ width: "50vw", minWidth: "500px" }}>
          <Flex direction={"column"} gap={"xl"}>
            <OrderCardList filters={filters} list={foodOrders.data} navigateFn={navigateToFoodOrder}></OrderCardList>
            <OrderCardList
              filters={filters}
              list={excursionOrders.data}
              navigateFn={navigateToExcursionOrder}
            ></OrderCardList>
            <OrderCardList
              filters={filters}
              list={seaTripsOrders.data}
              navigateFn={navigateToSeaTripOrder}
            ></OrderCardList>
            <OrderCardList
              filters={filters}
              list={transferOrders.data}
              navigateFn={navigateToTransferOrder}
            ></OrderCardList>
          </Flex>
        </MediaQuery>
      </Flex>
      <NavigationBar></NavigationBar>
    </>
  );
};
