import { ArrowLeft, BarsDescendingAlignCenter, CircleXmark } from "@gravity-ui/icons";
import "./style.css";

import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { ActionIcon, Checkbox, Flex, Menu } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFn } from "../../hooks/useFn";

interface INavigationText {
  arrow: {
    isShow: boolean;
    onClickFunction?: () => void;
  };
  filterMenu?: {
    isShow: boolean;
    onFilterChanged?: (values: any[]) => void;
    dropdownItems: Array<{
      itemName: string;
      itemJSX: ReactJSXElement;
    }>;
  };
  title: string;
  subtitle?: string;
}

export const NavigationText = ({
  arrow = {
    isShow: false,
    onClickFunction: () => {},
  },
  filterMenu = {
    isShow: false,
    onFilterChanged: () => {},
    dropdownItems: [{ itemName: "", itemJSX: <></> }],
  },
  title = "Undefined title!!",
  subtitle = "",
}: INavigationText) => {
  const navigate = useNavigate();

  const navigateBack = useFn(() => {
    navigate(-1);
  });

  const [isOpenFilterMenu, setStateOpenFilterMenu] = useState(false);

  const closeFilterMenu = useFn(() => {
    setStateOpenFilterMenu(false);
  });

  const OpenFilterMenu = useFn(() => {
    setStateOpenFilterMenu(true);
  });

  const [filterValue, setFilterValue] = useState<string[]>([]);

  const updateFilterValues = useFn((values: string[]) => {
    if (filterMenu.onFilterChanged) {
      filterMenu.onFilterChanged(values);
    }
    setFilterValue(values);
  });

  let dropdownMenuItems: Array<any> = [];
  if (filterMenu.isShow) {
    dropdownMenuItems = filterMenu.dropdownItems.map((item, index) => (
      <Menu.Item key={`filter_menu_${index}`} closeMenuOnClick={false}>
        <Checkbox size={"lg"} radius={"sm"} value={item.itemName} label={item.itemJSX} color="violet" />
      </Menu.Item>
    ));
  }

  return (
    <div className="navigationText">
      <Flex justify="space-between" align="center">
        <div className="navigationText__title">
          {arrow.isShow && (
            <div className="navigationText__arrow" onClick={arrow.onClickFunction || navigateBack}>
              <ArrowLeft height={24} width={24}></ArrowLeft>
            </div>
          )}
          {title}
        </div>
        {filterMenu.isShow && (
          <Menu shadow="md" width={200} onClose={closeFilterMenu} onOpen={OpenFilterMenu} radius="lg">
            <Menu.Target>
              <ActionIcon color="violet">
                {isOpenFilterMenu && <CircleXmark height={24} width={24}></CircleXmark>}
                {!isOpenFilterMenu && <BarsDescendingAlignCenter height={24} width={24}></BarsDescendingAlignCenter>}
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>Filter</Menu.Label>
              <Checkbox.Group value={filterValue} onChange={updateFilterValues}>
                {filterMenu.isShow && <>{dropdownMenuItems}</>}
              </Checkbox.Group>
            </Menu.Dropdown>
          </Menu>
        )}
      </Flex>
      {subtitle && <div className="navigationText__subtitle">{subtitle}</div>}
    </div>
  );
};
