import { CircleCheckFill, Clock, Globe, ShoppingCart } from "@gravity-ui/icons";
import { ActionIcon, Badge, Card, Flex, Group, Image, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { EColors, ECurrency } from "../../definitions";
import { OrderBadge, TBadgeType } from "../badges/Badge";

import "./style.css";

export type TCard = {
  id: string;
  title: string;
  description?: string;
  shortDescription?: string;
  image?: string;
  price?: number;
  currency?: ECurrency;
  address?: string;
  addressIcon?: string;
  time?: string;
  status?: string;
  tags?: { id: string; name: string }[];
  labels?: { iconType: string; text: string }[];
  badgeType?: TBadgeType;
};

export const BaseCard = ({ card, onCardClick }: { card: TCard; onCardClick?: any }) => {
  let tags_list: Array<JSX.Element> = [];

  const { t } = useTranslation("base_card");
  const { t: t_city } = useTranslation("city");

  if (card.tags) {
    tags_list = card.tags.map((tag: any) => {
      return (
        <Badge
          pt={1}
          key={`tag_${tag.id}`}
          className="BaseCArd__tag"
          color="grape"
          radius="lg"
          size="lg"
          variant="outline"
        >
          {" "}
          {t(tag.name)}
        </Badge>
      );
    });
  }

  let labels_list: Array<JSX.Element> = [];
  if (card.labels) {
    labels_list = card.labels.map((label: any) => {
      return (
        <Flex className="mainCard__text" key={`${card.title}_${label.iconType}_${card.id}`}>
          {label.iconType === "ShoppingCart" && <ShoppingCart className="mainCard__icon"></ShoppingCart>}
          {label.iconType === "Clock" && <Clock className="mainCard__icon"></Clock>}
          {label.iconType === "Done" && <CircleCheckFill color={EColors.violet}></CircleCheckFill>}
          {`${label.text}`}
        </Flex>
      );
    });
  }

  const description = card.shortDescription ? card.shortDescription : card.description;

  return (
    <Card
      // component={Link}
      // to={card.route}
      onClick={onCardClick}
      className="mainCard"
      key={`${card.title}_${card.id}`}
      shadow="sm"
      padding="lg"
      radius="lg"
    >
      <Card.Section>
        <Image className="mainCard__image" height={180} src={card.image} alt={card.title} />
      </Card.Section>

      <Flex justify="space-between" mt="md" align="center" pt={1}>
        {!!card.address && (
          <div className="mainCard__text">
            <>
              {!!card.addressIcon && (
                <ActionIcon radius="xl" variant="transparent" pb={1}>
                  <Globe color=""></Globe>
                </ActionIcon>
              )}

              <Text pt={3}>
                <span style={{ textTransform: "capitalize" }}>{t_city(card.address.toLowerCase())}</span>
              </Text>
            </>
          </div>
        )}

        {!card.address && (
          <Text className="mainCard__title" weight={700}>
            {card.title}
          </Text>
        )}

        {!!card.badgeType && !!card.status && <OrderBadge value={card.status} type={card.badgeType}></OrderBadge>}
      </Flex>

      <Group position="apart" mt="0" mb="md">
        {!!card.address && (
          <Text className="mainCard__title" weight={700}>
            {card.title}
          </Text>
        )}

        {!!card.time && (
          <Badge
            pl={7}
            pt={1}
            className="BaseCArd__tag"
            leftSection={
              <ActionIcon size="xs" radius="xl" variant="transparent">
                <Clock style={{ color: "#fff" }}></Clock>
              </ActionIcon>
            }
            size="lg"
            color="green"
            radius="lg"
            variant="filled"
          >
            {card.time}
          </Badge>
        )}
      </Group>

      {!!description && (
        <Flex justify="space-between" mt="md" mb="md" align="center" pt={1}>
          <Text weight={400}>{description}</Text>

          {!!card.price && (
            <Text weight={700} size={30} style={{ flex: "1 0 30%", textAlign: "end" }} color="violet">
              {card.price ? `${card.price} ${card.currency}` : "call to get price"}
            </Text>
          )}
        </Flex>
      )}

      {!!card.labels && (
        <Flex
          justify={card.labels.find((label) => label.iconType === "Done") ? "space-between" : "start"}
          className="mainCard__container"
        >
          {labels_list}
        </Flex>
      )}

      {!!card.tags && (
        <Flex wrap="wrap" columnGap="md" rowGap="xs">
          {tags_list}
        </Flex>
      )}
    </Card>
  );
};
