import { restaurantsApi } from "../../../apiDrivers/restaurants";
import { BASE_PATH, FoodOrderLookup, FoodOrderUpdateStatus } from "../../../client";
import { TCard } from "../../../components/BaseCard/BaseCard";
import { formatDateTime, TIME_FORMAT_HUMAN_NO_SEC } from "../../../utils/date";
import { TBadgeType } from "../../../components/badges/Badge";
import { ECurrency } from "../../../definitions";

export const fetchUserFoodOrdersList = async ({ queryKey }: any) => {
  const initData = queryKey[1];
  const userId = queryKey[2];

  const res = await restaurantsApi.foodUserFoodOrdersUserIdGet({ initData, userId });

  return res.userOrdersLookups;
};

export type TNormalizedFoodOrderLookup = TCard;

export const mapFoodOrderStatusToBadgeType: Record<FoodOrderUpdateStatus, TBadgeType> = {
  [FoodOrderUpdateStatus.Created]: "Ordered",
  [FoodOrderUpdateStatus.InDelivery]: "In delivery",
  [FoodOrderUpdateStatus.Resolved]: "Completed",
  [FoodOrderUpdateStatus.CancelledByUser]: "Cancelled",
  [FoodOrderUpdateStatus.CancelledByAdmin]: "Cancelled",
  [FoodOrderUpdateStatus.CancelledByRestaurant]: "Cancelled",
};

export function normalizeFoodOrder(foodOrders: FoodOrderLookup[] | undefined): TNormalizedFoodOrderLookup[] {
  if (!foodOrders) {
    return [];
  }

  return foodOrders.map((foodOrder) => {

    const labels = [
      { iconType: "Clock", text: formatDateTime(foodOrder.creationDate, TIME_FORMAT_HUMAN_NO_SEC) },
    ];

    if (foodOrder.deliveryDate) {
      labels.push({ iconType: "Done", text: formatDateTime(foodOrder.deliveryDate, TIME_FORMAT_HUMAN_NO_SEC) });
    }

    return {
      id: foodOrder.id,
      title: foodOrder.restaurant.name,
      badgeType: mapFoodOrderStatusToBadgeType[foodOrder.status],
      status: mapFoodOrderStatusToBadgeType[foodOrder.status],
      price: foodOrder.price,
      currency: ECurrency.TRY,
      description: foodOrder.address,
      image: `${BASE_PATH}/${foodOrder.restaurant.images[0]}`,
      labels,
    };
  });
}
