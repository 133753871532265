import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { MantineProvider } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { RouterProvider } from "react-router-dom";
import React from "react";
import "./index.css";
import "./i18n";
import { initStore } from "./store/rootStore";
import { router } from "./router";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const queryClient = new QueryClient({});

// Sentry.init({
//   dsn: "https://fd6bfcfba658b4bb8748958ab6511834@o4507385824804864.ingest.de.sentry.io/4507385843744848",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         "localhost:3001",
//         "localhost:80",
//         "https://easy-booking.github.io/kalkan_delivery_frontend/",
//         "https://kalkan-delivery.herokuapp.com/",
//         "app.travolza.com",
//         "travolza.com",
//         "https://travolza.github.io/travolza-web-app",
//       ],
//     }),
//     new Sentry.Replay(),
//   ],
//   environment: process.env.NODE_ENV,
//   tracesSampleRate: process.env.NODE_ENV === "development" ? 1.0 : 0.25,
//   // Session Replay
//   replaysSessionSampleRate: process.env.NODE_ENV === "development" ? 1.0 : 0.25,
//   replaysOnErrorSampleRate: 1.0,
// });

const { store, persistor } = initStore();
serviceWorkerRegistration.unregister();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          fontFamily: "Roboto Flex",
        }}
      >
        <Notifications />
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ModalsProvider>
              <RouterProvider router={router} />
            </ModalsProvider>
          </PersistGate>
        </Provider>
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
