import { toLower } from "lodash";
import { UserLanguageEnum } from "./client";

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + toLower(string.slice(1));
}

export const setCookieLanguage = (locale: UserLanguageEnum) => {
  document.cookie = `LOCALE=${locale}; max-age=31536000; path=/`;
};

interface IErrorResponse {
  response: {
    status: number;
  };
}

export const isResponseError = (error: unknown): error is IErrorResponse => {
  return (
    typeof error === "object" &&
    error !== null &&
    "response" in error &&
    typeof error.response === "object" &&
    error.response !== null &&
    "status" in error.response &&
    typeof error.response.status === "number"
  );
};
