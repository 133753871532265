import { IconBase } from "../IconBase";

export const IconSedan = ({ width, height }: { width?: number; height?: number }) => {
  return (
    <IconBase
      width={width}
      height={height}
      children={
        // <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <>
          <g clipPath="url(#clip0_1224_225792)">
            <path
              d="M23.4917 11.2813L22.9108 9.82904L21.8807 7.2537C20.9964 5.04323 18.8873 3.61523 16.5063 3.61523H9.94255C7.56205 3.61523 5.45236 5.04323 4.56807 7.2537L3.53803 9.82904L2.95712 11.2813C1.71053 11.7553 0.820557 12.9522 0.820557 14.3652V16.846C0.820557 17.6791 1.13892 18.4311 1.64748 19.0131V21.8075C1.64748 22.7208 2.38809 23.4614 3.30133 23.4614H4.95517C5.86841 23.4614 6.60902 22.7208 6.60902 21.8075V20.1537H19.8398V21.8075C19.8398 22.7208 20.5804 23.4614 21.4936 23.4614H23.1475C24.0607 23.4614 24.8013 22.7208 24.8013 21.8075V19.0131C25.3099 18.4316 25.6282 17.6796 25.6282 16.846V14.3652C25.6282 12.9522 24.7383 11.7553 23.4917 11.2813ZM7.63905 8.48219C8.01582 7.54053 8.92802 6.92293 9.94255 6.92293H16.5063C17.5208 6.92293 18.433 7.54053 18.8098 8.48219L19.8398 11.0575H6.60902L7.63905 8.48219ZM4.95517 16.8357C3.96286 16.8357 3.30133 16.1762 3.30133 15.187C3.30133 14.1978 3.96286 13.5383 4.95517 13.5383C5.94748 13.5383 7.43594 15.0221 7.43594 16.0113C7.43594 17.0005 5.94748 16.8357 4.95517 16.8357ZM21.4936 16.8357C20.5013 16.8357 19.0129 17.0005 19.0129 16.0113C19.0129 15.0221 20.5013 13.5383 21.4936 13.5383C22.4859 13.5383 23.1475 14.1978 23.1475 15.187C23.1475 16.1762 22.4859 16.8357 21.4936 16.8357Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_1224_225792">
              <rect width="24.8077" height="26.4615" fill="white" transform="translate(0.820557 0.307617)" />
            </clipPath>
          </defs>
        </>
        // </svg>
      }
    ></IconBase>
  );
};
