/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ECity } from './ECity';
import {
    ECityFromJSON,
    ECityFromJSONTyped,
    ECityToJSON,
} from './ECity';
import type { ExcursionTag } from './ExcursionTag';
import {
    ExcursionTagFromJSON,
    ExcursionTagFromJSONTyped,
    ExcursionTagToJSON,
} from './ExcursionTag';

/**
 * 
 * @export
 * @interface Excursion
 */
export interface Excursion {
    /**
     * 
     * @type {string}
     * @memberof Excursion
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Excursion
     */
    rating: number;
    /**
     * 
     * @type {string}
     * @memberof Excursion
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof Excursion
     */
    duration: number;
    /**
     * 
     * @type {Array<ExcursionTag>}
     * @memberof Excursion
     */
    tags: Array<ExcursionTag>;
    /**
     * 
     * @type {boolean}
     * @memberof Excursion
     */
    isForAdultsOnly: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Excursion
     */
    isForChildrenOnly: boolean;
    /**
     * 
     * @type {string}
     * @memberof Excursion
     */
    workingDays: string;
    /**
     * 
     * @type {number}
     * @memberof Excursion
     */
    priceAdult: number;
    /**
     * 
     * @type {number}
     * @memberof Excursion
     */
    priceKids: number;
    /**
     * 
     * @type {number}
     * @memberof Excursion
     */
    deposit: number;
    /**
     * 
     * @type {number}
     * @memberof Excursion
     */
    discount: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Excursion
     */
    images: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Excursion
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Excursion
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Excursion
     */
    excursionType: string;
    /**
     * 
     * @type {number}
     * @memberof Excursion
     */
    capacity: number;
    /**
     * 
     * @type {string}
     * @memberof Excursion
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof Excursion
     */
    referentName: string;
    /**
     * 
     * @type {string}
     * @memberof Excursion
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof Excursion
     */
    creationDate: string;
    /**
     * 
     * @type {ECity}
     * @memberof Excursion
     */
    city: ECity;
}

/**
 * Check if a given object implements the Excursion interface.
 */
export function instanceOfExcursion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "rating" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "duration" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "isForAdultsOnly" in value;
    isInstance = isInstance && "isForChildrenOnly" in value;
    isInstance = isInstance && "workingDays" in value;
    isInstance = isInstance && "priceAdult" in value;
    isInstance = isInstance && "priceKids" in value;
    isInstance = isInstance && "deposit" in value;
    isInstance = isInstance && "discount" in value;
    isInstance = isInstance && "images" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "excursionType" in value;
    isInstance = isInstance && "capacity" in value;
    isInstance = isInstance && "company" in value;
    isInstance = isInstance && "referentName" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "creationDate" in value;
    isInstance = isInstance && "city" in value;

    return isInstance;
}

export function ExcursionFromJSON(json: any): Excursion {
    return ExcursionFromJSONTyped(json, false);
}

export function ExcursionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Excursion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'rating': json['rating'],
        'title': json['title'],
        'duration': json['duration'],
        'tags': ((json['tags'] as Array<any>).map(ExcursionTagFromJSON)),
        'isForAdultsOnly': json['isForAdultsOnly'],
        'isForChildrenOnly': json['isForChildrenOnly'],
        'workingDays': json['workingDays'],
        'priceAdult': json['priceAdult'],
        'priceKids': json['priceKids'],
        'deposit': json['deposit'],
        'discount': json['discount'],
        'images': json['images'],
        'description': json['description'],
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
        'excursionType': json['excursionType'],
        'capacity': json['capacity'],
        'company': json['company'],
        'referentName': json['referentName'],
        'language': json['language'],
        'creationDate': json['creationDate'],
        'city': ECityFromJSON(json['city']),
    };
}

export function ExcursionToJSON(value?: Excursion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rating': value.rating,
        'title': value.title,
        'duration': value.duration,
        'tags': ((value.tags as Array<any>).map(ExcursionTagToJSON)),
        'isForAdultsOnly': value.isForAdultsOnly,
        'isForChildrenOnly': value.isForChildrenOnly,
        'workingDays': value.workingDays,
        'priceAdult': value.priceAdult,
        'priceKids': value.priceKids,
        'deposit': value.deposit,
        'discount': value.discount,
        'images': value.images,
        'description': value.description,
        'shortDescription': value.shortDescription,
        'excursionType': value.excursionType,
        'capacity': value.capacity,
        'company': value.company,
        'referentName': value.referentName,
        'language': value.language,
        'creationDate': value.creationDate,
        'city': ECityToJSON(value.city),
    };
}

