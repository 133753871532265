import { seaTripsApi } from "../../../apiDrivers/seaTrips";

const fetchSeaTripById = async ({ queryKey }: any) => {
  const initData = queryKey[1];
  const id = queryKey[2];
  if (!id) {
    throw new Error(`API Returns all food of restaurant use failed`);
  }

  const res = await seaTripsApi.seaTripsSeaTripByIdSeaTripIdGet({
    initData,
    seaTripId: id,
  });

  if (!res.seaTrip) {
    throw new Error(`get seaTrip by id fetch failed`);
  }

  return { seaTrip: res.seaTrip, bookedDates: res.bookedDates };
};

export default fetchSeaTripById;
