import { useTranslation } from "react-i18next";
import { Anchor, Flex, MediaQuery, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { NavigationText } from "../../components/NavigationText/NavigationText";
import { LoaderPageCustom } from "../../components/UI/Vendors/Mantine/LoaderPageCustom";
import { useTelegram } from "../../hooks/useTelegram";
import { MenuFoodList } from "../../modules/MenuFoodList";
import fetchRestaurantMenu from "./api";
import { normalizeRestaurantMenu } from "./definitions";

export const DeliveryMenuPage = ({ pageName, pageDescription }: { pageName?: string; pageDescription?: string }) => {
  const { id } = useParams();

  const location = useLocation();
  const restaurant = location.state;
  pageName = restaurant.title;
  // TODO: для макета у ресторана должно быть описание, пока заглушкой адрес
  pageDescription = restaurant.address;

  const { t } = useTranslation(["delivery_menu_page"]);

  const { initData } = useTelegram();

  const restaurantMenuQuery = useQuery(["food of restaurant", initData, id], fetchRestaurantMenu, {
    onError: (error) => {
      console.error(error);
    },
  });

  const restaurantMenu = normalizeRestaurantMenu(restaurantMenuQuery.data!);

  if (restaurantMenuQuery.isLoading || !restaurantMenu) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }

  return (
    <>
      <NavigationText
        arrow={{
          isShow: true,
        }}
        title={pageName || ``}
        subtitle={pageDescription || ""}
      ></NavigationText>

      <MediaQuery query="(orientation: landscape)" styles={{ width: "50vw", minWidth: "500px" }}>
        <Flex gap="lg" direction={"column"}>
          <Anchor color="violet" href={restaurant.googleMapsUrl} target="_blank">
            {t("Open in google maps")}
          </Anchor>
          <Text weight={700} size={22}>
            {t("Menu")}
          </Text>
          <MenuFoodList restaurantMenu={restaurantMenu} restaurant={restaurant} hasPriceButton={true}></MenuFoodList>
        </Flex>
      </MediaQuery>
      <NavigationBar></NavigationBar>
    </>
  );
};
