import { useEffect, useState } from "react";
import { Flex, Text } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { modals } from "@mantine/modals";
import { MenuItem } from "../../components/MenuItem";
import { RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { TRestaurantMenuItem } from "../../pages/DeliveryMenuPage/definitions";
import { TRestaurantCardLookup } from "../../pages/DeliveryPage/definitions";
import {
  AddItemAction,
  ClearCartAction,
  selectDeliveryCartRestaurant,
  SetRestaurantNameAction,
} from "../../store/deliveryCartStore";
import { capitalizeFirstLetter } from "../../utils";

interface TMenuFoodList {
  restaurantMenu: TRestaurantMenuItem[];
  restaurant: TRestaurantCardLookup;
  hasPriceButton: boolean;
}

export const MenuFoodList = ({ restaurantMenu, restaurant, hasPriceButton }: TMenuFoodList) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storedRestaurant = useSelector(selectDeliveryCartRestaurant);

  const setRestaurantName = useFn(
    (restaurant: { title: string; id: string; deliveryPrice?: number; deliveryPriceThreshold?: number }) => {
      dispatch(
        SetRestaurantNameAction({
          title: restaurant.title,
          id: restaurant.id,
          deliveryPrice: restaurant.deliveryPrice,
          deliveryPriceThreshold: restaurant.deliveryPriceThreshold,
        })
      );
    }
  );

  const addItemAction = useFn((item: TRestaurantMenuItem, restaurantId: string) => {
    dispatch(
      AddItemAction({
        ...item,
        restaurantId,
      })
    );
  });

  const dispatchAddNewItem = useFn((item: TRestaurantMenuItem) => {
    if (storedRestaurant.id && storedRestaurant.id !== restaurant.id) {
      modals.openConfirmModal({
        title: "Please confirm your action",
        children: <Text size="sm">Your order from other restaurant will be cleared</Text>,
        labels: { confirm: "Continue and clear previous order", cancel: "Cancel" },
        onCancel: () => {},
        onConfirm: () => {
          dispatch(ClearCartAction());
          setRestaurantName(restaurant);
          addItemAction(item, restaurant.id);
        },
      });
    } else {
      setRestaurantName(restaurant);
      addItemAction(item, restaurant.id);
    }
  });

  const [menuCategoryList, setMenuCategoryList] = useState<
    { id: string; name: string; menuCategoryItems: TRestaurantMenuItem[] }[]
  >([]);

  useEffect(() => {
    const set = new Set(restaurantMenu.map((item) => item.category));

    setMenuCategoryList(
      Array.from(set).map((category) => {
        return {
          id: category,
          name: capitalizeFirstLetter(category.split("_").join(" ").toLowerCase()),
          menuCategoryItems: restaurantMenu.filter((item) => item.category === category),
        };
      })
    );
  }, [restaurantMenu]);

  const cardsList = menuCategoryList.map((menuCategoryItem) => {
    const menuItemList = menuCategoryItem.menuCategoryItems.map((menuItem) => {
      const onMenuItemClick = (): void => {
        navigate(RouterPath.delivery.foodItemById(menuItem.id), {
          state: { menuItem, restaurant },
        });
      };
      let result;
      if (hasPriceButton) {
        result = (
          <MenuItem
            menuItem={menuItem}
            onMenuItemClick={onMenuItemClick}
            key={menuItem.id}
            restaurantId={restaurant.id}
            hasPriceButton={true}
            onBadgeClick={(): void => {
              dispatchAddNewItem(menuItem);
            }}
          ></MenuItem>
        );
      } else {
        result = (
          <MenuItem
            menuItem={menuItem}
            onMenuItemClick={onMenuItemClick}
            key={menuItem.id}
            restaurantId={restaurant.id}
            hasCountValue={true}
          ></MenuItem>
        );
      }
      return result;
    });
    return (
      <Flex gap="lg" direction={"column"} key={menuCategoryItem.id}>
        <Text weight={700} size={22}>
          {menuCategoryItem.name}
        </Text>
        <Flex gap="md" direction={"column"}>
          {menuItemList}
        </Flex>
      </Flex>
    );
  });

  return (
    <Flex gap="md" direction={"column"}>
      {cardsList}
    </Flex>
  );
};
