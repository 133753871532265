import { Copy, MapPin } from "@gravity-ui/icons";
import { ActionIcon, CheckIcon, CopyButton, Flex, Text, TextInputProps, Tooltip } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import L, { LatLngLiteral } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useCallback, useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import { useTranslation } from "react-i18next";
import { BASE_PATH } from "../../../client";
import { ChangeView } from "../ChangeView";
import { fetchLocation } from "../lib/fetchLocation";
import { AddressAutocomplete } from "../AddressAutocomplete";
import { useTelegram } from "../../../hooks/useTelegram";
import { externalApis } from "../../../externalAPIs/definitions";
import { markerDefault, markerFinish, markerSelected, markerStart } from "../constants";
import { debouncedReverseGeocoder } from "../../../externalAPIs/reverseGeocoderAPI";

interface ILocation {
  latitude: number;
  longitude: number;
}

// TODO: Получилось иконку от дизайна
const mapPinIcon = L.icon({
  iconUrl: `${BASE_PATH}/assets/leaflet/marker-icon.png`,
  iconSize: [38, 95], // Размер иконки в пикселях
  iconAnchor: [22, 94], // Точка, которая будет соответствовать "ножке" маркера на карте
  popupAnchor: [-3, -76], // Точка относительно иконки маркера, где будет показываться всплывающее окно
});

export const customIcon = L.icon({
  iconUrl: `${BASE_PATH}/assets/leaflet/marker-icon.png`,
  iconSize: [25, 41], // размеры иконки
  iconAnchor: [12, 41], // точка иконки, которая соответствует ее местоположению на карте
  popupAnchor: [1, -34], // точка относительно которой будет открываться всплывающее окно
});

export const defaultMarker = L.icon(markerDefault);

export const selectedMarker = L.icon(markerSelected);
export const startMarker = L.icon(markerStart);
export const finishMarker = L.icon(markerFinish);

export const PointPickerMap = ({
  onAddressPick,
  address,
  markerType,
  location,
  onPointPick,
}: // disabled = false,
{
  address: string;
  location: [number, number];
  onPointPick?: (locations: [number, number]) => void;
  onAddressPick?: (currentAddress: string) => void;
  markerType?: "start" | "end" | "default";
  disabled?: boolean;
} & TextInputProps) => {
  const { t } = useTranslation(["transfer_page"]);
  const [targetLocation, setTragetLocation] = useState<[number, number]>(location);
  const [currentAddress, setAddress] = useState<string>(address);
  const [currentAddressInputValue, setAddressInputValue] = useState<string>(address);
  const [center, setCenter] = useState<[number, number]>([0, 0]);
  const [centerView, setCenterView] = useState<[number, number]>([0, 0]);

  const [zoom, setZoom] = useState(13);
  const [userLocation, setUserLocation] = useState<[number, number] | null>(null);
  const disabled = false;
  const { user } = useTelegram();
  const [targetId, setTargetId] = useState<string>();

  const { refetch: findLocation } = useQuery(
    ["location", currentAddressInputValue],
    async () => {
      const response = await fetchLocation(currentAddressInputValue, user.local);

      return response;
    },
    {
      enabled: false,
      onSuccess: (data) => {
        if (!data || !data[0] || !data[0].lat || !data[0].lon) {
          return;
        }
        const { lat, lon } = data[0];

        const latitude = parseFloat(lat);
        const longitude = parseFloat(lon);

        if (!disabled && onPointPick) {
          onPointPick([latitude, longitude]);
        }

        // setLocations((prev: any) => {
        //   const newLocations = [...prev];
        //   newLocations.pop(); // удалить последнюю точку

        //   return [...newLocations, [latitude, longitude]];
        // });

        setCenter([latitude, longitude]);
        debouncedReverseGeocoder(latitude, longitude, user.local).then(async (ad) => {
          setAddress(await ad);
          if (!disabled && onAddressPick) {
            onAddressPick(await ad);
          }
          if (!disabled && onPointPick) {
            onPointPick([latitude, longitude]);
          }
        });
      },
    }
  );

  useEffect(() => {
    if (location[0] !== 0 && location[1] !== 0) {
      setCenter([location[0], location[1]]);
      setCenterView([location[0], location[1]]);
    } else {
      getGeoLocation();
    }
  }, []);

  const getGeoLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        locationSetup(latitude, longitude);
      },
      (error) => {
        console.error(error);
        fetchApproximateLocation()
          .then(({ latitude, longitude }) => {
            locationSetup(latitude, longitude);
          })
          .catch((error) => {
            console.error("Failed to fetch approximate location", error);
          });
      }
    );
  };

  const locationSetup = (latitude: number, longitude: number) => {
    setUserLocation([latitude, longitude]);
    // setLocations([[latitude, longitude]]);
    setCenter([latitude, longitude]);
    debouncedReverseGeocoder(latitude, longitude, user.local).then(async (ad) => {
      setAddress(await ad);
      if (!disabled && onAddressPick) {
        onAddressPick(await ad);
      }
      if (!disabled && onPointPick) {
        onPointPick([latitude, longitude]);
      }
    });
  };

  const fetchApproximateLocation = (): Promise<ILocation> => {
    // ip-api, который работает без ключа API для базовых запросов.
    const url = externalApis.ip;

    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch approximate location");
        }

        return response.json();
      })
      .then((data) => {
        // ip-api.com возвращает поля lat и lon для широты и долготы соответственно.
        const { lat: latitude, lon: longitude } = data;

        return { latitude, longitude };
      })
      .catch((error) => {
        console.error("Error fetching approximate location:", error);
        throw error;
      });
  };

  const handleMarkerDrag = (index: number, event: any) => {
    const newLocation: LatLngLiteral = event.target.getLatLng();
    // setLocations((prev: any) => {
    //   const newLocations = [...prev];
    //   newLocations[index] = [newLocation.lat, newLocation.lng];

    //   return newLocations;
    // });
    if (!disabled && onPointPick) {
      onPointPick([newLocation.lat, newLocation.lng]);
    }
    setCenter([newLocation.lat, newLocation.lng]);
    debouncedReverseGeocoder(newLocation.lat, newLocation.lng, user.local).then(async (ad) => {
      setAddress(await ad);
      if (!disabled && onAddressPick) {
        onAddressPick(await ad);
      }
      if (!disabled && onPointPick) {
        onPointPick([newLocation.lat, newLocation.lng]);
      }
    });
  };

  const handleMarkerMapDrag = (index: number, latLng: LatLngLiteral) => {
    const newLocation: LatLngLiteral = latLng;
    // setLocations((prev: any) => {
    //   const newLocations = [...prev];
    //   newLocations[index] = [newLocation.lat, newLocation.lng];

    //   return newLocations;
    // });
    console.log("test", location[0] === newLocation.lat && location[1] === newLocation.lng);

    if (location[0] === newLocation.lat && location[1] === newLocation.lng) {
      return;
    }
    if (!disabled && onPointPick) {
      onPointPick([newLocation.lat, newLocation.lng]);
    }
    // setCenter([newLocation.lat, newLocation.lng]);
    debouncedReverseGeocoder(newLocation.lat, newLocation.lng, user.local).then(async (ad) => {
      setAddress(await ad);
      if (!disabled && onAddressPick) {
        onAddressPick(await ad);
      }
      if (!disabled && onPointPick) {
        onPointPick([newLocation.lat, newLocation.lng]);
      }
    });
  };

  const onChangeValue = useCallback(
    (value: string) => {
      setAddressInputValue(value);
      if (!disabled && onAddressPick) {
        onAddressPick(value);
      }
    },
    [disabled, onAddressPick, setAddressInputValue]
  );

  const ZoomMap = () => {
    const map = useMapEvents({
      zoomend: () => {
        setZoom(map.getZoom());
      },
    });
    return null;
  };
  const CenterMarker = () => {
    const map = useMapEvents({
      move() {
        setCenter([map.getCenter().lat, map.getCenter().lng]);
      },
      moveend() {
        console.log("moveend", map.getCenter());
        handleMarkerMapDrag(0, map.getCenter());
      },
    });
    return null;
  };

  // console.log(userLocation, "userLocation");

  let marker = selectedMarker;
  if (markerType === "start") {
    marker = startMarker;
  } else if (markerType === "end") {
    marker = finishMarker;
  } else if (markerType === "default") {
    marker = defaultMarker;
  }

  return (
    <div>
      <Flex direction={"column"} gap={"md"} pb={"sm"}>
        <Flex direction={"row"} gap={"md"} align={"center"}>
          <AddressAutocomplete
            onChange={onChangeValue}
            onItemSubmit={(value: any) => {
              if (!value) {
                return;
              }
              findLocation();
              const { lat, lon } = value;

              const latitude = parseFloat(lat);
              const longitude = parseFloat(lon);
              // setLocations((prev: any) => {
              //   const newLocations = [...prev];
              //   newLocations.pop(); // удалить последнюю точку

              //   return [...newLocations, [latitude, longitude]];
              // });
              setCenter([latitude, longitude]);
              debouncedReverseGeocoder(latitude, longitude, user.local).then((ad: any) => {
                setAddress(ad);
                if (!disabled && onAddressPick) {
                  onAddressPick(ad);
                }
                if (!disabled && onPointPick) {
                  onPointPick([latitude, longitude]);
                }
              });
            }}
            disabled={disabled}
            width={"100%"}
            placeholder={t(`to`)}
            id={"currentAddress"}
            hasClearButton={true}
            data={[]}
          ></AddressAutocomplete>
          {/* кнопка поиска */}
          {/* <ActionIcon
            radius="xl"
            disabled={disabled}
            size="xl"
            color="violet"
            onClick={() => {
              findLocation();
            }}
            variant="outline"
          >
            <Magnifier color=""></Magnifier>
          </ActionIcon> */}
          <ActionIcon
            radius="xl"
            disabled={disabled}
            size="xl"
            color="violet"
            onClick={() => {
              getGeoLocation();
            }}
            variant="outline"
          >
            <MapPin color=""></MapPin>
          </ActionIcon>
        </Flex>
      </Flex>
      <Flex direction={"row"} pb={"sm"}>
        <Text>
          {t("Address")}: {currentAddress}
        </Text>
        {currentAddress && (
          <CopyButton value={currentAddress} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip label={copied ? t("Copied") : t("Copy")} withArrow position="right">
                <ActionIcon color={copied ? "teal" : "gray"} variant="subtle" onClick={copy}>
                  {copied ? <CheckIcon style={{ width: 16 }} /> : <Copy style={{ width: 16 }} />}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        )}
      </Flex>

      <MapContainer
        center={center}
        zoom={zoom}
        style={{ height: "50vh", width: "100%", borderRadius: "16px" }}
        attributionControl={false}
      >
        <CenterMarker></CenterMarker>
        <ZoomMap />
        <ChangeView center={center} zoom={zoom} />
        <TileLayer url={externalApis.mapbox} id="mapbox/streets-v11" />
        {/* {center && (
          <Marker
            position={center}
            icon={markerType ? marker : selectedMarker}
            zIndexOffset={1000}
            eventHandlers={{
              dragend: (e: any) => {
                if (!disabled) {
                  return handleMarkerDrag(idx, e);
                }
              },
            }}
          >
            <Popup>
              {t("Point")}
            </Popup>{" "}
          </Marker>
        )} */}

        {userLocation && (
          <Marker position={userLocation} icon={defaultMarker} draggable={false} zIndexOffset={100}>
            <Popup>{t("Your current location")}</Popup>
          </Marker>
        )}
        {center && (
          <Marker
            key={`marker-${0}`}
            position={center}
            draggable={disabled}
            icon={markerType ? marker : selectedMarker}
            zIndexOffset={1000}
          >
            <Popup>{t("Position")}</Popup>
          </Marker>
        )}
      </MapContainer>
    </div>
  );
};
