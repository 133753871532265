import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, ECity, UserLanguageEnum } from "../client";

export type TUser = User & { isAuthenticated: boolean };

type TUserState = {
  data: TUser;
};

function getInitialState(): TUserState {
  return {
    data: {
      id: "",
      tgChatId: "",
      address: "",
      role: "USER",
      appName: "Guest",
      tgName: "Guest",
      city: ECity.Kalkan,
      language: UserLanguageEnum.En,
      registered: false,
      isAuthenticated: false,
    },
  };
}

type TUserUpdateAction = PayloadAction<User>;
type TUserUpdateCityAction = PayloadAction<ECity>;
type TUserUpdateLanguageAction = PayloadAction<UserLanguageEnum>;
type TUserUpdateNameAction = PayloadAction<string>;

const userSlice = createSlice({
  name: "user",
  initialState: getInitialState(),
  reducers: {
    SetUserAction: (state, action: TUserUpdateAction) => {
      state.data = { ...action.payload, isAuthenticated: true };
    },
    SetUserNameAction: (state, action: TUserUpdateNameAction) => {
      state.data.appName = action.payload;
    },
    SetUserCityAction: (state, action: TUserUpdateCityAction) => {
      state.data.city = action.payload;
    },
    SetUserLanguageAction: (state, action: TUserUpdateLanguageAction) => {
      state.data.language = action.payload;
    },
  },
});

export const { SetUserAction, SetUserCityAction, SetUserLanguageAction, SetUserNameAction } = userSlice.actions;

export const userReducer = userSlice.reducer;

export function selectCurrentUser(state: { user: TUserState }) {
  return state.user.data;
}
