import { useState, useEffect, useCallback } from "react";
import { fetchLocation } from "../lib/fetchLocation";
import { AutocompleteCustom, AutocompleteCustomProps } from "../../UI/Vendors/Mantine/AutocompleteCustom";
import { useTelegram } from "../../../hooks/useTelegram";

type AddressAutocompleteProps = {
  onChange: any;
} & AutocompleteCustomProps;

const MAX_ROW_LENGTH = 35;
const allowSuggestedCountry = ["Turkey", "Турция", "Türkiye"];

export const AddressAutocomplete = ({ onChange, onItemSubmit, ...props }: AddressAutocompleteProps) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [suggestionLocations, setSuggestionLocations] = useState<any[]>([]);
  const { user } = useTelegram();

  const fetchSuggestions = async () => {
    if (query.length > 2) {
      try {
        const locations = (await fetchLocation(query, user.local)).filter((location: any) => {
          if (!location || !location.display_name) {
            return false;
          }
          return (
            location.display_name.includes(allowSuggestedCountry[0]) ||
            location.display_name.includes(allowSuggestedCountry[1]) ||
            location.display_name.includes(allowSuggestedCountry[2])
          );
        });
        setSuggestionLocations(locations);
        const locationLabels = locations.map((location: any) => {
          return location.display_name;
        });
        setSuggestions(locationLabels);
      } catch (error) {
        console.error("Error fetching address suggestions:", error);
      }
    }
  };

  const handleSelectedLocation = useCallback(
    ({ value: locationName }: any) => {
      if (onItemSubmit) {
        onItemSubmit(suggestionLocations.filter((location) => location.display_name === locationName)[0]);
      }
    },
    [onItemSubmit, suggestionLocations]
  );

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      fetchSuggestions();
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [query]);

  useEffect(() => {
    // Ваш код здесь будет выполняться каждый раз, когда обновляется suggestions
  }, [suggestions]);
  return (
    <AutocompleteCustom
      {...props}
      // label="Search for an address"
      placeholder="Start typing..."
      value={query}
      onItemSubmit={handleSelectedLocation}
      onChange={(value: string) => {
        onChange(value);
        setQuery(value);
      }}
      limit={5}
      data={suggestions}
      // onDropdownClose={() => setSuggestions([])}
    />
  );
};

export default AddressAutocomplete;
