/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { YachtRoute } from './YachtRoute';
import {
    YachtRouteFromJSON,
    YachtRouteFromJSONTyped,
    YachtRouteToJSON,
} from './YachtRoute';

/**
 * 
 * @export
 * @interface YachtsYachtRoutesYachtIdGet200Response
 */
export interface YachtsYachtRoutesYachtIdGet200Response {
    /**
     * 
     * @type {Array<YachtRoute>}
     * @memberof YachtsYachtRoutesYachtIdGet200Response
     */
    yachtRoutes?: Array<YachtRoute>;
}

/**
 * Check if a given object implements the YachtsYachtRoutesYachtIdGet200Response interface.
 */
export function instanceOfYachtsYachtRoutesYachtIdGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function YachtsYachtRoutesYachtIdGet200ResponseFromJSON(json: any): YachtsYachtRoutesYachtIdGet200Response {
    return YachtsYachtRoutesYachtIdGet200ResponseFromJSONTyped(json, false);
}

export function YachtsYachtRoutesYachtIdGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): YachtsYachtRoutesYachtIdGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'yachtRoutes': !exists(json, 'yachtRoutes') ? undefined : ((json['yachtRoutes'] as Array<any>).map(YachtRouteFromJSON)),
    };
}

export function YachtsYachtRoutesYachtIdGet200ResponseToJSON(value?: YachtsYachtRoutesYachtIdGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'yachtRoutes': value.yachtRoutes === undefined ? undefined : ((value.yachtRoutes as Array<any>).map(YachtRouteToJSON)),
    };
}

