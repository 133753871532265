/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SeaTripOrderLookup } from './SeaTripOrderLookup';
import {
    SeaTripOrderLookupFromJSON,
    SeaTripOrderLookupFromJSONTyped,
    SeaTripOrderLookupToJSON,
} from './SeaTripOrderLookup';

/**
 * 
 * @export
 * @interface SeaTripsUserSeaTripsOrdersUserIdGet200Response
 */
export interface SeaTripsUserSeaTripsOrdersUserIdGet200Response {
    /**
     * 
     * @type {Array<SeaTripOrderLookup>}
     * @memberof SeaTripsUserSeaTripsOrdersUserIdGet200Response
     */
    userSeaTripOrdersLookups?: Array<SeaTripOrderLookup>;
}

/**
 * Check if a given object implements the SeaTripsUserSeaTripsOrdersUserIdGet200Response interface.
 */
export function instanceOfSeaTripsUserSeaTripsOrdersUserIdGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SeaTripsUserSeaTripsOrdersUserIdGet200ResponseFromJSON(json: any): SeaTripsUserSeaTripsOrdersUserIdGet200Response {
    return SeaTripsUserSeaTripsOrdersUserIdGet200ResponseFromJSONTyped(json, false);
}

export function SeaTripsUserSeaTripsOrdersUserIdGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeaTripsUserSeaTripsOrdersUserIdGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userSeaTripOrdersLookups': !exists(json, 'userSeaTripOrdersLookups') ? undefined : ((json['userSeaTripOrdersLookups'] as Array<any>).map(SeaTripOrderLookupFromJSON)),
    };
}

export function SeaTripsUserSeaTripsOrdersUserIdGet200ResponseToJSON(value?: SeaTripsUserSeaTripsOrdersUserIdGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userSeaTripOrdersLookups': value.userSeaTripOrdersLookups === undefined ? undefined : ((value.userSeaTripOrdersLookups as Array<any>).map(SeaTripOrderLookupToJSON)),
    };
}

