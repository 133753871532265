import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en, ru } from "./config";

const resources = {
  EN: en,
  RU: ru,
};

i18n.use(initReactI18next).init({
  resources,
  lng: "RU",
  fallbackLng: "RU",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
