/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Yacht } from './Yacht';
import {
    YachtFromJSON,
    YachtFromJSONTyped,
    YachtToJSON,
} from './Yacht';

/**
 * 
 * @export
 * @interface YachtsYachtByIdYachtIdGet200Response
 */
export interface YachtsYachtByIdYachtIdGet200Response {
    /**
     * 
     * @type {Yacht}
     * @memberof YachtsYachtByIdYachtIdGet200Response
     */
    yacht?: Yacht;
}

/**
 * Check if a given object implements the YachtsYachtByIdYachtIdGet200Response interface.
 */
export function instanceOfYachtsYachtByIdYachtIdGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function YachtsYachtByIdYachtIdGet200ResponseFromJSON(json: any): YachtsYachtByIdYachtIdGet200Response {
    return YachtsYachtByIdYachtIdGet200ResponseFromJSONTyped(json, false);
}

export function YachtsYachtByIdYachtIdGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): YachtsYachtByIdYachtIdGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'yacht': !exists(json, 'yacht') ? undefined : YachtFromJSON(json['yacht']),
    };
}

export function YachtsYachtByIdYachtIdGet200ResponseToJSON(value?: YachtsYachtByIdYachtIdGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'yacht': YachtToJSON(value.yacht),
    };
}

