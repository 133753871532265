import { restaurantsApi } from "../../../apiDrivers/restaurants";

const fetchRestaurants = async ({ queryKey }: any) => {
  const initData = queryKey[1];

  const res = await restaurantsApi.foodRestaurantsListGet({
    initData,
  });

  if (!res.restaurantsList) {
    throw new Error(`Restaurants list fetch failed`);
  }

  return res.restaurantsList;
};

export default fetchRestaurants;
