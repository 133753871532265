// import { normalizeRestaurant } from "./definitions";

import { Minus, Plus } from "@gravity-ui/icons";
import { ActionIcon, Button, Flex, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { modals } from "@mantine/modals";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { PriceBadge } from "../../components/PriceBadge";
import { LoaderPageCustom } from "../../components/UI/Vendors/Mantine/LoaderPageCustom";
import { assets, ECurrency, RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import { ItemBase } from "../../modules/Items/ItemBase";
import {
  AddItemAction,
  ClearCartAction,
  getFoodCartPrice,
  RemoveItemAction,
  selectCartItemCountById,
  selectDeliveryCartList,
  selectDeliveryCartRestaurant,
  SetRestaurantNameAction,
} from "../../store/deliveryCartStore";
import { TRestaurantMenuItem } from "../DeliveryMenuPage/definitions";
import fetchFoodById from "./api";
import { normalizeFoodById } from "./definitions";
import { selectCurrentUser } from "../../store/userStore";
import { EOrderPageIsType, OrderPageFoodOrder } from "../OrderPage/definitions";

interface IItemDelivery {
  optionItem?: {
    programme?: string;
    image: string;
    name: string;
    description: string;
    long_description?: string;
    price: number;
    count: number;
    id: number | string;
  };
  // id?: string;
}
// TODO: проблемы с изменением числа вызванных хуков
export const DeliveryFoodItemPage = ({
  optionItem = {
    // TODO: когда в будет ручка сделать дефолтную картинку
    // image: "No Image",
    image: assets.delivery_img,
    name: "No Name",
    description: "No description",
    price: 0,
    count: 0,
    id: 0,
  },
}: // id,
IItemDelivery): JSX.Element => {
  const { initData } = useTelegram();
  const { id } = useParams();
  const location = useLocation();
  const locationState = location.state;

  const restaurant = locationState.restaurant;

  const menuItem = locationState.menuItem;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const cartItemCount = useSelector(selectCartItemCountById(menuItem.id));

  const deliveryCartList = useSelector(selectDeliveryCartList);

  const storedRestaurant = useSelector(selectDeliveryCartRestaurant);

  const summaryCartPrice = useSelector(getFoodCartPrice);

  const currentUser = useSelector(selectCurrentUser);

  const toOrderPage = useFn(() => {
    if (!deliveryCartList.length) {
      return;
    }
    navigate(RouterPath.order.complete, {
      state: {
        data: getDeliveryOrder(),
        type: EOrderPageIsType.IS_FOOD_DELIVERY,
      },
    });
  });

  const getDeliveryOrder = useFn(() => {
    const foodOrder: OrderPageFoodOrder = {
      address: currentUser.address || "",
      deliveryDate: new Date().toString(),
      price: summaryCartPrice,
      userId: currentUser.id,
      restaurantId: storedRestaurant.id ?? restaurant.id,
      restaurantName: storedRestaurant.title ?? restaurant.title,
      items: deliveryCartList.map((item) => ({ foodId: item.id, count: item.count, title: item.name })),
      comment: "",
      paymentMethod: "CASH",
      contactType: "TELEGRAM",
      contact: ``,
      currency: ECurrency.TRY,
    };
    return foodOrder;
  });

  const dispatchAddNewItem = useFn((item: TRestaurantMenuItem, restaurantId: string) => {
    dispatch(
      AddItemAction({
        ...item,
        restaurantId,
      })
    );
  });

  const dispatchRemoveItem = useFn((item: TRestaurantMenuItem) => {
    dispatch(
      RemoveItemAction({
        id: item.id,
      })
    );
  });

  const setRestaurantName = useFn(
    (restaurant: { title: string; id: string; deliveryPrice?: number; deliveryPriceThreshold?: number }) => {
      dispatch(
        SetRestaurantNameAction({
          title: restaurant.title,
          id: restaurant.id,
          deliveryPrice: restaurant.deliveryPrice,
          deliveryPriceThreshold: restaurant.deliveryPriceThreshold,
        })
      );
    }
  );

  const onClickMinus = useCallback(() => {
    dispatchRemoveItem(menuItem);
  }, [menuItem]);

  const onClickPlus = useCallback(() => {
    if (storedRestaurant.id && storedRestaurant.id !== restaurant.id) {
      modals.openConfirmModal({
        title: "Please confirm your action",
        children: <Text size="sm">Your order from other restaurant will be cleared</Text>,
        labels: { confirm: "Continue and clear previous order", cancel: "Cancel" },
        onCancel: () => {},
        onConfirm: () => {
          dispatch(ClearCartAction());
          setRestaurantName(restaurant);
          dispatchAddNewItem(menuItem, restaurant.id);
        },
      });
    } else {
      setRestaurantName(restaurant);
      dispatchAddNewItem(menuItem, restaurant.id);
    }
  }, [menuItem]);

  const onClickNavigateBack = useFn(() => {
    navigate(-1);
  });

  const foodByIdQuery = useQuery(["food of restaurant", initData, id], fetchFoodById, {
    onError: (error) => {
      console.error(error);
    },
  });

  if (foodByIdQuery.isLoading || !foodByIdQuery.data) {
    return (
      <>
        <LoaderPageCustom></LoaderPageCustom>
      </>
    );
  }
  const foodItem = normalizeFoodById(foodByIdQuery.data);

  const renderFoodItemPart = () => {
    return (
      <>
        <Flex direction={"column"} gap={"lg"}>
          <Flex className="itemCounter" justify="center" gap="lg" align="center">
            <ActionIcon size={50} radius="lg" variant="filled" color="violet" onClick={onClickMinus}>
              <Minus></Minus>
            </ActionIcon>
            <PriceBadge
              hasCounter={true}
              currentCounter={cartItemCount}
              onIconClick={toOrderPage}
              onBadgeClick={toOrderPage}
              banknote={ECurrency.TRY}
              price={100}
              size="big"
            ></PriceBadge>
            <ActionIcon size={50} radius="lg" variant="filled" color="violet" onClick={onClickPlus}>
              <Plus></Plus>
            </ActionIcon>
          </Flex>
          <Flex gap={"lg"} wrap={"wrap-reverse"}>
            <Button
              fullWidth={true}
              onClick={onClickNavigateBack}
              variant="filled"
              color="violet"
              radius="lg"
              size="xl"
            >
              Back to menu
            </Button>
          </Flex>
        </Flex>
      </>
    );
  };
  return (
    <>
      <ItemBase optionItem={{ ...foodItem, count: cartItemCount! }} children={renderFoodItemPart()}></ItemBase>
      <NavigationBar></NavigationBar>
      {/* mb={type === "delivery" ? "10vh" : "0vh"} */}
      {/* {type === "delivery" && <></>} */}
    </>
  );
};
