/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceLookup
 */
export interface ServiceLookup {
    /**
     * 
     * @type {string}
     * @memberof ServiceLookup
     */
    count?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLookup
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLookup
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLookup
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLookup
     */
    routeTitle: string;
}

/**
 * Check if a given object implements the ServiceLookup interface.
 */
export function instanceOfServiceLookup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "routeTitle" in value;

    return isInstance;
}

export function ServiceLookupFromJSON(json: any): ServiceLookup {
    return ServiceLookupFromJSONTyped(json, false);
}

export function ServiceLookupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLookup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'title': json['title'],
        'description': json['description'],
        'image': json['image'],
        'routeTitle': json['routeTitle'],
    };
}

export function ServiceLookupToJSON(value?: ServiceLookup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'title': value.title,
        'description': value.description,
        'image': value.image,
        'routeTitle': value.routeTitle,
    };
}

