import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TelegramAuthData } from "@telegram-auth/react";
import { useDispatch, useSelector } from "react-redux";
import { RouterPath } from "../definitions";
import { selectCurrentUser, SetUserNameAction } from "../store/userStore";
import { selectCurrentTGUser, SetTGUserAction } from "../store/telegramAuthUserStore";

// @ts-ignore
export const telegram = window.Telegram.WebApp;

interface TelegramProps {
  user: { id: string; tgName: string; chatId?: string; local: string };
  initData: string;
  isAdmin: boolean;
  isExpanded: boolean;
  expandMethod: () => void;
  backButton?: any;
  backButtonClicked?: boolean;
  onAuthCallback: (user: TelegramAuthData) => void;
  tgImage?: string;
  webApp?: any;
}

export function useTelegram(): TelegramProps {
  const dispatch = useDispatch();

  const currentTGUser = useSelector(selectCurrentTGUser);

  const onAuthCallback = useCallback((user: TelegramAuthData) => {
    if (user.id) {
      dispatch(SetTGUserAction(user));
    }
  }, []);

  if (telegram.initData === "" && currentTGUser.id) {
    return {
      user: {
        id: String(currentTGUser.id),
        tgName: currentTGUser.username,
        local: "ru",
      } as TelegramProps["user"],
      initData: JSON.stringify(currentTGUser),
      isAdmin: false,
      isExpanded: telegram.isExpanded as boolean,
      expandMethod: telegram.expand,
      backButton: telegram.BackButton,
      backButtonClicked: telegram.backButtonClicked,
      tgImage: currentTGUser.photo_url,
      onAuthCallback,
    };
  }

  // @ts-ignore
  if (!telegram.isClosingConfirmationEnabled && telegram.version >= "6") {
    if (typeof telegram.enableClosingConfirmation === "function") {
      telegram.enableClosingConfirmation();
    } else {
      telegram.enableClosingConfirmation = true;
    }
  }

  if (telegram.version === "6") {
    return {
      user: {
        id: telegram.initDataUnsafe.user?.id,
        tgName: telegram.initDataUnsafe?.user?.username,
        local: telegram.initDataUnsafe?.user?.language_code,
      } as TelegramProps["user"],
      initData: telegram.initData as string,
      isExpanded: telegram.isExpanded as boolean,
      expandMethod: telegram.expand,
      isAdmin: false,
      onAuthCallback,
    };
  }

  return {
    user: {
      id: telegram.initDataUnsafe.user?.id,
      tgName: telegram.initDataUnsafe?.user?.username,
      local: telegram.initDataUnsafe?.user?.language_code,
    } as TelegramProps["user"],
    initData: telegram.initData as string,
    isExpanded: telegram.isExpanded as boolean,
    expandMethod: telegram.expand,
    backButton: telegram.BackButton,
    backButtonClicked: telegram.backButtonClicked,
    webApp: telegram,
    isAdmin: false,
    onAuthCallback,
  };
}
