import { restaurantsApi } from "../../../apiDrivers/restaurants";
import { FoodOrder } from "../../../client";

export const createFoodOrderQuery = async (foodOrder: FoodOrder, initData: string) => {
  const res = await restaurantsApi.foodUserFoodOrdersCreatePost({
    foodUserFoodOrdersCreatePostRequest: {
      foodOrder,
      initData,
    },
  });

  if (!res.id) {
    throw new Error("Failed to create food order. Please contact support");
  }

  return res.id;
};
