/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EContactType } from './EContactType';
import {
    EContactTypeFromJSON,
    EContactTypeFromJSONTyped,
    EContactTypeToJSON,
} from './EContactType';
import type { ExcursionOrderExcursion } from './ExcursionOrderExcursion';
import {
    ExcursionOrderExcursionFromJSON,
    ExcursionOrderExcursionFromJSONTyped,
    ExcursionOrderExcursionToJSON,
} from './ExcursionOrderExcursion';
import type { ExcursionOrderStatus } from './ExcursionOrderStatus';
import {
    ExcursionOrderStatusFromJSON,
    ExcursionOrderStatusFromJSONTyped,
    ExcursionOrderStatusToJSON,
} from './ExcursionOrderStatus';
import type { ExcursionOrderUpdateStatus } from './ExcursionOrderUpdateStatus';
import {
    ExcursionOrderUpdateStatusFromJSON,
    ExcursionOrderUpdateStatusFromJSONTyped,
    ExcursionOrderUpdateStatusToJSON,
} from './ExcursionOrderUpdateStatus';

/**
 * 
 * @export
 * @interface ExcursionOrder
 */
export interface ExcursionOrder {
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrder
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrder
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrder
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof ExcursionOrder
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrder
     */
    contact: string;
    /**
     * 
     * @type {EContactType}
     * @memberof ExcursionOrder
     */
    contactType: EContactType;
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrder
     */
    excursionId: string;
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrder
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrder
     */
    excursionDate: string;
    /**
     * 
     * @type {number}
     * @memberof ExcursionOrder
     */
    personsCount: number;
    /**
     * 
     * @type {number}
     * @memberof ExcursionOrder
     */
    childrenCount: number;
    /**
     * 
     * @type {ExcursionOrderUpdateStatus}
     * @memberof ExcursionOrder
     */
    status?: ExcursionOrderUpdateStatus;
    /**
     * 
     * @type {Array<ExcursionOrderStatus>}
     * @memberof ExcursionOrder
     */
    statusHistory?: Array<ExcursionOrderStatus>;
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrder
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof ExcursionOrder
     */
    paymentMethod: string;
    /**
     * 
     * @type {ExcursionOrderExcursion}
     * @memberof ExcursionOrder
     */
    excursion?: ExcursionOrderExcursion;
}

/**
 * Check if a given object implements the ExcursionOrder interface.
 */
export function instanceOfExcursionOrder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "contact" in value;
    isInstance = isInstance && "contactType" in value;
    isInstance = isInstance && "excursionId" in value;
    isInstance = isInstance && "excursionDate" in value;
    isInstance = isInstance && "personsCount" in value;
    isInstance = isInstance && "childrenCount" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "paymentMethod" in value;

    return isInstance;
}

export function ExcursionOrderFromJSON(json: any): ExcursionOrder {
    return ExcursionOrderFromJSONTyped(json, false);
}

export function ExcursionOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExcursionOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': json['userId'],
        'address': json['address'],
        'price': json['price'],
        'contact': json['contact'],
        'contactType': EContactTypeFromJSON(json['contactType']),
        'excursionId': json['excursionId'],
        'creationDate': !exists(json, 'creationDate') ? undefined : json['creationDate'],
        'excursionDate': json['excursionDate'],
        'personsCount': json['personsCount'],
        'childrenCount': json['childrenCount'],
        'status': !exists(json, 'status') ? undefined : ExcursionOrderUpdateStatusFromJSON(json['status']),
        'statusHistory': !exists(json, 'statusHistory') ? undefined : ((json['statusHistory'] as Array<any>).map(ExcursionOrderStatusFromJSON)),
        'comment': json['comment'],
        'paymentMethod': json['paymentMethod'],
        'excursion': !exists(json, 'excursion') ? undefined : ExcursionOrderExcursionFromJSON(json['excursion']),
    };
}

export function ExcursionOrderToJSON(value?: ExcursionOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'address': value.address,
        'price': value.price,
        'contact': value.contact,
        'contactType': EContactTypeToJSON(value.contactType),
        'excursionId': value.excursionId,
        'creationDate': value.creationDate,
        'excursionDate': value.excursionDate,
        'personsCount': value.personsCount,
        'childrenCount': value.childrenCount,
        'status': ExcursionOrderUpdateStatusToJSON(value.status),
        'statusHistory': value.statusHistory === undefined ? undefined : ((value.statusHistory as Array<any>).map(ExcursionOrderStatusToJSON)),
        'comment': value.comment,
        'paymentMethod': value.paymentMethod,
        'excursion': ExcursionOrderExcursionToJSON(value.excursion),
    };
}

