import { userApi } from "../../../apiDrivers/user";
import { ECity } from "../../../client";

export const sendSupportMessageRequest = async (
  initData: string,
  userId: string,
  tgName: string,
  userCity: ECity,
  message: string,
  appName: string,
  contact: string,
) => {
  const res = await userApi.userSendSupportMessagePost({
    userSendSupportMessagePostRequest: {
      initData,
      userId,
      tgName,
      userCity,
      message,
      contact,
      appName,
    },
  });

  return res;
};
