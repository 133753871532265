import {
  EContactType,
  ExcursionOrder,
  FoodOrder,
  FoodOrderPaymentMethodEnum,
  SeaTripOrder,
  Transfer,
} from "../../client";
import { ECurrency } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { TUser } from "../../store/userStore";
import {
  EOrderPageIsType,
  OrderPageLocationState,
  TOrderFormValues,
  mapFoodOrderPaymentMethodEnum,
  mapHumanStringToFoodOrderPaymentMethodEnum,
} from "./definitions";

export function initializeForm(currentUser: TUser, locationState: OrderPageLocationState) {
  // @ts-ignore typescript botched
  const startPrice = locationState.data.price ?? locationState.data.priceAdult;

  return {
    contactType: EContactType.Telegram,
    contact: currentUser.tgName ? `@${currentUser.tgName}` : "",
    address: currentUser.address ?? "",
    adultsCount: 1,
    childrenCount: 0,
    babySeatCount: 0,
    luggageCount: 0,
    paymentMethod: mapFoodOrderPaymentMethodEnum[FoodOrderPaymentMethodEnum.Cash],
    paymentCurrency: ECurrency.EUR,
    comment: "",
    price: startPrice,
  };
}

export function createFoodOrder(
  currentUser: TUser,
  formValues: TOrderFormValues,
  locationStateData: FoodOrder
): FoodOrder {
  return {
    address: formValues.address,
    deliveryDate: locationStateData.deliveryDate,
    price: formValues.price,
    userId: currentUser.id,
    restaurantId: locationStateData.restaurantId,
    restaurantName: locationStateData.restaurantName,
    items: locationStateData.items,
    comment: formValues.comment,
    paymentMethod: mapHumanStringToFoodOrderPaymentMethodEnum[formValues.paymentMethod],
    contactType: formValues.contactType,
    contact: formValues.contact,
  };
}

export function createExcursionOrder(
  currentUser: TUser,
  formValues: TOrderFormValues,
  locationStateData: ExcursionOrder
): ExcursionOrder {
  return {
    userId: currentUser.id,
    contact: formValues.contact,
    contactType: formValues.contactType,
    address: formValues.address,
    price: formValues.price,
    excursionId: locationStateData.excursionId,
    excursionDate: locationStateData.excursionDate,
    personsCount: formValues.adultsCount!,
    childrenCount: formValues.childrenCount!,
    paymentMethod: mapHumanStringToFoodOrderPaymentMethodEnum[formValues.paymentMethod],
    comment: formValues.comment,
  };
}

export function createSeaTripOrder(
  currentUser: TUser,
  formValues: TOrderFormValues,
  locationStateData: SeaTripOrder
): SeaTripOrder {
  return {
    userId: currentUser.id,
    contact: formValues.contact,
    contactType: formValues.contactType,
    address: formValues.address,
    seaTripId: locationStateData.seaTripId,
    price: formValues.price,
    actualSeaTripDate: locationStateData.actualSeaTripDate,
    personsCount: formValues.adultsCount!,
    childrenCount: formValues.childrenCount!,
    paymentMethod: mapHumanStringToFoodOrderPaymentMethodEnum[formValues.paymentMethod],
    comment: formValues.comment,
  };
}

export function createTransferOrder(
  currentUser: TUser,
  formValues: TOrderFormValues,
  locationStateData: Transfer
): Transfer {
  return {
    userId: currentUser.id,
    contact: formValues.contact,
    contactType: formValues.contactType,
    comment: formValues.comment,
    approximatePrice: formValues.price,
    babySeats: formValues.babySeatCount,
    carType: locationStateData.carType,
    date: locationStateData.date,
    time: locationStateData.time,
    flightNumber: formValues.flightNumber,
    from: locationStateData.from,
    fromPointLat: locationStateData.fromPointLat,
    fromPointLon: locationStateData.fromPointLon,
    to: locationStateData.to,
    toPointLat: locationStateData.toPointLat,
    toPointLon: locationStateData.toPointLon,
    luggage: formValues.luggageCount?.toString(),
    personCount: formValues.adultsCount,
    status: "CREATED",
    passengerNames: formValues.passengerNames,
  };
}

export function useGetPrice(locationState: OrderPageLocationState) {
  return useFn((values: TOrderFormValues) => {
    if (!values.adultsCount && !values.childrenCount) {
      if (locationState.type === EOrderPageIsType.IS_FOOD_DELIVERY) {
        // TODO;
        return -1;
      } else return 0;
    }
    if (locationState.type === EOrderPageIsType.IS_TRANSFER) {
      if ("price" in locationState.data && "from" in locationState.data && "to" in locationState.data) {
        return locationState.data?.price;
      }
    }

    const adults = values.adultsCount!;
    const children = values.childrenCount!;

    if ("pricePolicy" in locationState.data && locationState.data.pricePolicy.length) {
      const peopleSum = adults + children;
      for (const region of locationState.data.pricePolicy) {
        if (peopleSum <= region.personsCount) {
          return region.price;
        }
      }
      return locationState.data.pricePolicy[0].price;
    } else if ("price" in locationState.data) {
      const price = locationState.data.price;
      return price * adults + price * children;
    } else if ("priceAdult" in locationState.data) {
      const priceAdult = locationState.data.priceAdult;
      const priceKids = locationState.data.priceKids;
      return priceAdult * adults + priceKids * children;
    } else {
      return 0;
    }
  });
}
