import { useTranslation } from "react-i18next";
import { Carousel } from "@mantine/carousel";
import { Flex, Group, Image, Text } from "@mantine/core";
import ReactMarkdown from "react-markdown";
import { NavigationText } from "../../../components/NavigationText/NavigationText";
import { assets } from "../../../definitions";
import "./style.css";

export interface IItemBase {
  optionItem?: {
    // programme?: string;
    image: string;
    name: string;
    description: string;
    // long_description?: string;
    price: number;
    count?: number;
    images: string[];
  };
  title?: string;
  children?: React.ReactNode;
}

export const ItemBase = ({
  optionItem = {
    image: assets.delivery_img,
    images: [],
    name: "",
    description: "No description",
    price: 0,
    count: 0,
  },
  title,
  children,
}: IItemBase): JSX.Element => {
  const item = optionItem;

  const { t } = useTranslation(["item_base"]);

  const renderCarouselSlides = item.images.map((image, index) => {
    return (
      <Carousel.Slide key={`${image}_${index}`} style={{ backgroundColor: "transparent" }}>
        <Image className="ItemPage__image" height={300} radius="lg" src={image} alt="item image" />
      </Carousel.Slide>
    );
  });
  return (
    <>
      {Boolean(optionItem.name) && (
        <NavigationText
          arrow={{
            isShow: true,
          }}
          title={title || t("Back")}
        ></NavigationText>
      )}

      <div className="ItemPage__content">
        {item.images?.length > 1 && (
          <Carousel
            className="ItemPage__image"
            style={{ backgroundColor: "transparent" }}
            height={300}
            withIndicators
            color="violet"
            slideGap={"lg"}
            loop={true}
          >
            {renderCarouselSlides}
          </Carousel>
        )}
        {Boolean(item.name && item.images?.length && item.image) && (
          <Flex className="ItemPage__header" justify="start" wrap="wrap">
            {item.images?.length === 1 && (
              <>
                <Image className="ItemPage__image" height={300} radius="lg" src={item.image} alt="item image" />
              </>
            )}
            <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
              <Text className="mainCard__title" color="" weight={700}>
                {item.name}
              </Text>
            </Group>
          </Flex>
        )}
        <Group position="apart" mt="md" mb="xs">
          <div className={"clamped"}>
            <ReactMarkdown children={item.description} />
          </div>
        </Group>
        {children}
      </div>
      {/* <NavigationBar mb={type === "delivery" ? "10vh" : "0vh"}>{type === "delivery" && <></>}</NavigationBar> */}
    </>
  );
};
