import "./style.css";
import React from "react";

import { LoginButton, TelegramAuthData } from "@telegram-auth/react";
import { Flex } from "@mantine/core";

export type TTelegramAuthProps = {
  onAuthCallback: (user: TelegramAuthData) => void;
  authCallbackUrl?: string;
};

export const TelegramAuth = (props: TTelegramAuthProps) => {
  return (
    <Flex direction={"column"}>
      <LoginButton
        botUsername={"travolza_bot"}
        lang="ru"
        cornerRadius={10}
        buttonSize={"large"}
        onAuthCallback={props.onAuthCallback}
        requestAccess={"write"}
        showAvatar={true}
      />
    </Flex>
  );
};
