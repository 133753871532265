import { useQuery } from "@tanstack/react-query";
import { useFn } from "../../../hooks/useFn";
import { fetchUserSeaTripsOrdersList, normalizeSeaTripOrder } from "../api/seaTripOrders";

export default function useSeaTripsOrders(userId: string, initData: string) {
  return useQuery({
    queryKey: [`${userId} sea trips orders`, initData, userId],
    queryFn: fetchUserSeaTripsOrdersList,
    select: useFn(normalizeSeaTripOrder),
    onError: (error) => {
      console.log(error);
    },
  });
}
