import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FoodOrder } from "../../client";

type TFoodOrdersState = {
  orders: FoodOrder[];
};

type TAddOrderAction = PayloadAction<FoodOrder>;
type TOrderRemoveAction = PayloadAction<{ id: string }>;
type TRemoveOrderAction = TOrderRemoveAction & { payload: { id: string } };
type TUpdateOrderAction = PayloadAction<FoodOrder>;

function getInitialState(): TFoodOrdersState {
  return {
    orders: [],
  };
}

const foodOrdersSlice = createSlice({
  name: "foodOrders",
  initialState: getInitialState(),
  reducers: {
    AddOrderAction: (state, action: TAddOrderAction) => {
      state.orders.push(action.payload);
    },
    RemoveOrderAction: (state, action: TRemoveOrderAction) => {
      const { id } = action.payload;
      const orderIndex = state.orders.findIndex((order) => order.id === id);

      if (orderIndex) {
        state.orders = state.orders.filter((order) => order.id !== id);
      }
    },
    UpsertOrderAction: (state, action: TUpdateOrderAction) => {
      const { id } = action.payload;

      const orderIndex = state.orders.findIndex((order) => order.id === id);
      if (orderIndex !== -1) {
        state.orders[orderIndex] = action.payload;
      } else {
        state.orders.push(action.payload);
      }
    },
    ClearOrdersAction: (state) => {
      state.orders = [];
    },
  },
});

const { AddOrderAction, RemoveOrderAction, UpsertOrderAction } = foodOrdersSlice.actions;

export { AddOrderAction as AddFoodOrderAction };
export { RemoveOrderAction as RemoveFoodOrderAction };
export { UpsertOrderAction as UpsertFoodOrderAction };

export const foodOrdersReducer = foodOrdersSlice.reducer;
