import { CloseButton, Input, InputProps, TextInput, TextInputProps } from "@mantine/core";
import {
  ChangeEvent,
  ElementType,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

type TextInputCustomProps = {
  component?: any;
  hasClearButton?: boolean;
  ref?: any;
} & TextInputProps;

export const TextInputCustom = ({
  children,
  component,
  label,
  value,
  hasClearButton = true,
  onChange,
  rightSection,
  ref,
  required = true,
  ...textInputProps
}: TextInputCustomProps) => {
  const Component = component ? Input : TextInput;

  const [currentValue, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const onChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange?.(e);
  }, []);

  return (
    <Input.Wrapper label={label} required={required} size={"lg"}>
      <Component
        {...textInputProps}
        value={currentValue}
        component={component}
        ref={ref}
        onChange={onChangeInput}
        size={"lg"}
        radius={"lg"}
        rightsectionpointerevents="all"
        rightSection={
          hasClearButton ? (
            <CloseButton
              aria-label="Clear input"
              onClick={() => {
                setValue("");
              }}
              style={{ display: currentValue ? undefined : "none" }}
            />
          ) : (
            rightSection
          )
        }
        styles={(theme) => ({
          input: {
            "&:focus-within": {
              borderColor: theme.colors.violet[9],
            },
          },
          item: {
            "&[data-selected]": {
              "&, &:hover": {
                backgroundColor: theme.colors.violet[0],
                color: theme.colors.violet[9],
              },
            },
            "&[data-hovered]": {},
          },
        })}
      >
        {children}
      </Component>
    </Input.Wrapper>
  );
};
