import { transfersApi } from "../../../apiDrivers/transfers";
import { Transfer } from "../../../client";

export const createTransferOrderQuery = async (transferOrder: Transfer, initData: string) => {
  const res = await transfersApi.transferCreateTransferPost({
    transferInput: {
      ...transferOrder,
      initData,
    },
  });

  return res;
};
