import { Button, Divider, Flex, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MenuItem } from "../../components/MenuItem";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { NavigationText } from "../../components/NavigationText/NavigationText";
import { EColors, ECurrency, RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { getFoodCartPrice, selectDeliveryCartList, selectDeliveryCartRestaurant } from "../../store/deliveryCartStore";
import { selectCurrentUser } from "../../store/userStore";
import { EOrderPageIsType, OrderPageFoodOrder } from "../OrderPage/definitions";

export const CartPage = ({ pageName, pageDescription }: { pageName?: string; pageDescription?: string }) => {
  const deliveryCartList = useSelector(selectDeliveryCartList);
  const restaurant = useSelector(selectDeliveryCartRestaurant);

  const summaryCartPrice = useSelector(getFoodCartPrice);

  const { t } = useTranslation(["order_page"]);

  const currentUser = useSelector(selectCurrentUser);

  const navigate = useNavigate();

  const toOrderPage = useFn(() => {
    navigate(RouterPath.order.complete, {
      state: {
        data: getDeliveryOrder(),
        type: EOrderPageIsType.IS_FOOD_DELIVERY,
      },
    });
  });

  const getDeliveryOrder = useFn(() => {
    const foodOrder: OrderPageFoodOrder = {
      address: currentUser.address || "",
      deliveryDate: new Date().toString(),
      price: summaryCartPrice,
      userId: currentUser.id,
      restaurantId: restaurant.id!,
      restaurantName: restaurant.title!,
      items: deliveryCartList.map((item) => ({ foodId: item.id, count: item.count, title: item.name })),
      comment: "",
      paymentMethod: "CASH",
      contactType: "TELEGRAM",
      contact: ``,
      currency: ECurrency.TRY,
    };
    return foodOrder;
  });

  // TODO: добавить поле категории
  const menuCategoryList = [
    {
      id: "menu",
      name: "Menu",
      menuCategoryItems: deliveryCartList,
    },
  ];
  const cardsList = menuCategoryList.map((menuCategoryItem) => {
    const menuItemList = menuCategoryItem.menuCategoryItems.map((menuItem) => {
      return (
        <MenuItem
          menuItem={menuItem}
          restaurantId={menuItem.restaurantId}
          onMenuItemClick={() => {}}
          key={menuItem.id}
          counter={{
            hasCounter: true,
            updateCount: (value) => {
              console.log(`update value=${value} menuItem=${menuItem.name}`);
            },
          }}
          onBadgeClick={function (param: any): void {
            throw new Error("Function not implemented.");
          }}
        ></MenuItem>
      );
    });

    return (
      <Flex gap="lg" direction={"column"} key={menuCategoryItem.id}>
        <Text weight={700} size={22}>
          {/* {menuCategoryItem.name} */}
        </Text>
        <Flex gap="md" direction={"column"}>
          {menuItemList}
        </Flex>
      </Flex>
    );
  });

  //   return (
  //     <Flex gap="lg" direction={"column"} key={menuCategoryItem.id}>
  //       <Text weight={700} size={22}>
  //         {menuCategoryItem.name}
  //       </Text>
  //       <Flex gap="md" direction={"column"}>
  //         {menuItemList}
  //       </Flex>
  //     </Flex>
  //   );
  // });

  return (
    <>
      <NavigationText
        arrow={{
          isShow: true,
        }}
        title={pageName || `Корзина доставки`}
        subtitle={pageDescription || ""}
      ></NavigationText>
      {deliveryCartList.length !== 0 && (
        <>
          {cardsList}
          <Divider my="sm" mb={"xl"} mt={"xl"} />

          <Flex direction={"column"}>
            <Text weight={400} size={16} c={EColors.dark}>
              Total
              {t("Total")}
            </Text>
            <Text weight={700} size={34} c={EColors.gray_1}>
              {`${summaryCartPrice} ${ECurrency.TRY}`}
            </Text>
          </Flex>
        </>
      )}

      <Button color="violet" disabled={!deliveryCartList.length} radius="lg" size="lg" onClick={toOrderPage}>
        Proceed
      </Button>
      <NavigationBar></NavigationBar>
    </>
  );
};
