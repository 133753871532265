import { externalApis } from "./definitions";

export const reverseGeocoder = async (lat: number, lon: number, local = "en") => {
  const response = await fetch(
    `${externalApis.nominatimOpenstreetmap}/reverse?format=json&lat=${lat}&lon=${lon}&accept-language=${local}&countrycodes=tr`
  );
  const data = await response.json();
  return data.display_name;
};

function debounce<T extends (...args: any[]) => Promise<any>>(
  func: T,
  wait: number
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let timeout: ReturnType<typeof setTimeout>;
  return function (...args: Parameters<T>): Promise<ReturnType<T>> {
    return new Promise((resolve, reject) => {
      clearTimeout(timeout);
      timeout = setTimeout(async () => {
        try {
          const result = await func(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, wait);
    });
  };
}

export const debouncedReverseGeocoder = debounce(reverseGeocoder, 500);
