import { externalApis } from "../../../externalAPIs/definitions";

export const fetchLocation = async (currentAddress: string, local = "en") => {
  const response = await fetch(
    `${externalApis.nominatimOpenstreetmap}/search?format=json&q=${currentAddress}&countrycodes=tr`,
    {
      headers: {
        "Accept-Language": local,
      },
    }
  );
  const data = await response.json();

  return data;
};
