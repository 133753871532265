import { useEffect, useRef } from "react";
import L, { LatLngTuple } from "leaflet";
import "leaflet-routing-machine";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, useMap } from "react-leaflet";

import { Route } from "@gravity-ui/icons";
import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import "./leaflet.css";
import { externalApis } from "../../../externalAPIs/definitions";
import { markerDefault } from "../constants";
import { finishMarker, startMarker } from "../PointPickerMap/PointPickerMap";

const RoutingMachine = ({
  fromLocation,
  toLocation,
  returnDistance,
  returnDuration,
  withNotification,
}: {
  fromLocation: LatLngTuple;
  toLocation: LatLngTuple;
  returnDistance: (distance: number) => void;
  returnDuration?: (duration: number) => void;
  withNotification?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation(["transfer_page"]);

  const map = useMap();
  const routingRef = useRef<L.Routing.Control | null>(null);
  useEffect(() => {
    if (map && routingRef.current) {
      map.removeControl(routingRef.current);
    }

    if (!(fromLocation[0] || fromLocation[1] || toLocation[0] || toLocation[1])) {
      return;
    }
    const routing = L.Routing.control({
      plan: L.Routing.plan([L.latLng(fromLocation[0], fromLocation[1]), L.latLng(toLocation[0], toLocation[1])], {
        draggableWaypoints: false,
        createMarker(i, waypoint, n) {
          if (i === 0) {
            // Начальная точка
            return L.marker(waypoint.latLng, { icon: startMarker });
          } else if (i === n - 1) {
            // Конечная точка
            return L.marker(waypoint.latLng, { icon: finishMarker });
          } else {
            return L.marker(waypoint.latLng);
          }
        },
      }),
      router: L.Routing.mapbox(
        "pk.eyJ1IjoibWF4Ym9ibyIsImEiOiJjbGhmZTd6bnAxdWpmM3JudTEyaWUwdGFpIn0.5Bn6DTNUUhZzcTDzHxswCw",
        {}
      ),
    });
    routing.on("routesfound", function (e: any) {
      routing.hide();
      const route = e.routes[0];
      returnDistance(route.summary.totalDistance);
      if (returnDuration) {
        returnDuration(route.summary.totalTime);
      }
      if (withNotification) {
        notifications.show({
          message: t("Route was successfully built"),
          autoClose: 5000,
          color: "green",
          icon: <Route />,
        });
      }
    });

    routing.on("routingerror", function (e) {
      console.error("Ошибка построения маршрута: ", e);
    });

    routingRef.current = routing;

    map.addControl(routing);
    return () => {
      // Удаляем контроль маршрута при размонтировании компонента
      if (map && routingRef.current) {
        map.removeControl(routingRef.current);
      }
    };
  }, [fromLocation, toLocation]);

  return null;
};

export const RouteByPointsMap = ({
  fromLocation,
  toLocation,
  getDistance,
  getDuration,
  withNotification,
}: {
  fromLocation: LatLngTuple;
  toLocation: LatLngTuple;
  getDistance: (distance: number) => void;
  getDuration?: (duration: number) => void;
  withNotification: boolean;
}) => {
  const customIcon = L.icon(markerDefault);
  L.Marker.prototype.options.icon = customIcon;

  return (
    <MapContainer
      id="mapId"
      center={[36.9081, 30.6956]}
      zoom={13}
      style={{
        height: "50vh",
        width: "100%",
        borderRadius: "16px",
      }}
      attributionControl={false}
    >
      <TileLayer url={externalApis.mapbox} id="mapbox/streets-v11" />
      <RoutingMachine
        fromLocation={fromLocation}
        toLocation={toLocation}
        returnDistance={getDistance}
        returnDuration={getDuration}
        withNotification={withNotification}
      />
    </MapContainer>
  );
};
