import { Badge } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { BaseCard } from "../../components/BaseCard/BaseCard";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { NavigationText } from "../../components/NavigationText/NavigationText";
import { LoaderPageCustom } from "../../components/UI/Vendors/Mantine";
import { RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import fetchYachts from "./api";
import { normalizeYachts } from "./definitions";

export const YachtsPage = ({ pageName, pageDescription }: { pageName?: string; pageDescription?: string }) => {
  // TODO: сделать рабочий фильтр
  const filters = ["Turkish", "European", "fast food", "coffee"];

  const { initData } = useTelegram();

  const navigate = useNavigate();

  const navigateToYacht = useFn((card: { id: string }) => {
    navigate(RouterPath.yachts.itemById(card.id));
  });

  const yachtsQuery = useQuery(["yachtsList", initData], fetchYachts, {
    onError: (error) => {
      console.error(error);
    },
  });

  if (yachtsQuery.isLoading || !yachtsQuery.data) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }

  const yachtsList = normalizeYachts(yachtsQuery.data!);

  const filterList = filters.map((filter) => {
    return {
      itemName: filter,
      itemJSX: (
        <Badge
          pt={1}
          key={`filter_${filter}`}
          className="BaseCArd__tag"
          color="grape"
          radius="lg"
          size="lg"
          variant="outline"
        >
          {" "}
          {`${filter}`}
        </Badge>
      ),
    };
  });

  const cardsList = yachtsList.map((card) => {
    return (
      <BaseCard
        key={card.id}
        card={card}
        onCardClick={() => {
          navigateToYacht(card);
        }}
      ></BaseCard>
    );
  });

  return (
    <>
      <NavigationText
        arrow={{
          isShow: true,
        }}
        filterMenu={{
          isShow: true,
          dropdownItems: filterList,
        }}
        title={pageName || `Yacht Charters`}
        subtitle={pageDescription || ""}
      ></NavigationText>
      {cardsList}
      <NavigationBar></NavigationBar>
    </>
  );
};
