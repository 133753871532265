/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSendSupportMessagePostRequest
 */
export interface UserSendSupportMessagePostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserSendSupportMessagePostRequest
     */
    initData: string;
    /**
     * 
     * @type {string}
     * @memberof UserSendSupportMessagePostRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserSendSupportMessagePostRequest
     */
    userCity: string;
    /**
     * 
     * @type {string}
     * @memberof UserSendSupportMessagePostRequest
     */
    appName: string;
    /**
     * 
     * @type {string}
     * @memberof UserSendSupportMessagePostRequest
     */
    tgName: string;
    /**
     * 
     * @type {string}
     * @memberof UserSendSupportMessagePostRequest
     */
    contact: string;
    /**
     * 
     * @type {string}
     * @memberof UserSendSupportMessagePostRequest
     */
    message: string;
}

/**
 * Check if a given object implements the UserSendSupportMessagePostRequest interface.
 */
export function instanceOfUserSendSupportMessagePostRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "initData" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "userCity" in value;
    isInstance = isInstance && "appName" in value;
    isInstance = isInstance && "tgName" in value;
    isInstance = isInstance && "contact" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function UserSendSupportMessagePostRequestFromJSON(json: any): UserSendSupportMessagePostRequest {
    return UserSendSupportMessagePostRequestFromJSONTyped(json, false);
}

export function UserSendSupportMessagePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSendSupportMessagePostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initData': json['initData'],
        'userId': json['userId'],
        'userCity': json['userCity'],
        'appName': json['appName'],
        'tgName': json['tgName'],
        'contact': json['contact'],
        'message': json['message'],
    };
}

export function UserSendSupportMessagePostRequestToJSON(value?: UserSendSupportMessagePostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initData': value.initData,
        'userId': value.userId,
        'userCity': value.userCity,
        'appName': value.appName,
        'tgName': value.tgName,
        'contact': value.contact,
        'message': value.message,
    };
}

