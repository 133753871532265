import { Button, Divider, Flex, Group, Radio, Text } from "@mantine/core";

import { useTranslation } from "react-i18next";
import { CircleCheck } from "@gravity-ui/icons";
import { notifications } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { NavigationText } from "../../components/NavigationText/NavigationText";

import { EContactType, ExcursionOrder, FoodOrder, SeaTripOrder, Transfer } from "../../client";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { NumberInputCustom, SelectCustom, TextAreaCustom, TextInputCustom } from "../../components/UI/Vendors/Mantine";
import { EColors, RouterPath, TRANSFERCLASS } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import { ClearCartAction } from "../../store/deliveryCartStore";
import { UpsertExcursionOrderAction } from "../../store/orders/excursionOrders";
import { UpsertSeaTripOrderAction } from "../../store/orders/seaTripOrders";
import { UpsertTransferOrderAction } from "../../store/orders/transferOrders";
import { selectCurrentUser } from "../../store/userStore";
import { createExcursionOrderQuery } from "./api/excursions";
import { createFoodOrderQuery } from "./api/restaurants";
import { createSeaTripOrderQuery } from "./api/seaTrips";
import { createTransferOrderQuery } from "./api/transfers";
import {
  EOrderPageIsType,
  isExcursionOrder,
  isFoodOrder,
  isSeaTripOrder,
  isTransferOrder,
  OrderPageLocationState,
  TOrderFormValues,
} from "./definitions";
import { useOrderForm } from "./hooks/useOrderForm";
import { createExcursionOrder, createFoodOrder, createSeaTripOrder, createTransferOrder } from "./utils";
import { reachGoalExcursionSubmit, reachGoalSubmitSeaTripOrder, reachGoalTransferSubmit } from "../../utils/metrika";
import { HelpTooltip } from "../../components/HelpTooltip";

export const OrderPage = () => {
  const navigate = useNavigate();

  const { initData } = useTelegram();

  const location = useLocation();
  const locationState = location.state as OrderPageLocationState;

  const dispatch = useDispatch();

  const { t } = useTranslation(["order_page"]);

  const currentUser = useSelector(selectCurrentUser);

  const validateForm = useFn(() => {
    if (!form.isValid()) {
      window.scrollTo(0, 0);
    }
  });

  const [date, setDate] = useState<Date | null>(null);

  const toCongratulationPage = useFn(() => {
    navigate(RouterPath.information.index, {
      state: {
        message: t("Dear customer, your request has been received and successfully created."),
      },
    });
  });

  const onSuccess = useFn(async () => {
    notifications.show({
      message: t("You order created"),
      autoClose: 6000,
      color: "green",
      icon: <CircleCheck />,
    });

    toCongratulationPage();
  });

  const createFoodOrderMutation = useMutation({
    mutationFn: ([foodOrder, initData]: [FoodOrder, string]) => {
      return createFoodOrderQuery(foodOrder, initData);
    },
    onSuccess,
  });

  const createTransferOrderMutation = useMutation({
    mutationFn: ([transferOrder, initData]: [Transfer, string]) => {
      return createTransferOrderQuery(transferOrder, initData);
    },
    onSuccess,
  });

  const createExcursionOrderMutation = useMutation({
    mutationFn: ([excursionOrder, initData]: [ExcursionOrder, string]) => {
      return createExcursionOrderQuery(excursionOrder, initData);
    },
    onSuccess,
  });

  const createSeaTripOrderMutation = useMutation({
    mutationFn: ([seaTripOrder, initData]: [SeaTripOrder, string]) => {
      return createSeaTripOrderQuery(seaTripOrder, initData);
    },
    onSuccess,
  });

  const form = useOrderForm(currentUser, locationState);

  const formSubmit = useFn((values: TOrderFormValues, event) => {
    if (locationState.type === EOrderPageIsType.IS_EXCURSION && isExcursionOrder(locationState.data)) {
      reachGoalExcursionSubmit();
      const order = createExcursionOrder(currentUser, values, locationState.data);
      createExcursionOrderMutation.mutate([order, initData]);
      dispatch(UpsertExcursionOrderAction(order));
    } else if (locationState.type === EOrderPageIsType.IS_SEA_TRIP && isSeaTripOrder(locationState.data)) {
      reachGoalSubmitSeaTripOrder();
      const order = createSeaTripOrder(currentUser, values, locationState.data);
      createSeaTripOrderMutation.mutate([order, initData]);
      dispatch(UpsertSeaTripOrderAction(order));
    } else if (locationState.type === EOrderPageIsType.IS_FOOD_DELIVERY && isFoodOrder(locationState.data)) {
      const order = createFoodOrder(currentUser, values, locationState.data);
      createFoodOrderMutation.mutate([order, initData]);
      dispatch(ClearCartAction());
    } else if (locationState.type === EOrderPageIsType.IS_TRANSFER && isTransferOrder(locationState.data)) {
      reachGoalTransferSubmit();
      const order = createTransferOrder(currentUser, values, locationState.data);
      createTransferOrderMutation.mutate([order, initData]);
      dispatch(UpsertTransferOrderAction(order));
    }
  });

  const isFormSubmitting = useFn(() => {
    return (
      createExcursionOrderMutation.isLoading ||
      createSeaTripOrderMutation.isLoading ||
      createFoodOrderMutation.isLoading
    );
  });

  const getContactLabel = useFn(() => {
    const value = form.getInputProps("contactType").value;
    if (value === EContactType.Telegram) {
      return "Telegram";
    } else if (value === EContactType.Whatsapp) {
      return "WhatsApp";
    } else {
      return t("Phone number");
    }
  });

  const getContactInputType = useFn(() => {
    const value = form.getInputProps("contactType").value;
    if (value === EContactType.Telegram) {
      return "text";
    } else {
      return "number";
    }
  });

  const getContactInputPlaceholder = useFn(() => {
    const value = form.getInputProps("contactType").value;
    if (value === EContactType.Telegram) {
      return t("@Your login");
    } else {
      return "+90 000 000 0000 ";
    }
  });

  const getMaxPersonsCount = useFn((type: "adult" | "kids") => {
    const adults = form.getInputProps("adultsCount").value;
    const children = form.getInputProps("childrenCount").value;
    if (locationState.type === EOrderPageIsType.IS_TRANSFER) {
      const seatsCount = TRANSFERCLASS.find((item) => {
        if ("carType" in locationState.data) {
          return item.value === locationState.data.carType;
        } else {
          return false;
        }
      })?.seatsCount;
      if (seatsCount) {
        if (type === "adult") {
          return seatsCount - children;
        } else {
          return seatsCount - adults;
        }
      }
    }
    if (!("capacity" in locationState.data)) return;

    const capacity = locationState.data.capacity;

    if (type === "adult") {
      return capacity - children;
    } else {
      return capacity - adults;
    }
  });

  const BabyItem = {
    name: "Baby Seat",
    count: 0,
    description: "",
    price: 0,
    image: "",
    category: "",
    id: "",
  };

  return (
    <>
      <NavigationText
        arrow={{
          isShow: true,
        }}
        title={t(`Back`)}
      ></NavigationText>
      <Flex direction={"column"}>
        <Text size={40} weight={700}>
          {t("Complete Order")}
        </Text>
        <form onSubmit={form.onSubmit(formSubmit)}>
          <Divider my="sm" mb={"xl"} mt={"xl"} />
          <Flex direction={"column"} gap={"md"}>
            <Radio.Group
              mt="xs"
              {...form.getInputProps("contactType")}
              label={
                <Flex className="ItemPage__header" justify="start" wrap="wrap">
                  <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
                    <Text className="mainCard__title" color={EColors.gray_1} weight={700}>
                      {t("Select your contact")}
                    </Text>
                  </Group>
                </Flex>
              }
            >
              <Flex gap={"lg"} direction={"column"}>
                <Radio color="violet" size={"lg"} value={EContactType.Telegram} label="Telegram" />
                <Radio color="violet" size={"lg"} value={EContactType.Whatsapp} label="WhatsApp" />
                <Radio color="violet" size={"lg"} value={EContactType.TelephoneNumber} label={t("Phone number")} />
              </Flex>
            </Radio.Group>
            <TextInputCustom
              label={getContactLabel()}
              type={getContactInputType()}
              placeholder={getContactInputPlaceholder()}
              {...form.getInputProps("contact")}
            ></TextInputCustom>
            {locationState.type !== EOrderPageIsType.IS_TRANSFER && (
              <TextInputCustom
                label="Address"
                placeholder="Kalkan, Istiklal"
                {...form.getInputProps("address")}
              ></TextInputCustom>
            )}
          </Flex>
          <Divider my="sm" mb={"xl"} mt={"xl"} />
          {locationState.type !== EOrderPageIsType.IS_FOOD_DELIVERY && (
            <Flex direction={"column"} gap={"md"}>
              <Flex className="ItemPage__header" justify="start" wrap="wrap">
                <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
                  <Text className="mainCard__title" color={EColors.gray_1} weight={700}>
                    {t("People")}
                  </Text>
                </Group>
              </Flex>
              <Flex gap="md">
                <NumberInputCustom
                  withAsterisk={true}
                  placeholder={t("count")}
                  max={getMaxPersonsCount("adult")}
                  label={t("Adults")}
                  {...form.getInputProps("adultsCount")}
                />
                <NumberInputCustom
                  // withAsterisk={true}
                  placeholder={t("count")}
                  label={t("Children")}
                  max={getMaxPersonsCount("kids")}
                  {...form.getInputProps("childrenCount")}
                />
              </Flex>
            </Flex>
          )}
          {locationState.type === EOrderPageIsType.IS_TRANSFER && (
            <>
              <Divider my="sm" mb={"xl"} mt={"xl"} />
              <Flex direction={"column"} gap={"md"}>
                <Flex className="ItemPage__header" justify="start" wrap="wrap">
                  <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
                    <Text className="mainCard__title" color={EColors.gray_1} weight={700}>
                      {t("Additional items")}
                    </Text>
                  </Group>
                </Flex>
                <Flex gap="md">
                  <NumberInputCustom
                    placeholder={t("count")}
                    max={getMaxPersonsCount("kids")}
                    label={t("Baby seats")}
                    {...form.getInputProps("babySeatCount")}
                  />
                  <NumberInputCustom
                    placeholder={t("count")}
                    label={t("Luggage")}
                    {...form.getInputProps("luggageCount")}
                  />
                </Flex>
              </Flex>
            </>
          )}
          <Divider my="sm" mb={"xl"} mt={"xl"} />
          {/* TODO: need to shovel this swamp of conditional mud.  */}
          {/* {locationState.type === EOrderPageIsType.IS_TRANSFER && (
            <Flex direction={"column"} gap={"md"}>
              <Flex className="ItemPage__header" justify="start" wrap="wrap">
                <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
                  <Text className="mainCard__title" color={EColors.gray_1} weight={700}>
                    Payment currency
                  </Text>
                </Group>
              </Flex>
              <SelectCustom data={ECurrency} {...form.getInputProps("paymentMethod")} />
            </Flex>
          )} */}
          {locationState.type !== EOrderPageIsType.IS_TRANSFER && (
            <>
              <Flex direction={"column"} gap={"md"}>
                <Flex className="ItemPage__header" justify="start" wrap="wrap">
                  <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
                    <Text className="mainCard__title" color={EColors.gray_1} weight={700}>
                      {t("Payment method")}
                    </Text>
                  </Group>
                </Flex>
                <SelectCustom data={["Cash", "Bank card"]} {...form.getInputProps("paymentMethod")} />
              </Flex>
              <Divider my="sm" mb={"xl"} mt={"xl"} />
            </>
          )}

          {locationState.type === EOrderPageIsType.IS_TRANSFER && (
            <>
              <Flex direction={"column"} gap={"md"}>
                <Flex className="ItemPage__header" justify="start" wrap="wrap">
                  <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
                    <Text className="mainCard__title" color={EColors.gray_1} weight={700}>
                      {t("Passenger names")}
                    </Text>
                  </Group>
                </Flex>
                <TextAreaCustom
                  withAsterisk={true}
                  placeholder={t("Surname Patronymic Name, ...")}
                  label={t("Please enter the names of the passengers")}
                  {...form.getInputProps("passengerNames")}
                />
              </Flex>
              <Divider my="sm" mb={"xl"} mt={"xl"} />
            </>
          )}

          <Flex direction={"column"} gap={"md"}>
            <Flex className="ItemPage__header" justify="start" wrap="wrap">
              <Group className="ItemPage__text" position="apart" mt="md" mb="xs">
                <Text className="mainCard__title" color={EColors.gray_1} weight={700}>
                  {t("Comment")}
                </Text>
              </Group>
            </Flex>
            <TextAreaCustom
              placeholder={t("Any questions ?")}
              label={t("Your Message")}
              {...form.getInputProps("comment")}
            />
            {locationState.type === EOrderPageIsType.IS_TRANSFER && (
              <>
                <TextInputCustom
                  required={false}
                  placeholder="SU 2122"
                  label={t("You can enter flight number")}
                  {...form.getInputProps("flightNumber")}
                />
              </>
            )}
          </Flex>
          <Divider my="sm" mb={"xl"} mt={"xl"} />

          <Flex mt={30} direction={"column"} gap={"lg"} style={{ flex: "1 1 auto" }}>
            <Flex direction={"column"}>
              <Flex align={"center"}>
                <Text weight={400} size={16} c={EColors.dark}>
                  {t("Total")}
                </Text>
                <HelpTooltip
                  text={t(
                    "Place an order, after its confirmation, the manager will tell you how to pay for the order. Specify your preferred payment method: cash, bank card, money transfer"
                  )}
                  position={"top"}
                />
              </Flex>
              <Text weight={700} size={34} c={EColors.gray_1}>
                {`${form.getTransformedValues(form.values).price} ${locationState.data.currency}`}
              </Text>
            </Flex>
            <Flex direction={"column"} justify={"space-between"} gap={"md"}>
              <Button
                fullWidth={true}
                type="submit"
                onClick={validateForm}
                disabled={isFormSubmitting()}
                variant="filled"
                color="violet"
                radius="lg"
                size="xl"
              >
                {t("Submit Order")}
              </Button>
            </Flex>
          </Flex>
        </form>
      </Flex>
      <NavigationBar></NavigationBar>
    </>
  );
};
