/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserRegisterUserPostRequest
 */
export interface UserRegisterUserPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRegisterUserPostRequest
     */
    initData: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterUserPostRequest
     */
    userId: string;
}

/**
 * Check if a given object implements the UserRegisterUserPostRequest interface.
 */
export function instanceOfUserRegisterUserPostRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "initData" in value;
    isInstance = isInstance && "userId" in value;

    return isInstance;
}

export function UserRegisterUserPostRequestFromJSON(json: any): UserRegisterUserPostRequest {
    return UserRegisterUserPostRequestFromJSONTyped(json, false);
}

export function UserRegisterUserPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRegisterUserPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initData': json['initData'],
        'userId': json['userId'],
    };
}

export function UserRegisterUserPostRequestToJSON(value?: UserRegisterUserPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'initData': value.initData,
        'userId': value.userId,
    };
}

