/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FoodOrderUpdateStatus = {
    Created: 'CREATED',
    InDelivery: 'IN_DELIVERY',
    Resolved: 'RESOLVED',
    CancelledByUser: 'CANCELLED_BY_USER',
    CancelledByAdmin: 'CANCELLED_BY_ADMIN',
    CancelledByRestaurant: 'CANCELLED_BY_RESTAURANT'
} as const;
export type FoodOrderUpdateStatus = typeof FoodOrderUpdateStatus[keyof typeof FoodOrderUpdateStatus];


export function FoodOrderUpdateStatusFromJSON(json: any): FoodOrderUpdateStatus {
    return FoodOrderUpdateStatusFromJSONTyped(json, false);
}

export function FoodOrderUpdateStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodOrderUpdateStatus {
    return json as FoodOrderUpdateStatus;
}

export function FoodOrderUpdateStatusToJSON(value?: FoodOrderUpdateStatus | null): any {
    return value as any;
}

