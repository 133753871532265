import { Button, Flex, Group, Modal, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import { NavigationBar } from "../../components/NavigationBar/NavigationBar";
import { LoaderPageCustom } from "../../components/UI/Vendors/Mantine/LoaderPageCustom";
import { EColors, ECurrency, RouterPath } from "../../definitions";
import { useFn } from "../../hooks/useFn";
import { useTelegram } from "../../hooks/useTelegram";
import { ItemBase } from "../../modules/Items/ItemBase";
import fetchSeaTripById from "./api";
import { normalizeSeaTrip } from "./definitions";
import { EOrderPageIsType, OrderPageSeaTrip } from "../OrderPage/definitions";
import { formatDateTime, TIME_FORMAT_HUMAN } from "../../utils/date";
import { selectCurrentUser } from "../../store/userStore";
import { SeaTripSeaTripTypeEnum } from "../../client";
import { reachGoalSeaTripDateChoose, reachGoalSeaTripsItemOpen } from "../../utils/metrika";

export const SeaTripsItemPage = () => {
  const currentUser = useSelector(selectCurrentUser);
  const { t } = useTranslation(["transfer_page"]);

  const { initData } = useTelegram();

  const { id } = useParams();

  const navigate = useNavigate();

  const toOrderPage = useFn(() => {
    navigate(RouterPath.order.complete, {
      state: {
        data: getSeaTripOrder(),
        type: EOrderPageIsType.IS_SEA_TRIP,
      },
    });
  });

  const getSeaTripOrder = useFn((): OrderPageSeaTrip => {
    return {
      userId: currentUser.id,
      contact: "",
      contactType: "TELEGRAM",
      address: currentUser.address,
      price: +seaTrip.price,
      pricePolicy: seaTrip.pricePolicy,
      currency: ECurrency.EUR,
      seaTripId: seaTrip.id,
      actualSeaTripDate: formatDateTime(selectedDate!.toString()),
      personsCount: 1,
      childrenCount: 0,
      comment: "",
      paymentMethod: "",
      capacity: seaTrip.capacity,
    };
  });

  const getPrice = useFn(() => {
    if (seaTrip.pricePolicy.length) {
      return seaTrip.pricePolicy[0].price;
    } else {
      return seaTrip.price;
    }
  });

  const isExcludedDate = useFn((date: Date) => {
    if (date.getTime() < Date.now()) {
      return true;
    }

    if (seaTrip.seaTripType === SeaTripSeaTripTypeEnum.Private) {
      for (const bookedDate of seaTrip.bookedDates) {
        if (formatDateTime(date.toString(), TIME_FORMAT_HUMAN) === formatDateTime(bookedDate, TIME_FORMAT_HUMAN)) {
          return true;
        }
      }
    }

    return false;
  });

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [openedModal, { open, close }] = useDisclosure(false);

  const onDateClick = (date: Date) => {
    reachGoalSeaTripDateChoose(formatDateTime(date.toString()));
    setSelectedDate(date);
  };

  const seaTripByIdQuery = useQuery({
    queryKey: [`sea trip${id}`, initData, id],
    queryFn: fetchSeaTripById,
    onError: (error) => {
      console.error(error);
    },
    select: useFn((data) => normalizeSeaTrip(data, currentUser.language)),
  });

  if (seaTripByIdQuery.isLoading || !seaTripByIdQuery.data) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }

  reachGoalSeaTripsItemOpen(seaTripByIdQuery.data.name);

  const seaTrip = seaTripByIdQuery.data;

  const renderSeaTripsItemPart = () => {
    return (
      <>
        <Modal
          opened={openedModal}
          onClose={close}
          withCloseButton={true}
          centered
          title={seaTrip.name}
          transitionProps={{ transition: "rotate-left" }}
        >
          <ReactMarkdown children={seaTrip.description} />
        </Modal>

        <Group position="apart" mt="md" mb="xs">
          <Text weight={700} onClick={open} color="violet" underline={true}>
            {t("Read More")}
          </Text>
        </Group>
        <Flex mt={30} direction={"column"} gap={"lg"} style={{ flex: "1 1 auto" }}>
          <Flex gap={"lg"}>
            <Flex direction={"column"}>
              <Text weight={400} size={16} c={EColors.dark}>
                {t("Adult price")}
              </Text>
              <Text weight={700} size={34} c={EColors.gray_1}>
                {`${getPrice()} ${ECurrency.EUR}`}
              </Text>
            </Flex>
          </Flex>
          <Flex direction={"column"} justify={"space-between"} gap={"md"}>
            <DatePickerInput
              dropdownType="modal"
              placeholder="Date"
              value={selectedDate}
              excludeDate={isExcludedDate}
              onChange={onDateClick}
              color="violet"
              radius="lg"
              size="lg"
              lang={"ru"}
              c={EColors.violet}
              styles={(theme) => ({
                input: {
                  "&": {
                    borderColor: theme.colors.violet[7],
                    color: theme.colors.violet[7],
                    wordBreak: "keep-all",
                  },
                  "&:focus": {
                    borderColor: theme.colors.violet[7],
                  },
                },
              })}
              // rightSection={<Calendar></Calendar>}
              style={{ color: EColors.violet }}
            />
            <Button
              fullWidth={true}
              disabled={!selectedDate}
              onClick={toOrderPage}
              variant="filled"
              color="violet"
              radius="lg"
              size="xl"
            >
              {t("Next step")}
            </Button>
          </Flex>
        </Flex>
      </>
    );
  };
  return (
    <>
      <ItemBase optionItem={{ ...seaTripByIdQuery.data, count: 0 }} children={renderSeaTripsItemPart()}></ItemBase>
      <NavigationBar></NavigationBar>
    </>
  );
};
