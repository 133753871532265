import { BASE_PATH, YachtRoute } from "../../client";

interface IYachtRouteLookup extends YachtRoute {
  image: string;
  name: string;
}

export type TYachtRouteLookup = Omit<IYachtRouteLookup, "price"> & { price: number };

export function normalizeYacht(yachts: YachtRoute[]): TYachtRouteLookup[] {
  return yachts.map((yacht) => {
    const imagesPaths = yacht.images!.map((image) => {
      return `${BASE_PATH}/${image}`;
    });
    return {
      ...yacht,
      name: yacht.title ? yacht.title : "no name",
      image: `${BASE_PATH}/${yacht.images ? yacht.images[0] : "no photo"}`,
      price: yacht.price ? yacht.price : 0,
      images: imagesPaths,
    };
  });
}
