import { BeachImage } from "../images/BeachImage";
import { BoatImage } from "../images/BoatImage";
import { CharterImage } from "../images/CharterImage";
import { PassportImage } from "../images/PassportImage";
import { WalletImage } from "../images/WalletImage";
import { TelegramAuthImage } from "../images/TelegramAuthImage";

export const constantSteps = [
  {
    title: "Lets’s explore \n Turkey together!",
    label: "Discovering the treasures of Turkey with this Telegram application",
    image: <PassportImage></PassportImage>,
  },
  {
    title: "Choose among trusted travel service providers",
    label: "We've put together the best deals with easy navigation through the cities",
    image: <CharterImage></CharterImage>,
  },
  {
    title: "Book services in advanced or already on site",
    label: "Explore ratings and descriptions, see photos, compare prices",
    image: <BoatImage></BoatImage>,
  },
  {
    title: "See prices in different currencies",
    label: "Pay with cash in dollars, euros, pounds and lira or by card transfer",
    image: <WalletImage></WalletImage>,
  },
  {
    isTelegramAuth: true,
    title: "Please log in to continue",
    label: "Only Telegram users can use the app at the moment",
    subLabel: "We are already working on adding new authorization methods",
    image: <TelegramAuthImage></TelegramAuthImage>,
  },
  {
    isLast: true,
    title: "Kalkan, Turkey",
    label: "Is it your current location? You can always switch the city in the settings later.",
    image: <BeachImage></BeachImage>,
  },
];
