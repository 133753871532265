import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SeaTripOrder } from "../../client";

type TSeaTripOrdersState = {
  orders: SeaTripOrder[];
};

type TAddOrderAction = PayloadAction<SeaTripOrder>;
type TOrderRemoveAction = PayloadAction<{ id: string }>;
type TRemoveOrderAction = TOrderRemoveAction & { payload: { seaTripId: string } };
type TUpdateOrderAction = PayloadAction<SeaTripOrder>;

function getInitialState(): TSeaTripOrdersState {
  return {
    orders: [],
  };
}

const seaTripOrdersSlice = createSlice({
  name: "seaTripOrder",
  initialState: getInitialState(),
  reducers: {
    AddOrderAction: (state, action: TAddOrderAction) => {
      state.orders.push(action.payload);
    },
    RemoveOrderAction: (state, action: TRemoveOrderAction) => {
      const { seaTripId } = action.payload;
      const orderIndex = state.orders.findIndex((order) => order.seaTripId === seaTripId);

      if (orderIndex) {
        state.orders = state.orders.filter((order) => order.seaTripId !== seaTripId);
      }
    },
    UpsertOrderAction: (state, action: TUpdateOrderAction) => {
      const { seaTripId } = action.payload;

      const orderIndex = state.orders.findIndex((order) => order.seaTripId === seaTripId);
      if (orderIndex !== -1) {
        state.orders[orderIndex] = action.payload;
      } else {
        state.orders.push(action.payload);
      }
    },
    ClearOrdersAction: (state) => {
      state.orders = [];
    },
  },
});

export function selectSeaTripOrdersCount(state: { orders: TSeaTripOrdersState }) {
  return state.orders.orders.length;
}

const { AddOrderAction, RemoveOrderAction, UpsertOrderAction } = seaTripOrdersSlice.actions;

export { AddOrderAction as AddSeaTripOrderAction };
export { RemoveOrderAction as RemoveSeaTripOrderAction };
export { UpsertOrderAction as UpsertSeaTripOrderAction };

export const seaTripsOrdersReducer = seaTripOrdersSlice.reducer;
