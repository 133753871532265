import { transfersApi } from "../../../apiDrivers/transfers";
import { TransferStatus } from "../../../client";

export const fetchTransferById = async ({ queryKey }: any) => {
  const initData = queryKey[1];
  const transferId = queryKey[2];

  const res = await transfersApi.transferTransferByIdTransferIdGet({
    initData,
    transferId,
  });
  return res.transferOrder;
};

export const updateTransferStatusRequest = async (
  transferId: string,
  userName: string,
  status: TransferStatus,
  initData: string
) => {
  const res = await transfersApi.transferSetOrderStatusPost({
    transferSetOrderStatusPostRequest: {
      userName,
      status,
      initData,
      transferId,
    },
  });

  return res;
};
