/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ECity } from './ECity';
import {
    ECityFromJSON,
    ECityFromJSONTyped,
    ECityToJSON,
} from './ECity';
import type { Sight } from './Sight';
import {
    SightFromJSON,
    SightFromJSONTyped,
    SightToJSON,
} from './Sight';

/**
 * 
 * @export
 * @interface YachtRoute
 */
export interface YachtRoute {
    /**
     * The overall rating of the yacht route
     * @type {number}
     * @memberof YachtRoute
     */
    rating?: number;
    /**
     * The duration of the yacht route
     * @type {string}
     * @memberof YachtRoute
     */
    duration?: string;
    /**
     * Available discount for the yacht route
     * @type {number}
     * @memberof YachtRoute
     */
    discount?: number;
    /**
     * Price of the yacht route
     * @type {number}
     * @memberof YachtRoute
     */
    price?: number;
    /**
     * Deposit required for the yacht route
     * @type {number}
     * @memberof YachtRoute
     */
    deposit?: number;
    /**
     * A list of URLs to images of the yacht route
     * @type {Array<string>}
     * @memberof YachtRoute
     */
    images?: Array<string>;
    /**
     * What is not included in the yacht route package
     * @type {Array<string>}
     * @memberof YachtRoute
     */
    notIncluded?: Array<string>;
    /**
     * What is included in the yacht route package
     * @type {Array<string>}
     * @memberof YachtRoute
     */
    included?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof YachtRoute
     */
    yachtId?: string;
    /**
     * The title of the yacht route
     * @type {string}
     * @memberof YachtRoute
     */
    title?: string;
    /**
     * A detailed description of the yacht route
     * @type {string}
     * @memberof YachtRoute
     */
    description?: string;
    /**
     * The company offering the yacht route
     * @type {string}
     * @memberof YachtRoute
     */
    company?: string;
    /**
     * Languages spoken by the staff during the yacht route
     * @type {Array<string>}
     * @memberof YachtRoute
     */
    staffLanguages?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof YachtRoute
     */
    onlyCharterWeek?: boolean;
    /**
     * The date when the yacht route was created
     * @type {Date}
     * @memberof YachtRoute
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof YachtRoute
     */
    referentName?: string;
    /**
     * Whether the yacht route is currently active
     * @type {boolean}
     * @memberof YachtRoute
     */
    isActive?: boolean;
    /**
     * Unique identifier for the yacht route
     * @type {string}
     * @memberof YachtRoute
     */
    id?: string;
    /**
     * 
     * @type {ECity}
     * @memberof YachtRoute
     */
    departureCity?: ECity;
    /**
     * Whether the yacht route includes a captain
     * @type {boolean}
     * @memberof YachtRoute
     */
    hasCaptain?: boolean;
    /**
     * Services available on board during the yacht route
     * @type {Array<string>}
     * @memberof YachtRoute
     */
    serviceOnBoard?: Array<string>;
    /**
     * Facilities available during the yacht route
     * @type {Array<string>}
     * @memberof YachtRoute
     */
    facilities?: Array<string>;
    /**
     * The start date of the yacht route season
     * @type {string}
     * @memberof YachtRoute
     */
    startSeasonDate?: string;
    /**
     * The end date of the yacht route season
     * @type {string}
     * @memberof YachtRoute
     */
    endSeasonDate?: string;
    /**
     * 
     * @type {Array<Sight>}
     * @memberof YachtRoute
     */
    sights?: Array<Sight>;
}

/**
 * Check if a given object implements the YachtRoute interface.
 */
export function instanceOfYachtRoute(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function YachtRouteFromJSON(json: any): YachtRoute {
    return YachtRouteFromJSONTyped(json, false);
}

export function YachtRouteFromJSONTyped(json: any, ignoreDiscriminator: boolean): YachtRoute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'deposit': !exists(json, 'deposit') ? undefined : json['deposit'],
        'images': !exists(json, 'images') ? undefined : json['images'],
        'notIncluded': !exists(json, 'notIncluded') ? undefined : json['notIncluded'],
        'included': !exists(json, 'included') ? undefined : json['included'],
        'yachtId': !exists(json, 'yachtId') ? undefined : json['yachtId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'staffLanguages': !exists(json, 'staffLanguages') ? undefined : json['staffLanguages'],
        'onlyCharterWeek': !exists(json, 'onlyCharterWeek') ? undefined : json['onlyCharterWeek'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'referentName': !exists(json, 'referentName') ? undefined : json['referentName'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'departureCity': !exists(json, 'departureCity') ? undefined : ECityFromJSON(json['departureCity']),
        'hasCaptain': !exists(json, 'hasCaptain') ? undefined : json['hasCaptain'],
        'serviceOnBoard': !exists(json, 'serviceOnBoard') ? undefined : json['serviceOnBoard'],
        'facilities': !exists(json, 'facilities') ? undefined : json['facilities'],
        'startSeasonDate': !exists(json, 'startSeasonDate') ? undefined : json['startSeasonDate'],
        'endSeasonDate': !exists(json, 'endSeasonDate') ? undefined : json['endSeasonDate'],
        'sights': !exists(json, 'sights') ? undefined : ((json['sights'] as Array<any>).map(SightFromJSON)),
    };
}

export function YachtRouteToJSON(value?: YachtRoute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rating': value.rating,
        'duration': value.duration,
        'discount': value.discount,
        'price': value.price,
        'deposit': value.deposit,
        'images': value.images,
        'notIncluded': value.notIncluded,
        'included': value.included,
        'yachtId': value.yachtId,
        'title': value.title,
        'description': value.description,
        'company': value.company,
        'staffLanguages': value.staffLanguages,
        'onlyCharterWeek': value.onlyCharterWeek,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'referentName': value.referentName,
        'isActive': value.isActive,
        'id': value.id,
        'departureCity': ECityToJSON(value.departureCity),
        'hasCaptain': value.hasCaptain,
        'serviceOnBoard': value.serviceOnBoard,
        'facilities': value.facilities,
        'startSeasonDate': value.startSeasonDate,
        'endSeasonDate': value.endSeasonDate,
        'sights': value.sights === undefined ? undefined : ((value.sights as Array<any>).map(SightToJSON)),
    };
}

