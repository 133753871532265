import { Flex } from "@mantine/core";
import { useEffect, useState } from "react";
import StepLoader from "./StepLoader";

export const LoaderPageCustom = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = 5;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentStep((prevStep) => (prevStep % totalSteps) + 1);
    }, 300);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Flex align={"center"} justify={"center"} h={"85vh"}>
      <StepLoader totalSteps={totalSteps} currentStep={currentStep} />
    </Flex>
  );
};
