/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enumerated types of boats available for charter
 * @export
 */
export const EYachtCharterBoatType = {
    Catamaran: 'CATAMARAN',
    Yacht: 'YACHT',
    Gulet: 'GULET',
    Boat: 'BOAT',
    Superyacht: 'SUPERYACHT',
    Motoryacht: 'MOTORYACHT'
} as const;
export type EYachtCharterBoatType = typeof EYachtCharterBoatType[keyof typeof EYachtCharterBoatType];


export function EYachtCharterBoatTypeFromJSON(json: any): EYachtCharterBoatType {
    return EYachtCharterBoatTypeFromJSONTyped(json, false);
}

export function EYachtCharterBoatTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EYachtCharterBoatType {
    return json as EYachtCharterBoatType;
}

export function EYachtCharterBoatTypeToJSON(value?: EYachtCharterBoatType | null): any {
    return value as any;
}

