import { BASE_PATH, Excursion, UserLanguageEnum } from "../../client";

export type ExcursionLookup = Excursion & {
  image: string;
  name: string;
  price: number;
};

export function normalizeExcursionById(excursion: Excursion, userLanguage: UserLanguageEnum): ExcursionLookup {
  const imagesPaths = excursion.images.map((image) => {
    return `${BASE_PATH}/${image}`;
  });

  return {
    id: excursion.id,
    image: `${BASE_PATH}/${excursion.images[0]}`,
    images: imagesPaths,
    name: excursion.title,
    price: excursion.priceAdult,
    shortDescription: excursion.shortDescription,
    description: excursion.description,
    priceKids: excursion.priceKids,
    priceAdult: excursion.priceAdult,
    capacity: excursion.capacity,
    city: excursion.city,
    creationDate: excursion.creationDate,
    deposit: excursion.deposit,
    discount: excursion.discount,
    duration: excursion.duration,
    excursionType: excursion.excursionType,
    // TODO: нет в типе экскурсии
    // isActive: excursion.isActive,
    isForAdultsOnly: excursion.isForAdultsOnly,
    isForChildrenOnly: excursion.isForChildrenOnly,
    language: excursion.language,
    rating: excursion.rating,
    referentName: excursion.referentName,
    workingDays: excursion.workingDays,
    title: excursion.title,
    tags: excursion.tags,
    company: excursion.company,
  };
}
