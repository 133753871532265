import { seaTripsApi } from "../../../apiDrivers/seaTrips";
import { TCard } from "../../../components/BaseCard/BaseCard";
import { BASE_PATH, EUserSeaTripStatus, SeaTripOrderLookup } from "../../../client";
import { TBadgeType } from "../../../components/badges/Badge";
import { DATE_FORMAT, formatDateTime, TIME_FORMAT_HUMAN_NO_SEC } from "../../../utils/date";
import { ECurrency } from "../../../definitions";

export const fetchUserSeaTripsOrdersList = async ({ queryKey }: any) => {
  const initData = queryKey[1];
  const userId = queryKey[2];

  const res = await seaTripsApi.seaTripsUserSeaTripsOrdersUserIdGet({ initData, userId });

  return res.userSeaTripOrdersLookups;
};

export type TNormalizedSeaTripOrderLookup = TCard;

export const mapSeaTripOrderStatusToBadgeType: Record<EUserSeaTripStatus, TBadgeType> = {
  [EUserSeaTripStatus.Created]: "Booked",
  [EUserSeaTripStatus.Approved]: "Approved",
  [EUserSeaTripStatus.Completed]: "Completed",
  [EUserSeaTripStatus.CancelledByUser]: "Cancelled",
  [EUserSeaTripStatus.CancelledByAdmin]: "Cancelled",
};

export function normalizeSeaTripOrder(
  seaTripsOrders: SeaTripOrderLookup[] | undefined
): TNormalizedSeaTripOrderLookup[] {
  if (!seaTripsOrders) {
    return [];
  }

  return seaTripsOrders.map((order) => {
    return {
      id: order.id,
      title: order.seaTrip.title,
      badgeType: mapSeaTripOrderStatusToBadgeType[order.status],
      price: order.price,
      currency: ECurrency.EUR,
      status: mapSeaTripOrderStatusToBadgeType[order.status],
      image: `${BASE_PATH}/${order.seaTrip.images[0]}`,
      description: order.seaTrip.city,
      labels: [
        { iconType: "Clock", text: formatDateTime(order.creationDate, TIME_FORMAT_HUMAN_NO_SEC) },
        { iconType: "Done", text: formatDateTime(order.actualSeaTripDate.slice(0, -5), DATE_FORMAT) },
      ],
    };
  });
}
