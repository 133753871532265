import { BASE_PATH, SeaTrip, UserLanguageEnum } from "../../client";

export type SeaTripLookup = SeaTrip & {
  image: string;
  name: string;
  bookedDates: string[];
};

export function normalizeSeaTrip(
  {
    seaTrip,
    bookedDates,
  }: {
    seaTrip: SeaTrip;
    bookedDates?: string[];
  },
  userLanguage: UserLanguageEnum
): SeaTripLookup {
  const imagesPaths = seaTrip.images.map((image) => {
    return `${BASE_PATH}/${image}`;
  });

  return {
    id: seaTrip.id,
    image: `${BASE_PATH}/${seaTrip.images[0]}`,
    images: imagesPaths,
    name: seaTrip.title,
    price: seaTrip.price,
    description: seaTrip.description,
    shortDescription: seaTrip.shortDescription,
    capacity: seaTrip.capacity,
    pricePolicy: seaTrip.pricePolicy,
    city: seaTrip.city,
    creationDate: seaTrip.creationDate,
    deposit: seaTrip.deposit,
    discount: seaTrip.discount,
    duration: seaTrip.duration,
    seaTripType: seaTrip.seaTripType,
    // TODO: нет в типе экскурсии
    // isActive: seaTrip.isActive,
    rating: seaTrip.rating,
    referentName: seaTrip.referentName,
    workingDays: seaTrip.workingDays,
    title: seaTrip.title,
    tags: seaTrip.tags,
    company: seaTrip.company,
    bookedDates: bookedDates ?? [],
  };
}
