/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TransferInput } from './TransferInput';
import {
    TransferInputFromJSON,
    TransferInputFromJSONTyped,
    TransferInputToJSON,
} from './TransferInput';

/**
 * 
 * @export
 * @interface TransferUpdateTransferPostRequest
 */
export interface TransferUpdateTransferPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TransferUpdateTransferPostRequest
     */
    transferId: string;
    /**
     * 
     * @type {TransferInput}
     * @memberof TransferUpdateTransferPostRequest
     */
    input: TransferInput;
}

/**
 * Check if a given object implements the TransferUpdateTransferPostRequest interface.
 */
export function instanceOfTransferUpdateTransferPostRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "transferId" in value;
    isInstance = isInstance && "input" in value;

    return isInstance;
}

export function TransferUpdateTransferPostRequestFromJSON(json: any): TransferUpdateTransferPostRequest {
    return TransferUpdateTransferPostRequestFromJSONTyped(json, false);
}

export function TransferUpdateTransferPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransferUpdateTransferPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transferId': json['transferId'],
        'input': TransferInputFromJSON(json['input']),
    };
}

export function TransferUpdateTransferPostRequestToJSON(value?: TransferUpdateTransferPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transferId': value.transferId,
        'input': TransferInputToJSON(value.input),
    };
}

