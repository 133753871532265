import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExcursionOrder } from "../../client";

type TExcursionOrdersState = {
  orders: ExcursionOrder[];
};

type TOrderRemoveAction = PayloadAction<{ id: string }>;
type TRemoveOrderAction = TOrderRemoveAction & { payload: { excursionId: string } };
type TUpdateOrderAction = PayloadAction<ExcursionOrder>;

function getInitialState(): TExcursionOrdersState {
  return {
    orders: [],
  };
}

const excursionOrdersSlice = createSlice({
  name: "excursionOrders",
  initialState: getInitialState(),
  reducers: {
    RemoveOrderAction: (state, action: TRemoveOrderAction) => {
      const { excursionId } = action.payload;
      const orderIndex = state.orders.findIndex((order) => order.excursionId === excursionId);

      if (orderIndex) {
        state.orders = state.orders.filter((order) => order.excursionId !== excursionId);
      }
    },
    UpsertOrderAction: (state, action: TUpdateOrderAction) => {
      const { excursionId } = action.payload;

      const orderIndex = state.orders.findIndex((order) => order.excursionId === excursionId);
      if (orderIndex !== -1) {
        Object.assign(state.orders[orderIndex], action.payload);
      } else {
        state.orders.push(action.payload);
      }
    },
    ClearOrdersAction: (state) => {
      state.orders = [];
    },
  },
});

const { RemoveOrderAction, UpsertOrderAction } = excursionOrdersSlice.actions;

export { RemoveOrderAction as RemoveExcursionOrderAction, UpsertOrderAction as UpsertExcursionOrderAction };

export const excursionsOrdersReducer = excursionOrdersSlice.reducer;

export function selectExcursionOrders(state: { orders: TExcursionOrdersState }) {
  return state.orders.orders;
}

export function selectExcursionOrdersCount(state: { orders: TExcursionOrdersState }) {
  return state.orders.orders.length;
}

export const selectExcursionOrderById = (orderId: string) => (state: { excursionOrders: TExcursionOrdersState }) => {
  const item = state.excursionOrders.orders.find((_item) => _item.id === orderId);
  return item;
};
