/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExcursionTag
 */
export interface ExcursionTag {
    /**
     * 
     * @type {string}
     * @memberof ExcursionTag
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ExcursionTag
     */
    name: ExcursionTagNameEnum;
}


/**
 * @export
 */
export const ExcursionTagNameEnum = {
    Historical: 'HISTORICAL',
    Nature: 'NATURE',
    Action: 'ACTION',
    Diving: 'DIVING',
    Hiking: 'HIKING',
    Extreme: 'EXTREME',
    Sport: 'SPORT'
} as const;
export type ExcursionTagNameEnum = typeof ExcursionTagNameEnum[keyof typeof ExcursionTagNameEnum];


/**
 * Check if a given object implements the ExcursionTag interface.
 */
export function instanceOfExcursionTag(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ExcursionTagFromJSON(json: any): ExcursionTag {
    return ExcursionTagFromJSONTyped(json, false);
}

export function ExcursionTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExcursionTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

export function ExcursionTagToJSON(value?: ExcursionTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}

