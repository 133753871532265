/* tslint:disable */
/* eslint-disable */
/**
 * Travolza backend api docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Restaurant } from './Restaurant';
import {
    RestaurantFromJSON,
    RestaurantFromJSONTyped,
    RestaurantToJSON,
} from './Restaurant';

/**
 * 
 * @export
 * @interface FoodRestaurantsListGet200Response
 */
export interface FoodRestaurantsListGet200Response {
    /**
     * 
     * @type {Array<Restaurant>}
     * @memberof FoodRestaurantsListGet200Response
     */
    restaurantsList?: Array<Restaurant>;
}

/**
 * Check if a given object implements the FoodRestaurantsListGet200Response interface.
 */
export function instanceOfFoodRestaurantsListGet200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FoodRestaurantsListGet200ResponseFromJSON(json: any): FoodRestaurantsListGet200Response {
    return FoodRestaurantsListGet200ResponseFromJSONTyped(json, false);
}

export function FoodRestaurantsListGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodRestaurantsListGet200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'restaurantsList': !exists(json, 'restaurantsList') ? undefined : ((json['restaurantsList'] as Array<any>).map(RestaurantFromJSON)),
    };
}

export function FoodRestaurantsListGet200ResponseToJSON(value?: FoodRestaurantsListGet200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'restaurantsList': value.restaurantsList === undefined ? undefined : ((value.restaurantsList as Array<any>).map(RestaurantToJSON)),
    };
}

