import { BASE_PATH, Restaurant } from "../../client";

export type TRestaurantCardLookup = {
  id: string;
  time: string;
  image: string;
  title: string;
  address: string;
  route: string;
  tags: { id: string; name: string }[];
  deliveryPrice?: number;
  deliveryPriceThreshold?: number;
};

export function normalizeRestaurants(restaurants: Restaurant[]): TRestaurantCardLookup[] {
  return restaurants.map((restaurant) => {
    return {
      id: restaurant.id,
      time: restaurant.onlyTakeAway ? "Takeaway" : restaurant.deliveryTime,
      image: `${BASE_PATH}/${restaurant.images[0]}`,
      title: restaurant.name,
      address: restaurant.address,
      route: restaurant.id,
      tags: restaurant.tags,
      deliveryPrice: restaurant.deliveryPrice,
      deliveryPriceThreshold: restaurant.deliveryPriceThreshold,
      googleMapsUrl: restaurant.googleMapsUrl,
    };
  });
}
