import { Button, Flex } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { CircleCheck } from "@gravity-ui/icons";
import { useMutation } from "@tanstack/react-query";
import { useInputState } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavigationBar } from "../../../components/NavigationBar/NavigationBar";
import { NavigationText } from "../../../components/NavigationText/NavigationText";
import { TextInputCustom } from "../../../components/UI/Vendors/Mantine/TextInputCustom";
import { useFn } from "../../../hooks/useFn";
import { sendSupportMessageRequest } from "./api";
import { ECity } from "../../../client";
import { useTelegram } from "../../../hooks/useTelegram";
import { selectCurrentUser } from "../../../store/userStore";
import { TextAreaCustom } from "../../../components/UI/Vendors/Mantine";

export const SettingsSupport = () => {
  const { initData } = useTelegram();

  const currentUser = useSelector(selectCurrentUser);

  const { t } = useTranslation(["settings"]);

  const titlePage = t("Support");
  const subtitle = "";

  const [message, setMessage] = useInputState("");
  const [contact, setContact] = useInputState(currentUser.tgName ?? "");

  const onSuccess = useFn(async () => {
    notifications.show({
      message: t("Your message sent"),
      autoClose: 6000,
      color: "green",
      icon: <CircleCheck />,
    });
  });

  const sendSupportMessageMutation = useMutation({
    mutationFn: ([initDate, userId, tgName, userCity, message, appName, contact]: [
      string,
      string,
      string,
      ECity,
      string,
      string,
      string
    ]) => {
      return sendSupportMessageRequest(initDate, userId, tgName, userCity, message, appName, contact);
    },
    onSuccess,
    onError: (error) => {
      console.error(error);
    },
  });

  const onSubmit = useFn(() => {
    sendSupportMessageMutation.mutate([
      initData,
      currentUser.id,
      currentUser.tgName,
      currentUser.city,
      message,
      currentUser.appName,
      contact,
    ]);

    setMessage("");
  });

  return (
    <>
      <NavigationText
        arrow={{
          isShow: true,
        }}
        title={titlePage}
        subtitle={subtitle}
      ></NavigationText>

      <>
        <TextInputCustom
          value={contact}
          onChange={setContact}
          label={"WhatsApp / Telegram"}
          placeholder={"+90 (534) 123-45-67"}
        ></TextInputCustom>
        <TextAreaCustom
          value={message}
          onChange={setMessage}
          placeholder={t("Type your problem here ")}
          label={t("Your Message")}
        />
        <Flex direction={"column"} justify={"space-between"} gap={"md"}>
          <Button
            fullWidth={true}
            disabled={!contact || !message}
            onClick={onSubmit}
            variant="filled"
            color="violet"
            radius="lg"
            size="xl"
          >
            {t("Submit a message")}
          </Button>
        </Flex>
      </>
      <NavigationBar></NavigationBar>
    </>
  );
};
