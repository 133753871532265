import { NumberInput, NumberInputProps } from "@mantine/core";

export const NumberInputCustom = ({
  placeholder,
  defaultValue,
  label,
  disabled,
  withAsterisk = false,
  ...rest
}: {
  placeholder: string;
  defaultValue?: number;
  withAsterisk?: boolean;
  label: string;
  disabled?: boolean;
} & NumberInputProps) => {
  return (
    <NumberInput
      {...rest}
      readOnly={disabled}
      defaultValue={defaultValue}
      placeholder={placeholder}
      noClampOnBlur={false}
      label={label}
      withAsterisk={withAsterisk}
      size={"lg"}
      radius={"lg"}
      min={0}
      styles={(theme) => ({
        input: {
          "&:focus-within": {
            borderColor: theme.colors.violet[9],
          },
        },
        item: {
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: theme.colors.violet[0],
              color: theme.colors.violet[9],
            },
          },
          "&[data-hovered]": {},
        },
      })}
    />
  );
};
