import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { OrderCompletedBase } from "../../../modules/orders/OrderCompletedBase";
import { ECurrency } from "../../../definitions";
import { useTelegram } from "../../../hooks/useTelegram";
import { selectCurrentUser } from "../../../store/userStore";
import { useFn } from "../../../hooks/useFn";
import { fetchSeaTripOrderById, updateSeaTripOrderStatusRequest } from "./api";
import { LoaderPageCustom } from "../../../components/UI/Vendors/Mantine/LoaderPageCustom";
import { BASE_PATH, EUserSeaTripStatus, ExcursionOrderUpdateStatus } from "../../../client";
import { mapSeaTripOrderStatusToBadgeType } from "../../OrderListPage/api/seaTripOrders";
import { useSuccessStatusChanged } from "../common";

export const OrderSeaTripCompletedPage = () => {
  const { initData } = useTelegram();

  const currentUser = useSelector(selectCurrentUser);

  const { id } = useParams();

  const seaTripOrderQuery = useQuery({
    queryKey: [`${currentUser.id} sea trip order ${id}`, initData, id],
    queryFn: fetchSeaTripOrderById,
    onError: (error) => {
      console.log(error);
    },
  });

  const { onSuccessCancel } = useSuccessStatusChanged();

  const updateSeaTripOrderStatusMutation = useMutation({
    mutationFn: ([orderId, seaTripTitle, userName, status, initData]: [
      string,
      string,
      string,
      EUserSeaTripStatus,
      string
    ]) => {
      return updateSeaTripOrderStatusRequest(orderId, seaTripTitle, userName, status, initData);
    },
    onSuccess: onSuccessCancel,
    onError: (error) => {
      console.error(error);
    },
  });

  const onCancel = useFn(() => {
    updateSeaTripOrderStatusMutation.mutate([
      seaTripOrderQuery.data!.id!,
      seaTripOrderQuery.data!.seaTrip!.title,
      currentUser.tgName ? currentUser.tgName : currentUser.tgChatId,
      ExcursionOrderUpdateStatus.CancelledByUser,
      initData,
    ]);
  });

  if (!seaTripOrderQuery.data || seaTripOrderQuery.isLoading || !seaTripOrderQuery.data.seaTrip) {
    return <LoaderPageCustom></LoaderPageCustom>;
  }

  const seaTripOrder = seaTripOrderQuery.data;

  const imagesPaths = seaTripOrder.seaTrip!.images.map((image) => {
    return `${BASE_PATH}/${image}`;
  });

  return (
    <>
      <OrderCompletedBase
        description={seaTripOrder.seaTrip!.description!}
        image={imagesPaths[0]}
        images={imagesPaths}
        name={seaTripOrder.seaTrip!.title}
        price={seaTripOrder.price}
        currency={ECurrency.EUR}
        contactType={seaTripOrder.contactType}
        contactValue={seaTripOrder.contact}
        addressValue={seaTripOrder.address}
        adultsCount={seaTripOrder.personsCount}
        childrenCount={seaTripOrder.childrenCount}
        paymentMethod={seaTripOrder.paymentMethod}
        commentValue={seaTripOrder.comment}
        actualDate={seaTripOrder.actualSeaTripDate}
        creationDate={seaTripOrder.creationDate!}
        status={mapSeaTripOrderStatusToBadgeType[seaTripOrder.status!]}
        onCancel={onCancel}
      ></OrderCompletedBase>
    </>
  );
};
