import { restaurantsApi } from "../../../apiDrivers/restaurants";

const fetchRestaurantMenu = async ({ queryKey }: any) => {
  const initData = queryKey[1];
  const id = queryKey[2];
  if (!id) {
    throw new Error(`API Returns all food of restaurant use failed`);
  }

  const res = await restaurantsApi.foodRestaurantMenuRestaurantIdGet({
    initData,
    restaurantId: id,
  });

  if (!res.restaurantMenu) {
    throw new Error(`Returns all food of restaurant fetch failed`);
  }

  return res.restaurantMenu;
};

export default fetchRestaurantMenu;
