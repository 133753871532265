import { SeaTripOrder } from "../../../client";
import { seaTripsApi } from "../../../apiDrivers/seaTrips";

export const createSeaTripOrderQuery = async (seaTripOrder: SeaTripOrder, initData: string) => {
  const res = await seaTripsApi.seaTripsCreateSeaTripOrderPost({
    seaTripsCreateSeaTripOrderPostRequest: {
      seaTripOrder,
      initData,
    },
  });

  return res;
};
