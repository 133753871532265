import { TransferCarTypeEnum } from "../../../client";
import { getRegionCostIndex } from "./getRegionCostIndex";

export const calculateTransferPriceFromDistance = (
  from: string,
  to: string,
  routeDistance: number,
  carType: TransferCarTypeEnum
) => {
  let result = 0;
  let distancePricePerKm = 0;
  if (routeDistance < 30000) {
    distancePricePerKm = 60 / 1000;
    switch (carType) {
      case TransferCarTypeEnum.Sedan:
        distancePricePerKm *= 1;
        break;
      case TransferCarTypeEnum.Vip:
        distancePricePerKm *= 1.5;
        break;
      case TransferCarTypeEnum.Minivan:
        distancePricePerKm *= 1.15;
        break;
      case TransferCarTypeEnum.Minibus:
        distancePricePerKm *= 1.4;
        break;
      case TransferCarTypeEnum.Bigbus:
        distancePricePerKm *= 2;
        break;
      default:
        break;
    }
  } else if (routeDistance < 50000) {
    distancePricePerKm = 45 / 1000;
    switch (carType) {
      case TransferCarTypeEnum.Sedan:
        distancePricePerKm *= 1;
        break;
      case TransferCarTypeEnum.Vip:
        distancePricePerKm *= 1.5;
        break;
      case TransferCarTypeEnum.Minivan:
        distancePricePerKm *= 1.14;
        break;
      case TransferCarTypeEnum.Minibus:
        distancePricePerKm *= 1.6;
        break;
      case TransferCarTypeEnum.Bigbus:
        distancePricePerKm *= 2;
        break;
      default:
        break;
    }
  } else if (routeDistance < 100000) {
    distancePricePerKm = 27 / 1000;
    switch (carType) {
      case TransferCarTypeEnum.Sedan:
        distancePricePerKm *= 1;
        break;
      case TransferCarTypeEnum.Vip:
        distancePricePerKm *= 1.5;
        break;
      case TransferCarTypeEnum.Minivan:
        distancePricePerKm *= 1.13;
        break;
      case TransferCarTypeEnum.Minibus:
        distancePricePerKm *= 1.58;
        break;
      case TransferCarTypeEnum.Bigbus:
        distancePricePerKm *= 2;
        break;
      default:
        break;
    }
  } else if (routeDistance < 150000) {
    distancePricePerKm = 22 / 1000;
    switch (carType) {
      case TransferCarTypeEnum.Sedan:
        distancePricePerKm *= 1;
        break;
      case TransferCarTypeEnum.Vip:
        distancePricePerKm *= 1.5;
        break;
      case TransferCarTypeEnum.Minivan:
        distancePricePerKm *= 1.11;
        break;
      case TransferCarTypeEnum.Minibus:
        distancePricePerKm *= 1.55;
        break;
      case TransferCarTypeEnum.Bigbus:
        distancePricePerKm *= 2;
        break;
      default:
        break;
    }
  } else {
    distancePricePerKm = 16 / 1000;
    console.log("carType", carType);

    switch (carType) {
      case TransferCarTypeEnum.Sedan:
        distancePricePerKm *= 1;
        break;
      case TransferCarTypeEnum.Vip:
        distancePricePerKm *= 1.5;
        break;
      case TransferCarTypeEnum.Minivan:
        distancePricePerKm *= 1.2;
        break;
      case TransferCarTypeEnum.Minibus:
        distancePricePerKm *= 1.4;
        break;
      case TransferCarTypeEnum.Bigbus:
        distancePricePerKm *= 2;
        break;
      default:
        break;
    }
  }

  result = Math.ceil(routeDistance * distancePricePerKm);
  console.log("result", routeDistance, distancePricePerKm);

  const costIndexFrom = 1;
  // const costIndexFrom = getRegionCostIndex(from) ?? 1;
  const costIndexTo = 1;
  // const costIndexTo = getRegionCostIndex(to) ?? 1;

  const costIndex = Math.max(costIndexFrom, costIndexTo);
  return result * costIndex;
};
