import { restaurantsApi } from "../../../apiDrivers/restaurants";
import { FoodOrderUpdateStatus } from "../../../client";

export const fetchFoodOrderById = async ({ queryKey }: any) => {
  const initData = queryKey[1];
  const orderId = queryKey[2];

  const res = await restaurantsApi.foodFoodOrdersOrderIdGet({
    orderId,
    initData,
  });

  return { items: res.orderItems, orderLookup: res.orderLookup };
};

export const updateFoodnOrderStatusRequest = async (
  foodOrderId: string,
  restaurantName: string,
  restaurantId: string,
  userName: string,
  status: FoodOrderUpdateStatus,
  initData: string
) => {
  const res = await restaurantsApi.foodFoodOrdersSetOrderStatusPost({
    foodFoodOrdersSetOrderStatusPostRequest: {
      foodOrderId,
      restaurantName,
      restaurantId,
      userName,
      status,
      initData,
    },
  });

  return res;
};
